<template>
    <div style="overflow-x:auto !important">
        <div>
            <div>
                <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:5%;">
                    <div style="grid-column:1 / 1; grid-row: 1 / 1">
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px;">
                            <div class="ZAfont_family ZAfont_size">
                                <div>{{$t('3334')}}:</div>
                            </div>
                            <div>
                                <div v-if="loader_forLoading_note_Shown==true">
                                    <LoaderIcon v-bind:loaderSize="'xs'"></LoaderIcon>
                                </div>
                                <div v-else>
                                    <div v-if="((statusAt_responseOf_select_Note_from_databaseRecord_configurationOverviews.hasOwnProperty('statusCode')==false)||((statusAt_responseOf_select_Note_from_databaseRecord_configurationOverviews.hasOwnProperty('statusCode'))&&(statusAt_responseOf_select_Note_from_databaseRecord_configurationOverviews.statusCode.startsWith('2'))))">
                                        <!--<div v-if="((configuration_Modifiable==true)||((configuration_Modifiable==false)&&(configuration_CreatedByLoggedInUser==true)))">-->
                                        <div>
                                            <textarea tabindex="1" v-model="note" :placeholder="this.$i18n.i18next.t('3349')" class="my_input ZAfont_family ZAfont_size" v-bind:class="{my_input_border_at_invalidInput: ((note_ValidationResult.invalid==true)&&(note_ValidationResult.invalidityReason!=this.$invalidityReasons.notSet)), my_input_background_color_at_validInput:note_ValidationResult.invalid==false}"></textarea>
                                            <div style="line-height:100%">
                                                <InvalidityReasons v-if="((note_ValidationResult.invalid==true)&&(note_ValidationResult.invalidityReason!=this.$invalidityReasons.notSet))" v-bind:validationResult="note_ValidationResult" style="margin-top:0px !important"></InvalidityReasons>
                                                <div v-else class="ZAfont_family">{{$t('3350')}} <span class="my_font-weight_600">" , ; < > \</span></div>
                                            </div>
                                        </div>
                                        <!--<div v-else class="ZAfont_family ZAfont_size">
                                            <div v-if="note!=null">{{note}}</div>
                                            <div v-else>-</div>
                                        </div>-->
                                    </div>
                                    <div v-else class="ZAfont_family">
                                        <div>{{$t('3351')}}</div>
                                        <a @click="select_Note_from_databaseRecord_configurationOverviews" href="#">{{$t('3256')}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            <div style="overflow:auto">
                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('3033')}}</div>
                <div style="display:grid; grid-template-columns:1fr 1fr; grid-template-rows:repeat(5, auto); grid-column-gap:5%; grid-row-gap:10px; min-width: 700px">
                    <div style="grid-column:1 / 1; grid-row: 1 / 1">
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px;">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('1049')}}:</div>
                            </div>
                            <div>
                                <div class="input-group">
                                    <input tabindex="2" v-model="nominalLoad" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: nominalLoad_ValidationResult.invalid==true, my_input_background_color_at_validInput: nominalLoad_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                    <select v-model="nominalLoad_select" class="my_input_combobox_select my_select">
                                        <option v-for="nominalLoad in nominalLoad_ValueList">{{nominalLoad}}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button tabindex="-1" class="btn my_input_combobox_button"><i class="fas fa-chevron-down"></i></button>
                                        <div class="input-group-text my_input_unit ZAfont_family">kg</div>
                                    </div>
                                </div>
                                <InvalidityReasons v-if="nominalLoad_ValidationResult.invalid==true" v-bind:validationResult="nominalLoad_ValidationResult"></InvalidityReasons>
                            </div>
                        </div>
                    </div>
                    <div style="grid-column:1 / 1; grid-row: 2 / 2">
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px;">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('1050')}}:</div>
                            </div>
                            <div>
                                <div class="input-group">
                                    <input tabindex="3" v-model="carWeight" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: carWeight_ValidationResult.invalid==true, my_input_background_color_at_validInput:carWeight_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                    <select v-model="carWeight_select" class="my_input_combobox_select my_select">
                                        <option v-for="carWeight in carWeight_ValueList">{{carWeight}}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button tabindex="-1" class="btn my_input_combobox_button"><i class="fas fa-chevron-down"></i></button>
                                        <div class="input-group-text my_input_unit ZAfont_family">kg</div>
                                    </div>
                                </div>
                                <InvalidityReasons v-if="carWeight_ValidationResult.invalid==true" v-bind:validationResult="carWeight_ValidationResult"></InvalidityReasons>
                            </div>
                        </div>
                    </div>
                    <div style="grid-column:1 / 1; grid-row: 3 / 3">
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px;">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('1051')}}:</div>
                            </div>
                            <div>
                                <div class="input-group">
                                    <input tabindex="5" v-if="counterweightInPercent==false" v-model="counterweight" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: counterweight_ValidationResult.invalid==true, my_input_background_color_at_validInput:counterweight_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                    <select tabindex="5" v-if="counterweightInPercent==true" v-model="counterweight" class="my_select" style="width:calc(100% - 64px)" v-bind:class="{my_input_border_at_invalidInput: counterweight_ValidationResult.invalid==true, my_input_background_color_at_validInput:counterweight_ValidationResult.invalid==false}">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="counterweight in counterweightInPercent_ValueList">{{counterweight}}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <select tabindex="4" v-model="counterweightInPercent" class="my_input_unit my_select">
                                            <option v-for="counterweightUnit in counterweightUnit_Options" v-bind:value="counterweightUnit.value">{{counterweightUnit.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <InvalidityReasons v-if="counterweight_ValidationResult.invalid==true" v-bind:validationResult="counterweight_ValidationResult"></InvalidityReasons>
                            </div>


                            <div v-if="counterweightInPercent==true">
                                <div class="ZAfont_family ZAfont_size"> </div>
                            </div>
                            <div v-if="counterweightInPercent==true">
                                <div class="ZAfont_family ZAfont_size">{{counterweightDisplay}} kg</div>
                            </div>
                        </div>
                    </div>
                    <div style="grid-column:1 / 1; grid-row: 4 / 4">
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px;">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('1053')}}:</div>
                            </div>
                            <div>
                                <div class="input-group">
                                    <input tabindex="6" v-model="nominalSpeed" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: nominalSpeed_ValidationResult.invalid==true, my_input_background_color_at_validInput:nominalSpeed_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                    <select v-model="nominalSpeed_select" class="my_input_combobox_select my_select">
                                        <option v-for="nominalSpeed in nominalSpeed_ValueList">{{ValueDecimalSeparator(nominalSpeed)}}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button tabindex="-1" class="btn my_input_combobox_button"><i class="fas fa-chevron-down"></i></button>
                                        <div class="input-group-text my_input_unit ZAfont_family">m/s</div>
                                    </div>
                                </div>
                                <InvalidityReasons v-if="nominalSpeed_ValidationResult.invalid==true" v-bind:validationResult="nominalSpeed_ValidationResult"></InvalidityReasons>
                            </div>
                        </div>
                    </div>
                    <div style="grid-column:1 / 1; grid-row: 5 / 5">
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px;">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('1054')}}:</div>
                            </div>
                            <div>
                                <div class="input-group">
                                    <input tabindex="7" v-model="travelDistance" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: travelDistance_ValidationResult.invalid==true, my_input_background_color_at_validInput:travelDistance_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                    <select v-model="travelDistance_select" class="my_input_combobox_select my_select">
                                        <option v-for="travelDistance in travelDistance_ValueList">{{ValueDecimalSeparator(travelDistance)}}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button tabindex="-1" class="btn my_input_combobox_button"><i class="fas fa-chevron-down"></i></button>
                                        <div class="input-group-text my_input_unit ZAfont_family">m</div>
                                    </div>
                                </div>
                                <InvalidityReasons v-if="travelDistance_ValidationResult.invalid==true" v-bind:validationResult="travelDistance_ValidationResult"></InvalidityReasons>
                            </div>
                        </div>
                    </div>
                    <div style="grid-column:2 / 2; grid-row: 1 / 1">
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px;">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('1085')}}:</div>
                            </div>
                            <div>
                                <select tabindex="8" v-model="mainsVoltageAndMainsFrequency" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: mainsVoltageAndMainsFrequency_ValidationResult.invalid==true, my_input_background_color_at_validInput:mainsVoltageAndMainsFrequency_ValidationResult.invalid==false}">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option v-for="mainsVoltageAndMainsFrequency in mainsVoltageAndMainsFrequency_Selection">{{mainsVoltageAndMainsFrequency}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div style="grid-column:2 / 2; grid-row: 2 / 2">
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px;">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('372')}}:</div>
                            </div>
                            <div>
                                <select tabindex="9" v-model="usageCategoryAndAverageTravelTimeAndDutyCycle" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: usageCategoryAndAverageTravelTimeAndDutyCycle_ValidationResult.invalid==true, my_input_background_color_at_validInput:usageCategoryAndAverageTravelTimeAndDutyCycle_ValidationResult.invalid==false}">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option v-for="usageCategoryAndAverageTravelTimeAndDutyCycle in usageCategoryAndAverageTravelTimeAndDutyCycle_Selection">{{usageCategoryAndAverageTravelTimeAndDutyCycle}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div style="grid-column:2 / 2; grid-row: 3 / 3">
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px;">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('1086')}}:</div>
                            </div>
                            <div>
                                <select tabindex="9" v-model="startsPerHour" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: startsPerHour_ValidationResult.invalid==true, my_input_background_color_at_validInput:startsPerHour_ValidationResult.invalid==false}">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option v-for="startsPerHour in startsPerHour_Selection">{{startsPerHour}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            <div style="overflow:auto">
                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('3034')}}</div>
                <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:5%; min-width: 800px">
                    <div>
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('1415')}}:</div>
                            <div class="my_machinePosition_Selection">
                                <ul class="ZAfont_family ZAfont_size">
                                    <li v-for="itemOf_locationOfMachine_VerticalPositionOfMachineAndDetailedPositionOfMachine_Selection in locationOfMachine_VerticalPositionOfMachineAndDetailedPositionOfMachine_Selection" @click="locationOfMachine_verticalPositionOfMachineAndDetailedPositionOfMachine=itemOf_locationOfMachine_VerticalPositionOfMachineAndDetailedPositionOfMachine_Selection" v-bind:class="{active: ((itemOf_locationOfMachine_VerticalPositionOfMachineAndDetailedPositionOfMachine_Selection.verticalPositionOfMachine.text==locationOfMachine_verticalPositionOfMachineAndDetailedPositionOfMachine.verticalPositionOfMachine)&&(itemOf_locationOfMachine_VerticalPositionOfMachineAndDetailedPositionOfMachine_Selection.detailedPositionOfMachine.text==locationOfMachine_verticalPositionOfMachineAndDetailedPositionOfMachine.detailedPositionOfMachine))}">
                                        <div>{{$t(itemOf_locationOfMachine_VerticalPositionOfMachineAndDetailedPositionOfMachine_Selection.verticalPositionOfMachine.textKey)}}</div>
                                        <div>{{$t(itemOf_locationOfMachine_VerticalPositionOfMachineAndDetailedPositionOfMachine_Selection.detailedPositionOfMachine.textKey)}}</div>
                                    </li>
                                </ul>
                            </div>
                            <div class="my_unorderedList_at_construction" style="margin-top:10px">
                                <ul>
                                    <li v-for="itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine in locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine" @click="change_locationOfMachine_footDirectionOfMachineAndUseOfPulleySheave(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine)" v-bind:class="{active: itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.selected}">
                                        <div>
                                            <div class="ZAfont_family ZAfont_size" v-if="itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.textKeyOfFootDirectionOfMachineAndUseOfPulleySheave>0">{{$t(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.textKeyOfFootDirectionOfMachineAndUseOfPulleySheave)}}</div>
                                            <div>
                                                <img v-if="((itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.verticalPositionOfMachine.text=='top')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.detailedPositionOfMachine.text=='above')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.footDirectionOfMachine=='down')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.useOfPulleySheave==true))" src="../../../../public/img/LocationOfMachine/ObenUeberEineRollenTransp.png" class="my_img_machinePositions" />
                                                <img v-if="(((itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.verticalPositionOfMachine.text=='top')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.detailedPositionOfMachine.text=='above')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.footDirectionOfMachine=='down')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.useOfPulleySheave==false))||((itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.verticalPositionOfMachine.text=='top')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.detailedPositionOfMachine.text=='in the shaft')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.footDirectionOfMachine=='down')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.useOfPulleySheave==null)))" src="../../../../public/img/LocationOfMachine/ObenImSchachtFuesseUntenTransp.png" class="my_img_machinePositions" />
                                                <img v-if="((itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.verticalPositionOfMachine.text=='top')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.detailedPositionOfMachine.text=='besides')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.footDirectionOfMachine=='lateral')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.useOfPulleySheave==null))" src="../../../../public/img/LocationOfMachine/ObenNebenFuesseSeiteTransp.png" class="my_img_machinePositions" />
                                                <img v-if="((itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.verticalPositionOfMachine.text=='top')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.detailedPositionOfMachine.text=='besides')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.footDirectionOfMachine=='down')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.useOfPulleySheave==null))" src="../../../../public/img/LocationOfMachine/ObenNebenFuesseUntenTransp.png" class="my_img_machinePositions" />
                                                <img v-if="((itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.verticalPositionOfMachine.text=='top')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.detailedPositionOfMachine.text=='in the shaft')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.footDirectionOfMachine=='up')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.useOfPulleySheave==null))" src="../../../../public/img/LocationOfMachine/ObenImSchachtFuesseObenTransp.png" class="my_img_machinePositions" />
                                                <img v-if="((itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.verticalPositionOfMachine.text=='bottom')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.detailedPositionOfMachine.text=='besides')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.footDirectionOfMachine=='lateral')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.useOfPulleySheave==null))" src="../../../../public/img/LocationOfMachine/UntenNebenFuesseSeiteTransp.png" class="my_img_machinePositions" />
                                                <img v-if="((itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.verticalPositionOfMachine.text=='bottom')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.detailedPositionOfMachine.text=='besides')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.footDirectionOfMachine=='down')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.useOfPulleySheave==null))" src="../../../../public/img/LocationOfMachine/UntenNebenFuesseUntenTransp.png" class="my_img_machinePositions" />
                                                <img v-if="((itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.verticalPositionOfMachine.text=='bottom')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.detailedPositionOfMachine.text=='in the shaft')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.footDirectionOfMachine=='up')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.useOfPulleySheave==null))" src="../../../../public/img/LocationOfMachine/UntenImSchachtFuesseObenTransp.png" class="my_img_machinePositions" />
                                                <img v-if="((itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.verticalPositionOfMachine.text=='bottom')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.detailedPositionOfMachine.text=='in the shaft')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.footDirectionOfMachine=='down')&&(itemOf_locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine.useOfPulleySheave==null))" src="../../../../public/img/LocationOfMachine/UntenImSchachtFuesseUntenTransp.png" class="my_img_machinePositions" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div style="margin-top:20px">
                            <div class="ZAfont_family ZAfont_size">{{$t('3001')}}:</div>
                            <div class="my_unorderedList_at_construction" style="margin-top:10px">
                                <ul>
                                    <li @click="designType='standard'" v-bind:class="{active: designType=='standard'}">
                                        <div>
                                            <div class="ZAfont_family ZAfont_size">{{$t('148')}}</div>
                                            <div>
                                                <img src="../../../../public/img/DesignType/Standard.jpg" style="height:90px;" />
                                            </div>
                                        </div>
                                    </li>
                                    <li @click="designType='rucksack'" v-bind:class="{active: designType=='rucksack'}">
                                        <div>
                                            <div class="ZAfont_family ZAfont_size">{{$t('1279')}}</div>
                                            <div>
                                                <img src="../../../../public/img/DesignType/Rucksack.jpg" style="height:90px;" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px;">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3002')}}:</div>
                            </div>
                            <div>
                                <div class="input-group">
                                    <input tabindex="10" v-model="suspensionRatio1" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: suspensionRatio1_ValidationResult.invalid==true, my_input_background_color_at_validInput:suspensionRatio1_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                    <select v-model="suspensionRatio1_select" class="my_input_combobox_select my_select">
                                        <option v-for="suspensionRatio1 in suspensionRatio1_ValueList">{{suspensionRatio1}}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button tabindex="-1" class="btn my_input_combobox_button"><i class="fas fa-chevron-down"></i></button>
                                        <div class="input-group-text my_input_unit ZAfont_family">: 1</div>
                                    </div>
                                </div>
                                <InvalidityReasons v-if="suspensionRatio1_ValidationResult.invalid==true" v-bind:validationResult="suspensionRatio1_ValidationResult"></InvalidityReasons>
                            </div>
                        </div>
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div>
                                <span class="ZAfont_family ZAfont_size">{{$t('3003')}}:</span>
                                <span class="hover-text" style="margin-left:5px">
                                    <span>i</span>
                                    <span class="tooltip-text" id="tooltip_top">{{$t('3473')}}</span>
                                </span>
                            </div>
                            <div>
                                <div class="input-group">
                                    <input tabindex="11" v-model="numberOfPulleysOnCarSide" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: numberOfPulleysOnCarSide_ValidationResult.invalid==true, my_input_background_color_at_validInput:numberOfPulleysOnCarSide_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                    <select v-model="numberOfPulleysOnCarSide_select" class="my_input_combobox_select my_select">
                                        <option v-for="numberOfPulleysOnCarSide in numberOfPulleysOnCarSide_ValueList">{{numberOfPulleysOnCarSide}}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button tabindex="-1" class="btn my_input_combobox_button"><i class="fas fa-chevron-down"></i></button>
                                        <div class="input-group-text my_input_unit ZAfont_family">{{$t('3078')}}</div>
                                    </div>
                                </div>
                                <InvalidityReasons v-if="numberOfPulleysOnCarSide_ValidationResult.invalid==true" v-bind:validationResult="numberOfPulleysOnCarSide_ValidationResult"></InvalidityReasons>
                            </div>
                        </div>
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; ; margin-top:10px; margin-bottom:10px">
                            <div>
                                <span class="ZAfont_family ZAfont_size">{{$t('3005')}}:</span>
                                <span class="hover-text" style="margin-left:5px">
                                    <span>i</span>
                                    <span class="tooltip-text" id="tooltip_top">{{$t('3473')}}</span>
                                </span>
                            </div>
                            <div>
                                <div class="input-group">
                                    <input tabindex="12" v-model="numberOfPulleysOnCounterweightSide" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: numberOfPulleysOnCounterweightSide_ValidationResult.invalid==true, my_input_background_color_at_validInput:numberOfPulleysOnCounterweightSide_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                    <select v-model="numberOfPulleysOnCounterweightSide_select" class="my_input_combobox_select my_select">
                                        <option v-for="numberOfPulleysOnCounterweightSide in numberOfPulleysOnCounterweightSide_ValueList">{{numberOfPulleysOnCounterweightSide}}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button tabindex="-1" class="btn my_input_combobox_button"><i class="fas fa-chevron-down"></i></button>
                                        <div class="input-group-text my_input_unit ZAfont_family">{{$t('3078')}}</div>
                                    </div>
                                </div>
                                <InvalidityReasons v-if="numberOfPulleysOnCounterweightSide_ValidationResult.invalid==true" v-bind:validationResult="numberOfPulleysOnCounterweightSide_ValidationResult"></InvalidityReasons>
                            </div>
                        </div>
                        <hr />
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('1140')}}? </div>
                            </div>
                            <div>
                                <!--<CSwitch :color="'success'" :checked.sync="calculatedWithDoublewrap" />-->
                                <input tabindex="13" type="checkbox" v-model="calculatedWithDoublewrap" />
                            </div>
                        </div>
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3006')}}? </div>
                            </div>
                            <div>
                                <!--<CSwitch :color="'success'" :checked.sync="wrappingAngle_Calculated" />-->
                                <input tabindex="14" type="checkbox" v-model="wrappingAngle_Calculated" />
                            </div>
                        </div>
                        <div v-if="accept_useFrame==true" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3417')}}:</div>
                            </div>
                            <div>
                                <input tabindex="14" type="checkbox" v-model="useFrame" />
                            </div>
                        </div>
                        <div v-if="wrappingAngle_Calculated==false" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('1073')}}:</div>
                            </div>
                            <div>
                                <div class="input-group">
                                    <input tabindex="14" v-model="wrappingAngle" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: wrappingAngle_ValidationResult.invalid==true, my_input_background_color_at_validInput:wrappingAngle_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                    <select v-model="wrappingAngle_select" class="my_input_combobox_select my_select">
                                        <option v-for="wrappingAngle in wrappingAngle_ValueList">{{wrappingAngle}}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button tabindex="-1" class="btn my_input_combobox_button"><i class="fas fa-chevron-down"></i></button>
                                        <div class="input-group-text my_input_unit ZAfont_family">°</div>
                                    </div>
                                </div>
                                <InvalidityReasons v-if="wrappingAngle_ValidationResult.invalid==true" v-bind:validationResult="wrappingAngle_ValidationResult"></InvalidityReasons>
                            </div>
                        </div>
                        <div v-if="inputdFactor_Disabled==false" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div class="ZAfont_family ZAfont_size">
                                <div>dFactor:</div>
                            </div>
                            <div>
                                <input class="form-control" v-bind:placeholder="this.$i18n.i18next.t('3173')" v-model="dFactor" v-bind:class="{my_input_border_at_invalidInput: dFactor_ValidationResult.invalid==true, my_input_background_color_at_validInput:dFactor_ValidationResult.invalid==false}" />
                                <InvalidityReasons v-if="dFactor_ValidationResult.invalid==true" v-bind:validationResult="dFactor_ValidationResult"></InvalidityReasons>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            <div style="overflow:auto">
                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('3007')}} & {{$t('411')}}</div>
                <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:5%; min-width: 700px">
                    <div>
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px;">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3008')}}:</div>
                            </div>
                            <div>
                                <ul class="my_typeOfSuspensionMeans">
                                    <li @click="typeOfSuspensionMeans='rope'" v-bind:class="{active: typeOfSuspensionMeans=='rope'}">
                                        <div class="ZAfont_family ZAfont_size">{{$t('1432')}}</div>
                                    </li>
                                    <li @click="typeOfSuspensionMeans='belt'" v-bind:class="{active: typeOfSuspensionMeans=='belt'}">
                                        <div class="ZAfont_family ZAfont_size">{{$t('1360')}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3009')}}:</div>
                            </div>
                            <div>
                                <select tabindex="15" v-model="suspensionMeansManufacturerAndType" @focus="change_Disabled_at_suspensionMeansManufacturerAndType_Selection" v-bind:disabled="individualSuspensionMeans" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: suspensionMeansManufacturerAndType_ValidationResult.invalid==true, my_input_background_color_at_validInput:suspensionMeansManufacturerAndType_ValidationResult.invalid==false}">
                                    <option value="null">{{$t('3174')}}</option>
                                    <option class="disabled" v-for="itemOf_suspensionMeansManufacturerAndType_Selection in suspensionMeansManufacturerAndType_Selection" v-bind:value="itemOf_suspensionMeansManufacturerAndType_Selection.manufacturerAndType" v-bind:disabled="itemOf_suspensionMeansManufacturerAndType_Selection.disabled">{{itemOf_suspensionMeansManufacturerAndType_Selection.manufacturerAndType}}</option>
                                </select>
                            </div>
                        </div>
                        <div v-if="individualSuspensionMeans==true" style="margin-top:5px;">
                            <div style="margin-bottom: 15px; text-align: right">
                                <a @click.prevent="individualSuspensionMeans=false" class="ZAfont_family ZAfont_size" href="#">{{$t('3175')}}</a>
                            </div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3011')}}</div>
                        </div>
                        <div v-if="individualSuspensionMeans==false" style="margin-top:5px; margin-bottom:0px; text-align:right">
                            <a @click.prevent="show_modal_suspensionMeans_Selection" class="ZAfont_family ZAfont_size" href="#">{{$t('3015')}}</a>
                        </div>
                        <div v-if="individualSuspensionMeans==false" style="margin-top:5px; margin-bottom:15px; text-align:right">
                            <span v-if="typeOfSuspensionMeans==='rope'">
                                <!--<a @click.prevent="delete_suspensionMeans_Selection" class="ZAfont_family ZAfont_size" href="javascript:void(0)">{{$t('3290')}}</a>
                                <span class="fal fa-pipe" style="margin-left:5px; margin-right:5px"></span>-->
                                <a @click.prevent="individualSuspensionMeans=true;breakingForce=null;weightPerMeter=null" class="ZAfont_family ZAfont_size" href="#">{{$t('3176')}}</a>
                            </span>
                        </div>
                        <div v-if="individualSuspensionMeans==true">
                            <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                                <div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('3012')}}:</div>
                                </div>
                                <div>
                                    <div class="input-group">
                                        <input v-model="individualSuspensionMeans_breakingForce" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: individualSuspensionMeans_breakingForce_ValidationResult.invalid==true, my_input_background_color_at_validInput:individualSuspensionMeans_breakingForce_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                        <div class="input-group-append">
                                            <div class="input-group-text my_input_unit ZAfont_family">N</div>
                                        </div>
                                    </div>
                                    <InvalidityReasons v-if="individualSuspensionMeans_breakingForce_ValidationResult.invalid==true" v-bind:validationResult="individualSuspensionMeans_breakingForce_ValidationResult"></InvalidityReasons>
                                </div>
                            </div>
                            <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                                <div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('3013')}}:</div>
                                </div>
                                <div>
                                    <div class="input-group">
                                        <input v-model="individualSuspensionMeans_weightPerMeter" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: individualSuspensionMeans_weightPerMeter_ValidationResult.invalid==true, my_input_background_color_at_validInput:individualSuspensionMeans_weightPerMeter_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                        <div class="input-group-append">
                                            <div class="input-group-text my_input_unit ZAfont_family">kg/m</div>
                                        </div>
                                    </div>
                                    <InvalidityReasons v-if="individualSuspensionMeans_weightPerMeter_ValidationResult.invalid==true" v-bind:validationResult="individualSuspensionMeans_weightPerMeter_ValidationResult"></InvalidityReasons>
                                </div>
                            </div>
                        </div>
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('1327')}}:</div>
                            </div>
                            <div>
                                <div class="my_input-group">
                                    <select tabindex="16" v-model="numberOfSuspensionMeans" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: numberOfSuspensionMeans_ValidationResult.invalid==true, my_input_background_color_at_validInput:numberOfSuspensionMeans_ValidationResult.invalid==false}">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="numberOfSuspensionMeans in numberOfSuspensionMeans_ValueList">{{numberOfSuspensionMeans}}</option>
                                    </select>
                                    <div class="my_input-group_unit ZAfont_family">{{$t('3078')}}</div>
                                </div>
                            </div>
                        </div>
                        <div v-if="typeOfSuspensionMeans=='rope'" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3010')}}:</div>
                            </div>
                            <div>
                                <div v-if="individualSuspensionMeans==false">
                                    <div class="my_input-group">
                                        <select tabindex="17" v-model="ropeDiameter" @focus="change_Disabled_at_ropeDiameter_ValueList" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: ropeDiameter_ValidationResult.invalid==true, my_input_background_color_at_validInput:ropeDiameter_ValidationResult.invalid==false}">
                                            <option value="null">{{$t('3174')}}</option>
                                            <option class="disabled" v-for="itemOf_ropeDiameter_ValueList in ropeDiameter_ValueList" v-bind:disabled="itemOf_ropeDiameter_ValueList.disabled">{{ValueDecimalSeparator(itemOf_ropeDiameter_ValueList.ropeDiameter)}}</option>
                                        </select>
                                        <div class="my_input-group_unit ZAfont_family">mm</div>
                                    </div>
                                </div>
                                <div v-if="individualSuspensionMeans==true">
                                    <div class="input-group">
                                        <input v-model="individualSuspensionMeans_ropeDiameter" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="my_input_combobox_input form-control" v-bind:class="{my_input_border_at_invalidInput: individualSuspensionMeans_ropeDiameter_ValidationResult.invalid==true, my_input_background_color_at_validInput:individualSuspensionMeans_ropeDiameter_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                        <select v-model="individualSuspensionMeans_ropeDiameter_select" class="my_input_combobox_select my_select">
                                            <option v-for="itemOf_ropeDiameter_ValueList in ropeDiameter_ValueList" v-bind:disabled="itemOf_ropeDiameter_ValueList.disabled">{{ValueDecimalSeparator(itemOf_ropeDiameter_ValueList.ropeDiameter)}}</option>
                                        </select>
                                        <div class="input-group-append">
                                            <button tabindex="-1" class="btn my_input_combobox_button"><i class="fas fa-chevron-down"></i></button>
                                            <div class="input-group-text my_input_unit ZAfont_family">mm</div>
                                        </div>
                                    </div>
                                    <InvalidityReasons v-if="individualSuspensionMeans_ropeDiameter_ValidationResult.invalid==true" v-bind:validationResult="individualSuspensionMeans_ropeDiameter_ValidationResult"></InvalidityReasons>
                                </div>
                            </div>
                        </div>
                        <div v-if="typeOfSuspensionMeans=='belt'" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3020')}}:</div>
                            </div>
                            <div>
                                <div v-if="individualSuspensionMeans==false">
                                    <div class="my_input-group" style="width:100%">
                                        <select v-model="cordDiameter" @focus="change_Disabled_at_cordDiameter_ValueList" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: cordDiameter_ValidationResult.invalid==true, my_input_background_color_at_validInput:cordDiameter_ValidationResult.invalid==false}">
                                            <option value="null">{{$t('3174')}}</option>
                                            <option class="disabled" v-for="itemOf_cordDiameter_ValueList in cordDiameter_ValueList" v-bind:disabled="itemOf_cordDiameter_ValueList.disabled">{{ValueDecimalSeparator(itemOf_cordDiameter_ValueList.cordDiameter)}}</option>
                                        </select>
                                        <div class="my_input-group_unit ZAfont_family">mm</div>
                                    </div>
                                </div>
                                <div v-if="individualSuspensionMeans==true">
                                    <div class="input-group">
                                        <input v-model="individualSuspensionMeans_cordDiameter" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: individualSuspensionMeans_cordDiameter_ValidationResult.invalid==true, my_input_background_color_at_validInput:individualSuspensionMeans_cordDiameter_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                        <select v-model="individualSuspensionMeans_cordDiameter_select" class="my_input_combobox_select my_select">
                                            <option v-for="itemOf_cordDiameter_ValueList in cordDiameter_ValueList" v-bind:disabled="itemOf_cordDiameter_ValueList.disabled">{{ValueDecimalSeparator(itemOf_cordDiameter_ValueList.cordDiameter)}}</option>
                                        </select>
                                        <div class="input-group-append">
                                            <button class="btn my_input_combobox_button"><i class="fas fa-chevron-down"></i></button>
                                            <div class="input-group-text my_input_unit ZAfont_family">mm</div>
                                        </div>
                                    </div>
                                    <InvalidityReasons v-if="individualSuspensionMeans_cordDiameter_ValidationResult.invalid==true" v-bind:validationResult="individualSuspensionMeans_cordDiameter_ValidationResult"></InvalidityReasons>
                                </div>
                            </div>
                        </div>
                        <div v-if="individualSuspensionMeans==false" style="margin-top:5px; margin-bottom:15px; text-align:right">
                            <a @click.prevent="delete_suspensionMeans_Selection" class="ZAfont_family ZAfont_size" href="javascript:void(0)">{{$t('3290')}}</a>
                        </div>
                        <div style="background-color:#f5f5f5; padding:0.75rem; margin-top:10px">
                            <div class="my_color_00338e my_fontWeight_600 ZAfont_family ZAfont_size">{{$t('3177')}}</div>
                            <table class="ZAfont_family" style="width:100%">
                                <tr v-if="individualSuspensionMeans==false">
                                    <td class="ZAfont_size">{{$t('3012')}}:</td>
                                    <td align="right">
                                        <div v-if="suspensionMeans_Selected==false">{{$t('3277')}}</div>
                                        <div v-if="suspensionMeans_Selected==true" class="ZAfont_size">{{ValueDecimalSeparator(selected_suspensionMeans.suspensionMeans.breakingForce)}} N</div>
                                    </td>
                                </tr>
                                <tr v-if="individualSuspensionMeans==false">
                                    <td class="ZAfont_size">{{$t('3013')}}:</td>
                                    <td align="right">
                                        <div v-if="suspensionMeans_Selected==false">{{$t('3277')}}</div>
                                        <div v-if="suspensionMeans_Selected==true" class="ZAfont_size">{{ValueDecimalSeparator(selected_suspensionMeans.suspensionMeans.weightPerMeter)}} kg/m</div>
                                    </td>
                                </tr>
                                <tr style="vertical-align:top!important">
                                    <td class="ZAfont_size">{{$t('3178')}}:</td>
                                    <td align="right">
                                        <LoaderIcon v-if="loader_forWeightCalculation_Shown==true" v-bind:loaderSize="'xs'"></LoaderIcon>
                                        <div v-if="loader_forWeightCalculation_Shown==false">
                                            <div v-if="weight.canBeCalculated==false">{{$t('3278')}}</div>
                                            <div v-if="weight.canBeCalculated==true">
                                                <div v-if="weight.calculatedSuccessfully==false">
                                                    <div style="line-height:100%">{{$t('3279')}}</div>
                                                    <a @click="checkIf_suspensionMeans_Weight_canBeCalculated" href="#">{{$t('3280')}}</a>
                                                </div>
                                                <div v-if="(weight.calculatedSuccessfully==true)" class="ZAfont_size">{{weight.value}} kg</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr style="vertical-align:top!important">
                                    <td class="ZAfont_size">{{$t('3179')}}:</td>
                                    <td align="right">
                                        <LoaderIcon v-if="loader_forSafetyFactorCalculation_Shown==true" v-bind:loaderSize="'xs'"></LoaderIcon>
                                        <div v-if="loader_forSafetyFactorCalculation_Shown==false">
                                            <div v-if="safetyFactor.canBeCalculated==false">{{$t('3278')}}</div>
                                            <div v-if="safetyFactor.canBeCalculated==true">
                                                <div v-if="safetyFactor.calculatedSuccessfully==false">
                                                    <div style="line-height:100%">{{$t('3279')}}</div>
                                                    <a @click="checkIf_suspensionMeans_SafetyFactor_canBeCalculated" href="#">{{$t('3280')}}</a>
                                                </div>
                                                <div v-if="(safetyFactor.calculatedSuccessfully==true)" class="ZAfont_size">{{safetyFactor.value}}</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div>
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px;">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('1072')}}:</div>
                            </div>
                            <div>
                                <div class="input-group">
                                    <input tabindex="18" v-model="cableWeightPerMeter" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: cableWeightPerMeter_ValidationResult.invalid==true, my_input_background_color_at_validInput:cableWeightPerMeter_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                    <select v-model="cableWeightPerMeter_select" class="my_input_combobox_select my_select">
                                        <option v-for="cableWeightPerMeter in cableWeightPerMeter_ValueList">{{ValueDecimalSeparator(cableWeightPerMeter)}}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button tabindex="-1" class="btn my_input_combobox_button"><i class="fas fa-chevron-down"></i></button>
                                        <div class="input-group-text my_input_unit ZAfont_family">kg/m</div>
                                    </div>
                                </div>
                                <InvalidityReasons v-if="cableWeightPerMeter_ValidationResult.invalid==true" v-bind:validationResult="cableWeightPerMeter_ValidationResult"></InvalidityReasons>
                            </div>
                        </div>
                        <hr v-show="useOfRopeTensionWeight" />
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">
                                    {{$t('3180')}}:
                                    <span v-if="tooltip_ropeTensionWeight.value == true" class="hover-errorText">
                                        <span>!</span>
                                        <span class="tooltip-errorText" id="tooltip_top">{{$t(tooltip_ropeTensionWeight.textKey, tooltip_ropeTensionWeight.textValues)}}</span>
                                    </span>
                                </div>
                            </div>
                            <div>
                                <!--<CSwitch :color="'success'" :checked.sync="useOfRopeTensionWeight" />-->
                                <input tabindex="19" type="checkbox" v-model="useOfRopeTensionWeight" />
                            </div>
                        </div>
                        <div v-if="useOfRopeTensionWeight" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div style="grid-column: 2 / 2">
                                <div class="input-group">
                                    <input tabindex="19" v-model="ropeTensionWeight" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: ropeTensionWeight_ValidationResult.invalid==true, my_input_background_color_at_validInput:ropeTensionWeight_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                    <select v-model="ropeTensionWeight_select" class="my_input_combobox_select my_select">
                                        <option v-for="ropeTensionWeight in ropeTensionWeight_ValueList">{{ropeTensionWeight}}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button tabindex="-1" class="btn my_input_combobox_button"><i class="fas fa-chevron-down"></i></button>
                                        <div class="input-group-text my_input_unit ZAfont_family">kg</div>
                                    </div>
                                </div>
                                <InvalidityReasons v-if="ropeTensionWeight_ValidationResult.invalid==true" v-bind:validationResult="ropeTensionWeight_ValidationResult"></InvalidityReasons>
                            </div>
                        </div>
                        <hr v-show="useOfRopeTensionWeight || useOfCompensationRope" />
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">
                                    {{$t('1070')}}:
                                    <span v-if="tooltip_compensationRope.value == true" class="hover-errorText">
                                        <span>!</span>
                                        <span class="tooltip-errorText" id="tooltip_top">{{$t(tooltip_compensationRope.textKey,tooltip_compensationRope.textValues)}}</span>
                                    </span>
                                </div>
                            </div>
                            <div>
                                <!--<CSwitch :color="'success'" :checked.sync="useOfCompensationRope" />-->
                                <input tabindex="20" type="checkbox" v-model="useOfCompensationRope" />
                            </div>
                        </div>
                        <div v-if="useOfCompensationRope" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div style="grid-column: 2 / 2">
                                <div class="input-group">
                                    <input tabindex="21" v-if="compensationRopeInPercent==true" v-model="compensationRope" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: compensationRope_ValidationResult.invalid==true, my_input_background_color_at_validInput:compensationRope_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                    <input tabindex="21" v-if="compensationRopeInPercent==false" v-model="compensationRope" v-bind:placeholder="this.$i18n.i18next.t('3173')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: compensationRope_ValidationResult.invalid==true, my_input_background_color_at_validInput:compensationRope_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                    <select v-model="compensationRope_select" class="my_input_combobox_select my_select" v-bind:disabled="!compensationRopeInPercent">
                                        <option v-for="compensationRope in compensationRopeInPercent_ValueList">{{compensationRope}}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button tabindex="-1" class="btn my_input_combobox_button" v-bind:disabled="!compensationRopeInPercent"><i class="fas fa-chevron-down"></i></button>
                                        <select tabindex="20" v-model="compensationRopeInPercent" class="my_input_unit my_select">
                                            <option v-for="compensationRopeUnit in compensationRopeUnit_Options" v-bind:value="compensationRopeUnit.value">{{compensationRopeUnit.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <InvalidityReasons v-if="compensationRope_ValidationResult.invalid==true" v-bind:validationResult="compensationRope_ValidationResult"></InvalidityReasons>
                            </div>
                        </div>

                        <hr v-show="useOfStandardGrooveDistance==false" />
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">
                                    {{$t('3226')}}:
                                    <span v-if="tooltip_grooveDistance.value == true" class="hover-errorText">
                                        <span>!</span>
                                        <span class="tooltip-errorText" id="tooltip_top">{{$t(tooltip_grooveDistance.textKey,tooltip_grooveDistance.textValues)}}</span>
                                    </span>
                                </div>
                            </div>
                            <div>
                                <!--<CSwitch :color="'success'" :checked.sync="useOfStandardGrooveDistance" />-->
                                <input tabindex="22" type="checkbox" v-model="useOfStandardGrooveDistance" />
                            </div>
                        </div>
                        <div v-if="useOfStandardGrooveDistance==false" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div style="grid-column: 2 / 2">
                                <div class="input-group">
                                    <input tabindex="22" v-model="grooveDistance" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control my_input_combobox_input" v-bind:class="{my_input_border_at_invalidInput: grooveDistance_ValidationResult.invalid==true, my_input_background_color_at_validInput:grooveDistance_ValidationResult.invalid==false}" v-bind:disabled="statusModal_Shown==true" />
                                    <select v-model="grooveDistance_select" class="my_input_combobox_select my_select">
                                        <option v-for="grooveDistance in grooveDistance_ValueList">{{grooveDistance}}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button tabindex="-1" class="btn my_input_combobox_button"><i class="fas fa-chevron-down"></i></button>
                                        <div class="input-group-text my_input_unit ZAfont_family">mm</div>
                                    </div>
                                </div>
                                <InvalidityReasons v-if="grooveDistance_ValidationResult.invalid==true" v-bind:validationResult="grooveDistance_ValidationResult"></InvalidityReasons>
                            </div>
                        </div>

                        <hr v-show="useStandardNumberOfGrooves==false" />
                        <div style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">
                                    {{$t('3451')}}:
                                </div>
                            </div>
                            <div>
                                <!--<CSwitch :color="'success'" :checked.sync="useOfStandardGrooveDistance" />-->
                                <input tabindex="22" type="checkbox" v-model="useStandardNumberOfGrooves" />
                            </div>
                        </div>
                        <div v-if="useStandardNumberOfGrooves==false" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px">
                            <div style="grid-column: 2 / 2">
                                <div>
                                    <input class="form-control" v-bind:placeholder="this.$i18n.i18next.t('3173')" v-model="numberOfGrooves" v-bind:class="{my_input_border_at_invalidInput: numberOfGrooves_ValidationResult.invalid==true, my_input_background_color_at_validInput:numberOfGrooves_ValidationResult.invalid==false}" />
                                    <InvalidityReasons v-if="numberOfGrooves_ValidationResult.invalid==true" v-bind:validationResult="numberOfGrooves_ValidationResult"></InvalidityReasons>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            <div class="my_btn_nextConfigurationStep_position">
                <button tabindex="25" @click="furtherToDriveTechnologies()" class="btn my_btn_nextConfigurationStep">{{$t('1023')}}</button>
            </div>
        </div>
        <div class="my_modal" v-bind:class="{show: modal_suspensionMeans_Selection_Shown==true}" align="center">
            <div class="my_modal-dialog">
                <div class="my_modal-content">
                    <div class="my_modal-header" style="border-bottom:none">
                        <div class="my_fontSize_1Point5 my_fontWeight_600 my_color_00338e ZAfont_family">{{$t('3018')}}</div>
                        <div>
                            <i @click="hide_modal_suspensionMeans_Selection" class="fal fa-times fa-2x"></i>
                        </div>
                    </div>
                    <div class="my_modal-body" style="overflow-y:hidden; height:calc(100vh - 90px)">
                        <table class="my_table_suspensionMeans ZAfont_family ZAfont_size" style="height:100%">
                            <thead style="height:60px">
                                <tr style="display:flex; height:100%">
                                    <th style="flex:0 0 7%">{{$t('3008')}}</th>
                                    <th style="flex:0 0 11%">{{$t('3019')}}</th>
                                    <th style="flex:0 0 15%">{{$t('407')}}</th>
                                    <th style="flex:0 0 19%; text-align:right">{{$t('29')}}<span class="my_fontWeight_500"> [mm]</span></th>
                                    <th style="flex:0 0 16%; text-align:right">{{$t('3020')}}<span class="my_fontWeight_500"> [mm]</span></th>
                                    <th style="flex:0 0 16%; text-align:right">{{$t('3012')}}<span class="my_fontWeight_500"> [N]</span></th>
                                    <th style="flex: 0 0 calc(16% + 0.9vw);background-color: #6491c8; text-align:right">{{$t('3013')}}<span class="my_fontWeight_500"> [kg/m]</span></th>
                                </tr>
                            </thead>
                            <tbody style="height:calc(100% - 60px)">
                                <tr @click="change_Selected_at_supensionMeans_Selection(suspensionMeans.suspensionMeans)" v-for="suspensionMeans in suspensionMeans_Selection" style="display:flex">
                                    <td style="flex:0 0 7%">{{$t(suspensionMeans.suspensionMeans.typeOfSuspensionMeans.textKey)}}</td>
                                    <td style="flex:0 0 11%">{{suspensionMeans.suspensionMeans.manufacturer}}</td>
                                    <td style="flex:0 0 15%">{{suspensionMeans.suspensionMeans.type}}</td>
                                    <td style="flex:0 0 19%; text-align:right">
                                        <div v-if="suspensionMeans.suspensionMeans.ropeDiameter!=null">{{ValueDecimalSeparator(suspensionMeans.suspensionMeans.ropeDiameter)}}</div>
                                        <div v-else>-</div>
                                    </td>
                                    <td style="flex:0 0 16%; text-align:right">
                                        <div v-if="suspensionMeans.suspensionMeans.cordDiameter!=null">{{ValueDecimalSeparator(suspensionMeans.suspensionMeans.cordDiameter)}}</div>
                                        <div v-else>-</div>
                                    </td>
                                    <td style="flex:0 0 16%; text-align:right">{{ValueDecimalSeparator(suspensionMeans.suspensionMeans.breakingForce)}}</td>
                                    <td style="flex:0 0 16%; text-align:right">{{ValueDecimalSeparator(suspensionMeans.suspensionMeans.weightPerMeter)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!--<div style="position:fixed; bottom:55px;left:5px">
            <button @click="openHelpFile" style="width:25px; height:25px; border-radius: 50%; background-color: #00338e; border: none; color: white; text-align: center; text-decoration: none; display: inline-block; font-size: 14px; cursor: pointer; font-weight:bold">i</button>
        </div>-->
    </div>
</template>


<script>
    import router from '../../../router';
    import InvalidityReasons from '../../dataValidation/InvalidityReasons';
    import LoaderIcon from '../../loader/LoaderIcon';
    import axios from 'axios';
    export default {
        name: 'Inputs',

        components: {
            InvalidityReasons,
            LoaderIcon,
        },


        data() {
            return {
                // suspensionMeans
                modal_suspensionMeans_Selection_Shown: false,
            }
        },

        computed: {
            note: {
                get() {
                    return this.$store.state.configurations.note.value;
                },
                set(value) {
                    this.$store.dispatch('configurations/change_note_storeAction', value)
                }
            },
            note_ValidationResult: {
                get() {
                    return this.$store.state.configurations.note;
                },
            },
            loader_forLoading_note_Shown: {
                get() {
                    return this.$store.state.configurations.loader_forLoading_note_Shown;
                }
            },
            statusAt_responseOf_select_Note_from_databaseRecord_configurationOverviews: {
                get() {
                    return this.$store.state.configurations.statusAt_responseOf_select_Note_from_databaseRecord_configurationOverviews
                }
            },

            configurationStep_inputs_Changed: {
                get() {
                    return this.$store.state.configurations.configurationStep_inputs_Changed
                },
                set(value) {
                    this.$store.dispatch('configurations/change_configurationStep_inputs_Changed_storeAction', value);
                }
            },
            inputs_Passed: {
                get() {
                    if (this.$store.state.configurations.navigation.inputs == 'passed') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            configurationOverviewsId: {
                get() {
                    return this.$store.state.configurations.configurationOverviewsId;
                },
            },
            configurationSteps: {
                get() {
                    return this.$store.state.configurations.configurationSteps;
                },
            },
            configuration_Modifiable: {
                get() {
                    return this.$store.state.configurations.configuration_Modifiable;
                },
            },
            configuration_CreatedByLoggedInUser: {
                get() {
                    return this.$store.state.configurations.configuration_CreatedByLoggedInUser;
                },
            },
            statusModal_Shown: {
                get() {
                    return this.$store.state.statusHandling.statusModal_Shown;
                },
            },

            // elevatorSystem
            nominalLoad_ValueList: {
                get() {
                    return this.$store.state.inputs.nominalLoad_ValueList;
                },
            },
            nominalLoad: {
                get() {
                    return this.$store.state.inputs.nominalLoad.value;
                },
                set(value) {
                    this.$store.dispatch('inputs/checkIf_nominalLoad_isValid_storeAction', value);
                }
            },
            nominalLoad_select: {
                get() {
                    if (this.nominalLoad_ValueList.includes(this.nominalLoad)) {
                        return this.nominalLoad;
                    }
                },
                set(value) {
                    this.nominalLoad = value;
                }
            },
            nominalLoad_ValidationResult: {
                get() {
                    return this.$store.state.inputs.nominalLoad;
                },
            },

            nominalSpeed_ValueList: {
                get() {
                    return this.$store.state.inputs.nominalSpeed_ValueList;
                },
            },
            nominalSpeed: {
                get() {
                    const localDecimal = { value: this.$store.state.inputs.nominalSpeed.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {

                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'nominalSpeed' }
                    this.$store.dispatch('inputs/set_decimalValue', localDecimal)
                }
            },
            nominalSpeed_select: {
                get() {
                    if (this.$store.state.inputs.nominalSpeed_ValueList.includes(this.nominalSpeed)) {
                        const localDecimal = { value: this.nominalSpeed, country: this.$store.state.configurations.language, }
                        return this.$get_decimalValueToLocaleString(localDecimal);
                    }
                },
                set(value) {
                    this.nominalSpeed = value;
                }
            },
            nominalSpeed_ValidationResult: {
                get() {
                    return this.$store.state.inputs.nominalSpeed;
                },
            },

            travelDistance_ValueList: {
                get() {
                    return this.$store.state.inputs.travelDistance_ValueList;
                },
            },
            travelDistance: {
                get() {
                    const localDecimal = { value: this.$store.state.inputs.travelDistance.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);

                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'travelDistance' }
                    this.$store.dispatch('inputs/set_decimalValue', localDecimal)
                }
            },
            travelDistance_select: {
                get() {
                    if (this.travelDistance_ValueList.includes(this.travelDistance)) {
                        const localDecimal = { value: this.travelDistance, country: this.$store.state.configurations.language, }
                        return this.$get_decimalValueToLocaleString(localDecimal);
                    }
                },
                set(value) {
                    this.travelDistance = value;
                }
            },
            travelDistance_ValidationResult: {
                get() {
                    return this.$store.state.inputs.travelDistance;
                },
            },

            carWeight_ValueList: {
                get() {
                    return this.$store.state.inputs.carWeight_ValueList;
                },
            },
            carWeight: {
                get() {
                    return this.$store.state.inputs.carWeight.value;
                },
                set(value) {
                    this.$store.dispatch('inputs/checkIf_carWeight_isValid_storeAction', value);
                }
            },
            carWeight_select: {
                get() {
                    if (this.carWeight_ValueList.includes(this.carWeight)) {
                        return this.carWeight;
                    }
                },
                set(value) {
                    this.carWeight = value;
                }
            },
            carWeight_ValidationResult: {
                get() {
                    return this.$store.state.inputs.carWeight;
                },
            },

            counterweightUnit_Options: {
                get() {
                    return this.$store.state.inputs.counterweightUnit_Options;
                },
            },
            counterweightInPercent_ValueList: {
                get() {
                    return this.$store.state.inputs.counterweightInPercent_ValueList;
                },
            },
            counterweightInPercent: {
                get() {
                    return this.$store.state.inputs.counterweightInPercent
                },
                set(value) {
                    this.$store.dispatch('inputs/change_counterweightInPercent_storeAction', value);
                }
            },
            counterweight: {
                get() {
                    if (this.$store.state.inputs.counterweight.value != null) {
                        return this.$store.state.inputs.counterweight.value;
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.$store.dispatch('inputs/checkIf_counterweight_isValid_storeAction', value);
                }
            },
            counterweight_ValidationResult: {
                get() {
                    return this.$store.state.inputs.counterweight;
                },
            },
            counterweightDisplay: {
                get() {
                    return this.$store.state.inputs.counterweightDisplay;
                },
            },

            mainsVoltageAndMainsFrequency_Selection: {
                get() {
                    return this.$store.state.inputs.mainsVoltageAndMainsFrequency_Selection;
                },
            },
            mainsVoltageAndMainsFrequency: {
                get() {
                    if ((this.$store.state.inputs.mainsVoltage != null) && (this.$store.state.inputs.mainsFrequency != null)) {
                        return this.$store.state.inputs.mainsVoltage + ' V' + ' | ' + this.$store.state.inputs.mainsFrequency + ' Hz';
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.$store.dispatch('inputs/checkIf_mainsVoltageAndMainsFrequency_isValid_storeAction', value);
                }
            },
            mainsVoltageAndMainsFrequency_ValidationResult: {
                get() {
                    return this.$store.state.inputs.mainsVoltageAndMainsFrequency;
                },
            },

            usageCategoryAndAverageTravelTimeAndDutyCycle_Selection: {
                get() {
                    return this.$store.state.inputs.usageCategoryAndAverageTravelTimeAndDutyCycle_Selection;
                },
            },
            usageCategoryAndAverageTravelTimeAndDutyCycle: {
                get() {
                    if ((this.$store.state.inputs.usageCategory != null) && (this.$store.state.inputs.averageTravelTime != null) && (this.$store.state.inputs.dutyCycle != null)) {
                        return this.$store.state.inputs.usageCategory + ' | ' + this.$store.state.inputs.averageTravelTime + ' h' + ' | ' + this.$store.state.inputs.dutyCycle + ' %';
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    //this.$store.dispatch('inputs/checkIf_usageCategoryAndAverageTravelTimeAndDutyCycle_isValid_storeAction', value);
                    this.$store.dispatch('inputs/get_usageCategoryAndAverageTravelTimeAndDutyCycle_StartsPerHour_storeAction', value);
                }
            },
            usageCategoryAndAverageTravelTimeAndDutyCycle_ValidationResult: {
                get() {
                    return this.$store.state.inputs.usageCategoryAndAverageTravelTimeAndDutyCycle;
                },
            },

            startsPerHour_Selection: {
                get() {
                    return this.$store.state.inputs.startsPerHour_Selection;
                },
            },
            startsPerHour: {
                get() {
                    return this.$store.state.inputs.startsPerHour.value;
                },
                set(value) {
                    this.$store.dispatch('inputs/checkIf_startsPerHour_isValid_storeAction', value);
                }
            },
            startsPerHour_ValidationResult: {
                get() {
                    return this.$store.state.inputs.startsPerHour;
                },
            },


            // construction
            selected_locationOfMachine: {
                get() {
                    return this.$store.state.inputs.locationOfMachine_Selection.find(i => i.selected == true);
                },
            },

            locationOfMachine_VerticalPositionOfMachineAndDetailedPositionOfMachine_Selection: {
                get() {
                    return this.$store.state.inputs.locationOfMachine_VerticalPositionOfMachineAndDetailedPositionOfMachine_Selection;
                },
            },
            locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine: {
                get() {
                    return this.$store.state.inputs.locationOfMachine_Selection_filteredBy_selected_verticalPositionOfMachineAndDetailedPositionOfMachine;
                },
            },
            locationOfMachine_verticalPositionOfMachineAndDetailedPositionOfMachine: {
                get() {
                    const firstIndex_whichMeetCondition = this.$store.state.inputs.locationOfMachine_Selection.findIndex(i => i.selected == true);
                    if (firstIndex_whichMeetCondition > -1) {
                        return { verticalPositionOfMachine: this.$store.state.inputs.locationOfMachine_Selection[firstIndex_whichMeetCondition].verticalPositionOfMachine.text, detailedPositionOfMachine: this.$store.state.inputs.locationOfMachine_Selection[firstIndex_whichMeetCondition].detailedPositionOfMachine.text }
                    }
                    else {
                        return { verticalPositionOfMachine: '', detailedPositionOfMachine: '' }
                        // error
                    }
                },
                set(value) {
                    if ((value.verticalPositionOfMachine.text != this.selected_locationOfMachine.verticalPositionOfMachine.text) || (value.detailedPositionOfMachine.text != this.selected_locationOfMachine.detailedPositionOfMachine.text)) {
                        this.$store.dispatch('inputs/change_locationOfMachine_verticalPositionOfMachineAndDetailedPositionOfMachine_storeAction', value);
                        this.check_positionOfMachine(this.$store.state.inputs.suspensionRatio1.value);
                    }
                }
            },

            designType: {
                get() {
                    return this.$store.state.inputs.designType
                },
                set(value) {
                    if (value != this.designType) {
                        this.$store.dispatch('inputs/change_designType_storeAction', value);
                    }
                }
            },

            suspensionRatio1_ValueList: {
                get() {
                    return this.$store.state.inputs.suspensionRatio1_ValueList;
                },
            },
            suspensionRatio1: {
                get() {
                    return this.$store.state.inputs.suspensionRatio1.value;
                },
                set(value) {
                    this.$store.dispatch('inputs/checkIf_suspensionRatio1_isValid_storeAction', value);
                    this.check_positionOfMachine(value);
                }
            },
            suspensionRatio1_select: {
                get() {
                    if (this.suspensionRatio1_ValueList.includes(this.suspensionRatio1)) {
                        return this.suspensionRatio1;
                    }
                },
                set(value) {
                    this.suspensionRatio1 = value;
                }
            },
            suspensionRatio1_ValidationResult: {
                get() {
                    return this.$store.state.inputs.suspensionRatio1;
                },
            },

            numberOfPulleysOnCarSide_ValueList: {
                get() {
                    return this.$store.state.inputs.numberOfPulleysOnCarSide_ValueList;
                },
            },
            numberOfPulleysOnCarSide: {
                get() {
                    return this.$store.state.inputs.numberOfPulleysOnCarSide.value;
                },
                set(value) {
                    this.$store.dispatch('inputs/checkIf_numberOfPulleysOnCarSide_isValid_storeAction', value);
                }
            },
            numberOfPulleysOnCarSide_select: {
                get() {
                    if (this.numberOfPulleysOnCarSide_ValueList.includes(this.numberOfPulleysOnCarSide)) {
                        return this.numberOfPulleysOnCarSide;
                    }
                },
                set(value) {
                    this.numberOfPulleysOnCarSide = value;
                }
            },
            numberOfPulleysOnCarSide_ValidationResult: {
                get() {
                    return this.$store.state.inputs.numberOfPulleysOnCarSide;
                },
            },

            numberOfPulleysOnCounterweightSide_ValueList: {
                get() {
                    return this.$store.state.inputs.numberOfPulleysOnCounterweightSide_ValueList;
                },
            },
            numberOfPulleysOnCounterweightSide: {
                get() {
                    return this.$store.state.inputs.numberOfPulleysOnCounterweightSide.value;
                },
                set(value) {
                    this.$store.dispatch('inputs/checkIf_numberOfPulleysOnCounterweightSide_isValid_storeAction', value);
                }
            },
            numberOfPulleysOnCounterweightSide_select: {
                get() {
                    if (this.numberOfPulleysOnCounterweightSide_ValueList.includes(this.numberOfPulleysOnCounterweightSide)) {
                        return this.numberOfPulleysOnCounterweightSide;
                    }
                },
                set(value) {
                    this.numberOfPulleysOnCounterweightSide = value;
                }
            },
            numberOfPulleysOnCounterweightSide_ValidationResult: {
                get() {
                    return this.$store.state.inputs.numberOfPulleysOnCounterweightSide;
                },
            },

            calculatedWithDoublewrap: {
                get() {
                    return this.$store.state.inputs.calculatedWithDoublewrap
                },
                set(value) {
                    this.$store.dispatch('inputs/change_calculatedWithDoublewrap_storeAction', value);
                    this.$store.dispatch('inputs/change_tooltip_grooveDistance_storeAction');
                }
            },
            wrappingAngle_Calculated: {
                get() {
                    return this.$store.state.inputs.wrappingAngle_Calculated
                },
                set(value) {
                    this.$store.dispatch('inputs/change_wrappingAngle_Calculated_storeAction', value);
                }
            },
            wrappingAngle_ValueList: {
                get() {
                    return this.$store.state.inputs.wrappingAngle_ValueList;
                },
            },
            wrappingAngle: {
                get() {
                    const localDecimal = { value: this.$store.state.inputs.wrappingAngle.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'wrappingAngle' }
                    this.$store.dispatch('inputs/set_decimalValue', localDecimal);
                }
            },
            wrappingAngle_select: {
                get() {
                    if (this.wrappingAngle_ValueList.includes(this.wrappingAngle)) {
                        const localDecimal = { value: this.wrappingAngle, country: this.$store.state.configurations.language, }
                        return this.$get_decimalValueToLocaleString(localDecimal);
                    }
                },
                set(value) {
                    this.wrappingAngle = value;
                }
            },
            wrappingAngle_ValidationResult: {
                get() {
                    return this.$store.state.inputs.wrappingAngle;
                },
            },


            // suspensionMeans
            suspensionMeans_Selection: {
                get() {
                    return this.$store.state.inputs.suspensionMeans_Selection;
                },
            },
            suspensionMeans_Selected: {
                get() {
                    return this.$store.state.inputs.suspensionMeans_Selection.some(i => i.selected == true);
                },
            },
            selected_suspensionMeans: {
                get() {
                    return this.$store.state.inputs.suspensionMeans_Selection.find(i => i.selected == true);
                },
            },

            typeOfSuspensionMeans: {
                get() {
                    return this.$store.state.inputs.typeOfSuspensionMeans
                },
                set(value) {
                    if (value != this.typeOfSuspensionMeans && !(value == "belt" && this.individualSuspensionMeans == true)) {
                        this.$store.dispatch('inputs/change_typeOfSuspensionMeans_storeAction', value)
                        this.$store.dispatch('inputs/change_tooltip_grooveDistance_storeAction');
                    }
                },
            },

            suspensionMeansManufacturerAndType_Selection: {
                get() {
                    if (this.typeOfSuspensionMeans == 'rope') {
                        return this.$store.state.inputs.ropeManufacturerAndType_Selection;
                    }
                    else if (this.typeOfSuspensionMeans == 'belt') {
                        return this.$store.state.inputs.beltManufacturerAndType_Selection;
                    }
                    else {
                        // error
                    }
                },
            },
            suspensionMeansManufacturerAndType: {
                get() {
                    if (this.typeOfSuspensionMeans == 'rope') {
                        if ((this.$store.state.inputs.ropeManufacturer != null) && (this.$store.state.inputs.ropeType != null)) {
                            return this.$store.state.inputs.ropeManufacturer + ' | ' + this.$store.state.inputs.ropeType;
                        }
                        else {
                            return null;
                        }
                    }
                    else if (this.typeOfSuspensionMeans == 'belt') {
                        if ((this.$store.state.inputs.beltManufacturer != null) && (this.$store.state.inputs.beltType != null)) {
                            return this.$store.state.inputs.beltManufacturer + ' | ' + this.$store.state.inputs.beltType;
                        }
                        else {
                            return null;
                        }
                    }
                },
                set(value) {
                    this.$store.dispatch('inputs/checkIf_suspensionMeansManufacturerAndType_isValid_storeAction', value);
                },
            },
            suspensionMeansManufacturerAndType_ValidationResult: {
                get() {
                    return this.$store.state.inputs.suspensionMeansManufacturerAndType;
                },
            },

            ropeDiameter_ValueList: {
                get() {
                    return this.$store.state.inputs.ropeDiameter_ValueList;
                },
            },
            ropeDiameter: {
                get() {
                    var value = null;
                    if (this.$store.state.inputs.ropeDiameter.value != null) {
                        value = this.$store.state.inputs.ropeDiameter.value;
                    }
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'ropeDiameter' }
                    this.$store.dispatch('inputs/set_decimalValue', localDecimal)

                },
            },
            ropeDiameter_ValidationResult: {
                get() {
                    return this.$store.state.inputs.ropeDiameter;
                },
            },

            cordDiameter_ValueList: {
                get() {
                    return this.$store.state.inputs.cordDiameter_ValueList;
                },
            },
            cordDiameter: {
                get() {
                    var value = null;
                    if (this.$store.state.inputs.cordDiameter.value != null) {
                        value = this.$store.state.inputs.cordDiameter.value;
                    }

                    const localDecimal = { value: value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'cordDiameter' }
                    this.$store.dispatch('inputs/set_decimalValue', localDecimal)
                }
            },
            cordDiameter_ValidationResult: {
                get() {
                    return this.$store.state.inputs.cordDiameter;
                },
            },

            individualSuspensionMeans: {
                get() {
                    return this.$store.state.inputs.individualSuspensionMeans
                },
                set(value) {
                    this.$store.dispatch('inputs/change_individualSuspensionMeans_storeAction', value)
                },
            },

            individualSuspensionMeans_ropeDiameter: {
                get() {
                    var value = null;
                    if (this.$store.state.inputs.individualSuspensionMeans_ropeDiameter.value != null) {
                        value = this.$store.state.inputs.individualSuspensionMeans_ropeDiameter.value;
                    }

                    const localDecimal = { value: value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'individualSuspensionMeans_ropeDiameter' }
                    this.$store.dispatch('inputs/set_decimalValue', localDecimal)
                },
            },
            individualSuspensionMeans_ropeDiameter_select: {
                get() {
                    if (this.ropeDiameter_ValueList.includes(this.individualSuspensionMeans_ropeDiameter)) {
                        const localDecimal = { value: this.individualSuspensionMeans_ropeDiameter, country: this.$store.state.configurations.language, }
                        return this.$get_decimalValueToLocaleString(localDecimal);
                    }
                },
                set(value) {
                    this.individualSuspensionMeans_ropeDiameter = value;
                }
            },
            individualSuspensionMeans_ropeDiameter_ValidationResult: {
                get() {
                    return this.$store.state.inputs.individualSuspensionMeans_ropeDiameter;
                },
            },

            individualSuspensionMeans_cordDiameter: {
                get() {
                    return this.$store.state.inputs.individualSuspensionMeans_cordDiameter.value;
                },
                set(value) {
                    this.$store.dispatch('inputs/checkIf_individualSuspensionMeans_cordDiameter_isValid_storeAction', value);
                }
            },
            individualSuspensionMeans_cordDiameter_select: {
                get() {
                    if (this.cordDiameter_ValueList.includes(this.individualSuspensionMeans_cordDiameter)) {
                        return this.individualSuspensionMeans_cordDiameter;
                    }
                },
                set(value) {
                    this.individualSuspensionMeans_cordDiameter = value;
                }
            },
            individualSuspensionMeans_cordDiameter_ValidationResult: {
                get() {
                    return this.$store.state.inputs.individualSuspensionMeans_cordDiameter;
                },
            },

            individualSuspensionMeans_breakingForce: {
                get() {
                    const localDecimal = { value: this.$store.state.inputs.individualSuspensionMeans_breakingForce.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'individualSuspensionMeans_breakingForce' }
                    this.$store.dispatch('inputs/set_decimalValue', localDecimal)
                }
            },
            individualSuspensionMeans_breakingForce_ValidationResult: {
                get() {
                    return this.$store.state.inputs.individualSuspensionMeans_breakingForce;
                },
            },

            individualSuspensionMeans_weightPerMeter: {
                get() {
                    const localDecimal = { value: this.$store.state.inputs.individualSuspensionMeans_weightPerMeter.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'individualSuspensionMeans_weightPerMeter' }
                    this.$store.dispatch('inputs/set_decimalValue', localDecimal)
                }
            },
            individualSuspensionMeans_weightPerMeter_ValidationResult: {
                get() {
                    return this.$store.state.inputs.individualSuspensionMeans_weightPerMeter;
                },
            },

            numberOfSuspensionMeans_ValueList: {
                get() {
                    return this.$store.state.inputs.numberOfSuspensionMeans_ValueList;
                },
            },
            numberOfSuspensionMeans: {
                get() {
                    if (this.$store.state.inputs.numberOfSuspensionMeans.value != null) {
                        return this.$store.state.inputs.numberOfSuspensionMeans.value;
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.$store.dispatch('inputs/checkIf_numberOfSuspensionMeans_isValid_storeAction', value);
                },
            },
            numberOfSuspensionMeans_ValidationResult: {
                get() {
                    return this.$store.state.inputs.numberOfSuspensionMeans;
                },
            },

            weight: {
                get() {
                    const localDecimal = { value: this.$store.state.inputs.weight.value, country: this.$store.state.configurations.language, }
                    const value = {
                        value: this.$get_decimalValueToLocaleString(localDecimal),
                        calculatedSuccessfully: this.$store.state.inputs.weight.calculatedSuccessfully,
                        canBeCalculated: this.$store.state.inputs.weight.canBeCalculated
                    }

                    return value;
                },
            },
            loader_forWeightCalculation_Shown: {
                get() {
                    return this.$store.state.inputs.loader_forWeightCalculation_Shown;
                },
            },

            safetyFactor: {
                get() {
                    const localDecimal = { value: this.$store.state.inputs.safetyFactor.value, country: this.$store.state.configurations.language, }
                    const value = {
                        value: this.$get_decimalValueToLocaleString(localDecimal),
                        calculatedSuccessfully: this.$store.state.inputs.safetyFactor.calculatedSuccessfully,
                        canBeCalculated: this.$store.state.inputs.safetyFactor.canBeCalculated
                    }
                    return value;
                },
            },
            loader_forSafetyFactorCalculation_Shown: {
                get() {
                    return this.$store.state.inputs.loader_forSafetyFactorCalculation_Shown;
                },
            },

            cableWeightPerMeter_ValueList: {
                get() {
                    return this.$store.state.inputs.cableWeightPerMeter_ValueList;
                },
            },
            cableWeightPerMeter: {
                get() {
                    const localDecimal = { value: this.$store.state.inputs.cableWeightPerMeter.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'cableWeightPerMeter' }
                    this.$store.dispatch('inputs/set_decimalValue', localDecimal)
                }
            },
            cableWeightPerMeter_select: {
                get() {
                    if (this.cableWeightPerMeter_ValueList.includes(this.cableWeightPerMeter)) {
                        const localDecimal = { value: this.cableWeightPerMeter, country: this.$store.state.configurations.language, }
                        return this.$get_decimalValueToLocaleString(localDecimal);
                    }
                },
                set(value) {
                    this.cableWeightPerMeter = value;
                }
            },
            cableWeightPerMeter_ValidationResult: {
                get() {
                    return this.$store.state.inputs.cableWeightPerMeter;
                },
            },

            useOfRopeTensionWeight: {
                get() {
                    return this.$store.state.inputs.useOfRopeTensionWeight
                },
                set(value) {
                    this.$store.dispatch('inputs/change_useOfRopeTensionWeight_storeAction', value);
                    this.$store.dispatch('inputs/change_tooltip_ropeTensionWeight_storeAction');
                    this.$store.dispatch('inputs/change_tooltip_compensationRope_storeAction');
                }
            },
            ropeTensionWeight_ValueList: {
                get() {
                    return this.$store.state.inputs.ropeTensionWeight_ValueList;
                },
            },
            ropeTensionWeight: {
                get() {
                    const localDecimal = { value: this.$store.state.inputs.ropeTensionWeight.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'ropeTensionWeight' }
                    this.$store.dispatch('inputs/set_decimalValue', localDecimal)
                }
            },
            ropeTensionWeight_select: {
                get() {
                    if (this.ropeTensionWeight_ValueList.includes(this.ropeTensionWeight)) {
                        const localDecimal = { value: this.ropeTensionWeight, country: this.$store.state.configurations.language, }
                        return this.$get_decimalValueToLocaleString(localDecimal);
                    }
                },
                set(value) {
                    this.ropeTensionWeight = value;
                }
            },
            ropeTensionWeight_ValidationResult: {
                get() {
                    return this.$store.state.inputs.ropeTensionWeight;
                },
            },
            tooltip_ropeTensionWeight: {
                get() {
                    return this.$store.state.inputs.tooltip_ropeTensionWeight;
                },
            },
            useOfCompensationRope: {
                get() {
                    return this.$store.state.inputs.useOfCompensationRope
                },
                set(value) {
                    this.$store.dispatch('inputs/change_useOfCompensationRope_storeAction', value);
                    this.$store.dispatch('inputs/change_tooltip_compensationRope_storeAction');
                }
            },
            compensationRopeUnit_Options: {
                get() {
                    return this.$store.state.inputs.compensationRopeUnit_Options;
                },
            },
            compensationRopeInPercent: {
                get() {
                    return this.$store.state.inputs.compensationRopeInPercent
                },
                set(value) {
                    this.$store.dispatch('inputs/change_compensationRopeInPercent_storeAction', value);
                }
            },
            compensationRopeInPercent_ValueList: {
                get() {
                    return this.$store.state.inputs.compensationRopeInPercent_ValueList;
                },
            },
            compensationRope: {
                get() {
                    const localDecimal = { value: this.$store.state.inputs.compensationRope.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'compensationRope' }
                    this.$store.dispatch('inputs/set_decimalValue', localDecimal)
                }
            },
            compensationRope_select: {
                get() {
                    if ((this.compensationRopeInPercent == true) && (this.compensationRopeInPercent_ValueList.includes(this.compensationRope))) {
                        const localDecimal = { value: this.compensationRope, country: this.$store.state.configurations.language, }
                        return this.$get_decimalValueToLocaleString(localDecimal);
                    }
                    else if (this.compensationRopeInPercent_ValueList.includes(this.compensationRope)) {
                        const localDecimal = { value: this.compensationRope, country: this.$store.state.configurations.language, }
                        return this.$get_decimalValueToLocaleString(localDecimal);
                    }
                },
                set(value) {
                    this.compensationRope = value;
                }
            },
            compensationRope_ValidationResult: {
                get() {
                    return this.$store.state.inputs.compensationRope;
                },
            },
            tooltip_compensationRope: {
                get() {
                    return this.$store.state.inputs.tooltip_compensationRope;
                },
            },
            dFactor: {
                get() {
                    const localDecimal = { value: this.$store.state.inputs.dFactor.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'dFactor' }
                    this.$store.dispatch('inputs/set_decimalValue', localDecimal)
                }
            },
            dFactor_ValidationResult: {
                get() {
                    return this.$store.state.inputs.dFactor;
                },
            },
            inputdFactor_Disabled: {
                get() {
                    return this.$store.state.inputs.inputdFactor_Disabled;
                },
            },

            // elevatorMotor_TractionSheave
            useOfStandardGrooveDistance: {
                get() {
                    return this.$store.state.inputs.useOfStandardGrooveDistance
                },
                set(value) {
                    this.$store.dispatch('inputs/change_useOfStandardGrooveDistance_storeAction', value);
                    this.$store.dispatch('inputs/change_tooltip_grooveDistance_storeAction');
                }
            },
            grooveDistance_ValueList: {
                get() {
                    return this.$store.state.inputs.grooveDistance_ValueList;
                },
            },
            grooveDistance: {
                get() {
                    const localDecimal = { value: this.$store.state.inputs.grooveDistance.value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);

                },
                set(value) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, name: 'grooveDistance' }
                    this.$store.dispatch('inputs/set_decimalValue', localDecimal)
                }
            },
            grooveDistance_select: {
                get() {
                    if (this.grooveDistance_ValueList.includes(this.grooveDistance)) {
                        const localDecimal = { value: this.grooveDistance, country: this.$store.state.configurations.language, }
                        return this.$get_decimalValueToLocaleString(localDecimal);
                    }
                },
                set(value) {
                    this.grooveDistance = value;
                }
            },
            grooveDistance_ValidationResult: {
                get() {
                    return this.$store.state.inputs.grooveDistance;
                },
            },
            tooltip_grooveDistance: {
                get() {
                    return this.$store.state.inputs.tooltip_grooveDistance;
                },
            },
            useStandardNumberOfGrooves: {
                get() {
                    return this.$store.state.inputs.useStandardNumberOfGrooves
                },
                set(value) {
                    this.$store.dispatch('inputs/change_useStandardNumberOfGrooves_storeAction', value);
                }
            },
            numberOfGrooves: {
                get() {
                    return this.$store.state.inputs.numberOfGrooves.value
                },
                set(value) {
                    this.$store.dispatch('inputs/checkIf_numberOfGrooves_isValid_storeAction', value);
                }
            },
            numberOfGrooves_ValidationResult: {
                get() {
                    return this.$store.state.inputs.numberOfGrooves;
                },
            },

            // additional components
            useFrame: {
                get() {
                    return this.$store.state.inputs.useFrame;
                },
                set(value) {
                    this.$store.dispatch('inputs/change_useFrame_storeAction', value);
                }
            },

            accept_useFrame: {
                //get() {
                //    this.$store.dispatch('inputs/check_accept_useFrame_storeAction');
                //    return this.$store.state.inputs.accept_useFrame
                //},

                get() {
                    axios({
                        method: 'post',
                        url: '/api/inputs/return_accept_useFrame'
                    })
                        .then((response) => {
                            if (response.data != null) {
                                this.$store.dispatch('inputs/change_accept_useFrame_storeAction', response.data);
                            }
                        });

                    return this.$store.state.inputs.accept_useFrame;
                },
            },
        },

        created() {
            if (this.configurationOverviewsId != null) {
                this.$store.dispatch('configurations/check_toDos_at_inputs_storeAction')
            }
            else {
                this.$store.dispatch('configurations/toDos_after_routeSlug_UpdatedOrEntered_storeAction', 'inputs')
            }
        },

        methods: {
            check_positionOfMachine(value) {
                if (value == 2 && (this.selected_locationOfMachine == null || (this.selected_locationOfMachine.detailedPositionOfMachine.text == 'above' && this.selected_locationOfMachine.useOfPulleySheave == true))) {
                    const firstIndex_whichMeetConditions = this.$store.state.inputs.locationOfMachine_Selection.findIndex(i => ((i.detailedPositionOfMachine.text == 'above') && (i.useOfPulleySheave == false)));
                    if (firstIndex_whichMeetConditions > -1) {
                        const selectedLocationOfMachine = this.$store.state.inputs.locationOfMachine_Selection[firstIndex_whichMeetConditions];
                        selectedLocationOfMachine.selected = true;
                        this.change_locationOfMachine_footDirectionOfMachineAndUseOfPulleySheave(selectedLocationOfMachine)
                    }
                }
            },
            openHelpFile() {
                this.$store.dispatch('configurations/openInputHelpFile_storeAction', this.$store.state.configurations.language);
            },
            ValueDecimalSeparator(value) {
                if (value != null) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                }
                return value;
            },
            select_Note_from_databaseRecord_configurationOverviews() {
                this.$store.dispatch('configurations/select_Note_from_databaseRecord_configurationOverviews_storeAction')
            },

            furtherToDriveTechnologies() {
                if (this.$store.state.inputs.useFrame == true && this.$store.state.inputs.wrappingAngle_Calculated == false) {
                    this.$store.dispatch('inputs/change_wrappingAngle_Calculated_storeAction', true);
                }
                if (this.inputs_Passed == true) {
                    router.push({ params: { slug: 'driveTechnologies' } });
                }
                else {
                    this.$store.dispatch('inputs/checkIf_inputs_areValid_storeAction')
                }
            },

            // construction
            change_locationOfMachine_footDirectionOfMachineAndUseOfPulleySheave(value) {
                if ((value.footDirectionOfMachine != this.selected_locationOfMachine.footDirectionOfMachine) || (value.useOfPulleySheave != this.selected_locationOfMachine.useOfPulleySheave)) {
                    this.$store.dispatch('inputs/change_locationOfMachine_footDirectionOfMachineAndUseOfPulleySheave_storeAction', value);
                    if (this.selected_locationOfMachine != null && this.selected_locationOfMachine.detailedPositionOfMachine.text == 'above') {
                        if (this.selected_locationOfMachine.useOfPulleySheave == true && (this.$store.state.inputs.wrappingAngle == null || this.$store.state.inputs.wrappingAngle.value == 180)) {
                            const localDecimal = { value: 150, country: this.$store.state.configurations.language, name: 'wrappingAngle' }
                            this.$store.dispatch('inputs/set_decimalValue', localDecimal);
                        }
                        else if (this.selected_locationOfMachine.useOfPulleySheave == false && (this.$store.state.inputs.wrappingAngle == null || this.$store.state.inputs.wrappingAngle.value != 180)) {
                            const localDecimal = { value: 180, country: this.$store.state.configurations.language, name: 'wrappingAngle' }
                            this.$store.dispatch('inputs/set_decimalValue', localDecimal);
                        }
                    }

                }
            },

            // suspensionMeans
            checkIf_suspensionMeans_Weight_canBeCalculated() {
                this.$store.dispatch('inputs/checkIf_suspensionMeans_Weight_canBeCalculated_storeAction')
            },
            checkIf_suspensionMeans_SafetyFactor_canBeCalculated() {
                this.$store.dispatch('inputs/checkIf_suspensionMeans_SafetyFactor_canBeCalculated_storeAction')
            },

            change_Selected_at_supensionMeans_Selection(value) {
                this.modal_suspensionMeans_Selection_Shown = false;
                if ((this.selected_suspensionMeans == undefined) || ((this.selected_suspensionMeans != undefined) && (value.blockNumber != this.selected_suspensionMeans.suspensionMeans.blockNumber))) {
                    this.$store.dispatch('inputs/change_Selected_at_supensionMeans_Selection_storeAction', value);
                }
            },
            change_Disabled_at_suspensionMeansManufacturerAndType_Selection() {
                this.$store.dispatch('inputs/change_Disabled_at_suspensionMeansManufacturerAndType_Selection_storeAction')
            },
            change_Disabled_at_ropeDiameter_ValueList() {
                this.$store.dispatch('inputs/change_Disabled_at_ropeDiameter_ValueList_storeAction')
            },
            change_Disabled_at_cordDiameter_ValueList() {
                this.$store.dispatch('inputs/change_Disabled_at_cordDiameter_ValueList_storeAction')
            },

            delete_suspensionMeans_Selection() {
                this.$store.dispatch('inputs/change_Selected_atEveryElement_supensionMeans_Selection_toFalse_storeAction');
            },

            show_modal_suspensionMeans_Selection() {
                this.modal_suspensionMeans_Selection_Shown = true;
            },
            hide_modal_suspensionMeans_Selection() {
                this.modal_suspensionMeans_Selection_Shown = false;
            },
        },

    }
</script>


<style>

    .my_typeOfSuspensionMeans {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_typeOfSuspensionMeans li {
            border: 2px solid #00338e;
            color: #000000;
            display: inline-block;
            padding: 0.3rem;
            text-align: center;
            width: 50%;
        }

        .my_typeOfSuspensionMeans .active {
            background-color: #00338e;
            color: #ffffff;
        }

    .classC {
        margin-top: 78px;
    }

    .input_margin-bottom {
        margin-bottom: 20px;
    }
</style>
