<template>
    <div style="overflow-x:auto !important">
        <div>
            <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                <div>
                    <div class="ZAfont_family ZAfont_size">{{$t('3544')}}:</div>
                </div>
                <div class="ZAfont_family ZAfont_size">
                    <div v-if="massbl!=null">{{massbl}}</div>
                </div>
            </div>
            <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                <div>
                    <div class="ZAfont_family ZAfont_size">{{$t('3539')}}:</div>
                </div>
                <div v-if="colorSelection.length==0 || colorSelection.length>4">
                    <select v-model="selectedColor" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selectedColor==null, my_input_background_color_at_validInput:selectedColor!=null}">
                        <option value="null" hidden>{{$t('3174')}}</option>
                        <option value="" hidden>{{$t('3174')}}</option>
                        <option v-for="color in colorSelection" v-bind:value="color">
                            <span v-if="color.text.useTextKey == false">{{color.text.textUI}}</span>
                            <span v-if="color.text.useTextKey == true">{{$t(color.text.textKey)}}</span>
                        </option>
                    </select>
                    <span v-if="selectedColor!=null">
                        <span v-if="selectedColor.farbe_Bez1_DE.useTextKey == false"> ({{selectedColor.farbe_Bez1_DE.textUI}})</span>
                        <span v-if="selectedColor.farbe_Bez1_DE.useTextKey == true">({{$t(selectedColor.farbe_Bez1_DE.textKey)}})</span>
                    </span>
                </div>
                <div v-if="colorSelection.length>0 && colorSelection.length<=4">
                    <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedColor==null, my_input_background_color_at_validInput:selectedColor!=null}">
                        <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                            <li v-for="color in colorSelection">
                                <input type="checkbox" @input="change_Selected_atElementOf_color(color)" v-bind:checked="color.selected" v-bind:disabled="color.selected" />
                                <span v-if="color.text.useTextKey == false" style="margin-left:10px">{{color.text.textUI}}</span>
                                <span v-if="color.text.useTextKey == true" style="margin-left:10px">{{$t(color.text.textKey)}}</span>
                            </li>
                        </ul>
                    </div>
                    <span v-if="selectedColor!=null">
                        <span v-if="selectedColor.farbe_Bez1_DE.useTextKey == false"> ({{selectedColor.farbe_Bez1_DE.textUI}})</span>
                        <span v-if="selectedColor.farbe_Bez1_DE.useTextKey == true">({{$t(selectedColor.farbe_Bez1_DE.textKey)}})</span>
                    </span>
                </div>
            </div>
            <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                <div>
                    <div class="ZAfont_family ZAfont_size">{{$t('3540')}}:</div>
                </div>
                <div v-if="logoSelection.length==0 || logoSelection.length>4">
                    <select v-model="selectedLogo" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selectedLogo==null, my_input_background_color_at_validInput:selectedLogo!=null}">
                        <option value="null" hidden>{{$t('3174')}}</option>
                        <option value="" hidden>{{$t('3174')}}</option>
                        <option v-for="logo in logoSelection" v-bind:value="logo">
                            <span v-if="logo.text.useTextKey == false">{{logo.text.textUI}}</span>
                            <span v-if="logo.text.useTextKey == true">{{$t(logo.text.textKey)}}</span>
                        </option>
                    </select>
                </div>
                <div v-if="logoSelection.length>0 && logoSelection.length<=4">
                    <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedLogo==null, my_input_background_color_at_validInput:selectedLogo!=null}">
                        <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                            <li v-for="logo in logoSelection">
                                <input type="checkbox" @input="change_Selected_atElementOf_logo(logo)" v-bind:checked="logo.selected" v-bind:disabled="logo.selected" />
                                <span v-if="logo.text.useTextKey == false" style="margin-left:10px">{{logo.text.textUI}}</span>
                                <span v-if="logo.text.useTextKey == true" style="margin-left:10px">{{$t(logo.text.textKey)}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                <div>
                    <div class="ZAfont_family ZAfont_size">{{$t('3390')}}:</div>
                </div>
                <div style="display: grid">
                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                        <li v-for="deliverOperatingInstruction in deliverOperatingInstructionSelection">
                            <input type="checkbox" @input="change_Selected_atElementOf_deliverOperatingInstruction(deliverOperatingInstruction)" v-bind:checked="deliverOperatingInstruction.selected" v-bind:disabled="deliverOperatingInstruction.selected==true" />
                            <span style="margin-left:10px" v-if="deliverOperatingInstruction.text.useTextKey == false">{{deliverOperatingInstruction.text.textUI}}</span>
                            <span style="margin-left:10px" v-if="deliverOperatingInstruction.text.useTextKey == true">{{$t(deliverOperatingInstruction.text.textKey)}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="selectedDeliverOperatingInstruction!=null&&selectedDeliverOperatingInstruction==1" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                <div>
                    <div class="ZAfont_family ZAfont_size">{{$t('3390')}}:</div>
                </div>
                <div style="display: grid">
                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                        <li v-for="operatingInstruction in operatingInstructionSelection">
                            <input type="checkbox" @input="change_Selected_atElementOf_operatingInstruction(operatingInstruction)" v-bind:checked="operatingInstruction.selected" v-bind:disabled="(operatingInstruction.selected==false && quantityOperatingInstructions>=3) || (operatingInstruction.selected==true && quantityOperatingInstructions==1)" />
                            <span style="margin-left:10px" v-if="operatingInstruction.text.useTextKey == false">{{operatingInstruction.text.textUI}}</span>
                            <span style="margin-left:10px" v-if="operatingInstruction.text.useTextKey == true">{{$t(operatingInstruction.text.textKey)}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="selectedDeliverOperatingInstruction!=null&&selectedDeliverOperatingInstruction==1" style="display: grid;">
                <ul class="ZAfont_family ZAfont_size" style="list-style:none;padding-top:5px;padding-left:0px;padding-right:0px;padding-bottom:0px;margin:0">
                    <li v-for="operatingInstruction in operatingInstructionSelection">
                        <span v-if="operatingInstruction.selected==true" style="display: grid; grid-template-columns: 1fr 1fr 1fr; padding-left: 3px; padding-right: 3px; grid-column-gap: 10px; margin-top: 20px ">
                            <span>
                                <span v-if="operatingInstruction.text.useTextKey == false">{{$t('1327')}} {{$t('3390')}} "{{operatingInstruction.text.textUI}}"</span>
                                <span v-if="operatingInstruction.text.useTextKey == true">{{$t('1327')}} {{$t('3390')}} "{{$t(operatingInstruction.text.textKey)}}"</span>
                            </span>
                            <span v-if="operatingInstruction.selected==true" class="my_input-group">
                                <input class="form-control" v-model="operatingInstruction.quantity" />
                                <span class="my_input-group_unit ZAfont_family" style="width:auto;max-width:60px">{{$t('3078')}}</span>
                                <!--<input class="form-control" v-bind:placeholder="this.$i18n.i18next.t('3173')" v-model="operatingInstruction.quantity" v-bind:class="{my_input_border_at_invalidInput: quantity_ValidationResult.invalid==true, my_input_background_color_at_validInput:quantity_ValidationResult.invalid==false}" />-->
                                <!--<InvalidityReasons v-if="quantity_ValidationResult.invalid==true" v-bind:validationResult="quantity_ValidationResult"></InvalidityReasons>-->
                            </span>
                        </span>
                    </li>
                </ul>
            </div>
            <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                <div v-if="cableGlandSelection.length==0 || cableGlandSelection.length>1">
                    <div class="ZAfont_family ZAfont_size">{{$t('3541')}} {{$t('3395')}}:</div>
                </div>
                <div v-if="cableGlandSelection.length==0 || cableGlandSelection.length>4">
                    <select v-model="selectedCableGland" class="my_select" style="width:100%">
                        <option value="null" hidden>{{$t('3174')}}</option>
                        <option value="" hidden>{{$t('3174')}}</option>
                        <option v-for="cableGland in cableGlandSelection" v-bind:value="cableGland">
                            <span v-if="cableGland.text.useTextKey == false">{{cableGland.text.textUI}}</span>
                            <span v-if="cableGland.text.useTextKey == true">{{$t(cableGland.text.textKey)}}</span>
                        </option>
                    </select>
                </div>
                <div v-if="cableGlandSelection.length>1 && cableGlandSelection.length<=4">
                    <div style="display: grid;">
                        <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                            <li v-for="cableGland in cableGlandSelection">
                                <input type="checkbox" @input="change_Selected_atElementOf_cableGland(cableGland)" v-bind:checked="cableGland.selected" v-bind:disabled="cableGland.selected" />
                                <span v-if="cableGland.text.useTextKey == false" style="margin-left:10px">{{cableGland.text.textUI}}</span>
                                <span v-if="cableGland.text.useTextKey == true" style="margin-left:10px">{{$t(cableGland.text.textKey)}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-if="customPartSelection != undefined && customPartSelection.length>0" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                <div>
                    <span class="ZAfont_family ZAfont_size">{{$t('3542')}} 1:</span>
                    <span>
                        <button @click="set_customPart_Modal_Shown(1)" class="fas fa-pen" style="padding:0; border: none; background-color: #ffffff; color: #00338e; margin-left: 20px"></button>
                        <button v-if="selectedCustomPart1!=null" @click="delete_customPart(1)" class="fas fa-trash" style="padding: 0; border: none; background-color: #ffffff; color: #00338e; margin-left: 15px "></button>
                    </span>
                </div>
                <div>
                    <div v-if="selectedCustomPart1!=null" class="ZAfont_family ZAfont_size">
                        <span>{{selectedCustomPart1.blockNumber}}</span>
                        <span v-if="selectedCustomPart1.text.useTextKey==false" style="text-align: left"><span v-if="selectedCustomPart1.text.textUI!=null"> | {{selectedCustomPart1.text.textUI}}</span></span>
                        <span v-if="selectedCustomPart1.text.useTextKey==true" style="text-align: left"> | {{$t(selectedCustomPart1.text.textKey)}}</span>
                    </div>
                </div>
            </div>
            <div v-if="customPartSelection != undefined && customPartSelection.length>0" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                <div>
                    <span class="ZAfont_family ZAfont_size">{{$t('3542')}} 2:</span>
                    <span>
                        <button @click="set_customPart_Modal_Shown(2)" class="fas fa-pen" style="padding:0; border: none; background-color: #ffffff; color: #00338e; margin-left: 20px"></button>
                        <button v-if="selectedCustomPart2!=null" @click="delete_customPart(2)" class="fas fa-trash" style="padding: 0; border: none; background-color: #ffffff; color: #00338e; margin-left: 15px "></button>
                    </span>

                </div>
                <div>
                    <div v-if="selectedCustomPart2!=null" class="ZAfont_family ZAfont_size">
                        <span>{{selectedCustomPart2.blockNumber}}</span>
                        <span v-if="selectedCustomPart2.text.useTextKey==false" style="text-align: left"><span v-if="selectedCustomPart2.text.textUI!=null"> | {{selectedCustomPart2.text.textUI}}</span></span>
                        <span v-if="selectedCustomPart2.text.useTextKey==true" style="text-align: left"> | {{$t(selectedCustomPart2.text.textKey)}}</span>
                    </div>
                </div>
            </div>
            <div v-if="customPartSelection != undefined && customPartSelection.length>0" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                <div>
                    <span class="ZAfont_family ZAfont_size">{{$t('3542')}} 3:</span>
                    <span>
                        <button @click="set_customPart_Modal_Shown(3)" class="fas fa-pen" style="padding:0; border: none; background-color: #ffffff; color: #00338e; margin-left: 20px"></button>
                        <button v-if="selectedCustomPart3!=null" @click="delete_customPart(3)" class="fas fa-trash" style="padding: 0; border: none; background-color: #ffffff; color: #00338e; margin-left: 15px "></button>
                    </span>
                </div>
                <div>
                    <div v-if="selectedCustomPart3!=null" class="ZAfont_family ZAfont_size">
                        <span>{{selectedCustomPart3.blockNumber}}</span>
                        <span v-if="selectedCustomPart3.text.useTextKey==false" style="text-align: left"><span v-if="selectedCustomPart3.text.textUI!=null"> | {{selectedCustomPart3.text.textUI}}</span></span>
                        <span v-if="selectedCustomPart3.text.useTextKey==true" style="text-align: left"> | {{$t(selectedCustomPart3.text.textKey)}}</span>
                    </div>
                </div>
            </div>
            <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                <div>
                    <div class="ZAfont_family ZAfont_size">{{$t('3554')}} 1:</div>
                </div>
                <div v-if="language1Selection.length>0 && selectedLanguage1!=null && selectedLanguage1.text!=null">
                    <div class="ZAfont_family ZAfont_size">
                        <span v-if="selectedLanguage1.text.useTextKey == false">{{selectedLanguage1.text.textUI}}</span>
                        <span v-if="selectedLanguage1.text.useTextKey == true">{{$t(selectedLanguage1.text.textKey)}}</span>
                    </div>
                </div>
            </div>
            <div v-if="language1Selection.length!=0" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                <div>
                    <div class="ZAfont_family ZAfont_size">{{$t('3555')}} 1:</div>
                </div>
                <div v-if="quantity1Selection.length==0 || quantity1Selection.length>4">
                    <select v-model="selectedQuantity1" class="my_select" style="width:100%">
                        <option value="null" hidden>{{$t('3174')}}</option>
                        <option value="" hidden>{{$t('3174')}}</option>
                        <option v-for="quantity in quantity1Selection" v-bind:value="quantity">{{quantity.value}}</option>
                    </select>
                </div>
                <div v-if="quantity1Selection.length>0 && quantity1Selection.length<=4">
                    <div style="display: grid;">
                        <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                            <li v-for="quantity in quantity1Selection">
                                <input type="checkbox" @input="change_Selected_atElementOf_quantity1(quantity)" v-bind:checked="quantity.selected" v-bind:disabled="quantity.selected" />
                                <span style="margin-left:10px">{{quantity.value}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-if="language2Selection.length!=0" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                <div>
                    <div class="ZAfont_family ZAfont_size">{{$t('3554')}} 2:</div>
                </div>
                <div v-if="language2Selection.length>0 && selectedLanguage2!=null && selectedLanguage2.text!=null">
                    <div class="ZAfont_family ZAfont_size">
                        <span v-if="selectedLanguage2.text.useTextKey == false">{{selectedLanguage2.text.textUI}}</span>
                        <span v-if="selectedLanguage2.text.useTextKey == true">{{$t(selectedLanguage2.text.textKey)}}</span>
                    </div>
                </div>
            </div>
            <div v-if="language2Selection.length!=0" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                <div>
                    <div class="ZAfont_family ZAfont_size">Anzal der Leistungsschilder 2:</div>
                </div>
                <div v-if="quantity2Selection.length==0 || quantity2Selection.length>4">
                    <select v-model="selectedQuantity2" class="my_select" style="width:100%">
                        <option value="null" hidden>{{$t('3174')}}</option>
                        <option value="" hidden>{{$t('3174')}}</option>
                        <option v-for="quantity in quantity2Selection" v-bind:value="quantity">{{quantity.value}}</option>
                    </select>
                </div>
                <div v-if="quantity2Selection.length>0 && quantity2Selection.length<=4">
                    <div style="display: grid;">
                        <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                            <li v-for="quantity in quantity2Selection">
                                <input type="checkbox" @input="change_Selected_atElementOf_quantity2(quantity)" v-bind:checked="quantity.selected" v-bind:disabled="quantity.selected" />
                                <span style="margin-left:10px">{{quantity.value}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="my_modal" v-bind:class="{show: customPart_Modal_Shown==true}" align="center">
                <div class="my_modal-dialog size-lg">
                    <div class="my_modal-content">
                        <div class="my_modal-header">
                            <div style="display:flex; align-items:center">
                                <div class="my_fontSize_1Point5 my_fontWeight_600 my_color_00338e ZAfont_family" style="align-self:center">{{$t('3529')}}</div>
                            </div>
                            <div>
                                <span @click="customPart_Modal_Shown=false" class="fal fa-times fa-2x"></span>
                            </div>
                        </div>
                        <div>
                            <div v-if="customPartSelection != undefined && customPartSelection.length>0">
                                <table class="my_table_customPart ZAfont_family ZAfont_size" style="margin-top:30px; margin-bottom:30px">
                                    <thead>
                                        <tr>
                                            <th style="text-align:left">Teilenummer</th>
                                            <th style="text-align: left">KID</th>
                                            <th style="text-align: left">BAUG</th>
                                            <th style="text-align: left">FE</th>
                                            <th style="text-align: left">FE2</th>
                                            <th style="text-align: left">FE3 </th>
                                            <th style="text-align: left">D-NDmax</th>
                                            <th style="text-align: left">D-NDmin</th>
                                            <th style="text-align: left">TEXT</th>
                                            <th style="text-align: left">KIDDRW</th>
                                            <th style="text-align: left">OPTION</th>
                                        </tr>
                                    </thead>
                                    <tbody style="overflow-y:scroll;cursor:pointer">
                                        <tr v-for="customPart in customPartSelection" @click="rowClick(customPart)" style="cursor: pointer" v-bind:class="{'selected-row': customPart.selected}">
                                            <td>{{customPart.blockNumber}}</td>
                                            <td style="text-align: left"><span v-if="customPart.kid.textUI!=null">{{customPart.kid.textUI}}</span></td>
                                            <td style="text-align: left"><span v-if="customPart.baug.textUI!=null">{{customPart.baug.textUI}}</span></td>
                                            <td style="text-align: left"><span v-if="customPart.fe.textUI!=null">{{customPart.fe.textUI}}</span></td>
                                            <td style="text-align: left"><span v-if="customPart.fe2.textUI!=null">{{customPart.fe2.textUI}}</span></td>
                                            <td style="text-align: left"><span v-if="customPart.fe3.textUI!=null">{{customPart.fe3.textUI}}</span></td>
                                            <td style="text-align: left"><span v-if="customPart.d_NDmax!=null">{{customPart.d_NDmax}}</span></td>
                                            <td style="text-align: left"><span v-if="customPart.d_NDmin!=null">{{customPart.d_NDmin}}</span></td>
                                            <td v-if="customPart.text.useTextKey==false" style="text-align: left"><span v-if="customPart.text.textUI!=null">{{customPart.text.textUI}}</span></td>
                                            <td v-if="customPart.text.useTextKey==true" style="text-align: left">{{$t(customPart.text.textKey)}}</td>
                                            <td style="text-align: left"><span v-if="customPart.kiddrw.textUI!=null">{{customPart.kiddrw.textUI}}</span></td>
                                            <td style="text-align: left"><span v-if="customPart.option.textUI!=null">{{customPart.option.textUI}}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="my_modal-footer" style="justify-content: flex-end;">
                            <div>
                                <button @click="hide_customPart_Modal(2)" class="btn my_btn_standard">{{$t('3366')}}</button>
                                <button @click="hide_customPart_Modal(1)" class="btn my_btn_standard" style="margin-left:10px !important">{{$t('383')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my_btn_nextConfigurationStep_position">
            <button @click="furtherToManualHome()" class="btn my_btn_nextConfigurationStep" style="margin-right: 5px">{{$t('3366')}}</button>
            <button v-if="plusManual==false" @click="furtherToCables()" class="btn my_btn_nextConfigurationStep" style="margin-right: 5px">{{$t('1018')}}</button>
            <button @click="furtherTo()" class="btn my_btn_nextConfigurationStep">{{$t('1023')}}</button>
        </div>
    </div>
</template>


<script>
    import router from '../../../router';
    import InvalidityReasons from '../../dataValidation/InvalidityReasons';
    import LoaderIcon from '../../loader/LoaderIcon';
    import axios from 'axios';
    import { suppliesMotorManual } from '../../../store/suppliesMotorManual.module';

    export default {
        name: 'SuppliesMotorManual',
        created() {

            this.$store.dispatch('configurations/check_plusManual');
            if (this.$store.state.configurations.plusManual == true) {
                this.$store.dispatch('configurations/change_manualConfiguration_storeAction', true);
                this.$store.dispatch('suppliesMotorManual/get_suppliesInformations_storeAction');
            }
            else {
                if (this.$store.state.inputsManual.configurationStepsManualId == null) {
                    router.push({ params: { slug: 'inputsManual' } })
                }
                else {
                    if (this.$store.state.configurations.MANUAL == false) {
                        this.$store.commit('configurations/MANU_configuration', true);
                        this.$store.commit('configurations/change_elevatorMotor_Manual_storeMutation', true);
                    }

                    this.$store.dispatch('suppliesMotorManual/get_suppliesInformations_storeAction')
                }
                this.$store.dispatch('configurations/change_manualConfiguration_storeAction', false);

            }     
        },
        computed: {
            plusManual: {
                get() {
                    return this.$store.state.configurations.plusManual;
                }
            },
            colorSelection: {
                get() {
                    return this.$store.state.suppliesMotorManual.motorColorSelection;
                }
            },
            selectedColor: {
                get() {
                    return this.$store.state.suppliesMotorManual.selectedMotorColor;
                },
                set(value) {
                    this.change_Selected_atElementOf_color(value);
                }
            },
            logoSelection: {
                get() {
                    return this.$store.state.suppliesMotorManual.motorLogoSelection;
                }
            },
            selectedLogo: {
                get() {
                    return this.$store.state.suppliesMotorManual.selectedLogo;
                },
                set(value) {
                    this.change_Selected_atElementOf_logo(value);
                }
            },
            operatingInstructionSelection: {
                get() {
                    return this.$store.state.suppliesMotorManual.motorOperatingInstructionSelection;
                }
            },
            selectedOperatingInstruction: {
                get() {
                    return null;
                },
                set(value) {
                    this.change_Selected_atElementOf_operatingInstruction(value);
                }
            },
            deliverOperatingInstructionSelection: {
                get() {
                    return this.$store.state.suppliesMotorManual.deliverOperatingInstructionSelection;
                }
            },
            selectedDeliverOperatingInstruction: {
                get() {
                    if (this.$store.state.suppliesMotorManual.deliverOperatingInstructionSelection != null) {
                        const firstIndex_whichMeetCondition = this.$store.state.suppliesMotorManual.deliverOperatingInstructionSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetCondition > -1) {
                            return this.$store.state.suppliesMotorManual.deliverOperatingInstructionSelection[firstIndex_whichMeetCondition].text.code;
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                }
            },
            quantityOperatingInstructions: {
                get() {
                    return this.$store.state.suppliesMotorManual.quantityOperatingInstructions;
                }
            },
            cableGlandSelection: {
                get() {
                    return this.$store.state.suppliesMotorManual.cableGlandSelection;
                }
            },
            selectedCableGland: {
                get() {
                    return this.$store.state.suppliesMotorManual.selectedCableGland;
                },
                set(value) {
                    this.change_Selected_atElementOf_cableGland(value);
                }
            },
            customPart_Modal_Shown: {
                get() {
                    return this.$store.state.suppliesMotorManual.customPart_Modal_Shown;
                },
                set(value) {
                    this.$store.dispatch('suppliesMotorManual/change_customPart_Modal_Shown_storeAction', value);
                }
            },
            customPartSelection: {
                get() {
                    return this.$store.state.suppliesMotorManual.customPartSelection;
                }
            },
            selectedCustomPart1: {
                get() {
                    return this.$store.state.suppliesMotorManual.selectedCustomPart1;
                },
            },
            selectedCustomPart2: {
                get() {
                    return this.$store.state.suppliesMotorManual.selectedCustomPart2;
                },
            },
            selectedCustomPart3: {
                get() {
                    return this.$store.state.suppliesMotorManual.selectedCustomPart3;
                },
            },
            language1Selection: {
                get() {
                    if (this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage != null && this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage.hasOwnProperty('language1') == true) {
                        return this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage.language1;
                    }
                    else {
                        return [];
                    }
                }
            },
            selectedLanguage1: {
                get() {
                    if (this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage != null && this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage.hasOwnProperty('language1') == true) {
                        const firstIndex_whichMeetCondition_language1 = this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage.language1.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetCondition_language1 > -1) {
                            return this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage.language1[firstIndex_whichMeetCondition_language1];
                        }
                    }
                    return null;
                },
            },
            quantity1Selection: {
                get() {
                    if (this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage != null && this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage.hasOwnProperty('quantity1') == true) {
                        return this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage.quantity1;
                    }
                    else {
                        return [];
                    }
                }
            },
            selectedQuantity1: {
                get() {
                    return this.$store.state.suppliesMotorManual.selectedQuantity1;
                },
                set(value) {
                    this.change_Selected_atElementOf_quantity1(value);
                }
            },
            language2Selection: {
                get() {
                    if (this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage != null && this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage.hasOwnProperty('language2') == true) {
                        return this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage.language2;
                    }
                    else {
                        return [];
                    }
                }
            },
            selectedLanguage2: {
                get() {
                    if (this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage != null && this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage.hasOwnProperty('language2') == true) {
                        const firstIndex_whichMeetCondition_language2 = this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage.language2.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetCondition_language2 > -1) {
                            return this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage.language2[firstIndex_whichMeetCondition_language2];
                        }
                    }
                    return null;
                },
            },
            quantity2Selection: {
                get() {
                    if (this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage != null && this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage.hasOwnProperty('quantity2') == true) {
                        return this.$store.state.suppliesMotorManual.ratingPlateLayoutLanguage.quantity2;
                    }
                    else {
                        return [];
                    }
                }
            },
            selectedQuantity2: {
                get() {
                    return this.$store.state.suppliesMotorManual.selectedQuantity2;
                },
                set(value) {
                    this.change_Selected_atElementOf_quantity2(value);
                }
            },
            massbl: {
                get() {
                    return this.$store.state.suppliesMotorManual.massbl;
                }
            },
        },

        methods: {
            change_Selected_atElementOf_color(value) {
                this.$store.dispatch('suppliesMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('suppliesMotorManual/change_Selected_motorColor_storeAction', value);
            },
            change_Selected_atElementOf_logo(value) {
                this.$store.dispatch('suppliesMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('suppliesMotorManual/change_Selected_motorLogo_storeAction', value);
            },
            change_Selected_atElementOf_operatingInstruction(value) {
                this.$store.dispatch('suppliesMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('suppliesMotorManual/change_Selected_operatingInstruction_storeAction', value);
            },
            change_Selected_atElementOf_deliverOperatingInstruction(value) {
                this.$store.dispatch('suppliesMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('suppliesMotorManual/change_Selected_deliverOperatingInstruction_storeAction', value);
            },
            change_Selected_atElementOf_cableGland(value) {
                this.$store.dispatch('suppliesMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('suppliesMotorManual/change_Selected_cableGland_storeAction', value);
            },

            set_customPart_Modal_Shown(value) {
                this.$store.dispatch('suppliesMotorManual/set_customPartModal_Shown_storeAction', value)
            },
            hide_customPart_Modal(value) {
                this.$store.dispatch('suppliesMotorManual/change_customPartModal_Shown_storeAction', value)
            },
            rowClick(value) {
                this.$store.dispatch('suppliesMotorManual/change_Selected_customPart_storeAction', value);
            },
            delete_customPart(value) {
                if (value == 1) {
                    this.$store.dispatch('suppliesMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                    this.$store.commit('suppliesMotorManual/change_selectedCustomPart1_storeMutation', null);
                }
                else if (value == 2) {
                    this.$store.dispatch('suppliesMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                    this.$store.commit('suppliesMotorManual/change_selectedCustomPart2_storeMutation', null);
                }
                else if (value == 3) {
                    this.$store.dispatch('suppliesMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                    this.$store.commit('suppliesMotorManual/change_selectedCustomPart3_storeMutation', null);
                }
            },
            change_Selected_atElementOf_language1(value) {
                this.$store.dispatch('suppliesMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('suppliesMotorManual/change_Selected_language1_storeAction', value);
            },
            change_Selected_atElementOf_quantity1(value) {
                this.$store.dispatch('suppliesMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('suppliesMotorManual/change_Selected_quantity1_storeAction', value);
            },
            change_Selected_atElementOf_language2(value) {
                this.$store.dispatch('suppliesMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('suppliesMotorManual/change_Selected_language2_storeAction', value);
            },
            change_Selected_atElementOf_quantity2(value) {
                this.$store.dispatch('suppliesMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('suppliesMotorManual/change_Selected_quantity2_storeAction', value);
            },

            furtherTo() {
                this.$store.dispatch('suppliesMotorManual/checkIf_suppliesMotorManual_areValid_storeAction');
            },
            furtherToCables() {
                router.push({ params: { slug: 'cables' } })
            },
            furtherToManualHome() {
                this.$store.dispatch('inputsManual/cancelManualMotorConfiguration_storeAction')
            },
        },
    }
</script>


<style>
    .my_table_customPart {
        width: calc(100% - 0.9vw);
        overflow-x: auto;
    }

        .my_table_customPart thead {
            color: #000000;
            width: 100%;
        }

            .my_table_customPart thead tr {
                /*background-color: #6491c8;
                color: #ffffff;*/
                background-color: #ffffff;
                color: black;
                border-bottom: 1px solid gray !important;
            }

            .my_table_customPart thead th, .my_table_customPart tbody td {
                padding: 0.2rem 0.5rem;
            }

        .my_table_customPart td {
            /*            border: 1px solid #E8E8E8;
            border-collapse: collapse;*/
            border-bottom: 1px solid #E8E8E8 !important;
        }

        .my_table_customPart tbody {
            max-height: 100vh;
            overflow-y: auto;
            width: 100%;
        }

            .my_table_customPart tbody tr:hover {
                background-color: rgba(100,145,200,0.6);
            }

            .my_table_customPart tbody tr:active {
                background-color: rgba(100,145,200,0.5);
            }

            .my_table_customPart tbody tr:default {
                background-color: white;
                color: black;
            }

    .my_table_customPart_header {
        color: #ffffff;
        background-color: #6491c8;
    }

    .selected-row {
        background-color: rgba(100,145,200,0.5);
        /*color: #ffffff;*/
    }
</style>
