<template>
    <div style="overflow-x:auto !important">
        <div>
            <div>
                <button v-if="showSCHMOD==false" @click="End_PSG518_600" class="btn my_btn_standard">{{$t('3452')}} {{$t('1146')}}</button>
                <button v-if="showSCHMOD==false" @click="Start_PSG518_600" class="btn my_btn_standard" style="margin-left:10px">PSG 518-600</button>
                <div class="my_modal" v-bind:class="{show: showSCHMOD==true}" align="center">
                    <div class="my_modal-dialog size-lg">
                        <div class="my_modal-content">
                            <div class="my_modal-header">
                                <div style="display:flex; align-items:center">
                                    <div class="my_fontSize_1Point5 my_fontWeight_600 my_color_00338e ZAfont_family" style="align-self:center">{{$t('3557')}}</div>
                                </div>
                                <div>
                                    <span @click="End_PSG518_600" class="fal fa-times fa-2x"></span>
                                </div>
                            </div>
                            <div class="my_modal-body" style="height: calc(100vh - 180px)">
                                <div>
                                    <div>
                                        <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                                            <div>
                                                <div>{{$t('3558')}}:</div>
                                            </div>
                                            <div v-if="schmod_locationProductionSelection.length==0 || schmod_locationProductionSelection.length>4">
                                                <select v-model="selected_schmod_locationProduction" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_locationProduction==null, my_input_background_color_at_validInput:selected_schmod_locationProduction!=null}">
                                                    <option value="null" hidden>{{$t('3174')}}</option>
                                                    <option value="" hidden>{{$t('3174')}}</option>
                                                    <option v-for="locationProduction in schmod_locationProductionSelection" v-bind:value="locationProduction">
                                                        <span v-if="locationProduction.text.useTextKey == false">{{locationProduction.text.textUI}}</span>
                                                        <span v-if="locationProduction.text.useTextKey == true">{{$t(locationProduction.text.textKey)}}</span>
                                                    </option>
                                                </select>
                                            </div>
                                            <div v-if="schmod_locationProductionSelection.length>0 && schmod_locationProductionSelection.length<=4">
                                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_locationProduction==null, my_input_background_color_at_validInput:selected_schmod_locationProduction!=null}">
                                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                                        <li v-for="locationProduction in schmod_locationProductionSelection">
                                                            <input type="checkbox" @input="change_Selected_atElementOf_schmod_locationProduction(locationProduction)" v-bind:checked="locationProduction.selected" v-bind:disabled="locationProduction.selected" />
                                                            <span style="margin-left:10px">
                                                                <span v-if="locationProduction.text.useTextKey == false">{{locationProduction.text.textUI}}</span>
                                                                <span v-if="locationProduction.text.useTextKey == true">{{$t(locationProduction.text.textKey)}}</span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                                            <div>
                                                <div>{{$t('3455')}}:</div>
                                            </div>
                                            <div v-if="schmod_certificationSelection.length==0 || schmod_certificationSelection.length>4">
                                                <select v-model="selected_schmod_Certification" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_Certification==null, my_input_background_color_at_validInput:selected_schmod_Certification!=null}">
                                                    <option value="null" hidden>{{$t('3174')}}</option>
                                                    <option value="" hidden>{{$t('3174')}}</option>
                                                    <option v-for="schmod_certification in schmod_certificationSelection" v-bind:value="schmod_certification">
                                                        <span v-if="schmod_certification.text.useTextKey == false">{{schmod_certification.text.textUI}}</span>
                                                        <span v-if="schmod_certification.text.useTextKey == true">{{$t(schmod_certification.text.textKey)}}</span>
                                                    </option>
                                                </select>
                                            </div>
                                            <div v-if="schmod_certificationSelection.length>0 && schmod_certificationSelection.length<=4">
                                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_Certification==null, my_input_background_color_at_validInput:selected_schmod_Certification!=null}">
                                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                                        <li v-for="schmod_certification in schmod_certificationSelection">
                                                            <input type="checkbox" @input="change_Selected_atElementOf_schmod_certification(schmod_certification)" v-bind:checked="schmod_certification.selected" v-bind:disabled="schmod_certification.selected" />
                                                            <span style="margin-left:10px">
                                                                <span v-if="schmod_certification.text.useTextKey == false">{{schmod_certification.text.textUI}}</span>
                                                                <span v-if="schmod_certification.text.useTextKey == true">{{$t(schmod_certification.text.textKey)}}</span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                                            <div>
                                                <div>{{$t('3559')}}:</div>
                                            </div>
                                            <div v-if="schmod_ProdApSelection.length==0 || schmod_ProdApSelection.length>4">
                                                <select v-model="selected_schmod_ProAp" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_ProAp==null, my_input_background_color_at_validInput:selected_schmod_ProAp!=null}">
                                                    <option value="null" hidden>{{$t('3174')}}</option>
                                                    <option value="" hidden>{{$t('3174')}}</option>
                                                    <option v-for="proAp in schmod_ProdApSelection" v-bind:value="proAp">
                                                        <span v-if="proAp.text.useTextKey == false">{{proAp.text.textUI}}</span>
                                                        <span v-if="proAp.text.useTextKey == true">{{$t(proAp.text.textKey)}}</span>
                                                    </option>
                                                </select>
                                            </div>
                                            <div v-if="schmod_ProdApSelection.length>0 && schmod_ProdApSelection.length<=4">
                                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_ProAp==null, my_input_background_color_at_validInput:selected_schmod_ProAp!=null}">
                                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                                        <li v-for="proAp in schmod_ProdApSelection">
                                                            <input type="checkbox" @input="change_Selected_atElementOf_schmod_ProAp(proAp)" v-bind:checked="proAp.selected" v-bind:disabled="proAp.selected" />
                                                            <span style="margin-left:10px">
                                                                <span v-if="proAp.text.useTextKey == false">{{proAp.text.textUI}}</span>
                                                                <span v-if="proAp.text.useTextKey == true">{{$t(proAp.text.textKey)}}</span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                                            <div>
                                                <div>{{$t('3560')}}:</div>
                                            </div>
                                            <div v-if="schmod_motorVoltageSelection.length==0 || schmod_motorVoltageSelection.length>4">
                                                <select v-model="selected_schmod_motorVoltage" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_motorVoltage==null, my_input_background_color_at_validInput:selected_schmod_motorVoltage!=null}">
                                                    <option value="null" hidden>{{$t('3174')}}</option>
                                                    <option value="" hidden>{{$t('3174')}}</option>
                                                    <option v-for="motorVoltage in schmod_motorVoltageSelection" v-bind:value="motorVoltage">{{motorVoltage.value}}</option>
                                                </select>
                                            </div>
                                            <div v-if="schmod_motorVoltageSelection.length>0 && schmod_motorVoltageSelection.length<=4">
                                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_motorVoltage==null, my_input_background_color_at_validInput:selected_schmod_motorVoltage!=null}">
                                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                                        <li v-for="motorVoltage in schmod_motorVoltageSelection">
                                                            <input type="checkbox" @input="change_Selected_atElementOf_schmod_motorVoltage(motorVoltage)" v-bind:checked="motorVoltage.selected" v-bind:disabled="motorVoltage.selected" />
                                                            <span style="margin-left:10px">{{motorVoltage.value}}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                                            <div>
                                                <div>{{$t('3561')}}:</div>
                                            </div>
                                            <div>
                                                <select v-model="selected_schmod_PSGSize" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_PSGSize==null, my_input_background_color_at_validInput:selected_schmod_PSGSize!=null}">
                                                    <option value="null" hidden>{{$t('3174')}}</option>
                                                    <option value="" hidden>{{$t('3174')}}</option>
                                                    <option v-for="psgSize in schmod_PSGSizeSelection" v-bind:value="psgSize">
                                                        <span v-if="psgSize.text.useTextKey == false">{{psgSize.text.textUI}}</span>
                                                        <span v-if="psgSize.text.useTextKey == true">{{$t(psgSize.text.textKey)}}</span>
                                                    </option>
                                                </select>
                                            </div>
                                            <!--<div v-if="schmod_PSGSizeSelection.length>0 && schmod_PSGSizeSelection.length<=4">
                                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_PSGSize==null, my_input_background_color_at_validInput:selected_schmod_PSGSize!=null}">
                                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                                        <li v-for="psgSize in schmod_PSGSizeSelection">
                                                            <input type="checkbox" @input="change_Selected_atElementOf_schmod_PSGSize(psgSize)" v-bind:checked="psgSize.selected" v-bind:disabled="psgSize.selected" />
                                                            <span style="margin-left:10px">
                                                                <span v-if="psgSize.text.useTextKey == false">{{psgSize.text.textUI}}</span>
                                                                <span v-if="psgSize.text.useTextKey == true">{{$t(psgSize.text.textKey)}}</span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>-->
                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                                            <div>
                                                <div>{{$t('3562')}}:</div>
                                            </div>
                                            <div>
                                                <ec
                                                    <select v-model="selected_schmod_motorID" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_motorID==null, my_input_background_color_at_validInput:selected_schmod_motorID!=null}">
                                                        <option value="null" hidden>{{$t('3174')}}</option>
                                                        <option value="" hidden>{{$t('3174')}}</option>
                                                        <option v-for="motorID in schmod_motorIDSelection" v-bind:value="motorID">
                                                            <span v-if="motorID.text.useTextKey == false">{{motorID.text.textUI}}</span>
                                                            <span v-if="motorID.text.useTextKey == true">{{$t(motorID.text.textKey)}}</span>
                                                        </option>
                                                    </select>
                                            </div>
                                            <!--<div v-if="schmod_motorIDSelection.length>0 && schmod_motorIDSelection.length<=4">
                                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_motorID==null, my_input_background_color_at_validInput:selected_schmod_motorID!=null}">
                                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                                        <li v-for="motorID in schmod_motorIDSelection" v-if="motorID.text.text!=null&&motorID.text.text!=''">
                                                            <input type="checkbox" @input="change_Selected_atElementOf_schmod_motorID(motorID)" v-bind:checked="motorID.selected" v-bind:disabled="motorID.selected" />
                                                            <span style="margin-left:10px">
                                                                <span v-if="motorID.text.useTextKey == false">{{motorID.text.textUI}}</span>
                                                                <span v-if="motorID.text.useTextKey == true">{{$t(motorID.text.textKey)}}</span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>-->
                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                                            <div>
                                                <div>{{$t('3563')}}:</div>
                                            </div>
                                            <div>
                                                <select v-model="selected_schmod_motorCOSName" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_motorCOSName==null, my_input_background_color_at_validInput:selected_schmod_motorCOSName!=null}">
                                                    <option value="null" hidden>{{$t('3174')}}</option>
                                                    <option value="" hidden>{{$t('3174')}}</option>
                                                    <option v-for="motorCOSName in schmod_motorCOSNameSelection" v-bind:value="motorCOSName">
                                                        <span v-if="motorCOSName.text.useTextKey == false">{{motorCOSName.text.textUI}}</span>
                                                        <span v-if="motorCOSName.text.useTextKey == true">{{$t(motorCOSName.text.textKey)}}</span>
                                                    </option>
                                                </select>
                                            </div>
                                            <!--<div v-if="schmod_motorCOSNameSelection.length>0 && schmod_motorCOSNameSelection.length<=4">
                                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_motorCOSName==null, my_input_background_color_at_validInput:selected_schmod_motorCOSName!=null}">
                                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                                        <li v-for="motorCOSName in schmod_motorCOSNameSelection" v-if="motorCOSName.text.text!=null&&motorCOSName.text.text!=''">
                                                            <input type="checkbox" @input="change_Selected_atElementOf_schmod_motorCOSName(motorCOSName)" v-bind:checked="motorCOSName.selected" v-bind:disabled="motorCOSName.selected" />
                                                            <span style="margin-left:10px">
                                                                <span v-if="motorCOSName.text.useTextKey == false">{{motorCOSName.text.textUI}}</span>
                                                                <span v-if="motorCOSName.text.useTextKey == true">{{$t(motorCOSName.text.textKey)}}</span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>-->
                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                                            <div>
                                                <div>{{$t('3564')}}:</div>
                                            </div>
                                            <div v-if="schmod_inverterTypeSelection.length==0 || schmod_inverterTypeSelection.length>4">
                                                <select v-model="selected_schmod_inverterType" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_inverterType==null, my_input_background_color_at_validInput:selected_schmod_inverterType!=null}">
                                                    <option value="null" hidden>{{$t('3174')}}</option>
                                                    <option value="" hidden>{{$t('3174')}}</option>
                                                    <option v-for="inverterType in schmod_inverterTypeSelection" v-bind:value="inverterType">
                                                        <span v-if="inverterType.text.useTextKey == false">{{inverterType.text.textUI}}</span>
                                                        <span v-if="inverterType.text.useTextKey == true">{{$t(inverterType.text.textKey)}}</span>
                                                    </option>
                                                </select>
                                            </div>
                                            <div v-if="schmod_inverterTypeSelection.length>0 && schmod_inverterTypeSelection.length<=4">
                                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_inverterType==null, my_input_background_color_at_validInput:selected_schmod_inverterType!=null}">
                                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                                        <li v-for="inverterType in schmod_inverterTypeSelection">
                                                            <input type="checkbox" @input="change_Selected_atElementOf_schmod_inverterType(inverterType)" v-bind:checked="inverterType.selected" v-bind:disabled="inverterType.selected" />
                                                            <span style="margin-left:10px">
                                                                <span v-if="inverterType.text.useTextKey == false">{{inverterType.text.textUI}}</span>
                                                                <span v-if="inverterType.text.useTextKey == true">{{$t(inverterType.text.textKey)}}</span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div style="margin-left: 20px; color: #808080">
                                                <span>{{$t('3571')}}</span>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                                            <div>
                                                <div>{{$t('3565')}}:</div>
                                            </div>
                                            <div v-if="schmod_cableLengthSelection.length==0 || schmod_cableLengthSelection.length>4">
                                                <select v-model="selected_schmod_cableLength" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_cableLength==null, my_input_background_color_at_validInput:selected_schmod_cableLength!=null}">
                                                    <option value="null" hidden>{{$t('3174')}}</option>
                                                    <option value="" hidden>{{$t('3174')}}</option>
                                                    <option v-for="cableLength in schmod_cableLengthSelection" v-bind:value="cableLength">
                                                        <span v-if="cableLength.text.useTextKey == false">{{cableLength.text.textUI}}</span>
                                                        <span v-if="cableLength.text.useTextKey == true">{{$t(cableLength.text.textKey)}}</span>
                                                    </option>
                                                </select>
                                            </div>
                                            <div v-if="schmod_cableLengthSelection.length>0 && schmod_cableLengthSelection.length<=4">
                                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_cableLength==null, my_input_background_color_at_validInput:selected_schmod_cableLength!=null}">
                                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                                        <li v-for="cableLength in schmod_cableLengthSelection">
                                                            <input type="checkbox" @input="change_Selected_atElementOf_schmod_cableLength(cableLength)" v-bind:checked="cableLength.selected" v-bind:disabled="cableLength.selected" />
                                                            <span style="margin-left:10px">
                                                                <span v-if="cableLength.text.useTextKey == false">{{cableLength.text.textUI}}</span>
                                                                <span v-if="cableLength.text.useTextKey == true">{{$t(cableLength.text.textKey)}}</span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div style="margin-left: 20px; color: #808080">
                                                <span>{{$t('3572')}}</span>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                                            <div>
                                                <div>{{$t('3566')}}:</div>
                                            </div>
                                            <div v-if="schmod_cableLengthBrakeSelection.length==0 || schmod_cableLengthBrakeSelection.length>4">
                                                <select v-model="selected_schmod_cableLengthBrake" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_cableLengthBrake==null, my_input_background_color_at_validInput:selected_schmod_cableLengthBrake!=null}">
                                                    <option value="null" hidden>{{$t('3174')}}</option>
                                                    <option value="" hidden>{{$t('3174')}}</option>
                                                    <option v-for="cableLengthBrake in schmod_cableLengthBrakeSelection" v-bind:value="cableLengthBrake">
                                                        <span v-if="cableLengthBrake.text.useTextKey == false">{{cableLengthBrake.text.textUI}}</span>
                                                        <span v-if="cableLengthBrake.text.useTextKey == true">{{$t(cableLengthBrake.text.textKey)}}</span>
                                                    </option>
                                                </select>
                                            </div>
                                            <div v-if="schmod_cableLengthBrakeSelection.length>0 && schmod_cableLengthBrakeSelection.length<=4">
                                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_cableLengthBrake==null, my_input_background_color_at_validInput:selected_schmod_cableLengthBrake!=null}">
                                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                                        <li v-for="cableLengthBrake in schmod_cableLengthBrakeSelection">
                                                            <input type="checkbox" @input="change_Selected_atElementOf_schmod_cableLengthBrake(cableLengthBrake)" v-bind:checked="cableLengthBrake.selected" v-bind:disabled="cableLengthBrake.selected" />
                                                            <span style="margin-left:10px">
                                                                <span v-if="cableLengthBrake.text.useTextKey == false">{{cableLengthBrake.text.textUI}}</span>
                                                                <span v-if="cableLengthBrake.text.useTextKey == true">{{$t(cableLengthBrake.text.textKey)}}</span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div style="margin-left: 20px; color: #808080">
                                                <span>{{$t('3573')}}</span>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                                            <div>
                                                <div>{{$t('3567')}}:</div>
                                            </div>
                                            <div v-if="schmod_handWheelSelection.length==0 || schmod_handWheelSelection.length>4">
                                                <select v-model="selected_schmod_handWheel" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_handWheel==null, my_input_background_color_at_validInput:selected_schmod_handWheel!=null}">
                                                    <option value="null" hidden>{{$t('3174')}}</option>
                                                    <option value="" hidden>{{$t('3174')}}</option>
                                                    <option v-for="handWheel in schmod_handWheelSelection" v-bind:value="handWheel">
                                                        <span v-if="handWheel.text.useTextKey == false">{{handWheel.text.textUI}}</span>
                                                        <span v-if="handWheel.text.useTextKey == true">{{$t(handWheel.text.textKey)}}</span>
                                                    </option>
                                                </select>
                                            </div>
                                            <div v-if="schmod_handWheelSelection.length>0 && schmod_handWheelSelection.length<=4">
                                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_handWheel==null, my_input_background_color_at_validInput:selected_schmod_handWheel!=null}">
                                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                                        <li v-for="handWheel in schmod_handWheelSelection">
                                                            <input type="checkbox" @input="change_Selected_atElementOf_schmod_handWheel(handWheel)" v-bind:checked="handWheel.selected" v-bind:disabled="handWheel.selected" />
                                                            <span style="margin-left:10px">
                                                                <span v-if="handWheel.text.useTextKey == false">{{handWheel.text.textUI}}</span>
                                                                <span v-if="handWheel.text.useTextKey == true">{{$t(handWheel.text.textKey)}}</span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div style="margin-left: 20px; color: #808080">
                                                <span>{{$t('3574')}}</span>
                                            </div>

                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                                            <div>
                                                <div>{{$t('3568')}}:</div>
                                            </div>
                                            <div v-if="schmod_handReleaseSelection.length==0 || schmod_handReleaseSelection.length>4">
                                                <select v-model="selected_schmod_handRelease" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_handRelease==null, my_input_background_color_at_validInput:selected_schmod_handRelease!=null}">
                                                    <option value="null" hidden>{{$t('3174')}}</option>
                                                    <option value="" hidden>{{$t('3174')}}</option>
                                                    <option v-for="handRelease in schmod_handReleaseSelection" v-bind:value="handRelease">
                                                        <span v-if="handRelease.text.useTextKey == false">{{handRelease.text.textUI}}</span>
                                                        <span v-if="handRelease.text.useTextKey == true">{{$t(handRelease.text.textKey)}}</span>
                                                    </option>
                                                </select>
                                            </div>
                                            <div v-if="schmod_handReleaseSelection.length>0 && schmod_handReleaseSelection.length<=4">
                                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_handRelease==null, my_input_background_color_at_validInput:selected_schmod_handRelease!=null}">
                                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                                        <li v-for="handRelease in schmod_handReleaseSelection">
                                                            <input type="checkbox" @input="change_Selected_atElementOf_schmod_handRelease(handRelease)" v-bind:checked="handRelease.selected" v-bind:disabled="handRelease.selected" />
                                                            <span style="margin-left:10px">
                                                                <span v-if="handRelease.text.useTextKey == false">{{handRelease.text.textUI}}</span>
                                                                <span v-if="handRelease.text.useTextKey == true">{{$t(handRelease.text.textKey)}}</span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div style="margin-left: 20px; color: #808080">
                                                <span>{{$t('3575')}}</span>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                                            <div>
                                                <div>{{$t('3569')}}</div>
                                            </div>
                                            <div>
                                                <input class="form-control" v-model="schmod_grooveDistance" v-bind:class="{my_input_border_at_invalidInput: schmod_grooveDistance_ValidationResult.invalid==true, my_input_background_color_at_validInput:schmod_grooveDistance_ValidationResult.invalid==false}" />
                                                <div>
                                                    <InvalidityReasons v-if="schmod_grooveDistance_ValidationResult.invalid==true" v-bind:validationResult="schmod_grooveDistance_ValidationResult"></InvalidityReasons>
                                                </div>
                                            </div>
                                            <div style="margin-left: 20px; color: #808080">
                                                <span>{{$t('3576')}}</span>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                                            <div>
                                                <div>{{$t('1123')}}:</div>
                                            </div>
                                            <div v-if="schmod_grooveSelection.length==0 || schmod_grooveSelection.length>4">
                                                <select v-model="selected_schmod_groove" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_groove==null, my_input_background_color_at_validInput:selected_schmod_groove!=null}">
                                                    <option value="null" hidden>{{$t('3174')}}</option>
                                                    <option value="" hidden>{{$t('3174')}}</option>
                                                    <option v-for="groove in schmod_grooveSelection" v-bind:value="groove">
                                                        <span v-if="groove.text.useTextKey == false">{{groove.text.textUI}}</span>
                                                        <span v-if="groove.text.useTextKey == true">{{$t(groove.text.textKey)}}</span>
                                                    </option>
                                                </select>
                                            </div>
                                            <div v-if="schmod_grooveSelection.length>0 && schmod_grooveSelection.length<=4">
                                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_groove==null, my_input_background_color_at_validInput:selected_schmod_groove!=null}">
                                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                                        <li v-for="groove in schmod_grooveSelection">
                                                            <input type="checkbox" @input="change_Selected_atElementOf_schmod_groove(groove)" v-bind:checked="groove.selected" v-bind:disabled="groove.selected" />
                                                            <span style="margin-left:10px">
                                                                <span v-if="groove.text.useTextKey == false">{{groove.text.textUI}}</span>
                                                                <span v-if="groove.text.useTextKey == true">{{$t(groove.text.textKey)}}</span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div style="margin-left: 20px; color: #808080">
                                                <div>{{$t('3577')}}</div>
                                                <div>{{$t('3578')}}</div>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                                            <div>
                                                <div class="ZAfont_family ZAfont_size">{{$t('165')}}:</div>
                                            </div>
                                            <div v-if="schmod_numberOfGroovesSelection.length==0 || schmod_numberOfGroovesSelection.length>4">
                                                <select v-model="selected_schmod_numberOfGrooves" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_numberOfGrooves==null, my_input_background_color_at_validInput:selected_schmod_numberOfGrooves!=null}">
                                                    <option value="null" hidden>{{$t('3174')}}</option>
                                                    <option value="" hidden>{{$t('3174')}}</option>
                                                    <option v-for="numberOfGrooves in schmod_numberOfGroovesSelection" v-bind:value="numberOfGrooves">
                                                        <span v-if="numberOfGrooves.text.useTextKey == false">{{numberOfGrooves.text.textUI}}</span>
                                                        <span v-if="numberOfGrooves.text.useTextKey == true">{{$t(numberOfGrooves.text.textKey)}}</span>
                                                    </option>
                                                </select>
                                            </div>
                                            <div v-if="schmod_numberOfGroovesSelection.length>0 && schmod_numberOfGroovesSelection.length<=4">
                                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selected_schmod_numberOfGrooves==null, my_input_background_color_at_validInput:selected_schmod_numberOfGrooves!=null}">
                                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                                        <li v-for="numberOfGrooves in schmod_numberOfGroovesSelection">
                                                            <input type="checkbox" @input="change_Selected_atElementOf_schmod_numberOfGrooves(numberOfGrooves)" v-bind:checked="numberOfGrooves.selected" v-bind:disabled="numberOfGrooves.selected" />
                                                            <span style="margin-left:10px">
                                                                <span v-if="numberOfGrooves.text.useTextKey == false">{{numberOfGrooves.text.textUI}}</span>
                                                                <span v-if="numberOfGrooves.text.useTextKey == true">{{$t(numberOfGrooves.text.textKey)}}</span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div style="margin-left: 20px; color: #808080">
                                                <span>{{$t('3579')}}</span>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                                            <div>
                                                <div>{{$t('3570')}}</div>
                                            </div>
                                            <div>
                                                <input class="form-control" v-model="operatingCurrentSplus" v-bind:class="{my_input_border_at_invalidInput: operatingCurrentSplus_ValidationResult.invalid==true, my_input_background_color_at_validInput:operatingCurrentSplus_ValidationResult.invalid==false}" />
                                                <div>
                                                    <InvalidityReasons v-if="operatingCurrentSplus_ValidationResult.invalid==true" v-bind:validationResult="operatingCurrentSplus_ValidationResult"></InvalidityReasons>
                                                </div>
                                            </div>
                                            <div style="margin-left: 20px; color: #808080">
                                                <span>{{$t('3580')}}</span>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size my_font-weight_600" style="margin-top: 40px; margin-bottom: 20px; color: #808080">
                                            <div>{{$t('3581')}}</div>
                                            <div>{{$t('3582')}}</div>
                                            <div>{{$t('3583')}}</div>
                                            <div>{{$t('3584')}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my_modal-footer" style="align-items:flex-end; justify-content:flex-end;">
                                <button @click="End_PSG518_600()" class="btn my_btn_standard">{{$t('1018')}}</button>
                                <button @click="Save_PSG518_600()" class="btn my_btn_standard" style="margin-left: 5px" :disabled="schmod_Valid==false">{{$t('1023')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showSCHMOD==false && schmod_Valid==true" style="overflow:auto">
                    <hr />
                    <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('1098')}}</div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3455')}}:</div>
                        </div>
                        <div v-if="certificationSelection.length==0 || certificationSelection.length>4">
                            <select v-model="selectedCertification" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selectedCertification==null, my_input_background_color_at_validInput:selectedCertification!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="certification in certificationSelection" v-bind:value="certification">
                                    <span v-if="certification.text.useTextKey == false">{{certification.text.textUI}}</span>
                                    <span v-if="certification.text.useTextKey == true">{{$t(certification.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="certificationSelection.length>0 && certificationSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedCertification==null, my_input_background_color_at_validInput:selectedCertification!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="certification in certificationSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_certification(certification)" v-bind:checked="certification.selected" v-bind:disabled="certification.selected" />
                                        <span v-if="certification.text.useTextKey == false" style="margin-left:10px">{{certification.text.textUI}}</span>
                                        <span v-if="certification.text.useTextKey == true" style="margin-left:10px">{{$t(certification.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3456')}}:</div>
                        </div>
                        <div v-if="preferenceSelection.length==0 || preferenceSelection.length>4">
                            <select v-model="selectedPreference" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selectedCertification==null, my_input_background_color_at_validInput:selectedPreference!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="preference in preferenceSelection" v-bind:value="preference">
                                    <span v-if="preference.text.useTextKey == false">{{preference.text.textUI}}</span>
                                    <span v-if="preference.text.useTextKey == true">{{$t(preference.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="preferenceSelection.length>0 && preferenceSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedCertification==null, my_input_background_color_at_validInput:selectedPreference!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="preference in preferenceSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_preference(preference)" v-bind:checked="preference.selected" v-bind:disabled="preference.selected" />
                                        <span v-if="preference.text.useTextKey == false" style="margin-left:10px">{{preference.text.textUI}}</span>
                                        <span v-if="preference.text.useTextKey == true" style="margin-left:10px">{{$t(preference.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3067')}}:</div>
                        </div>
                        <div v-if="temperatureMonitoringSelection.length==0 || temperatureMonitoringSelection.length>4">
                            <select v-model="selectedTemperatureMonitoring" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selectedTemperatureMonitoring==null, my_input_background_color_at_validInput:selectedTemperatureMonitoring!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="temperatureMonitoring in temperatureMonitoringSelection" v-bind:value="temperatureMonitoring.text">{{temperatureMonitoring.text}}</option>
                            </select>
                        </div>
                        <div v-if="temperatureMonitoringSelection.length>0 && temperatureMonitoringSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedTemperatureMonitoring==null, my_input_background_color_at_validInput:selectedTemperatureMonitoring!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="temperatureMonitoring in temperatureMonitoringSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_temperatureMonitoring(temperatureMonitoring)" v-bind:checked="temperatureMonitoring.selected" v-bind:disabled="temperatureMonitoring.selected" />
                                        <span style="margin-left:10px">{{temperatureMonitoring.text}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('407')}}:</div>
                        </div>
                        <div v-if="motorTypeSelection.length==0 || motorTypeSelection.length>4">
                            <select v-model="selectedMotorType" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selectedMotorType==null, my_input_background_color_at_validInput:selectedMotorType!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="motorType in motorTypeSelection" v-bind:value="motorType">
                                    <span v-if="motorType.text.useTextKey == false">{{motorType.text.textUI}}</span>
                                    <span v-if="motorType.text.useTextKey == true">{{$t(motorType.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="motorTypeSelection.length>0 && motorTypeSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedMotorType==null, my_input_background_color_at_validInput:selectedMotorType!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="motorType in motorTypeSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_motorType(motorType)" v-bind:checked="motorType.selected" v-bind:disabled="motorType.selected" />
                                        <span style="margin-left:10px">{{motorType.text.textUI}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3453')}}:</div>
                        </div>
                        <div v-if="motorSizeSelection.length==0 || motorSizeSelection.length>4">
                            <select v-model="selectedMotorSize" class="my_select" style="width:100%" v-bind:disabled="selectedMotorType==null || selectedMotorType.text.text==null" v-bind:class="{my_input_border_at_invalidInput: selectedMotorSize==null, my_input_background_color_at_validInput:selectedMotorSize!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="motorSize in motorSizeSelection" v-bind:value="motorSize">
                                    <span v-if="motorSize.text.useTextKey == false">{{motorSize.text.textUI}}</span>
                                    <span v-if="motorSize.text.useTextKey == true">{{$t(motorSize.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="motorSizeSelection.length>0 && motorSizeSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedMotorSize==null, my_input_background_color_at_validInput:selectedMotorSize!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="motorSize in motorSizeSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_motorSize(motorSize)" v-bind:checked="motorSize.selected" v-bind:disabled="motorSize.selected" />
                                        <span style="margin-left:10px">{{motorSize.text.textUI}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3454')}}:</div>
                        </div>
                        <div v-if="motorPackageSelection.length==0 || motorPackageSelection.length>4">
                            <select v-model="selectedMotorPackage" class="my_select" style="width:100%" v-bind:disabled="selectedMotorSize==null || selectedMotorSize.text.text==null" v-bind:class="{my_input_border_at_invalidInput: selectedMotorPackage==null, my_input_background_color_at_validInput:selectedMotorPackage!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="motorPackage in motorPackageSelection" v-bind:value="motorPackage">
                                    <span v-if="motorPackage.text.useTextKey == false">{{motorPackage.text.textUI}}</span>
                                    <span v-if="motorPackage.text.useTextKey == true">{{$t(motorPackage.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="motorPackageSelection.length>0 && motorPackageSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedMotorPackage==null, my_input_background_color_at_validInput:selectedMotorPackage!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="motorPackage in motorPackageSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_motorPackage(motorPackage)" v-bind:checked="motorPackage.selected" v-bind:disabled="motorPackage.selected" />
                                        <span style="margin-left:10px">{{motorPackage.text.textUI}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div v-if="motorZusatzSelection!=null" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">Zusatz:</div>
                        </div>
                        <div v-if="motorZusatzSelection.length==0 || motorZusatzSelection.length>4">
                            <select v-model="selectedMotorZusatz" class="my_select" style="width:100%" v-bind:disabled="selectedMotorPackage==null || selectedMotorPackage.text.text==null" v-bind:class="{my_input_border_at_invalidInput: motorZusatzSelection.length > 0 && selectedMotorPackage==null, my_input_background_color_at_validInput: motorZusatzSelection.length == 0 || selectedMotorPackage!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="motorZusatz in motorZusatzSelection" v-bind:value="motorZusatz">
                                    <span v-if="motorZusatz.text.useTextKey == false">{{motorZusatz.text.textUI}}</span>
                                    <span v-if="motorZusatz.text.useTextKey == true">{{$t(motorZusatz.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="motorZusatzSelection.length>0 && motorZusatzSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: motorZusatzSelection.length > 0 && selectedMotorPackage==null, my_input_background_color_at_validInput: motorZusatzSelection.length == 0 || selectedMotorPackage!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="motorZusatz in motorZusatzSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_motorZusatz(motorZusatz)" v-bind:checked="motorZusatz.selected" v-bind:disabled="motorZusatz.selected && motorZusatzNecessary==true" />
                                        <span style="margin-left:10px">{{motorZusatz.text.textUI}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('121')}}:</div>
                        </div>
                        <div v-if="motorVoltageSelection.length==0 || motorVoltageSelection.length>4">
                            <select v-model="selectedMotorVoltage" class="my_select" style="width:100%" v-bind:disabled="((selectedMotorPackage==null || selectedMotorPackage.text.text==null)) || (motorZusatzSelection!=null && (selectedMotorZusatz==null || selectedMotorZusatz.text.text==null) && motorZusatzNecessary==true)" v-bind:class="{my_input_border_at_invalidInput: selectedMotorVoltage==null, my_input_background_color_at_validInput:selectedMotorVoltage!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="motorVoltage in motorVoltageSelection" v-bind:value="motorVoltage">{{motorVoltage.value}}</option>
                            </select>
                        </div>
                        <div v-if="motorVoltageSelection.length>0 && motorVoltageSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedMotorVoltage==null, my_input_background_color_at_validInput:selectedMotorVoltage!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="motorVoltage in motorVoltageSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_motorVoltage(motorVoltage)" v-bind:checked="motorVoltage.selected" v-bind:disabled="motorVoltage.selected" />
                                        <span style="margin-left:10px">{{motorVoltage.value}} V</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('1091')}}:</div>
                        </div>
                        <div v-if="motorRatedSpeedSelection.length==0 || motorRatedSpeedSelection.length>4">
                            <select v-model="selectedMotorRatedSpeed" class="my_select" style="width:100%" v-bind:disabled="selectedMotorVoltage==null || selectedMotorVoltage.value==null" v-bind:class="{my_input_border_at_invalidInput: selectedMotorRatedSpeed==null, my_input_background_color_at_validInput:selectedMotorRatedSpeed!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="motorRatedSpeed in motorRatedSpeedSelection" v-bind:value="motorRatedSpeed">{{motorRatedSpeed.value}}</option>
                            </select>
                        </div>
                        <div v-if="motorRatedSpeedSelection.length>0 && motorRatedSpeedSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedMotorRatedSpeed==null, my_input_background_color_at_validInput:selectedMotorRatedSpeed!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="motorRatedSpeed in motorRatedSpeedSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_motorRatedSpeed(motorRatedSpeed)" v-bind:checked="motorRatedSpeed.selected" v-bind:disabled="motorRatedSpeed.selected" />
                                        <span style="margin-left:10px">{{motorRatedSpeed.value}} rpm</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3457')}}:</div>
                            </div>
                            <div v-if="upgradeSelection.length==0 || upgradeSelection.length>4">
                                <select v-model="selectedUpgrade" class="my_select" style="width:100%" v-bind:disabled="selectedMotorVoltage==null || selectedMotorVoltage.value==null" v-bind:class="{my_input_border_at_invalidInput: selectedUpgrade==null, my_input_background_color_at_validInput:selectedUpgrade!=null}">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option value="" hidden>{{$t('3174')}}</option>
                                    <option v-for="upgrade in upgradeSelection" v-bind:value="upgrade.text" v-bind:disabled="upgrade.disabled">{{upgrade.text}}</option>
                                </select>
                            </div>
                            <div v-if="upgradeSelection.length>0 && upgradeSelection.length<=4" style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedUpgrade==null, my_input_background_color_at_validInput:selectedUpgrade!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="upgrade in upgradeSelection" v-if="upgrade.display!=null && upgrade.display==true">
                                        <input type="checkbox" @input="change_Selected_atElementOf_upgradeSelection(upgrade)" v-bind:checked="upgrade.selected" v-bind:disabled="upgrade.disabled" />
                                        <span style="margin-left:10px">{{upgrade.text}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('298')}}:</div>
                        </div>
                        <div v-if="forcedVentilationSelection.length==0 && forcedVentilationSelection.length>4">
                            <select v-model="selectedForcedVentilation" class="my_select" style="width:100%" v-bind:disabled="selectedMotorVoltage==null || selectedMotorVoltage.value==null" v-bind:class="{my_input_border_at_invalidInput: selectedForcedVentilation==null, my_input_background_color_at_validInput:selectedForcedVentilation!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="forcedVentilation in forcedVentilationSelection" v-bind:value="forcedVentilation">
                                    <span v-if="forcedVentilation.text.useTextKey==false">{{forcedVentilation.text.textUI}}</span>
                                    <span v-if="forcedVentilation.text.useTextKey==true">{{$t(forcedVentilation.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="forcedVentilationSelection.length>0 && forcedVentilationSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedForcedVentilation==null, my_input_background_color_at_validInput:selectedForcedVentilation!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="forcedVentilation in forcedVentilationSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_forcedVentilation(forcedVentilation)" v-bind:checked="forcedVentilation.selected" v-bind:disabled="forcedVentilation.selected" />
                                        <span v-if="forcedVentilation.text.useTextKey == false" style="margin-left:10px">{{forcedVentilation.text.textUI}}</span>
                                        <span v-if="forcedVentilation.text.useTextKey == true" style="margin-left:10px">{{$t(forcedVentilation.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div v-if="selectedForcedVentilationWithBrakeRelease!=null" class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>{{$t('3553')}}</div>
                        <div>
                            <div>
                                <span v-if="selectedForcedVentilationWithBrakeRelease.useTextKey==false">{{selectedForcedVentilationWithBrakeRelease.textUI}} </span>
                                <span v-if="selectedForcedVentilationWithBrakeRelease.useTextKey==true">{{$t(selectedForcedVentilationWithBrakeRelease.textKey)}} </span>
                            </div>
                        </div>
                    </div>
                    <!--<div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div class="ZAfont_family ZAfont_size">
                            <div>{{$t('3049')}} 1:</div>
                        </div>
                        <div>
                            <input class="form-control" v-bind:placeholder="this.$i18n.i18next.t('3173')" v-model="operatingCurrentSplus" v-bind:disabled="customerName!='S+'" />
                        </div>
                    </div>-->
                    <!--</div>-->
                    <!--<div style="margin-left:50px">
                            <iframe src="https://za.io/products/drive-technology/zatop-sm315" style="height:500px;width:500px"></iframe>
                            <div>
                                <a href="https://za.io/products/drive-technology/zatop-sm315" target="_blank">ZAtop SM315</a>
                            </div>
                        </div>
                    </div>-->
                    <hr />
                </div>
                <div v-if="showSCHMOD==false && schmod_Valid==true" style="overflow:auto">
                    <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('1301')}}</div>

                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('411')}} {{$t('3395')}}:</div>
                        </div>
                        <div v-if="deliverTractionSheaveSelection.length==0 || deliverTractionSheaveSelection.length>4">
                            <select v-model="selectedDeliverTractionSheave" class="my_select" style="width:100%" v-bind:disabled="selectedTemperatureMonitoring==null || selectedTemperatureMonitoring==''" v-bind:class="{my_input_border_at_invalidInput: selectedDeliverTractionSheave==null, my_input_background_color_at_validInput:selectedDeliverTractionSheave!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="deliver in deliverTractionSheaveSelection" v-bind:value="deliver">
                                    <span v-if="deliver.text.useTextKey==null || deliver.text.useTextKey==false">{{deliver.text.textUI}}</span>
                                    <span v-if="deliver.text.useTextKey!=null && deliver.text.useTextKey==true">{{$t(deliver.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="deliverTractionSheaveSelection.length>0 && deliverTractionSheaveSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedDeliverTractionSheave==null, my_input_background_color_at_validInput:selectedDeliverTractionSheave!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="deliver in deliverTractionSheaveSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_deliverTractionSheave(deliver)" v-bind:checked="deliver.selected" v-bind:disabled="deliver.selected" />
                                        <span v-if="deliver.text.useTextKey == false" style="margin-left:10px">{{deliver.text.textUI}}</span>
                                        <span v-if="deliver.text.useTextKey == true" style="margin-left:10px">{{$t(deliver.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div v-if="showTractionSheaveDetails==true">
                        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3460')}}:</div>
                            </div>
                            <div v-if="machiningForHandWheelSelection.length==0 || machiningForHandWheelSelection.length>4">
                                <select v-model="selectedMachiningForHandWheel" class="my_select" style="width:100%" v-bind:disabled="selectedTemperatureMonitoring==null || selectedTemperatureMonitoring==''" v-bind:class="{my_input_border_at_invalidInput: machiningForHandWheelSelection.length > 0 && selectedMachiningForHandWheel==null, my_input_background_color_at_validInput: machiningForHandWheelSelection == 0 || selectedMachiningForHandWheel!=null}">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option value="" hidden>{{$t('3174')}}</option>
                                    <option v-for="machiningForHandWheel in machiningForHandWheelSelection" v-bind:value="machiningForHandWheel">
                                        <span v-if="machiningForHandWheel.text.useTextKey==null || machiningForHandWheel.text.useTextKey==false">{{machiningForHandWheel.text.textUI}}</span>
                                        <span v-if="machiningForHandWheel.text.useTextKey!=null && machiningForHandWheel.text.useTextKey==true">{{$t(machiningForHandWheel.text.textKey)}}</span>
                                    </option>
                                </select>
                            </div>
                            <div v-if="machiningForHandWheelSelection.length>0 && machiningForHandWheelSelection.length<=4">
                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: machiningForHandWheelSelection.length > 0 && selectedMachiningForHandWheel==null, my_input_background_color_at_validInput: machiningForHandWheelSelection == 0 || selectedMachiningForHandWheel!=null}">
                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                        <li v-for="machiningForHandWheel in machiningForHandWheelSelection">
                                            <input type="checkbox" @input="change_Selected_atElementOf_machiningForHandWheel(machiningForHandWheel)" v-bind:checked="machiningForHandWheel.selected" v-bind:disabled="machiningForHandWheel.selected" />
                                            <span v-if="machiningForHandWheel.text.useTextKey == false" style="margin-left:10px">{{machiningForHandWheel.text.textUI}}</span>
                                            <span v-if="machiningForHandWheel.text.useTextKey == true" style="margin-left:10px">{{$t(machiningForHandWheel.text.textKey)}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3038')}} | {{$t('1182')}}:</div>
                            </div>
                            <div v-if="tractionSheave_DiameterAndWidthSelection.length==0 || tractionSheave_DiameterAndWidthSelection.length>4">
                                <select v-model="selectedTractionSheave_DiameterAndWidth" class="my_select" style="width:100%" v-bind:disabled="selectedTemperatureMonitoring==null || selectedTemperatureMonitoring==''" v-bind:class="{my_input_border_at_invalidInput: selectedTractionSheave_DiameterAndWidth==null, my_input_background_color_at_validInput:selectedTractionSheave_DiameterAndWidth!=null}">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option value="" hidden>{{$t('3174')}}</option>
                                    <option v-for="diameterAndWidth in tractionSheave_DiameterAndWidthSelection" v-bind:value="diameterAndWidth">{{diameterAndWidth.text}}</option>
                                </select>
                            </div>
                            <div v-if="tractionSheave_DiameterAndWidthSelection.length>0 && tractionSheave_DiameterAndWidthSelection.length<=4">
                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedTractionSheave_DiameterAndWidth==null, my_input_background_color_at_validInput:selectedTractionSheave_DiameterAndWidth!=null}">
                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                        <li v-for="diameterAndWidth in tractionSheave_DiameterAndWidthSelection">
                                            <input type="checkbox" @input="change_Selected_atElementOf_diameterAndWidth(diameterAndWidth)" v-bind:checked="diameterAndWidth.selected" v-bind:disabled="diameterAndWidth.selected" />
                                            <span style="margin-left:10px">{{diameterAndWidth.text}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('1123')}}:</div>
                            </div>
                            <div v-if="sheaveProfileSelection.length==0 || sheaveProfileSelection.length>4">
                                <select v-model="selectedSheaveProfile" class="my_select" style="width:100%" v-bind:disabled="selectedTemperatureMonitoring==null || selectedTemperatureMonitoring==''" v-bind:class="{my_input_border_at_invalidInput: selectedSheaveProfile==null, my_input_background_color_at_validInput:selectedSheaveProfile!=null}">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option value="" hidden>{{$t('3174')}}</option>
                                    <option v-for="sheaveProfile in sheaveProfileSelection" v-bind:value="sheaveProfile">{{sheaveProfile.text.textUI}}</option>
                                </select>
                            </div>
                            <div v-if="sheaveProfileSelection.length>0 && sheaveProfileSelection.length<=4">
                                <div v-bind:class="{my_input_border_at_invalidInput: selectedSheaveProfile==null, my_input_background_color_at_validInput:selectedSheaveProfile!=null}">
                                    <ul class="my_inputCheckbox2 ZAfont_family ZAfont_size">
                                        <li v-for="sheaveProfile in sheaveProfileSelection">
                                            <div>
                                                <input type="checkbox" @input="change_Selected_atElementOf_sheaveProfile(sheaveProfile)" v-bind:checked="sheaveProfile.selected" v-bind:disabled="sheaveProfile.selected" />
                                                <span v-if="sheaveProfile.text.useTextKey == false" style="margin-left:10px">{{sheaveProfile.text.textUI}}</span>
                                                <span v-if="sheaveProfile.text.useTextKey == true" style="margin-left:10px">{{$t(sheaveProfile.text.textKey)}}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div v-if="showUnderCutAngleDecimal==false" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('1134')}}:</div>
                            </div>
                            <div>
                                <select v-model="selectedUndercutAngle" class="my_select" style="width:100%" v-bind:disabled="undercutAngleSelectionDisabled==true || selectedTemperatureMonitoring==null || selectedTemperatureMonitoring==''" v-bind:class="{my_input_border_at_invalidInput: undercutAngleSelectionLength > 0 && selectedUndercutAngle==null, my_input_background_color_at_validInput:undercutAngleSelectionLength==0 || selectedUndercutAngle!=null}">
                                    <option v-for="undercutAngle in undercutAngleSelection" v-bind:value="undercutAngle" v-if="undercutAngle.disabled==false">{{ValueDecimalSeparator(undercutAngle.value)}}</option>
                                </select>
                            </div>
                        </div>
                        <div v-if="showUnderCutAngleDecimal==true" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('1134')}}:</div>
                            </div>
                            <div>
                                <select v-model="selectedUndercutAngle" class="my_select" style="width:100%" v-bind:disabled="undercutAngleSelectionDisabled==true || selectedTemperatureMonitoring==null || selectedTemperatureMonitoring==''" v-bind:class="{my_input_border_at_invalidInput: undercutAngleSelectionLength > 0 && selectedUndercutAngle==null, my_input_background_color_at_validInput:undercutAngleSelectionLength==0 || selectedUndercutAngle!=null}">
                                    <option v-for="undercutAngle in undercutAngleSelection" v-bind:value="undercutAngle" v-if="undercutAngle.disabled==false">{{ValueDecimalSeparator(undercutAngle.ub)}} ({{ValueDecimalSeparator(undercutAngle.value)}})</option>
                                </select>
                            </div>
                        </div>
                        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('1135')}}:</div>
                            </div>
                            <div>
                                <select v-model="selectedVgrooveAngle" class="my_select" style="width:100%" v-bind:disabled="vgrooveAngleSelectionDisabled==true ||selectedTemperatureMonitoring==null || selectedTemperatureMonitoring==''" v-bind:class="{my_input_border_at_invalidInput: vgrooveAngleSelectionLength > 0 && selectedVgrooveAngle==null, my_input_background_color_at_validInput:vgrooveAngleSelectionLength==0 || selectedVgrooveAngle!=null}">
                                    <option v-for="vgrooveAngle in vgrooveAngleSelection" v-bind:value="vgrooveAngle" v-if="vgrooveAngle.disabled==false">{{vgrooveAngle.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('1067')}}:</div>
                            </div>
                            <div v-if="ropeDiameterSelectionLength==0 || ropeDiameterSelectionLength>4">
                                <select v-model="selectedRopeDiameter" class="my_select" style="width:100%" v-bind:disabled="selectedSheaveProfile==null || selectedSheaveProfile.text.text==null" v-bind:class="{my_input_border_at_invalidInput: selectedRopeDiameter==null, my_input_background_color_at_validInput:selectedRopeDiameter!=null}">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option value="" hidden>{{$t('3174')}}</option>
                                    <option v-for="ropeDiameter in ropeDiameterSelection" v-bind:value="ropeDiameter" v-if="ropeDiameter.disabled==false">{{ValueDecimalSeparator(ropeDiameter.value)}}</option>
                                </select>
                            </div>
                            <div v-if="ropeDiameterSelectionLength>0 && ropeDiameterSelectionLength<=4">
                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedRopeDiameter==null, my_input_background_color_at_validInput:selectedRopeDiameter!=null}">
                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                        <li v-for="ropeDiameter in ropeDiameterSelection" v-if="ropeDiameter.disabled==false">
                                            <input type="checkbox" @input="change_Selected_atElementOf_ropeDiameter(ropeDiameter)" v-bind:checked="ropeDiameter.selected" v-bind:disabled="ropeDiameter.selected" />
                                            <span style="margin-left:10px">{{ValueDecimalSeparator(ropeDiameter.value)}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('294')}}:</div>
                            </div>
                            <div v-if="grooveDistanceSelectionLength==0 || grooveDistanceSelectionLength>4">
                                <select v-model="selectedGrooveDistance" class="my_select" style="width:100%" v-bind:disabled="selectedRopeDiameter==null || selectedRopeDiameter.value==null" v-bind:class="{my_input_border_at_invalidInput: selectedGrooveDistance==null, my_input_background_color_at_validInput:selectedGrooveDistance!=null}">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option value="" hidden>{{$t('3174')}}</option>
                                    <option v-for="grooveDistance in grooveDistanceSelection" v-bind:value="grooveDistance" v-if="grooveDistance.disabled==false">{{ValueDecimalSeparator(grooveDistance.value)}}</option>
                                </select>
                            </div>
                            <div v-if="grooveDistanceSelectionLength>0 && grooveDistanceSelectionLength<=4">
                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedGrooveDistance==null, my_input_background_color_at_validInput:selectedGrooveDistance!=null}">
                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                        <li v-for="grooveDistance in grooveDistanceSelection" v-if="grooveDistance.disabled==false">
                                            <input type="checkbox" @input="change_Selected_atElementOf_grooveDistance(grooveDistance)" v-bind:checked="grooveDistance.selected" v-bind:disabled="grooveDistance.selected" />
                                            <span style="margin-left:10px">{{ValueDecimalSeparator(grooveDistance.value)}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('165')}}:</div>
                            </div>
                            <div v-if="numberOfGroovesSelectionLength==0 || numberOfGroovesSelectionLength>4">
                                <select v-model="selectedNumberOfGrooves" class="my_select" style="width:100%" v-bind:disabled="selectedGrooveDistance==null || selectedGrooveDistance.value==null" v-bind:class="{my_input_border_at_invalidInput: selectedNumberOfGrooves==null, my_input_background_color_at_validInput:selectedNumberOfGrooves!=null}">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option value="" hidden>{{$t('3174')}}</option>
                                    <option v-for="numberOfGrooves in numberOfGroovesSelection" v-bind:value="numberOfGrooves" v-if="numberOfGrooves.disabled==false">{{numberOfGrooves.value}}</option>
                                </select>
                            </div>
                            <div v-if="numberOfGroovesSelectionLength>0 && numberOfGroovesSelectionLength<=4">
                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedNumberOfGrooves==null, my_input_background_color_at_validInput:selectedNumberOfGrooves!=null}">
                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                        <li v-for="numberOfGrooves in numberOfGroovesSelection" v-if="numberOfGrooves.disabled==false">
                                            <input type="checkbox" @input="change_Selected_atElementOf_numberOfGrooves(numberOfGrooves)" v-bind:checked="numberOfGrooves.selected" v-bind:disabled="numberOfGrooves.selected" />
                                            <span style="margin-left:10px">{{numberOfGrooves.value}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
                <div v-if="showSCHMOD==false && schmod_Valid==true" style="overflow:auto">
                    <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('3533')}}</div>
                    <div style="display: grid; grid-template-columns: 2fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div v-if="showTractionSheaveDetails==false && showDeliverRope==true">
                                <div style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                                    <div>
                                        <div class="ZAfont_family ZAfont_size">{{$t('3038')}} | {{$t('1182')}}:</div>
                                    </div>
                                    <div v-if="tractionSheave_DiameterAndWidthSelection.length==0 || tractionSheave_DiameterAndWidthSelection.length>4">
                                        <select v-model="selectedTractionSheave_DiameterAndWidth" class="my_select" style="width:100%" v-bind:disabled="selectedTemperatureMonitoring==null || selectedTemperatureMonitoring==''" v-bind:class="{my_input_border_at_invalidInput: selectedTractionSheave_DiameterAndWidth==null, my_input_background_color_at_validInput:selectedTractionSheave_DiameterAndWidth!=null}">
                                            <option value="null" hidden>{{$t('3174')}}</option>
                                            <option value="" hidden>{{$t('3174')}}</option>
                                            <option v-for="diameterAndWidth in tractionSheave_DiameterAndWidthSelection" v-bind:value="diameterAndWidth">{{diameterAndWidth.text}}</option>
                                        </select>
                                    </div>
                                    <div v-if="tractionSheave_DiameterAndWidthSelection.length>0 && tractionSheave_DiameterAndWidthSelection.length<=4">
                                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedTractionSheave_DiameterAndWidth==null, my_input_background_color_at_validInput:selectedTractionSheave_DiameterAndWidth!=null}">
                                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                                <li v-for="diameterAndWidth in tractionSheave_DiameterAndWidthSelection">
                                                    <input type="checkbox" @input="change_Selected_atElementOf_diameterAndWidth(diameterAndWidth)" v-bind:checked="diameterAndWidth.selected" v-bind:disabled="diameterAndWidth.selected" />
                                                    <span style="margin-left:10px">{{diameterAndWidth.text}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                                <div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('3452')}} KID:</div>
                                </div>
                                <div v-if="customerIdKidSelection.length==0 || customerIdKidSelection.length>4">
                                    <select v-model="selectedCustomerIdKid" class="my_select" style="width:100%" v-bind:disabled="selectedNumberOfGrooves==null || selectedNumberOfGrooves=='' || deliverRopeDisabled==true" v-bind:class="{my_input_border_at_invalidInput: customerIdKidSelection.length > 0 && selectedCustomerIdKid==null, my_input_background_color_at_validInput: customerIdKidSelection.length == 0 || selectedCustomerIdKid!=null}">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option value="" hidden>{{$t('3174')}}</option>
                                        <option v-for="customerIdKid in customerIdKidSelection" v-bind:value="customerIdKid">
                                            <span v-if="customerIdKid.text.useTextKey==null || customerIdKid.text.useTextKey==false">{{customerIdKid.text.textUI}}</span>
                                            <span v-if="customerIdKid.text.useTextKey!=null && customerIdKid.text.useTextKey==true">{{$t(customerIdKid.text.textKey)}}</span>
                                        </option>
                                    </select>
                                </div>
                                <div v-if="customerIdKidSelection.length>0 && customerIdKidSelection.length<=4">
                                    <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedCustomerIdKid==null, my_input_background_color_at_validInput:selectedCustomerIdKid!=null}">
                                        <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                            <li v-for="customerIdKid in customerIdKidSelection">
                                                <input type="checkbox" @input="change_Selected_atElementOf_customerIdKid(customerIdKid)" v-bind:checked="customerIdKid.selected" v-bind:disabled="customerIdKid.selected || deliverRopeDisabled==true" />
                                                <span v-if="customerIdKid.text.useTextKey == false" style="margin-left:10px">{{customerIdKid.text.textUI}}</span>
                                                <span v-if="customerIdKid.text.useTextKey == true" style="margin-left:10px">{{$t(customerIdKid.text.textKey)}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                                <div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('3533')}} {{$t('3395')}}:</div>
                                </div>
                                <div v-if="deliverRopeSelection.length==0 || deliverRopeSelection.length>4">
                                    <select v-model="selectedDeliverRope" class="my_select" style="width:100%" v-bind:disabled="selectedNumberOfGrooves==null || selectedNumberOfGrooves=='' || deliverRopeSelectionDisabled==true" v-bind:class="{my_input_border_at_invalidInput: selectedDeliverRope==null, my_input_background_color_at_validInput:selectedDeliverRope!=null}">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option value="" hidden>{{$t('3174')}}</option>
                                        <option v-for="deliverRope in deliverRopeSelection" v-bind:value="sheaveProfile">{{deliverRope.text.textUI}}</option>
                                    </select>
                                </div>
                                <div v-if="deliverRopeSelection.length>0 && deliverRopeSelection.length<=4">
                                    <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedDeliverRope==null, my_input_background_color_at_validInput:selectedDeliverRope!=null}">
                                        <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                            <li v-for="deliverRope in deliverRopeSelection">
                                                <div>
                                                    <input type="checkbox" @input="change_Selected_atElementOf_deliverRope(deliverRope)" v-bind:checked="deliverRope.selected" v-bind:disabled="deliverRope.selected || deliverRopeSelectionDisabled==true" />
                                                    <span v-if="deliverRope.text.useTextKey == false" style="margin-left:10px">{{deliverRope.text.textUI}}</span>
                                                    <span v-if="deliverRope.text.useTextKey == true" style="margin-left:10px">{{$t(deliverRope.text.textKey)}}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div v-if="tractionSheaveCoverIncluded==true" style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                                <div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('3534')}}:</div>
                                </div>
                                <div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('3585')}}</div>
                                </div>
                            </div>
                            <div v-if="tractionSheaveCoverIncluded==false" style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                                <div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('3534')}}:</div>
                                </div>
                                <div v-if="tractionSheaveCoverSelection.length==0 || tractionSheaveCoverSelection.length>4">
                                    <select v-model="selectedTractionSheaveCover" class="my_select" style="width:100%" v-bind:disabled="selectedNumberOfGrooves==null || selectedNumberOfGrooves.value==null || tractionSheaveCoverSelection.length || tractionSheaveCoverSelectionDisabled==true">
                                        <option v-for="tractionSheaveCover in tractionSheaveCoverSelection" v-bind:value="tractionSheaveCover">{{tractionSheaveCover.text.text}}</option>
                                    </select>
                                </div>
                                <div v-if="tractionSheaveCoverSelection.length>0 && tractionSheaveCoverSelection.length<=4">
                                    <div style="display: grid;">
                                        <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                            <li v-for="tractionSheaveCover in tractionSheaveCoverSelection">
                                                <input type="checkbox" @input="change_Selected_atElementOf_tractionSheaveCover(tractionSheaveCover)" v-bind:checked="tractionSheaveCover.selected" v-bind:disabled="tractionSheaveCover.selected || tractionSheaveCoverSelectionDisabled==true" />
                                                <span style="margin-left:10px">{{tractionSheaveCover.text.text}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                                <div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('3535')}}:</div>
                                </div>
                                <div v-if="positionMotorSelection.length==0 || positionMotorSelection.length>4">
                                    <select v-model="selectedPositionMotor" class="my_select" style="width:100%" v-bind:disabled="selectedCustomerIdKid==null || selectedCustomerIdKid=='' || deliverRopeDisabled==true" v-bind:class="{my_input_border_at_invalidInput: positionMotorSelection.length > 0 && selectedPositionMotor==null, my_input_background_color_at_validInput: positionMotorSelection.length==0 || selectedPositionMotor!=null}">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option value="" hidden>{{$t('3174')}}</option>
                                        <option v-for="positionMotor in positionMotorSelection" v-bind:value="positionMotor">
                                            <span v-if="positionMotor.text.useTextKey==null || positionMotor.text.useTextKey==false">{{positionMotor.text.textUI}}</span>
                                            <span v-if="positionMotor.text.useTextKey!=null && positionMotor.text.useTextKey==true">{{$t(positionMotor.text.textKey)}}</span>
                                        </option>
                                    </select>
                                </div>
                                <div v-if="positionMotorSelection.length>0 && positionMotorSelection.length<=4">
                                    <div style="display: grid;" v-bind:disabled="selectedCustomerIdKid==null || selectedCustomerIdKid=='' || deliverRopeDisabled==true" v-bind:class="{my_input_border_at_invalidInput: positionMotorSelection.length > 0 && selectedPositionMotor==null, my_input_background_color_at_validInput: positionMotorSelection.length==0 || selectedPositionMotor!=null}">
                                        <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                            <li v-for="positionMotor in positionMotorSelection">
                                                <input type="checkbox" @input="change_Selected_atElementOf_positionMotor(positionMotor)" v-bind:checked="positionMotor.selected" v-bind:disabled="positionMotor.selected || deliverRopeDisabled==true" />
                                                <span v-if="positionMotor.text.useTextKey == false" style="margin-left:10px">{{positionMotor.text.textUI}}</span>
                                                <span v-if="positionMotor.text.useTextKey == true" style="margin-left:10px">{{$t(positionMotor.text.textKey)}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                                <div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('3536')}}:</div>
                                </div>
                                <div v-if="ropePullSelection.length==0 || ropePullSelection.length>4">
                                    <select v-model="selectedRopePull" class="my_select" style="width:100%" v-bind:disabled="selectedPositionMotor==null || selectedPositionMotor=='' || deliverRopeDisabled==true" v-bind:class="{my_input_border_at_invalidInput: ropePullSelection.length > 0 && selectedRopePull==null, my_input_background_color_at_validInput: ropePullSelection.length==0 || selectedRopePull!=null}">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option value="" hidden>{{$t('3174')}}</option>
                                        <option v-for="ropePull in ropePullSelection" v-bind:value="ropePull">
                                            <span v-if="ropePull.text.useTextKey==null || ropePull.text.useTextKey==false">{{ropePull.text.textUI}}</span>
                                            <span v-if="ropePull.text.useTextKey!=null && ropePull.text.useTextKey==true">{{$t(ropePull.text.textKey)}}</span>
                                        </option>
                                    </select>
                                </div>
                                <div v-if="ropePullSelection.length>0 && ropePullSelection.length<=4">
                                    <div style="display: grid;" v-bind:disabled="selectedCustomerIdKid==null || selectedCustomerIdKid=='' || deliverRopeDisabled==true" v-bind:class="{my_input_border_at_invalidInput: ropePullSelection.length > 0 && selectedRopePull==null, my_input_background_color_at_validInput: ropePullSelection.length==0 || selectedRopePull!=null}">
                                        <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                            <li v-for="ropePull in ropePullSelection">
                                                <input type="checkbox" @input="change_Selected_atElementOf_ropePull(ropePull)" v-bind:checked="ropePull.selected" v-bind:disabled="ropePull.selected || deliverRopeDisabled==true" />
                                                <span v-if="ropePull.text.useTextKey == false" style="margin-left:10px">{{ropePull.text.textUI}}</span>
                                                <span v-if="ropePull.text.useTextKey == true" style="margin-left:10px">{{$t(ropePull.text.textKey)}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                                <div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('3537')}}:</div>
                                </div>
                                <div v-if="adjustabilitySelection.length==0 || adjustabilitySelection.length>4">
                                    <select v-model="selectedAdjustability" class="my_select" style="width:100%" v-bind:disabled="selectedRopePull==null || selectedRopePull=='' || deliverRopeDisabled==true" v-bind:class="{my_input_border_at_invalidInput: adjustabilitySelection.length > 0 && selectedAdjustability==null, my_input_background_color_at_validInput: adjustabilitySelection.length==0 || selectedAdjustability!=null}">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option value="" hidden>{{$t('3174')}}</option>
                                        <option v-for="adjustability in adjustabilitySelection" v-bind:value="sheaveProfile">{{adjustability.text.textUI}}</option>
                                    </select>
                                </div>
                                <div v-if="adjustabilitySelection.length>0 && adjustabilitySelection.length<=4">
                                    <div v-bind:class="{my_input_border_at_invalidInput: adjustabilitySelection.length > 0 && selectedAdjustability==null, my_input_background_color_at_validInput: adjustabilitySelection.length==0 || selectedAdjustability!=null}">
                                        <ul class="my_inputCheckbox2 ZAfont_family ZAfont_size">
                                            <li v-for="adjustability in adjustabilitySelection">
                                                <div>
                                                    <input type="checkbox" @input="change_Selected_atElementOf_adjustability(adjustability)" v-bind:checked="adjustability.selected" v-bind:disabled="adjustability.selected || deliverRopeDisabled==true" />
                                                    <span v-if="adjustability.text.useTextKey == false" style="margin-left:10px">{{adjustability.text.textUI}}</span>
                                                    <span v-if="adjustability.text.useTextKey == true" style="margin-left:10px">{{$t(adjustability.text.textKey)}}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div v-if="deliverRopeDisabled==false" class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                                <div>
                                    <div>{{$t('3538')}}:</div>
                                </div>
                                <div>
                                    <div v-if="thirdRopeGuard!=null">
                                        <span v-if="thirdRopeGuard.text.useTextKey==false">{{thirdRopeGuard.text.textUI}} </span>
                                        <span v-if="thirdRopeGuard.text.useTextKey==true">{{$t(thirdRopeGuard.text.textKey)}} </span>
                                    </div>
                                </div>
                            </div>
                            <div style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                                <div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('3543')}}:</div>
                                </div>
                                <div v-if="ropeClampSelection.length==0 || ropeClampSelection.length>4">
                                    <select v-model="selectedRopeClamp" class="my_select" style="width:100%" v-bind:disabled="selectedNumberOfGrooves==null || selectedNumberOfGrooves.value==null || ropeClampSelection.length==0 || ropeClampSelectionDisabled">
                                        <option v-for="ropeClamp in ropeClampSelection" v-bind:value="ropeClamp">{{ropeClamp.text.textUI}}</option>
                                    </select>
                                </div>
                                <div v-if="ropeClampSelection.length>0 && ropeClampSelection.length<=4">
                                    <div style="display: grid;">
                                        <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                            <li v-for="ropeClamp in ropeClampSelection">
                                                <input type="checkbox" @input="change_Selected_atElementOf_ropeClamp(ropeClamp)" v-bind:checked="ropeClamp.selected" v-bind:disabled="ropeClamp.selected || ropeClampSelectionDisabled" />
                                                <span style="margin-left:10px">{{ropeClamp.text.textUI}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display:flex; justify-content: center; align-items: center">
                            <div v-if="imagePathRopeGuard!=null">
                                <Images :imageFilePath="imagePathRopeGuard" :maxImageHeight="250" :maxImageWidth="250"></Images>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
                <div>
                    <button v-if="showSCHMOD==false" @click="furtherToManualHome" class="btn my_btn_standard">{{$t('3237')}}</button>
                    <button v-if="showSCHMOD==false" @click="furtherToDriveTechnologies()" class="btn my_btn_standard" style="margin-left: 5px">{{$t('1023')}}</button>
                </div>

            </div>
        </div>

    </div>
</template>


<script>
    import router from '../../../router';
    import { configurations } from '../../../store/configurations.module';
    import InvalidityReasons from '../../dataValidation/InvalidityReasons';
    import LoaderIcon from '../../loader/LoaderIcon';
    import axios from 'axios';
    import Images from '../../configuration/Images';

    export default {
        name: 'PsgMenu',

        components: {
            InvalidityReasons,
            LoaderIcon,
            Images,
        },

        data() {
            return {
                accept_terms_of_request: false,
            }
        },

        computed: {
            showSCHMOD: {
                get() {
                    return this.$store.state.psgMenu.showSCHMOD;
                }
            },
            schmod_Valid: {
                get() {
                    return this.$store.state.psgMenu.schmod_Valid;
                }
            },
            cmd: {
                get() {
                    return this.$store.state.inputsManual.cmd;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_cmd_storeAction', value);
                }
            },
            note: {
                get() {
                    return this.$store.state.inputsManual.note;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_note_storeAction', value);
                }
            },
            projekt: {
                get() {
                    return this.$store.state.inputsManual.projekt;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_projekt_storeAction', value);
                }
            },
            sanum: {
                get() {
                    return this.$store.state.inputsManual.sanum;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_sanum_storeAction', value);
                }
            },
            onok: {
                get() {
                    return this.$store.state.inputsManual.onok.value;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_onok_storeAction', value);
                }
            },
            onok_ValidationResult: {
                get() {
                    return this.$store.state.inputsManual.onok;
                },
            },
            inok: {
                get() {
                    return this.$store.state.inputsManual.inok;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_inok_storeAction', value);
                }
            },
            mvlift: {
                get() {
                    return this.$store.state.inputsManual.mvlift.value;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_mvlift_storeAction', value);
                }
            },
            mvlift_ValidationResult: {
                get() {
                    return this.$store.state.inputsManual.mvlift;
                },
            },
            mis: {
                get() {
                    return this.$store.state.inputsManual.mis.value;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_mis_storeAction', value);
                }
            },
            mis_ValidationResult: {
                get() {
                    return this.$store.state.inputsManual.mis;
                },
            },
            customerNameSelection: {
                get() {
                    return this.$store.state.inputsManual.customerNameSelection;
                },
            },

            // Preselection motor
            schmodMotordetails: {
                get() {
                    if (this.$store.state.psgMenu.schmodMotordetails != null && this.$store.state.psgMenu.schmodMotordetails.hasOwnProperty('mtyp') && this.$store.state.psgMenu.schmodMotordetails.mtyp != null) {
                        return this.$store.state.psgMenu.schmodMotordetails
                    }
                    else {
                        return null;
                    }
                },
            },
            schmodTractionSheavedetails: {
                get() {
                    if (this.$store.state.psgMenu.schmodTractionSheavedetails != null && this.$store.state.psgMenu.schmodTractionSheavedetails.hasOwnProperty('tarill') && this.$store.state.psgMenu.schmodMotordetails.tarill != null) {
                        return this.$store.state.psgMenu.schmodTractionSheavedetails
                    }
                    else {
                        return null;
                    }
                },
            },
            motorTypeSelection: {
                get() {
                    return this.$store.state.inputsManual.motorTypeSelection;
                },
            },
            motorSizeSelection: {
                get() {
                    return this.$store.state.inputsManual.motorSizeSelection;
                },
            },
            motorPackageSelection: {
                get() {
                    return this.$store.state.inputsManual.motorPackageSelection;
                },
            },
            motorZusatzSelection: {
                get() {
                    if (this.$store.state.inputsManual.motorZusatzSelection != null && this.$store.state.inputsManual.motorZusatzSelection.hasOwnProperty('textSelection') && this.$store.state.inputsManual.motorZusatzSelection.textSelection.length > 0) {
                        return this.$store.state.inputsManual.motorZusatzSelection.textSelection;
                    }
                    else {
                        return null;
                    }
                },
            },
            motorZusatzNecessary: {
                get() {
                    if (this.$store.state.inputsManual.motorZusatzSelection != null && this.$store.state.inputsManual.motorZusatzSelection.hasOwnProperty('necessary')) {
                        return this.$store.state.inputsManual.motorZusatzSelection.necessary;
                    }
                    else {
                        return false;
                    }
                },
            },
            motorVoltageSelection: {
                get() {
                    return this.$store.state.inputsManual.motorVoltageSelection;
                },
            },
            motorRatedSpeedSelection: {
                get() {
                    return this.$store.state.inputsManual.motorRatedSpeedSelection;
                },
            },
            certificationSelection: {
                get() {
                    return this.$store.state.inputsManual.certificationSelection;
                },
            },
            preferenceSelection: {
                get() {
                    return this.$store.state.inputsManual.preferenceSelection;
                },
            },
            temperatureMonitoringSelection: {
                get() {
                    return this.$store.state.inputsManual.temperatureMonitoringSelection;
                },
            },
            forcedVentilationSelection: {
                get() {
                    return this.$store.state.inputsManual.forcedVentilationSelection;
                },
            },
            upgradeSelection: {
                get() {
                    return this.$store.state.inputsManual.upgradeSelection;
                },
            },
            selectedCustomerName: {
                get() {
                    if (this.$store.state.inputsManual.customerNameSelection != null && Array.isArray(this.$store.state.inputsManual.customerNameSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.customerNameSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.customerNameSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_customerName(value);
                }
            },
            customerName: {
                get() {
                    if (this.$store.state.inputsManual.customerNameSelection != null && Array.isArray(this.$store.state.inputsManual.customerNameSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.customerNameSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.customerNameSelection[firstIndex_whichMeetConditions].text.text;
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
            },
            selectedMotorType: {
                get() {
                    if (this.$store.state.inputsManual.motorTypeSelection != null && Array.isArray(this.$store.state.inputsManual.motorTypeSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.motorTypeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.motorTypeSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorType(value);
                }
            },
            selectedMotorSize: {
                get() {
                    if (this.$store.state.inputsManual.motorSizeSelection != null && Array.isArray(this.$store.state.inputsManual.motorSizeSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.motorSizeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.motorSizeSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorSize(value);
                }
            },
            selectedMotorPackage: {
                get() {
                    if (this.$store.state.inputsManual.motorPackageSelection != null && Array.isArray(this.$store.state.inputsManual.motorPackageSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.motorPackageSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.motorPackageSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorPackage(value);
                }
            },
            selectedMotorZusatz: {
                get() {
                    if (this.$store.state.inputsManual.motorZusatzSelection != null && this.$store.state.inputsManual.motorZusatzSelection.hasOwnProperty('textSelection') && Array.isArray(this.$store.state.inputsManual.motorZusatzSelection.textSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.motorZusatzSelection.textSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.motorZusatzSelection.textSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorPackage(value);
                }
            },
            selectedMotorVoltage: {
                get() {
                    if (this.$store.state.inputsManual.motorVoltageSelection != null && Array.isArray(this.$store.state.inputsManual.motorVoltageSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.motorVoltageSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.motorVoltageSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorVoltage(value);
                }
            },
            selectedMotorRatedSpeed: {
                get() {
                    if (this.$store.state.inputsManual.motorRatedSpeedSelection != null && Array.isArray(this.$store.state.inputsManual.motorRatedSpeedSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.motorRatedSpeedSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.motorRatedSpeedSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorRatedSpeed(value);
                }
            },
            selectedCertification: {
                get() {
                    if (this.$store.state.inputsManual.certificationSelection != null && Array.isArray(this.$store.state.inputsManual.certificationSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.certificationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.certificationSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_certification(value);
                }
            },
            selectedPreference: {
                get() {
                    if (this.$store.state.inputsManual.preferenceSelection != null && Array.isArray(this.$store.state.inputsManual.preferenceSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.preferenceSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.preferenceSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_preference(value);
                }
            },
            selectedTemperatureMonitoring: {
                get() {
                    if (this.$store.state.inputsManual.temperatureMonitoringSelection != null && Array.isArray(this.$store.state.inputsManual.temperatureMonitoringSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.temperatureMonitoringSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.temperatureMonitoringSelection[firstIndex_whichMeetConditions].text;
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_temperatureMonitoring(value);
                }
            },
            selectedUpgrade: {
                get() {
                    if (this.$store.state.inputsManual.upgradeSelection != null && Array.isArray(this.$store.state.inputsManual.upgradeSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.upgradeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.upgradeSelection[firstIndex_whichMeetConditions].text;
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_upgradeSelection(value);
                }
            },
            selectedForcedVentilation: {
                get() {
                    if (this.$store.state.inputsManual.forcedVentilationSelection != null && Array.isArray(this.$store.state.inputsManual.forcedVentilationSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.forcedVentilationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.forcedVentilationSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_forcedVentilation(value);
                }
            },
            selectedForcedVentilationWithBrakeRelease: {
                get() {
                    if (this.$store.state.inputsManual.forcedVentilationSelection != null && Array.isArray(this.$store.state.inputsManual.forcedVentilationSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.forcedVentilationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.forcedVentilationSelection[firstIndex_whichMeetConditions].canBeCombinedWithBrakeRelease;
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
            },
            operatingCurrentSplus: {
                get() {
                    return this.$store.state.inputsManual.operatingCurrentSplus.value;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_operatingCurrentSplus_storeAction', value);
                }
            },
            operatingCurrentSplus_ValidationResult: {
                get() {
                    return this.$store.state.inputsManual.operatingCurrentSplus;
                },
            },

            // SCH-MOD Modul
            schmod_locationProductionSelection: {
                get() {
                    return this.$store.state.psgMenu.schmod_locationProductionSelection;
                },
            },
            schmod_ProdApSelection: {
                get() {
                    return this.$store.state.psgMenu.schmod_ProdApSelection;
                },
            },
            schmod_motorVoltageSelection: {
                get() {
                    return this.$store.state.psgMenu.schmod_motorVoltageSelection;
                },
            },
            schmod_PSGSizeSelection: {
                get() {
                    return this.$store.state.psgMenu.schmod_PSGSizeSelection;
                },
            },
            schmod_motorIDSelection: {
                get() {
                    return this.$store.state.psgMenu.schmod_motorIDSelection;

                },
            },
            schmod_motorCOSNameSelection: {
                get() {
                    return this.$store.state.psgMenu.schmod_motorCOSNameSelection;
                },
            },
            schmod_inverterTypeSelection: {
                get() {
                    return this.$store.state.psgMenu.schmod_inverterTypeSelection;
                },
            },
            schmod_cableLengthSelection: {
                get() {
                    return this.$store.state.psgMenu.schmod_cableLengthSelection;
                },
            },
            schmod_cableLengthBrakeSelection: {
                get() {
                    return this.$store.state.psgMenu.schmod_cableLengthBrakeSelection;
                },
            },
            schmod_handWheelSelection: {
                get() {
                    return this.$store.state.psgMenu.schmod_handWheelSelection;
                },
            },
            schmod_handReleaseSelection: {
                get() {
                    return this.$store.state.psgMenu.schmod_handReleaseSelection;
                },
            },
            schmod_grooveSelection: {
                get() {
                    return this.$store.state.psgMenu.schmod_grooveSelection;
                },
            },
            schmod_numberOfGroovesSelection: {
                get() {
                    return this.$store.state.psgMenu.schmod_numberOfGroovesSelection;
                },
            },
            schmod_certificationSelection: {
                get() {
                    return this.$store.state.psgMenu.schmod_certificationSelection;
                },
            },
            selected_schmod_locationProduction: {
                get() {
                    if (this.$store.state.psgMenu.schmod_locationProductionSelection != null && Array.isArray(this.$store.state.psgMenu.schmod_locationProductionSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.psgMenu.schmod_locationProductionSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.psgMenu.schmod_locationProductionSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_schmod_locationProduction(value);
                }
            },
            selected_schmod_ProAp: {
                get() {
                    if (this.$store.state.psgMenu.schmod_ProdApSelection != null && Array.isArray(this.$store.state.psgMenu.schmod_ProdApSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.psgMenu.schmod_ProdApSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.psgMenu.schmod_ProdApSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_schmod_ProAp(value);
                }
            },
            selected_schmod_motorVoltage: {
                get() {
                    if (this.$store.state.psgMenu.schmod_motorVoltageSelection != null && Array.isArray(this.$store.state.psgMenu.schmod_motorVoltageSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.psgMenu.schmod_motorVoltageSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.psgMenu.schmod_motorVoltageSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_schmod_motorVoltage(value);
                }
            },
            selected_schmod_PSGSize: {
                get() {
                    if (this.$store.state.psgMenu.schmod_PSGSizeSelection != null && Array.isArray(this.$store.state.psgMenu.schmod_PSGSizeSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.psgMenu.schmod_PSGSizeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            if (this.$store.state.psgMenu.schmod_PSGSizeSelection[firstIndex_whichMeetConditions].text.text != null && this.$store.state.psgMenu.schmod_PSGSizeSelection[firstIndex_whichMeetConditions].text.text != "") {
                                return this.$store.state.psgMenu.schmod_PSGSizeSelection[firstIndex_whichMeetConditions];
                            }
                            else {
                                return null;
                            }
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_schmod_PSGSize(value);
                }
            },
            selected_schmod_motorID: {
                get() {
                    if (this.$store.state.psgMenu.schmod_motorIDSelection != null && Array.isArray(this.$store.state.psgMenu.schmod_motorIDSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.psgMenu.schmod_motorIDSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            if (this.$store.state.psgMenu.schmod_motorIDSelection[firstIndex_whichMeetConditions].text.text != null && this.$store.state.psgMenu.schmod_motorIDSelection[firstIndex_whichMeetConditions].text.text != "") {
                                return this.$store.state.psgMenu.schmod_motorIDSelection[firstIndex_whichMeetConditions];
                            }
                            else {
                                return null;
                            }
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_schmod_motorID(value);
                }
            },
            selected_schmod_motorCOSName: {
                get() {
                    if (this.$store.state.psgMenu.schmod_motorCOSNameSelection != null && Array.isArray(this.$store.state.psgMenu.schmod_motorCOSNameSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.psgMenu.schmod_motorCOSNameSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            if (this.$store.state.psgMenu.schmod_motorCOSNameSelection[firstIndex_whichMeetConditions].text.text != null && this.$store.state.psgMenu.schmod_motorCOSNameSelection[firstIndex_whichMeetConditions].text.text != "") {
                                return this.$store.state.psgMenu.schmod_motorCOSNameSelection[firstIndex_whichMeetConditions];
                            }
                            else {
                                return null;
                            }
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_schmod_motorCOSName(value);
                }
            },
            selected_schmod_inverterType: {
                get() {
                    if (this.$store.state.psgMenu.schmod_inverterTypeSelection != null && Array.isArray(this.$store.state.psgMenu.schmod_inverterTypeSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.psgMenu.schmod_inverterTypeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.psgMenu.schmod_inverterTypeSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_schmod_inverterType(value);
                }
            },
            selected_schmod_cableLength: {
                get() {
                    if (this.$store.state.psgMenu.schmod_cableLengthSelection != null && Array.isArray(this.$store.state.psgMenu.schmod_cableLengthSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.psgMenu.schmod_cableLengthSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.psgMenu.schmod_cableLengthSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_schmod_cableLength(value);
                }
            },
            selected_schmod_cableLengthBrake: {
                get() {
                    if (this.$store.state.psgMenu.schmod_cableLengthBrakeSelection != null && Array.isArray(this.$store.state.psgMenu.schmod_cableLengthBrakeSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.psgMenu.schmod_cableLengthBrakeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.psgMenu.schmod_cableLengthBrakeSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_schmod_cableLengthBrake(value);
                }
            },
            selected_schmod_handWheel: {
                get() {
                    if (this.$store.state.psgMenu.schmod_handWheelSelection != null && Array.isArray(this.$store.state.psgMenu.schmod_handWheelSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.psgMenu.schmod_handWheelSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.psgMenu.schmod_handWheelSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_schmod_handWheel(value);
                }
            },
            selected_schmod_handRelease: {
                get() {
                    if (this.$store.state.psgMenu.schmod_handReleaseSelection != null && Array.isArray(this.$store.state.psgMenu.schmod_handReleaseSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.psgMenu.schmod_handReleaseSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.psgMenu.schmod_handReleaseSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_schmod_handRelease(value);
                }
            },
            schmod_grooveDistance: {
                get() {
                    return this.$store.state.psgMenu.schmod_grooveDistance.value;
                },
                set(value) {
                    this.change_Selected_atElementOf_schmod_grooveDistance(value);
                }
            },
            schmod_grooveDistance_ValidationResult: {
                get() {
                    return this.$store.state.psgMenu.schmod_grooveDistance;
                },
            },
            selected_schmod_groove: {
                get() {
                    if (this.$store.state.psgMenu.schmod_grooveSelection != null && Array.isArray(this.$store.state.psgMenu.schmod_grooveSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.psgMenu.schmod_handReleaseSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.psgMenu.schmod_grooveSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_schmod_groove(value);
                }
            },
            selected_schmod_numberOfGrooves: {
                get() {
                    if (this.$store.state.psgMenu.schmod_numberOfGroovesSelection != null && Array.isArray(this.$store.state.psgMenu.schmod_numberOfGroovesSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.psgMenu.schmod_numberOfGroovesSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.psgMenu.schmod_numberOfGroovesSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_schmod_numberOfGrooves(value);
                }
            },

            selected_schmod_Certification: {
                get() {
                    if (this.$store.state.psgMenu.schmod_certificationSelection != null && Array.isArray(this.$store.state.psgMenu.schmod_certificationSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.psgMenu.schmod_certificationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.psgMenu.schmod_certificationSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_schmod_certification(value);
                }
            },
            //schmod_onok: {
            //    get() {
            //        return this.$store.state.psgMenu.onok;
            //    },
            //    set(value) {
            //        this.change_onok(value);
            //    }
            //},
            //schmod_onok_ValidationResult: {
            //    get() {
            //        return this.$store.state.psgMenu.onok;
            //    }
            //},
            //schmod_inok: {
            //    get() {
            //        return this.$store.state.psgMenu.inok;
            //    },
            //    set(value) {
            //        this.change_inok(value);
            //    }
            //},
            //schmod_inok_ValidationResult: {
            //    get() {
            //        return this.$store.state.psgMenu.inok;
            //    }
            //},


            // Preselection traction sheave
            deliverTractionSheaveSelection: {
                get() {
                    return this.$store.state.inputsManual.deliverTractionSheaveSelection;
                },
            },
            selectedDeliverTractionSheave: {
                get() {
                    if (this.$store.state.inputsManual.deliverTractionSheaveSelection != null && Array.isArray(this.$store.state.inputsManual.deliverTractionSheaveSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.deliverTractionSheaveSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.deliverTractionSheaveSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_deliverTractionSheave(value);
                }
            },
            showTractionSheaveDetails: {
                get() {
                    return this.$store.state.inputsManual.showTractionSheaveDetails;
                },
            },
            machiningForHandWheelSelection: {
                get() {
                    return this.$store.state.inputsManual.machiningForHandWheelSelection;
                },
            },
            selectedMachiningForHandWheel: {
                get() {
                    if (this.$store.state.inputsManual.machiningForHandWheelSelection != null && Array.isArray(this.$store.state.inputsManual.machiningForHandWheelSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.machiningForHandWheelSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.machiningForHandWheelSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_machiningForHandWheel(value);
                }
            },

            tractionSheave_DiameterAndWidthSelection: {
                get() {
                    return this.$store.state.inputsManual.tractionSheave_DiameterAndWidthSelection;
                },
            },
            selectedTractionSheave_DiameterAndWidth: {
                get() {
                    if (this.$store.state.inputsManual.tractionSheave_DiameterAndWidthSelection != null && Array.isArray(this.$store.state.inputsManual.tractionSheave_DiameterAndWidthSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.tractionSheave_DiameterAndWidthSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.tractionSheave_DiameterAndWidthSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_diameterAndWidth(value);
                }
            },
            sheaveProfileSelection: {
                get() {
                    return this.$store.state.inputsManual.sheaveProfileSelection;
                },
            },
            selectedSheaveProfile: {
                get() {
                    if (this.$store.state.inputsManual.sheaveProfileSelection != null && Array.isArray(this.$store.state.inputsManual.sheaveProfileSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.sheaveProfileSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.sheaveProfileSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_sheaveProfile(value);
                },
            },
            ropeDiameterSelection: {
                get() {
                    return this.$store.state.inputsManual.ropeDiameterSelection;
                },
            },
            ropeDiameterSelectionLength: {
                get() {
                    if (this.$store.state.inputsManual.ropeDiameterSelection != null && Array.isArray(this.$store.state.inputsManual.ropeDiameterSelection)) {
                        let length = this.$store.state.inputsManual.ropeDiameterSelection.filter((x) => { return (x.disabled == false) }).length;
                        if (length != null && length > 0) {
                            return length;
                        }
                        else {
                            return 0;
                        }
                    }
                    else {
                        return 0;
                    }
                }
            },
            selectedRopeDiameter: {
                get() {
                    if (this.$store.state.inputsManual.ropeDiameterSelection != null && Array.isArray(this.$store.state.inputsManual.ropeDiameterSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.ropeDiameterSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.ropeDiameterSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_ropeDiameter(value);
                }
            },
            undercutAngleSelection: {
                get() {
                    return this.$store.state.inputsManual.undercutAngleSelection;
                },
            },
            undercutAngleSelectionDisabled: {
                get() {
                    if (this.$store.state.inputsManual.undercutAngleSelection == null && this.$store.state.inputsManual.undercutAngleSelection.length < 1) {
                        return true;
                    }
                    else {
                        return false;
                    }
                },
            },
            undercutAngleSelectionLength: {
                get() {
                    if (this.$store.state.inputsManual.undercutAngleSelection != null && Array.isArray(this.$store.state.inputsManual.undercutAngleSelection)) {
                        let length = this.$store.state.inputsManual.undercutAngleSelection.filter((x) => { return (x.disabled == false) }).length;
                        if (length != null && length > 0) {
                            return length;
                        }
                        else {
                            return 0;
                        }
                    }
                    else {
                        return 0;
                    }
                }
            },
            selectedUndercutAngle: {
                get() {
                    if (this.$store.state.inputsManual.undercutAngleSelection != null && Array.isArray(this.$store.state.inputsManual.undercutAngleSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.undercutAngleSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.undercutAngleSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_tractionSheaveAngleUndercut(value);
                },
            },
            showUnderCutAngleDecimal: {
                get() {
                    if (this.$store.state.inputsManual.sheaveProfileSelection != null && Array.isArray(this.$store.state.inputsManual.sheaveProfileSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.sheaveProfileSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            if (this.$store.state.inputsManual.sheaveProfileSelection[firstIndex_whichMeetConditions].text.text.indexOf("SC-") > -1 &&
                                this.$store.state.inputsManual.sheaveProfileSelection[firstIndex_whichMeetConditions].text.text.indexOf("BZU") > -1) {
                                return true;
                            }
                        }
                    }
                    return false;
                }
            },
            vgrooveAngleSelection: {
                get() {
                    return this.$store.state.inputsManual.vgrooveAngleSelection;
                },
            },
            vgrooveAngleSelectionDisabled: {
                get() {
                    if (this.$store.state.inputsManual.vgrooveAngleSelection != null && this.$store.state.inputsManual.vgrooveAngleSelection.length < 1) {
                        return true;
                    }
                    else {
                        return false;
                    }
                },
            },
            vgrooveAngleSelectionLength: {
                get() {
                    if (this.$store.state.inputsManual.vgrooveAngleSelection != null && Array.isArray(this.$store.state.inputsManual.vgrooveAngleSelection)) {
                        let length = this.$store.state.inputsManual.vgrooveAngleSelection.filter((x) => { return (x.disabled == false) }).length;
                        if (length != null && length > 0) {
                            return length;
                        }
                        else {
                            return 0;
                        }
                    }
                    else {
                        return 0;
                    }
                }
            },
            selectedVgrooveAngle: {
                get() {
                    if (this.$store.state.inputsManual.vgrooveAngleSelection != null && Array.isArray(this.$store.state.inputsManual.vgrooveAngleSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.vgrooveAngleSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.vgrooveAngleSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_tractionSheaveAngleVgroove(value);
                },
            },
            grooveDistanceSelection: {
                get() {
                    return this.$store.state.inputsManual.grooveDistanceSelection;
                },
            },
            grooveDistanceSelectionLength: {
                get() {
                    if (this.$store.state.inputsManual.grooveDistanceSelection != null && Array.isArray(this.$store.state.inputsManual.grooveDistanceSelection)) {
                        let length = this.$store.state.inputsManual.grooveDistanceSelection.filter((x) => { return (x.disabled == false) }).length;
                        if (length != null && length > 0) {
                            return length;
                        }
                        else {
                            return 0;
                        }
                    }
                    else {
                        return 0;
                    }
                }
            },
            selectedGrooveDistance: {
                get() {
                    if (this.$store.state.inputsManual.grooveDistanceSelection != null && Array.isArray(this.$store.state.inputsManual.grooveDistanceSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.grooveDistanceSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.grooveDistanceSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_grooveDistance(value);
                }
            },
            numberOfGroovesSelection: {
                get() {
                    return this.$store.state.inputsManual.numberOfGroovesSelection;
                },
            },
            numberOfGroovesSelectionLength: {
                get() {
                    if (this.$store.state.inputsManual.numberOfGroovesSelection != null && Array.isArray(this.$store.state.inputsManual.numberOfGroovesSelection)) {
                        let length = this.$store.state.inputsManual.numberOfGroovesSelection.filter((x) => { return (x.disabled == false) }).length;
                        if (length != null && length > 0) {
                            return length;
                        }
                        else {
                            return 0;
                        }
                    }
                    else {
                        return 0;
                    }
                }
            },
            selectedNumberOfGrooves: {
                get() {
                    if (this.$store.state.inputsManual.numberOfGroovesSelection != null && Array.isArray(this.$store.state.inputsManual.numberOfGroovesSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.numberOfGroovesSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.numberOfGroovesSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_numberOfGrooves(value);
                }
            },

            // rope guard
            customerIdKidSelection: {
                get() {
                    return this.$store.state.inputsManual.customerIdKidSelection;
                },
            },
            selectedCustomerIdKid: {
                get() {
                    if (this.$store.state.inputsManual.customerIdKidSelection != null && Array.isArray(this.$store.state.inputsManual.customerIdKidSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.customerIdKidSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.customerIdKidSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_customerIdKid(value);
                }
            },
            positionMotorSelection: {
                get() {
                    return this.$store.state.inputsManual.positionMotorSelection;
                },
            },
            selectedPositionMotor: {
                get() {
                    if (this.$store.state.inputsManual.positionMotorSelection != null && Array.isArray(this.$store.state.inputsManual.positionMotorSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.positionMotorSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.positionMotorSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                }
                ,
                set(value) {
                    this.change_Selected_atElementOf_positionMotor(value);
                }
            },
            ropePullSelection: {
                get() {
                    return this.$store.state.inputsManual.ropePullSelection;
                },
            },
            selectedRopePull: {
                get() {
                    if (this.$store.state.inputsManual.ropePullSelection != null && Array.isArray(this.$store.state.inputsManual.ropePullSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.ropePullSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.ropePullSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_ropePull(value);
                }
            },
            adjustabilitySelection: {
                get() {
                    return this.$store.state.inputsManual.adjustabilitySelection;
                },
            },
            selectedAdjustability: {
                get() {
                    if (this.$store.state.inputsManual.adjustabilitySelection != null && Array.isArray(this.$store.state.inputsManual.adjustabilitySelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.adjustabilitySelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.adjustabilitySelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_adjustability(value);
                }
            },
            thirdRopeGuard: {
                get() {
                    if (this.$store.state.inputsManual.thirdRopeGuard != null && this.$store.state.inputsManual.thirdRopeGuard.hasOwnProperty('text')) {
                        return this.$store.state.inputsManual.thirdRopeGuard;
                    }
                    else {
                        return null;
                    }
                },
            },
            deliverRopeSelection: {
                get() {
                    return this.$store.state.inputsManual.deliverRopeSelection;
                },
            },
            selectedDeliverRope: {
                get() {
                    if (this.$store.state.inputsManual.deliverRopeSelection != null && Array.isArray(this.$store.state.inputsManual.deliverRopeSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.deliverRopeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.deliverRopeSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_deliverRope(value);
                }
            },
            showDeliverRope: {
                get() {
                    if (this.$store.state.inputsManual.deliverRopeSelection != null && Array.isArray(this.$store.state.inputsManual.deliverRopeSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.deliverRopeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            if (this.$store.state.inputsManual.deliverRopeSelection[firstIndex_whichMeetConditions].text.code == 0) {
                                return false;
                            }
                        }
                    }
                    return true;
                },
                set(value) {
                    this.change_Selected_atElementOf_deliverRope(value);
                }
            },
            deliverRopeSelectionDisabled: {
                get() {
                    if (this.$store.state.inputsManual.deliverRopeSelection != null && this.$store.state.inputsManual.deliverRopeSelection.length > 0 && this.$store.state.inputsManual.tractionSheaveCoverSelection != null && this.$store.state.inputsManual.tractionSheaveCoverSelection.hasOwnProperty('disabled')) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.tractionSheaveCoverSelection.selection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            if (this.$store.state.inputsManual.tractionSheaveCoverSelection.selection[firstIndex_whichMeetConditions].text.text != null && this.$store.state.inputsManual.tractionSheaveCoverSelection.selection[firstIndex_whichMeetConditions].text.text != 'ohne') {
                                if (this.$store.state.inputsManual.tractionSheaveCoverSelection.additionalCover == false) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    }
                    else {
                        return false;
                    }
                },
            },
            tractionSheaveCoverIncluded: {
                get() {
                    if (this.$store.state.inputsManual.tractionSheaveCoverSelection != null && this.$store.state.inputsManual.tractionSheaveCoverSelection.hasOwnProperty('coverIncluded')) {
                        return this.$store.state.inputsManual.tractionSheaveCoverSelection.coverIncluded;
                    }
                    else {
                        return false;
                    }
                },
            },
            tractionSheaveCoverSelection: {
                get() {
                    if (this.$store.state.inputsManual.tractionSheaveCoverSelection != null && this.$store.state.inputsManual.tractionSheaveCoverSelection.hasOwnProperty('selection') && Array.isArray(this.$store.state.inputsManual.tractionSheaveCoverSelection.selection)) {
                        return this.$store.state.inputsManual.tractionSheaveCoverSelection.selection;
                    }
                    else {
                        return [];
                    }
                },
            },
            selectedTractionSheaveCover: {
                get() {
                    if (this.$store.state.inputsManual.tractionSheaveCoverSelection != null && this.$store.state.inputsManual.tractionSheaveCoverSelection.hasOwnProperty('selection') && Array.isArray(this.$store.state.inputsManual.tractionSheaveCoverSelection.selection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.psgMenu.tractionSheaveCoverSelection.selection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.tractionSheaveCoverSelection.selection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_tractionSheaveCover(value);
                }
            },
            tractionSheaveCoverSelectionDisabled: {
                get() {
                    if (this.$store.state.inputsManual.tractionSheaveCoverSelection != null && this.$store.state.inputsManual.tractionSheaveCoverSelection.hasOwnProperty('disabled')) {
                        if (this.$store.state.inputsManual.deliverRopeSelection != null && this.$store.state.inputsManual.deliverRopeSelection.length > 0) {
                            const firstIndex_whichMeetConditions = this.$store.state.inputsManual.deliverRopeSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                if (this.$store.state.inputsManual.deliverRopeSelection[firstIndex_whichMeetConditions].text.text != null && this.$store.state.inputsManual.deliverRopeSelection[firstIndex_whichMeetConditions].text.text != 'ohne') {
                                    if (this.$store.state.inputsManual.tractionSheaveCoverSelection.additionalCover == false) {
                                        return true;
                                    }
                                }
                                else {
                                    if (this.$store.state.inputsManual.tractionSheaveCoverSelection.additionalCover == true && this.$store.state.inputsManual.tractionSheaveCoverSelection.coverInsteadOfRopeGuard == false) {
                                        this.change_Selected_atElementOf_tractionSheaveCover(this.$store.state.inputsManual.deliverRopeSelection[0]);
                                        return true;
                                    }
                                }
                            }
                        }
                        return this.$store.state.inputsManual.tractionSheaveCoverSelection.disabled;
                    }
                    else {
                        return true;
                    }
                },
            },
            ropeClampSelection: {
                get() {
                    if (this.$store.state.inputsManual.ropeClampSelection != null && this.$store.state.inputsManual.ropeClampSelection.hasOwnProperty('selection') && Array.isArray(this.$store.state.inputsManual.ropeClampSelection.selection)) {
                        return this.$store.state.inputsManual.ropeClampSelection.selection;
                    }
                    else {
                        return [];
                    }
                },
            },
            selectedRopeClamp: {
                get() {
                    if (this.$store.state.inputsManual.ropeClampSelection != null && this.$store.state.inputsManual.ropeClampSelection.hasOwnProperty('selection') && Array.isArray(this.$store.state.inputsManual.ropeClampSelection.selection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.ropeClampSelection.selection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.ropeClampSelection.selection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_ropeClamp(value);
                }
            },
            ropeClampSelectionDisabled: {
                get() {
                    if (this.$store.state.inputsManual.ropeClampSelection != null && this.$store.state.inputsManual.ropeClampSelection.hasOwnProperty('disabled')) {
                        return this.$store.state.inputsManual.ropeClampSelection.disabled;
                    }
                    else {
                        return true;
                    }
                },
            },
            deliverRopeDisabled: {
                get() {
                    return this.$store.state.inputsManual.deliverRopeDisabled;
                }
            },
            SM250_TS280: {
                get() {
                    return this.$store.state.inputsManual.sM250_TS280;
                },
                set(value) {
                    this.change_Selected_SM250_TS280(value);
                }
            },
            imagePathRopeGuard: {
                get() {
                    return this.$store.state.inputsManual.imagePathRopeGuard;
                }
            },
            statusModal_Shown: {
                get() {
                    return this.$store.state.statusHandling.statusModal_Shown;
                },
            },
        },

        created() {

            if (this.$store.state.configurations.MANUAL == false) {
                this.$store.commit('configurations/MANU_configuration', true);
                this.$store.commit('configurations/change_elevatorMotor_Manual_storeMutation', true);
            }
            //if (this.$store.state.psgMenu.motorTypeSelection != null && Array.isArray(this.$store.state.psgMenu.motorTypeSelection) && this.$store.state.psgMenu.motorTypeSelection.length > 0) {
            //    //if (this.$store.state.psgMenu.motorManualData != null && this.$store.state.psgMenu.motorManualData.preselectionMotorManual != null && this.$store.state.psgMenu.motorManualData.preselectionMotorManual.mN_MOT != null) {
            //    //    this.$store.dispatch('psgMenu/load_inputsManualDatabase_storeAction');
            //    //}
            //}
            //else {
            //    //this.$store.dispatch('inputsManual/set_schmod_inputsManual_storeAction');
            //}


            this.PSG518_600();
            //    this.showSCHMOD = true;
            //    this.$store.dispatch('psgMenu/set_schmod_inputsManual_storeAction');
        },

        methods: {
            ValueDecimalSeparator(value) {
                if (value != null) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                }
                return value;
            },
            SM500() {

            },
            change_Selected_SM250_TS280(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('psgMenu/change_SM250_TS280_storeAction', value);
            },
            PSG518_600() {
                //this.showSCHMOD = true;
                this.$store.dispatch('psgMenu/set_schmod_inputsManual_storeAction', this.$store.state.inputsManual.configurationStepsManualId);
            },
            Start_PSG518_600() {
                this.$store.commit('psgMenu/set_showSCHMOD_storeMutation', true);
                this.$store.dispatch('psgMenu/get_SCHMOD_DefaultAndValuelists_storeAction');
            },
            End_PSG518_600() {
                this.$store.dispatch('psgMenu/set_endPSG_storeAction');
            },
            Cancel_PSG518_600() {
                this.$store.commit('inputsManual/change_customerName_storeMutation', 'ZA');
                this.$store.dispatch('psgMenu/set_inputManual_SCHMOD_toDefault_storeAction');
                this.$store.commit('psgMenu/set_showSCHMOD_storeMutation', false);
                router.push('../manualStep/ManualHome');
            },

            Save_PSG518_600() {
                this.$store.dispatch('psgMenu/insertOrUpdate_schmod_storeAction');
                this.$store.commit('psgMenu/set_showSCHMOD_storeMutation', false);
            },
            change_Selected_atElementOf_upgradeSelection(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_upgradeSelected_storeAction', value);
            },
            change_Selected_atElementOf_customerName(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_customerName_storeAction', value);
            },
            change_Selected_atElementOf_motorType(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_motorType_storeAction', value);
            },
            change_Selected_atElementOf_motorSize(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_motorSize_storeAction', value);
            },
            change_Selected_atElementOf_motorPackage(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_motorPackage_storeAction', value);
            },
            change_Selected_atElementOf_motorZusatz(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_motorZusatz_storeAction', value);
            },
            change_Selected_atElementOf_motorVoltage(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_motorVoltage_storeAction', value);
            },
            change_Selected_atElementOf_motorRatedSpeed(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_motorRatedSpeed_storeAction', value);
            },
            change_Selected_atElementOf_certification(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_certification_storeAction', value);
            },
            change_Selected_atElementOf_preference(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_preference_storeAction', value);
            },
            change_Selected_atElementOf_temperatureMonitoring(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_temperatureMonitoring_storeAction', value);
            },
            change_Selected_atElementOf_forcedVentilation(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_forcedVentilation_storeAction', value);
            },

            // SCH-MOD
            change_Selected_atElementOf_schmod_locationProduction(value) {
                this.$store.dispatch('psgMenu/change_SCHMOD_locationProduction_storeAction', value);
            },
            change_Selected_atElementOf_schmod_ProAp(value) {
                this.$store.dispatch('psgMenu/change_SCHMOD_ProAp_storeAction', value);
            },
            change_Selected_atElementOf_schmod_motorVoltage(value) {
                this.$store.dispatch('psgMenu/change_SCHMOD_motorVoltage_storeAction', value);
            },
            change_Selected_atElementOf_schmod_PSGSize(value) {
                this.$store.dispatch('psgMenu/change_SCHMOD_PSGSize_storeAction', value);
            },
            change_Selected_atElementOf_schmod_motorID(value) {
                this.$store.dispatch('psgMenu/change_SCHMOD_motorID_storeAction', value);
            },
            change_Selected_atElementOf_schmod_motorCOSName(value) {
                this.$store.dispatch('psgMenu/change_SCHMOD_motorCosName_storeAction', value);
            },
            change_Selected_atElementOf_schmod_inverterType(value) {
                this.$store.dispatch('psgMenu/change_SCHMOD_inverterType_storeAction', value);
            },
            change_Selected_atElementOf_schmod_cableLength(value) {
                this.$store.dispatch('psgMenu/change_SCHMOD_cableLength_storeAction', value);
            },
            change_Selected_atElementOf_schmod_cableLengthBrake(value) {
                this.$store.dispatch('psgMenu/change_SCHMOD_cableLengthBrake_storeAction', value);
            },
            change_Selected_atElementOf_schmod_handWheel(value) {
                this.$store.dispatch('psgMenu/change_SCHMOD_handWheel_storeAction', value);
            },
            change_Selected_atElementOf_schmod_handRelease(value) {
                this.$store.dispatch('psgMenu/change_SCHMOD_handRelease_storeAction', value);
            },
            change_Selected_atElementOf_schmod_grooveDistance(value) {
                this.$store.dispatch('psgMenu/change_SCHMOD_grooveDistance_storeAction', value);
            },
            change_Selected_atElementOf_schmod_groove(value) {
                this.$store.dispatch('psgMenu/change_SCHMOD_grooves_storeAction', value);
            },
            change_Selected_atElementOf_schmod_numberOfGrooves(value) {
                this.$store.dispatch('psgMenu/change_SCHMOD_numberOfGrooves_storeAction', value);
            },
            change_Selected_atElementOf_schmod_certification(value) {
                this.$store.dispatch('psgMenu/change_SCHMOD_certification_storeAction', value);
            },
            //change_onok(value) {
            //    this.$store.commit('psgMenu/change_SCHMOD_onok_storeMutation', value);
            //},
            //change_inok(value) {
            //    this.$store.commit('psgMenu/change_SCHMOD_inok_storeMutation', value);
            //},
            // traction sheave
            change_Selected_atElementOf_deliverTractionSheave(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_deliverTractionSheave_storeAction', value);
            },
            change_Selected_atElementOf_machiningForHandWheel(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_machiningForHandWheel_storeAction', value);
            },
            change_Selected_atElementOf_diameterAndWidth(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_tractionSheave_DiameterAndWidth_storeAction', value);
            },
            change_Selected_atElementOf_ropeDiameter(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_ropeDiameter_storeAction', value);
            },
            change_Selected_atElementOf_grooveDistance(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_grooveDistance_storeAction', value);
            },
            change_Selected_atElementOf_numberOfGrooves(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_numberOfGrooves_storeAction', value);
            },
            change_Selected_atElementOf_sheaveProfile(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_sheaveProfile_storeAction', value);
            },
            change_Selected_atElementOf_tractionSheaveAngleVgroove(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_vgrooveAngle_storeAction', value);
            },
            change_Selected_atElementOf_tractionSheaveAngleUndercut(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_undercutAngle_storeAction', value);
            },

            // Rope guard
            change_Selected_atElementOf_customerIdKid(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_customerIdKid_storeAction', value);
            },
            change_Selected_atElementOf_positionMotor(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_positionMotor_storeAction', value);
            },
            change_Selected_atElementOf_ropePull(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_ropePull_storeAction', value);
            },
            change_Selected_atElementOf_adjustability(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_adjustability_storeAction', value);
            },
            change_Selected_atElementOf_deliverRope(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_deliverRope_storeAction', value);
            },
            change_Selected_atElementOf_tractionSheaveCover(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_tractionSheaveCover_storeAction', value);
            },
            change_Selected_atElementOf_ropeClamp(value) {
                this.$store.dispatch('psgMenu/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_ropeClamp_storeAction', value);
            },

            furtherToDriveTechnologies() {
                this.$store.dispatch('inputsManual/checkIf_inputsManual_areValid_storeAction');
            },
            furtherToManualHome() {
                this.$store.dispatch('psgMenu/set_inputManual_SCHMOD_toDefault_storeAction');
                this.$store.dispatch('inputsManual/cancelManualMotorConfiguration_storeAction')
            },

        },



    }
</script>


<style>
    .my_inputCheckbox2 {
        border: 1px solid #ced2d8;
        list-style: none;
        padding-top: 5px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        margin: 0;
    }

        .my_inputCheckbox2 li {
            padding: 3px;
            /*margin-bottom: 3px;*/
        }

    .my_inputCheckbox {
        border: 1px solid #ced2d8;
        list-style: none;
        padding-top: 5px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        margin: 0;
    }

        .my_inputCheckbox li {
            display: inline-block;
            padding: 3px;
            /*margin-bottom: 3px;*/
        }

            .my_inputCheckbox li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_inputCheckbox li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }
</style>
