<template>
    <div style="overflow-x:auto !important">
        <div style="display: grid; grid-template-columns: auto 400px; grid-column-gap: 50px;">
            <div>
                <table style=" margin-top:10px; width:100%; vertical-align: middle !important;" cellpadding="10">
                    <tr v-if="plusManual==false">
                        <td style=" width: calc(100%/5); vertical-align: top">
                            <img src="../../../../public/img/ProductImages/ZAtop/ZAtop-SM160-40B-side.jpg" style="width:100px" />
                        </td>

                        <td style=" width: calc(100%/5); vertical-align: top; padding-top:15px">
                            <h4 class="my_color_00338e my_font-weight_600">{{$t('3028')}}</h4>
                        </td>

                        <td style=" width:calc(100%/5); vertical-align:top">
                            <button v-if="sapProfile==true" @click="ZAtop" class="btn my_btn_standard_blue">{{$t('3464')}}</button>
                        </td>
                        <td class="ZAfont_family ZAfont_size" style=" width:calc(100%/5*2); vertical-align:top">
                            <div v-if="motorManualDataSelected!=null && showMotorManualDetails==false">
                                <div v-if="motorManualDataSelected.mN_MOT!=null">
                                    <div class="my_color_00338e my_font-weight_600">{{$t('1098')}}:</div>
                                    <div style="margin-left:5px">
                                        <span v-if="motorManualDataSelected.mtyP2!=null">{{motorManualDataSelected.mtyP2}} | </span>
                                        <span v-if="motorManualDataSelected.mPr1!=null">{{motorManualDataSelected.mPr1}} kW | </span>
                                        <span v-if="motorManualDataSelected.mnr!=null">{{motorManualDataSelected.mnr}} Upm | </span>
                                        <span v-if="motorManualDataSelected.mU1!=null">{{motorManualDataSelected.mU1}} V </span>
                                    </div>
                                </div>
                                <div v-if="motorManualDataSelected.ltr!=null">
                                    <div class="my_color_00338e my_font-weight_600">{{$t('411')}}:</div>
                                    <div style="margin-left:5px">
                                        <span v-if="motorManualDataSelected.td!=null">{{motorManualDataSelected.td}} mm | </span>
                                        <span v-if="motorManualDataSelected.tb!=null">{{motorManualDataSelected.tb}} mm | </span>
                                        <span v-if="motorManualDataSelected.tdrz!=null&&motorManualDataSelected.tcsd!=null">{{motorManualDataSelected.tdrz}}x{{motorManualDataSelected.tcsd}} | </span>
                                        <span v-if="motorManualDataSelected.tauwi!=null">{{motorManualDataSelected.tauwi}}/</span>
                                        <span v-if="motorManualDataSelected.tauwi==null&&motorManualDataSelected.takwi!=null">{{motorManualDataSelected.takwi}} | </span>
                                        <span v-if="motorManualDataSelected.tarill!=null">{{motorManualDataSelected.tarill}} | </span>
                                        <span v-if="motorManualDataSelected.tdra!=null">{{motorManualDataSelected.tdra}}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="motorManualDataSelected!=null">
                                <div>
                                    <button v-if="showMotorManualDetails==false" @click="ChangeMotorDetails(true)" class="btn my_btn_details">
                                        <span>&#9660; </span>
                                        <span class="my_color_00338e my_font-weight_600" style="padding: 5px;">{{$t('3240')}} {{$t('3028')}}</span>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="motorManualDataSelected!=null">
                        <td colspan="100" class="ZAfont_family ZAfont_size" style="vertical-align: top;">
                            <div>
                                <!--<button v-if="showMotorManualDetails==false" @click="ChangeMotorDetails(true)" class="btn my_btn_details">
                                    <span>&#9660; </span>
                                    <span class="ZAfont_family ZAfont_size my_color_00338e my_font-weight_700" style="padding: 5px;">{{$t('3240')}} {{$t('3028')}}</span>
                                </button>-->
                                <button v-if="showMotorManualDetails==true" @click="ChangeMotorDetails(false)" class="btn my_btn_details">
                                    <span>&#9650; </span>
                                    <span class="my_color_00338e my_font-weight_600" style="padding: 5px;">{{$t('3240')}} {{$t('3028')}}</span>
                                </button>
                            </div>
                            <div v-if="showMotorManualDetails==true">
                                <div style="margin-left:30px">
                                    <div v-if="motorManualDataSelected.kid!=null" style="display: grid; grid-template-columns: 1fr 3fr;padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3452')}}:</div>
                                        <div>{{motorManualDataSelected.kid}}</div>
                                        <div></div>
                                    </div>
                                    <div v-if="motorManualDataSelected.massbl!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3544')}}:</div>
                                        <div>{{motorManualDataSelected.massbl}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.onok!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3545')}}:</div>
                                        <div>{{motorManualDataSelected.onok}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.inok!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3546')}}:</div>
                                        <div>{{motorManualDataSelected.inok}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_MOT!=null" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('1098')}}:</div>
                                        <div>{{motorManualDataSelected.mN_MOT}}</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.mtyP2!=null">{{motorManualDataSelected.mtyP2}} | </span>
                                            <span v-if="motorManualDataSelected.mPr1!=null">{{motorManualDataSelected.mPr1}} kW | </span>
                                            <span v-if="motorManualDataSelected.mnr!=null">{{motorManualDataSelected.mnr}} Upm | </span>
                                            <span v-if="motorManualDataSelected.mU1!=null">{{motorManualDataSelected.mU1}} V </span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mfbtyp!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('298')}}:</div>
                                        <span v-if="motorManualDataSelected.mfbtyp!=null && motorManualDataSelected.mfbtyp.useTextKey==false">{{motorManualDataSelected.mfbtyp.textUI}}</span>
                                        <span v-if="motorManualDataSelected.mfbtyp!=null && motorManualDataSelected.mfbtyp.useTextKey==true">{{$t(motorManualDataSelected.mfbtyp.textKey)}}</span>
                                    </div>
                                    <div v-if="motorManualDataSelected.ltr!=null" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('411')}}:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.mN_TS!=null">{{motorManualDataSelected.mN_TS}}</span>
                                        </div>
                                        <div>
                                            <span v-if="motorManualDataSelected.ltr!=null && motorManualDataSelected.ltr.useTextKey==false">{{motorManualDataSelected.ltr.textUI}}</span>
                                            <span v-if="motorManualDataSelected.ltr!=null && motorManualDataSelected.ltr.useTextKey==true">{{$t(motorManualDataSelected.ltr.textKey)}}</span>
                                            <span v-if="motorManualDataSelected.td!=null"> | {{motorManualDataSelected.td}} mm | </span>
                                            <span v-if="motorManualDataSelected.tb!=null">{{motorManualDataSelected.tb}} mm | </span>
                                            <span v-if="motorManualDataSelected.tdrz!=null&&motorManualDataSelected.tcsd!=null">{{motorManualDataSelected.tdrz}}x{{motorManualDataSelected.tcsd}} | </span>
                                            <span v-if="motorManualDataSelected.tauwi!=null">{{motorManualDataSelected.tauwi}} | </span>
                                            <span v-if="motorManualDataSelected.tauwi==null&&motorManualDataSelected.takwi!=null">{{motorManualDataSelected.takwi}} | </span>
                                            <span v-if="motorManualDataSelected.tarill!=null">{{motorManualDataSelected.tarill}} | </span>
                                            <span v-if="motorManualDataSelected.tdra!=null">{{motorManualDataSelected.tdra}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnzbtr!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3462')}} {{$t('411')}}:</div>
                                        <div>{{motorManualDataSelected.tnzbtr}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_ZBSEILSCHU!=null" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3462')}} {{$t('3533')}}:</div>
                                        <div>{{motorManualDataSelected.mN_ZBSEILSCHU}}</div>
                                        <div v-if="motorManualDataSelected.lseils!=null">
                                            <span v-if="motorManualDataSelected.lseils!=null && motorManualDataSelected.lseils.useTextKey==false">{{motorManualDataSelected.lseils.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.lseils!=null && motorManualDataSelected.lseils.useTextKey==true">{{$t(motorManualDataSelected.lseils.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.fuesse!=null && motorManualDataSelected.fuesse.useTextKey==false">{{$t('1416')}} {{motorManualDataSelected.fuesse.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.fuesse!=null && motorManualDataSelected.fuesse.useTextKey==true">{{$t('1416')}} {{$t(motorManualDataSelected.fuesse.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.seilab!=null && motorManualDataSelected.seilab.useTextKey==false">{{$t('3536')}} {{motorManualDataSelected.seilab.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.seilab!=null && motorManualDataSelected.seilab.useTextKey==true">{{$t('3536')}} {{$t(motorManualDataSelected.seilab.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.einst!=null && motorManualDataSelected.einst.useTextKey==false">{{$t('3537')}} {{motorManualDataSelected.einst.textUI}}</span>
                                            <span v-if="motorManualDataSelected.einst!=null && motorManualDataSelected.einst.useTextKey==true">{{$t('3537')}} {{$t(motorManualDataSelected.einst.textKey)}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_SAS" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3538')}}:</div>
                                        <div>{{motorManualDataSelected.mN_SAS}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_TSAD!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3534')}}:</div>
                                        <div>{{motorManualDataSelected.mN_TSAD}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_TSKL!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3543')}}:</div>
                                        <div>{{motorManualDataSelected.mN_TSKL}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_BREM!=null" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('1396')}}:</div>
                                        <div>{{motorManualDataSelected.mN_BREM}}</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.btyp!=null">{{motorManualDataSelected.btyp}} | </span>
                                            <span v-if="motorManualDataSelected.bcspan!=null">{{motorManualDataSelected.bcspan}} V | </span>
                                            <span v-if="motorManualDataSelected.bhl!=null && motorManualDataSelected.bhl.useTextKey==false">{{motorManualDataSelected.bhl.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.bhl!=null && motorManualDataSelected.bhl.useTextKey==true">{{$t(motorManualDataSelected.bhl.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.bksich!=null && motorManualDataSelected.bksich.useTextKey==false">{{motorManualDataSelected.bksich.textUI}}</span>
                                            <span v-if="motorManualDataSelected.bksich!=null && motorManualDataSelected.bksich.useTextKey==true">{{$t(motorManualDataSelected.bksich.textKey)}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnzbb!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3462')}} {{$t('1396')}}:</div>
                                        <div>{{motorManualDataSelected.tnzbb}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.bowdenwireBlockNumber!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3442')}}:</div>
                                        <div>{{motorManualDataSelected.bowdenwireBlockNumber}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_ENC!=null" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3075')}}:</div>
                                        <div>{{motorManualDataSelected.mN_ENC}}</div>
                                        <div>{{motorManualDataSelected.gtyp}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnlgl!=null" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3436')}}:</div>
                                        <div>{{motorManualDataSelected.tnlgl}}</div>
                                        <div v-if="motorManualDataSelected.encoderCableTypeDesignation!=null">
                                            <span v-if="motorManualDataSelected.llgl!=null && motorManualDataSelected.llgl.useTextKey==false">{{motorManualDataSelected.llgl.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.llgl!=null && motorManualDataSelected.llgl.useTextKey==true">{{$t(motorManualDataSelected.llgl.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.sglMaN!=null">{{motorManualDataSelected.sglMaN}} m | </span>
                                            <span>{{motorManualDataSelected.encoderCableTypeDesignation}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnlml!=null" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3437')}}:</div>
                                        <div>{{motorManualDataSelected.tnlml}}</div>
                                        <div v-if="motorManualDataSelected.motorCableTypeDesignation!=null">
                                            <span v-if="motorManualDataSelected.llml!=null && motorManualDataSelected.llml.useTextKey==false">{{motorManualDataSelected.llml.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.llml!=null && motorManualDataSelected.llml.useTextKey==true">{{$t(motorManualDataSelected.llml.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.smlMaN!=null">{{motorManualDataSelected.smlMaN}} m | </span>
                                            <span>{{motorManualDataSelected.motorCableTypeDesignation}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnlmsl!=null" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3438')}}:</div>
                                        <div>{{motorManualDataSelected.tnlmsl}}</div>
                                        <div v-if="motorManualDataSelected.motorProtectionCableTypeDesignation!=null">
                                            <span v-if="motorManualDataSelected.llms!=null && motorManualDataSelected.llms.useTextKey==false">{{motorManualDataSelected.llms.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.llms!=null && motorManualDataSelected.llms.useTextKey==true">{{$t(motorManualDataSelected.llms.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.smsMaN!=null">{{motorManualDataSelected.smsMaN}} m | </span>
                                            <span>{{motorManualDataSelected.motorProtectionCableTypeDesignation}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnlba!=null" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3439')}}:</div>
                                        <div>{{motorManualDataSelected.tnlba}}</div>
                                        <div v-if="motorManualDataSelected.brakeCableTypeDesignation!=null">
                                            <span v-if="motorManualDataSelected.llba!=null && motorManualDataSelected.llba.useTextKey==false">{{motorManualDataSelected.llba.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.llba!=null && motorManualDataSelected.llba.useTextKey==true">{{$t(motorManualDataSelected.llba.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.sbaMaN!=null">{{motorManualDataSelected.sbaMaN}} m | </span>
                                            <span>{{motorManualDataSelected.brakeCableTypeDesignation}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnlbl!=null" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3440')}}:</div>
                                        <div>{{motorManualDataSelected.tnlbl}}</div>
                                        <div v-if="motorManualDataSelected.brakeMonitoringCableTypeDesignation!=null">
                                            <span v-if="motorManualDataSelected.llbl!=null && motorManualDataSelected.llbl.useTextKey==false">{{motorManualDataSelected.llbl.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.llbl!=null && motorManualDataSelected.llbl.useTextKey==true">{{$t(motorManualDataSelected.llbl.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.sblMaN!=null">{{motorManualDataSelected.sblMaN}} m | </span>
                                            <span>{{motorManualDataSelected.brakeMonitoringCableTypeDesignation}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnfLei!=null" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3547')}}:</div>
                                        <div>{{motorManualDataSelected.tnfLei}}</div>
                                        <div v-if="motorManualDataSelected.fbCableTypeDesignation!=null">
                                            <span v-if="motorManualDataSelected.llfbl!=null && motorManualDataSelected.llfbl.useTextKey==false">{{motorManualDataSelected.llfbl.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.llfbl!=null && motorManualDataSelected.llfbl.useTextKey==true">{{$t(motorManualDataSelected.llfbl.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.sflMaN!=null">{{motorManualDataSelected.sflMaN}} m | </span>
                                            <span>{{motorManualDataSelected.fbCableTypeDesignation}}</span>
                                        </div>
                                    </div>

                                    <div v-if="motorManualDataSelected.mN_FARBE!=null" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3539')}}:</div>
                                        <div>{{motorManualDataSelected.mN_FARBE}}</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.farbe!=null && motorManualDataSelected.farbe.useTextKey==false">{{motorManualDataSelected.farbe.textUI}}</span>
                                            <span v-if="motorManualDataSelected.farbe!=null && motorManualDataSelected.farbe.useTextKey==true">{{$t(motorManualDataSelected.farbe.textKey)}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_LOGO!=null" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3540')}}:</div>
                                        <div>{{motorManualDataSelected.mN_LOGO}}</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.logo!=null && motorManualDataSelected.logo.useTextKey==false">{{motorManualDataSelected.logo.textUI}}</span>
                                            <span v-if="motorManualDataSelected.logo!=null && motorManualDataSelected.logo.useTextKey==true">{{$t(motorManualDataSelected.logo.textKey)}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_BAL1!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3390')}}:</div>
                                        <div>{{motorManualDataSelected.mN_BAL1}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_BAL2!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3390')}}:</div>
                                        <div>{{motorManualDataSelected.mN_BAL2}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_BAL3!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3390')}}:</div>
                                        <div>{{motorManualDataSelected.mN_BAL3}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.verp1!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3465')}} 1:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.verp1!=null && motorManualDataSelected.verp1.useTextKey==false">{{motorManualDataSelected.verp1.textUI}}</span>
                                            <span v-if="motorManualDataSelected.verp1!=null && motorManualDataSelected.verp1.useTextKey==true">{{$t(motorManualDataSelected.verp1.textKey)}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.verp2!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3465')}} 2:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.verp2!=null && motorManualDataSelected.verp2.useTextKey==false">{{motorManualDataSelected.verp2.textUI}}</span>
                                            <span v-if="motorManualDataSelected.verp2!=null && motorManualDataSelected.verp2.useTextKey==true">{{$t(motorManualDataSelected.verp2.textKey)}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.verp3!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3465')}} 3:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.verp3!=null && motorManualDataSelected.verp3.useTextKey==false">{{motorManualDataSelected.verp3.textUI}}</span>
                                            <span v-if="motorManualDataSelected.verp3!=null && motorManualDataSelected.verp3.useTextKey==true">{{$t(motorManualDataSelected.verp3.textKey)}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="plusManual==false">
                        <td style=" width:calc(100%/5)">
                            <img src="../../../../public/img/ProductImages/ZAframe/ZAframe-MH/ZAframe-MH4000-frontcreative.jpg" style="width:100px" />
                        </td>

                        <td style=" width:calc(100%/5)">
                            <h4 class="my_color_00338e my_font-weight_600">{{$t('1025')}}</h4>

                        </td>

                        <td style=" width:calc(100%/5)">
                            <button v-if="sapProfile==true" @click="ZAframe" class="btn my_btn_standard_blue">{{$t('3464')}}</button>

                        </td>

                        <td style=" width:calc(100%/5)">

                            <!--<h4 class="my_color_00338e my_font-weight_600">{{$t('1025')}}</h4>-->
                        </td>

                        <td style=" width:calc(100%/5)">

                            <!--<h4 class="my_color_00338e my_font-weight_600">{{$t('1025')}}</h4>-->
                        </td>
                    </tr>
                    <tr v-if="plusManual==true">
                        <td style=" width:calc(100%/5)">
                            <img src="../../../../public/img/ProductImages/Leitung/L-ML2.jpg" style="width:100px" />

                        </td>

                        <td style=" width:calc(100%/5)">
                            <h4 class="my_color_00338e my_font-weight_600">{{$t('3028')}} {{$t('3435')}}</h4>

                        </td>

                        <td style=" width:calc(100%/5)">
                            <button v-if="sapProfile==true" @click="MotorCables" class="btn my_btn_standard_blue">{{$t('3464')}}</button>
                        </td>

                        <td style=" width:calc(100%/5*2)">
                            <div v-if="cableSelectedInformation!=null">
                                <div v-if="cableSelectedInformation.encoderConnectionLength!=null">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3428')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{$t('3431')}}:</span><span style="margin-left:5px">{{cableSelectedInformation.encoderConnectionLength}} m</span>
                                    </div>
                                </div>
                                <div v-if="cableSelectedInformation.encoderCableSelected!=null&&cableSelectedInformation.encoderCableSelected==true">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3436')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{$t('407')}}:</span>
                                        <span style="margin-left:5px">
                                            <span>{{cableSelectedInformation.encoderCableTypeDesignation}}</span>
                                        </span>
                                        <span v-if="cableSelectedInformation.encoderCableLength!=null">
                                            <span style="margin-left:10px">|</span>
                                            <span style="margin-left:10px">{{$t('3431')}}:</span><span style="margin-left:5px">{{cableSelectedInformation.encoderCableLength}} m</span>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="cableSelectedInformation.motorCableSelected!=null&&cableSelectedInformation.motorCableSelected==true">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3437')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{$t('407')}}:</span>
                                        <span style="margin-left:5px">
                                            <span>{{cableSelectedInformation.motorCableTypeDesignation}}</span>
                                        </span>
                                        <span v-if="cableSelectedInformation.motorCableLength!=null">
                                            <span style="margin-left:10px">|</span>
                                            <span style="margin-left:10px">{{$t('3431')}}:</span><span style="margin-left:5px">{{cableSelectedInformation.motorCableLength}} m</span>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="cableSelectedInformation.motorProtectionCableSelected!=null&&cableSelectedInformation.motorProtectionCableSelected==true">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3438')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{$t('407')}}:</span>
                                        <span style="margin-left:5px">
                                            <span>{{cableSelectedInformation.motorProtectionCableTypeDesignation}}</span>
                                        </span>
                                        <span v-if="cableSelectedInformation.motorProtectionCableLength!=null">
                                            <span style="margin-left:10px">|</span>
                                            <span style="margin-left:10px">{{$t('3431')}}:</span><span style="margin-left:5px">{{cableSelectedInformation.motorProtectionCableLength}} m</span>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="cableSelectedInformation.brakeCableSelected!=null&&cableSelectedInformation.brakeCableSelected==true">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3439')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{$t('407')}}:</span>
                                        <span style="margin-left:5px">
                                            <span>{{cableSelectedInformation.brakeCableTypeDesignation}}</span>
                                        </span>
                                        <span v-if="cableSelectedInformation.brakeCableLength!=null">
                                            <span style="margin-left:10px">|</span>
                                            <span style="margin-left:10px">{{$t('3431')}}:</span><span style="margin-left:5px">{{cableSelectedInformation.brakeCableLength}} m</span>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="cableSelectedInformation.brakeMonitoringCableSelected!=null&&cableSelectedInformation.brakeMonitoringCableSelected==true">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3439')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{$t('407')}}:</span>
                                        <span style="margin-left:5px">
                                            <span>{{cableSelectedInformation.brakeMonitoringCableTypeDesignation}}</span>
                                        </span>
                                        <span v-if="cableSelectedInformation.brakeMonitoringCableLength!=null">
                                            <span style="margin-left:10px">|</span>
                                            <span style="margin-left:10px">{{$t('3431')}}:</span><span style="margin-left:5px">{{cableSelectedInformation.brakeMonitoringCableLength}} m</span>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="cableSelectedInformation.forcedVentilationCableSelected!=null&&cableSelectedInformation.forcedVentilationCableSelected==true">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3447')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{$t('407')}}:</span>
                                        <span style="margin-left:5px">
                                            <span>{{cableSelectedInformation.forcedVentilationCableTypeDesignation}}</span>
                                        </span>
                                        <span v-if="cableSelectedInformation.forcedVentilationCableLength!=null">
                                            <span style="margin-left:10px">|</span>
                                            <span style="margin-left:10px">{{$t('3431')}}:</span><span style="margin-left:5px">{{cableSelectedInformation.forcedVentilationCableLength}} m</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="showMotorSupplies==true">
                        <td style=" width:calc(100%/5)">
                            <img src="../../../../public/img/ZAbrands/ZAsymbol.svg" style="width:70px" />
                        </td>
                        <td style=" width:calc(100%/5)">
                            <h4 class="my_color_00338e my_font-weight_600">{{$t('3028')}} {{$t('3462')}}</h4>
                        </td>
                        <td style=" width:calc(100%/5)">
                            <button v-if="sapProfile==true" @click="MotorSupplies" class="btn my_btn_standard_blue">{{$t('3464')}}</button>
                        </td>

                        <td style=" width:calc(100%/5*2)">
                            <div v-if="motorSuppliesSelected!=null">
                                <div v-if="motorSuppliesSelected.mn_FARBE!=null">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3539')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{motorSuppliesSelected.encoderConnectionLength}}</span>
                                        <span style="margin-left:10px">|</span>
                                        <span style="margin-left:10px">{{cableSelected.sglMaN}}</span>
                                    </div>
                                </div>
                                <div v-if="motorSuppliesSelected.mN_BAL1!=null">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3390')}}:</div>
                                    <div style="margin-left:5px">
                                        <span v-if="motorSuppliesSelected.mN_BAL1!=null">{{motorSuppliesSelected.mN_BAL1}}</span>
                                        <span v-if="motorSuppliesSelected.mN_BAL2!=null"> | {{motorSuppliesSelected.mN_BAL2}}</span>
                                        <span v-if="motorSuppliesSelected.mN_BAL3!=null"> | {{motorSuppliesSelected.mN_BAL3}}</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="showMotorPackaging==true">
                        <td style=" width:calc(100%/5)">
                            <img src="../../../../public/img/ZAbrands/ZAsymbol.svg" style="width:70px" />
                        </td>
                        <td style=" width:calc(100%/5)">
                            <h4 class="my_color_00338e my_font-weight_600">{{$t('3028')}} {{$t('3465')}}</h4>
                        </td>
                        <td style=" width:calc(100%/5)">
                            <button v-if="sapProfile==true" @click="MotorPackaging" class="btn my_btn_standard_blue">{{$t('3464')}}</button>
                        </td>

                        <td style=" width:calc(100%/5*2)">
                            <div v-if="motorPackagingSelected!=null">
                                <div v-if="motorPackagingSelected.verp1!=null">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3465')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{motorPackagingSelected.verp1}}</span>
                                        <span style="margin-left:10px">|</span>
                                        <span style="margin-left:10px">{{motorPackagingSelected.verp2}}</span>
                                        <span style="margin-left:10px">|</span>
                                        <span style="margin-left:10px">{{motorPackagingSelected.verp3}}</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style=" width:calc(100%/5)">
                            <img src="../../../../public/img/ProductImages/ZAdyn4C/ZAdyn4C_011-032_ZD4C01M0-front.jpg" style="width:100px" />
                        </td>
                        <td style=" width:calc(100%/5)">
                            <h4 class="my_color_00338e my_font-weight_600">{{$t('3502')}}</h4>
                        </td>
                        <td style=" width:calc(100%/5)">
                            <button v-if="sapProfile==true" @click="ZAdyn" class="btn my_btn_standard_blue" v-bind:disabled="plusManual==true">{{$t('3464')}}</button>
                        </td>


                        <td style=" width:calc(100%/5)">
                            <div v-if="Baug_Select != undefined" class="my_color_00338e my_font-weight_600">{{$t('305')}}:</div>
                            <div v-if="Baug_Select != undefined">{{Baug_Select}}</div>
                            <div v-if="Menue_Select != undefined">{{Menue_Select}}</div>
                            <div v-if="Bal_Select != undefined">{{Bal_Select}}</div>
                            <div v-if="Par_Select != undefined">{{Par_Select}}</div>
                            <div v-if="Conf_Select != undefined">{{Conf_Select}}</div>
                            <div v-if="Code_Select != undefined">{{Code_Select}}</div>
                        </td>

                        <td style=" width:calc(100%/5)">
                            <div v-if="PAD_Select != undefined || MON_Select != undefined || ND_Select != undefined || FEF_Select != undefined || BR_Select != undefined || BRLT_Select != undefined || DCP_Select != undefined || STO_Select != undefined || XOUT_Select != undefined || XIN_Select != undefined || Nachruestsatz_Select != undefined || ZAsbc_Select != undefined" class="my_color_00338e my_font-weight_600">{{$t('3527')}}:</div>
                            <div v-if="PAD_Select != undefined">{{PAD_Select}}</div>
                            <div v-if="MON_Select != undefined">{{MON_Select}}</div>
                            <div v-if="ND_Select != undefined">{{ND_Select}}</div>
                            <div v-if="FEF_Select != undefined">{{FEF_Select}}</div>
                            <div v-if="BR_Select != undefined">{{BR_Select}}</div>
                            <div v-if="BRLT_Select != undefined">{{BRLT_Select}}</div>
                            <div v-if="Nachruestsatz_Select != undefined">{{Nachruestsatz_Select}}</div>
                            <div v-if="DCP_Select != undefined">{{DCP_Select}}</div>
                            <div v-if="STO_Select != undefined">{{STO_Select}}</div>
                            <div v-if="XOUT_Select != undefined">{{XOUT_Select}}</div>
                            <div v-if="XIN_Select != undefined">{{XIN_Select}}</div>
                            <div v-if="ZAsbc_Select != undefined">{{ZAsbc_Select}}</div>
                            <div v-if="ZAsbc_LS_Select != undefined">{{ZAsbc_LS_Select}}</div>
                            <div v-if="ZAsbc_LSBA_Select != undefined">{{ZAsbc_LSBA_Select}}</div>
                            <div v-if="ZAsbc_BAL_Select != undefined">{{ZAsbc_BAL_Select}}</div>
                        </td>


                    </tr>
                    <tr>
                        <td style=" width:calc(100%/5)">
                            <img src="../../../../public/img/ProductImages/EVAC_3/EVAC3B-front-web.jpg" style="width:100px" />

                        </td>

                        <td style=" width:calc(100%/5)">

                            <h4 class="my_color_00338e my_font-weight_600">{{$t('3500')}}</h4>
                        </td>

                        <td style=" width:calc(100%/5)">

                            <button v-if="sapProfile==true" @click="EVAC" class="btn my_btn_standard_blue" v-bind:disabled="plusManual==true">{{$t('3464')}}</button>
                        </td>

                        <td style=" width:calc(100%/5)">
                            <div v-if="EVAC_Typ_select != undefined" class="my_color_00338e my_font-weight_600">{{$t('3501')}}:</div>
                            <div>{{EVAC_Typ_select}}</div>
                            <div>{{EVAC_Batt_select}}</div>
                            <div>{{EVAC_Cable_select}}</div>
                            <div>{{EVAC_Control_Cable_select}}</div>
                            <div>{{EVAC_Bal_select}}</div>
                        </td>

                        <td style=" width:calc(100%/5)">
                        </td>
                    </tr>

                    <tr>
                        <td style=" width:calc(100%/5)">


                            <img src="../../../../public/img/ProductImages/ZArec/ZArec4C_013_ZDRE01M0-front.jpg" style="width:100px" />

                        </td>

                        <td style=" width:calc(100%/5)">

                            <h4 class="my_color_00338e my_font-weight_600">{{$t('361')}}</h4>
                        </td>

                        <td style=" width:calc(100%/5)">

                            <button v-if="sapProfile==true" @click="ZArec" class="btn my_btn_standard_blue" v-bind:disabled="plusManual==true">{{$t('3464')}}</button>
                        </td>

                        <td style=" width:calc(100%/5)">
                            <div v-if="ZArec_Typ_select != undefined" class="my_color_00338e my_font-weight_600">Ausgewaehlte ZArec:</div>
                            <div>{{ZArec_Typ_select}}</div>
                            <div>{{ZArec_LS_select}}</div>
                            <div>{{ZArec_BR_select}}</div>
                            <div>{{ZArec_BRLT_select}}</div>
                            <div>{{ZArec_ZApad_select}}</div>
                            <div>{{ZArec_BAL_select}}</div>
                            <div v-if="RLD_Typ_select != undefined" class="my_color_00338e my_font-weight_600">Ausgewaehlte RLD:</div>
                            <div>{{RLD_Typ_select}}</div>
                            <div>{{RLD_FEF_select}}</div>
                        </td>

                        <td style=" width:calc(100%/5)">
                        </td>
                    </tr>

                    <tr>
                        <td style=" width:calc(100%/5)">


                            <!-- <img src="../../../../public/img/ProductImages" style="width:100px" />-->

                        </td>

                        <!--<td style=" width:calc(100%/5)">

                            <h4 class="my_color_00338e my_font-weight_600">RLD</h4>
                        </td>

                        <td style=" width:calc(100%/5)">

                            <button v-if="sapProfile==true" @click="RLD" class="btn my_btn_standard_blue">{{$t('3298')}}</button>
                        </td>-->

                        <td style=" width:calc(100%/5)">
                        </td>

                        <td style=" width:calc(100%/5)">
                        </td>
                    </tr>

                </table>
            </div>
            <div v-if="plusManual==true" style="margin-left: 0px; magin-rigth:0px">
                <SummaryInformation :additional="true"></SummaryInformation>
            </div>
            <div v-if="plusManual==false" style="margin-left: 0px; magin-rigth:0px">
                <SummaryInformationManual :additional="false"></SummaryInformationManual>
            </div>
        </div>
    </div>
</template>


<script>
    import router from '../../../router';
    import SummaryInformation from '../../configuration/configurationSteps/SummaryInformation';
    import SummaryInformationManual from '../../configuration/manualSteps/SummaryInformationManual';

    export default {
        name: 'ManualHome',
        components: {
            SummaryInformation,
            SummaryInformationManual,
        },
        computed: {
            sapProfile: {
                get() {
                    return this.$store.state.configurations.sap;
                },
            },
            ZLM: {
                get() {
                    return this.$store.state.manualHome.ZLM;
                },
            },
            Baug_Select: {
                get() {
                    return this.$store.state.FI.FI_ModelUI_selected;
                },
            },
            Menue_Select: {
                get() {
                    return this.$store.state.FI.FI_MenueUI_selected;
                },
            },
            Bal_Select: {
                get() {
                    return this.$store.state.FI.FI_BalUI_selected;
                },
            },
            Par_Select: {
                get() {
                    return this.$store.state.FI.FI_ParUI_selected;
                },
            },
            Conf_Select: {
                get() {
                    return this.$store.state.FI.FI_ConfUI_selected;
                },
            },
            Code_Select: {
                get() {
                    return this.$store.state.FI.FI_CodeUI_selected;
                },
            },
            PAD_Select: {
                get() {
                    return this.$store.state.FI.FI_PADUI_selected;
                },
            },
            MON_Select: {
                get() {
                    return this.$store.state.FI.FI_MONUI_selected;
                },
            },
            ND_Select: {
                get() {
                    return this.$store.state.FI.FI_NDUI_selected;
                },
            },
            FEF_Select: {
                get() {
                    return this.$store.state.FI.FI_FEFUI_selected;
                },
            },
            BR_Select: {
                get() {
                    return this.$store.state.FI.FI_BRUI_selected;
                },
            },
            BRLT_Select: {
                get() {
                    return this.$store.state.FI.FI_BRLTUI_selected;
                },
            },
            Nachruestsatz_Select: {
                get() {
                    return this.$store.state.FI.FI_NachruestsatzUI_selected;
                },
            },
            DCP_Select: {
                get() {
                    return this.$store.state.FI.FI_DCPUI_selected;
                },
            },
            STO_Select: {
                get() {
                    return this.$store.state.FI.FI_STOUI_selected;
                },
            },
            XOUT_Select: {
                get() {
                    return this.$store.state.FI.FI_XOUTUI_selected;
                },
            },
            XIN_Select: {
                get() {
                    return this.$store.state.FI.FI_XINUI_selected;
                },
            },
            ZAsbc_Select: {
                get() {
                    return this.$store.state.FI.FI_ZAsbcUI_selected;
                },
            },
            ZAsbc_LS_Select: {
                get() {
                    return this.$store.state.FI.FI_ZAsbc_LSUI_selected
                },
            },
            ZAsbc_LSBA_Select: {
                get() {
                    return this.$store.state.FI.FI_ZAsbc_LSBAUI_selcted
                },
            },
            ZAsbc_BAL_Select: {
                get() {
                    return this.$store.state.FI.FI_ZAsbc_BALUI_selcted
                },
            },
            EVAC_Typ_select: {
                get() {
                    return this.$store.state.EVAC.EVAC_TypUI_select;
                },
            },
            EVAC_Batt_select: {
                get() {
                    return this.$store.state.EVAC.EVAC_BattUI_select;
                },
            },
            EVAC_Cable_select: {
                get() {
                    return this.$store.state.EVAC.EVAC_CableUI_select;
                },
            },
            EVAC_Control_Cable_select: {
                get() {
                    return this.$store.state.EVAC.EVAC_Control_CableUI_select;
                },
            },
            EVAC_Bal_select: {
                get() {
                    return this.$store.state.EVAC.EVAC_BalUI_select;
                },
            },
            ZArec_Typ_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_TypUI_select;
                },
            },
            ZArec_LS_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_LSUI_select;
                },
            },
            ZArec_BR_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_BRUI_select;
                },
            },
            ZArec_BRLT_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_BRLTUI_select;
                },
            },
            ZArec_ZApad_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_ZApadUI_select;
                },
            },
            ZArec_BAL_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_BALUI_select;
                },
            },
            RLD_Typ_select: {
                get() {
                    return this.$store.state.ZArec.RLD_TypUI_select;
                },
            },
            RLD_FEF_select: {
                get() {
                    return this.$store.state.ZArec.RLD_FEFUI_select;
                },
            },

            plusManual: {
                get() {
                    return this.$store.state.configurations.plusManual;
                },
            },
            configurationOverviewsId: {
                get() {
                    return this.$store.state.configurations.configurationOverviewsId;
                },
            },

            cableSelected: {
                get() {
                    if (this.$store.state.summary.manualHomeSummary != null && this.$store.state.summary.manualHomeSummary.hasOwnProperty('cables')) {
                        return this.$store.state.summary.manualHomeSummary.cables;

                    }
                    else {
                        return null;
                    }
                },
            },
            cableSelectedInformation: {
                get() {
                    if (this.$store.state.summary.manualHomeSummary != null && this.$store.state.summary.manualHomeSummary.hasOwnProperty('cablesInformation')) {
                        return this.$store.state.summary.manualHomeSummary.cablesInformation;

                    }
                    else {
                        return null;
                    }
                }
            },
            motorSuppliesSelected: {
                get() {
                    if (this.$store.state.summary.manualHomeSummary != null && this.$store.state.summary.manualHomeSummary.hasOwnProperty('suppliesMotorManual')) {
                        return this.$store.state.summary.manualHomeSummary.suppliesMotorManual;

                    }
                    else {
                        return null;
                    }
                },
            },
            motorPackagingSelected: {
                get() {
                    if (this.$store.state.summary.manualHomeSummary != null && this.$store.state.summary.manualHomeSummary.hasOwnProperty('packagingMotorManual')) {
                        return this.$store.state.summary.manualHomeSummary.packagingMotorManual;

                    }
                    else {
                        return null;
                    }
                },
            },
            motorManualDataSelected: {
                get() {
                    if (this.$store.state.inputsManual.motorManualData != null && this.$store.state.inputsManual.motorManualData.hasOwnProperty('mN_MOT')) {
                        return this.$store.state.inputsManual.motorManualData;
                    }
                    else {
                        return null;
                    }
                }
            },
            showMotorManualDetails: {
                get() {
                    return this.$store.state.inputsManual.showMotorDetails;
                }
            },
            showMotorSupplies: {
                get() {
                    if (this.plusManual == true && this.cableSelected != null) {
                        return true;
                    }
                    return false;
                },
            },
            showMotorPackaging: {
                get() {
                    if (this.plusManual == true && this.motorSuppliesSelected != null) {
                        return true;
                    }
                    return false;
                },
            }

        },
        created() {
            this.$store.commit('configurations/change_elevatorMotor_Manual_storeMutation', false);
            this.$store.dispatch('configurations/check_plusManual');
            if (this.plusManual == true) {
                this.$store.commit('configurations/MANU_configuration', false);
                this.$store.dispatch('configurations/change_manualConfiguration_storeAction', false);
                this.$store.dispatch('summary/select_databaseRecords_for_manualHome_storeAction')

            }
            else {
                this.$store.commit('configurations/change_sap_storeMutation', true);

                if (this.$store.state.configurations.MANUAL == false) {
                    this.$store.commit('configurations/MANU_configuration', true);
                }
                this.$store.dispatch('manualHome/check_ZLM_storeAction');
                this.$store.dispatch('inputsManual/change_manualMotorToDefault_storeAction');

            }
        },

        methods: {
            ZAtop() {
                this.ChangeMotorDetails(false);
                this.$store.dispatch('configurations/change_elevatorMotor_Manual_storeAction', true);
                router.push('../manualStep/inputsManual');
            },

            ZAframe() {

            },

            ZAdyn() {

                this.$store.dispatch('configurations/MANU', true)
                this.$store.dispatch('inputs/change_inputs_toDefault_storeAction');
                this.$store.dispatch('driveTechnologies/change_driveTechnologies_toDefault_storeAction');
                this.$store.dispatch('controlTechnologies/change_controlTechnologies_toDefault_storeAction');
                this.$store.dispatch('summary/change_summary_toDefault_storeAction');
                this.$store.dispatch('configurations/change_configurations_toDefault_storeAction');
                //this.$store.dispatch('controlTechnologies/get_FI_Type_storeAction')

                this.$store.dispatch('FI/get_FI_Type_storeAction');

                router.push('../manualStep/FI');
            },

            EVAC() {
                console.log('EVAC')
                this.$store.dispatch('EVAC/get_EVAC_Typ_storeAction');

                router.push('../manualStep/EVAC');
            },

            ZArec() {
                console.log('ZArec')
                
                this.$store.dispatch('ZArec/get_ZArec_Typ_storeAction');
                this.$store.dispatch('ZArec/get_RLD_Typ_storeAction');
               
                router.push('../manualStep/ZArec');
            },

            RLD() {
                router.push('../manualStep/RLD');
            },
            MotorCables() {
                router.push('../manualStep/cables');
            },
            MotorSupplies() {
                router.push('../manualStep/suppliesMotorManual')
            },
            MotorPackaging() {
                router.push('../manualStep/packagingMotorManual')
            },
            ChangeMotorDetails(value) {
                this.$store.commit('inputsManual/change_showMotorDetails_storeMutation', value)
            },

        },
    }
</script>


<style>
    .my_typeOfSuspensionMeans {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_typeOfSuspensionMeans li {
            border: 2px solid #00338e;
            color: #000000;
            display: inline-block;
            padding: 0.3rem;
            text-align: center;
            width: 50%;
        }

        .my_typeOfSuspensionMeans .active {
            background-color: #00338e;
            color: #ffffff;
        }

    .classC {
        margin-top: 78px;
    }

    .input_margin-bottom {
        margin-bottom: 20px;
    }
</style>
