<template>
    <div style="overflow-x:auto !important">
        <div class="my_fontSize_1Point5 my_color_00338e my_fontWeight_600 ZAfont_family">{{$t('3027')}}</div>
        <div v-if="elevatorMotor_Machine_Manual != null">
            <!--v-if="((elevatorMotor_Machine_Selection_Shown==false)&&(elevatorMotor_Machine_Selected==true))"-->
            <ul style="list-style:none; margin:0; padding:0">
                <li style="display:block; border: 2px solid blue;">
                    <div class="my_div_width1500" style="width:100%">
                        <div style="border: 1px solid #ced2d8; display:grid;">
                            <div style="display:grid; grid-template-columns:auto auto auto auto auto auto auto; grid-template-rows:auto auto auto;  padding:9px; overflow: auto">
                                <div style="align-self:center; grid-column: 1 / span 7">
                                    <div class="my_fontWeight_600 my_fontSize_1Point1 ZAfont_family">
                                        <!--:style='{color:customizedColor(selected_elevatorMotor_Machine.customized)}'-->
                                        <span>{{elevatorMotor_Machine_Manual.elevatorMotorMachineManual.typeDesignation}}</span>
                                        <span class="my_fontWeight_600" v-if="elevatorMotor_Machine_Manual.elevatorMotorMachineManual.autoP==1" style="padding-left:15px; color:royalblue">A</span>
                                        <!--<span v-if="selected_elevatorMotor_Machine.customized===true"> ({{selected_elevatorMotor_Machine.customerName}})</span>-->
                                    </div>
                                </div>
                                <div class="width1500" style="grid-column: 1 / span 1; grid-row: 2 / span 2">
                                    <Images :imageFilePath="elevatorMotor_Machine_Manual.elevatorMotorMachineManual.imagePath" :maxImageHeight="90" :maxImageWidth="150"></Images>
                                </div>
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{elevatorMotor_Machine_Manual.tractionSheaveManual.diameter}} <!--|--> <!--{{selected_elevatorMotor_Machine.elevatorMotor_TractionSheave.grooveDistance}}--></span> mm</div>
                                    <div class="my_table_parameters_infoText">{{$t('1301')}}</div>
                                </div>
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{elevatorMotor_Machine_Manual.tractionSheaveManual.width}}</span> mm</div>
                                    <div class="my_table_parameters_infoText">{{$t('1182')}}</div>
                                </div>
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{elevatorMotor_Machine_Manual.tractionSheaveManual.grooveDistance}}</span> mm</div>
                                    <div class="my_table_parameters_infoText">{{$t('1294')}}</div>
                                </div>
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point4">
                                        <span class="my_font-weight_600" v-if="elevatorMotor_Machine_Manual.tractionSheaveManual.profile.useTextKey==false || elevatorMotor_Machine_Manual.tractionSheaveManual.profile.textKey==null ">{{elevatorMotor_Machine_Manual.tractionSheaveManual.profile.textUI}}</span>
                                        <span class="my_font-weight_600" v-if="elevatorMotor_Machine_Manual.tractionSheaveManual.profile.useTextKey==true && elevatorMotor_Machine_Manual.tractionSheaveManual.profile.textKey!=null">{{$t(elevatorMotor_Machine_Manual.tractionSheaveManual.profile.textKey)}}</span>
                                    </div>
                                    <div class="my_table_parameters_infoText">{{$t('1123')}}</div>
                                </div>
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{elevatorMotor_Machine_Manual.tractionSheaveManual.numberOfGrooves}}</span> mm</div>
                                    <div class="my_table_parameters_infoText">{{$t('165')}}</div>
                                </div>
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{elevatorMotor_Machine_Manual.tractionSheaveManual.ropeDiameter}}</span> mm</div>
                                    <div class="my_table_parameters_infoText">{{$t('1067')}}</div>
                                </div>
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{elevatorMotor_Machine_Manual.elevatorMotorMachineManual.ratedSpeed}}</span> rpm</div>
                                    <div class="my_table_parameters_infoText">{{$t('1091')}}</div>
                                </div>
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point4"><span class="my_font-weight_600">{{elevatorMotor_Machine_Manual.elevatorMotorMachineManual.voltage}}</span> V</div>
                                    <div class="my_table_parameters_infoText">{{$t('3073')}}</div>
                                </div>
                                <!--<div class="my_div_minWidth_100">
                                <div class="my_fontSize_1Point4"><span class="my_font-weight_600">PTC-->
                                <!--{{elevatorMotor_Machine_Manual.elevatorMotorMachineManual.nominalTorque}}-->
                                <!--</span></div>
                                    <div class="my_table_parameters_infoText">{{$t('3067')}}</div>
                                </div>-->
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point4">
                                        <span class="my_font-weight_600" v-if="elevatorMotor_Machine_Manual.elevatorMotorMachineManual.certification.useTextKey==false || elevatorMotor_Machine_Manual.elevatorMotorMachineManual.certification.textKey==null ">{{elevatorMotor_Machine_Manual.elevatorMotorMachineManual.certification.textUI}}</span>
                                        <span class="my_font-weight_600" v-if="elevatorMotor_Machine_Manual.elevatorMotorMachineManual.certification.useTextKey==true && elevatorMotor_Machine_Manual.elevatorMotorMachineManual.certification.textKey!=null">{{$t(elevatorMotor_Machine_Manual.elevatorMotorMachineManual.certification.textKey)}}</span>
                                    </div>
                                    <div class="my_table_parameters_infoText">{{$t('3455')}}</div>
                                </div>
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point4">
                                        <span class="my_font-weight_600" v-if="elevatorMotor_Machine_Manual.elevatorMotorMachineManual.forcedVentilation.useTextKey==false || elevatorMotor_Machine_Manual.elevatorMotorMachineManual.forcedVentilation.textKey==null ">{{elevatorMotor_Machine_Manual.elevatorMotorMachineManual.forcedVentilation.textUI}}</span>
                                        <span class="my_font-weight_600" v-if="elevatorMotor_Machine_Manual.elevatorMotorMachineManual.forcedVentilation.useTextKey==true && elevatorMotor_Machine_Manual.elevatorMotorMachineManual.forcedVentilation.textKey!=null">{{$t(elevatorMotor_Machine_Manual.elevatorMotorMachineManual.forcedVentilation.textKey)}}</span>
                                    </div>
                                    <div class="my_table_parameters_infoText">{{$t('298')}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div v-if="elevatorMotor_Brake_Selection.length>0" style="align-items:center; margin-top:25px">
            <div>
                <div class="my_fontSize_1Point5 my_color_00338e my_fontWeight_600 ZAfont_family">{{$t('3260')}}</div>
            </div>
            <div style="grid-column:2 / span 1">
                <div>
                    <div>
                        <div class="my_fontSize_1Point2 ZAfont_family">
                            <span>{{$t('3073')}}</span>
                            <span v-if="elevatorMotor_Brake_Voltage_Selection.length==1">: </span>
                            <span v-if="selected_elevatorMotor_Brake_Voltage.nominalVoltage == selected_elevatorMotor_Brake_Voltage.switchOnVoltage" class="my_font-weight_600"> {{selected_elevatorMotor_Brake_Voltage.nominalVoltage}}</span>
                            <span v-if="selected_elevatorMotor_Brake_Voltage.nominalVoltage != selected_elevatorMotor_Brake_Voltage.switchOnVoltage && selected_elevatorMotor_Brake_Voltage.nominalVoltage > 0" class="my_font-weight_600">{{selected_elevatorMotor_Brake_Voltage.switchOnVoltage}} / {{selected_elevatorMotor_Brake_Voltage.nominalVoltage}}</span>
                            <span v-if="selected_elevatorMotor_Brake_Voltage.nominalVoltage != selected_elevatorMotor_Brake_Voltage.switchOnVoltage && selected_elevatorMotor_Brake_Voltage.nominalVoltage <= 0" class="my_font-weight_600">{{selected_elevatorMotor_Brake_Voltage.switchOnVoltage}}</span>
                            <span> V</span>
                        </div>
                        <div v-if="elevatorMotor_Brake_Voltage_Selection.length>1">
                            <ul class="my_elevatorMotor_Brake_Voltage_Selection my_fontSize_1Point1">
                                <li style="cursor: pointer" v-for="elevatorMotor_Brake_Voltage in elevatorMotor_Brake_Voltage_Selection" @click="change_Selected_at_elevatorMotor_Brake_Voltage_Selection(elevatorMotor_Brake_Voltage)" v-bind:class="{active: elevatorMotor_Brake_Voltage.selected}"><span v-if="elevatorMotor_Brake_Voltage.nominalVoltage == elevatorMotor_Brake_Voltage.switchOnVoltage" class="my_fontWeight_600">{{elevatorMotor_Brake_Voltage.nominalVoltage}}</span><span v-if="elevatorMotor_Brake_Voltage.nominalVoltage != elevatorMotor_Brake_Voltage.switchOnVoltage && elevatorMotor_Brake_Voltage.nominalVoltage > 0" class="my_fontWeight_600">{{elevatorMotor_Brake_Voltage.switchOnVoltage}} / {{elevatorMotor_Brake_Voltage.nominalVoltage}}</span><span v-if="elevatorMotor_Brake_Voltage.nominalVoltage != elevatorMotor_Brake_Voltage.switchOnVoltage && elevatorMotor_Brake_Voltage.nominalVoltage <= 0" class="my_fontWeight_600">{{elevatorMotor_Brake_Voltage.switchOnVoltage}}</span> V</li>
                            </ul>
                        </div>
                    </div>
                    <div style="margin-top:10px">
                        <div class="my_fontSize_1Point2 ZAfont_family">
                            <span>{{$t('3045')}} :</span>
                            <span v-if="elevatorMotor_Brake_MechanicalRelease_Selection.length==1">
                                <span class="my_font-weight_600" v-if="selected_elevatorMotor_Brake_MechanicalRelease!=null">
                                    <span v-if="selected_elevatorMotor_Brake_MechanicalRelease.useTextKey==false"> {{selected_elevatorMotor_Brake_MechanicalRelease.textUI}}</span>
                                    <span v-if="selected_elevatorMotor_Brake_MechanicalRelease.useTextKey==true"> {{$t(selected_elevatorMotor_Brake_MechanicalRelease.textKey)}}</span>
                                </span>
                            </span>
                        </div>
                        <div v-if="elevatorMotor_Brake_MechanicalRelease_Selection.length>1">
                            <ul class="my_elevatorMotor_Brake_MechanicalRelease_Selection my_fontSize_1Point1 my_fontWeight_600">
                                <li style="cursor: pointer" v-for="elevatorMotor_Brake_MechanicalRelease in elevatorMotor_Brake_MechanicalRelease_Selection" @click="change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection(elevatorMotor_Brake_MechanicalRelease)" v-bind:class="{active: elevatorMotor_Brake_MechanicalRelease.selected, disabled: elevatorMotor_Brake_MechanicalRelease.disabled}">
                                    <span v-if="elevatorMotor_Brake_MechanicalRelease.useTextKey==true">{{$t(elevatorMotor_Brake_MechanicalRelease.textKey)}}</span>
                                    <span v-if="elevatorMotor_Brake_MechanicalRelease.useTextKey==false">{{elevatorMotor_Brake_MechanicalRelease.textUI}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div style="margin-top:10px; margin-bottom:10px">
                        <div class="my_fontSize_1Point2 ZAfont_family">
                            <span>{{$t('208')}}</span>
                            <span v-if="elevatorMotor_Brake_ReleaseMonitoring_Selection.length==1">
                                : <span class="my_font-weight_600" v-if="selected_elevatorMotor_Brake_ReleaseMonitoring!=null">
                                    <span v-if="selected_elevatorMotor_Brake_ReleaseMonitoring.useTextKey==false"> {{selected_elevatorMotor_Brake_ReleaseMonitoring.textUI}}</span>
                                    <span v-if="selected_elevatorMotor_Brake_ReleaseMonitoring.useTextKey==true"> {{$t(selected_elevatorMotor_Brake_ReleaseMonitoring.textKey)}}</span>
                                </span>
                            </span>
                        </div>
                        <div v-if="elevatorMotor_Brake_ReleaseMonitoring_Selection.length>1">
                            <ul class="my_elevatorMotor_Brake_ReleaseMonitoring_Selection my_fontSize_1Point1 my_fontWeight_600">
                                <li style="cursor: pointer" v-for="elevatorMotor_Brake_ReleaseMonitoring in elevatorMotor_Brake_ReleaseMonitoring_Selection" @click="change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection(elevatorMotor_Brake_ReleaseMonitoring)" v-bind:class="{active: elevatorMotor_Brake_ReleaseMonitoring.selected, disabled: elevatorMotor_Brake_ReleaseMonitoring.disabled}">
                                    <span v-if="elevatorMotor_Brake_ReleaseMonitoring.useTextKey==true">{{$t(elevatorMotor_Brake_ReleaseMonitoring.textKey)}}</span>
                                    <span v-if="elevatorMotor_Brake_ReleaseMonitoring.useTextKey==false">{{elevatorMotor_Brake_ReleaseMonitoring.textUI}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div style="margin-bottom:10px" v-if="elevatorMotor_Brake_Connection_Selection.length>0">
                        <div class="my_fontSize_1Point2 ZAfont_family">
                            <span>{{$t('3441')}}</span>
                            <span v-if="elevatorMotor_Brake_Connection_Selection.length==1">: <span class="my_font-weight_600" v-if="selected_elevatorMotor_Brake_Connection!=null && selected_elevatorMotor_Brake_Connection.useTextKey == true"> {{$t(selected_elevatorMotor_Brake_Connection.textKey)}}</span><span class="my_font-weight_600" v-if="selected_elevatorMotor_Brake_Connection.useTextKey == false"> {{selected_elevatorMotor_Brake_Connection.textUI}}</span></span>
                        </div>
                        <div v-if="elevatorMotor_Brake_Connection_Selection.length>1">
                            <ul class="my_elevatorMotor_Brake_Connection_Selection my_fontSize_1Point1">
                                <li style="cursor: pointer" v-for="elevatorMotor_Brake_Connection in elevatorMotor_Brake_Connection_Selection" @click="change_Selected_at_elevatorMotor_Brake_Connection_Selection(elevatorMotor_Brake_Connection)" v-bind:class="{active: elevatorMotor_Brake_Connection.selected}"><span class="my_fontWeight_600" v-if="elevatorMotor_Brake_Connection.useTextKey == true">{{$t(elevatorMotor_Brake_Connection.textKey)}}</span><span class="my_fontWeight_600" v-if="elevatorMotor_Brake_Connection.useTextKey == false">{{elevatorMotor_Brake_Connection.textUI}}</span></li>
                            </ul>
                        </div>
                    </div>
                    <div style="margin-bottom:10px" v-if="elevatorMotor_Brake_DeliverBowdenwire_Selection.length>0">
                        <div class="my_fontSize_1Point2 ZAfont_family">
                            <span>{{$t('3442')}}</span>
                            <span v-if="elevatorMotor_Brake_DeliverBowdenwire_Selection.length==1">: <span class="my_font-weight_600" v-if="selected_elevatorMotor_Brake_DeliverBowdenwire!=null && selected_elevatorMotor_Brake_DeliverBowdenwire.deliver.useTextKey == true"> {{$t(selected_elevatorMotor_Brake_DeliverBowdenwire.deliver.textKey)}}</span><span class="my_font-weight_600" v-if="selected_elevatorMotor_Brake_DeliverBowdenwire.deliver.useTextKey == false"> {{selected_elevatorMotor_Brake_DeliverBowdenwire.deliver.textUI}}</span></span>
                        </div>
                        <div v-if="elevatorMotor_Brake_DeliverBowdenwire_Selection.length>1">
                            <ul class="my_elevatorMotor_Brake_Bowdenwire_Selection my_fontSize_1Point1">
                                <li style="cursor: pointer" v-for="elevatorMotor_Brake_DeliverBowdenwire in elevatorMotor_Brake_DeliverBowdenwire_Selection" @click="change_Selected_at_elevatorMotor_Brake_DeliverBowdenwire_Selection(elevatorMotor_Brake_DeliverBowdenwire)" v-bind:class="{active: elevatorMotor_Brake_DeliverBowdenwire.selected}"><span class="my_fontWeight_600" v-if="elevatorMotor_Brake_DeliverBowdenwire.deliver.useTextKey == true">{{$t(elevatorMotor_Brake_DeliverBowdenwire.deliver.textKey)}}</span><span class="my_fontWeight_600" v-if="elevatorMotor_Brake_DeliverBowdenwire.deliver.useTextKey == false">{{elevatorMotor_Brake_DeliverBowdenwire.deliver.textUI}}</span></li>
                            </ul>
                        </div>
                    </div>
                    <div style="margin-bottom:10px" v-if="elevatorMotor_Brake_LengthBowdenwire_Selection.length>0">
                        <div class="my_fontSize_1Point2 ZAfont_family">
                            <span>{{$t('3443')}}</span>
                            <span v-if="elevatorMotor_Brake_LengthBowdenwire_Selection.length==1">: <span class="my_font-weight_600"> {{selected_elevatorMotor_Brake_LengthBowdenwire.length}}</span></span>
                        </div>
                        <div v-if="elevatorMotor_Brake_LengthBowdenwire_Selection.length>1">
                            <ul class="my_elevatorMotor_Brake_Bowdenwire_Selection my_fontSize_1Point1">
                                <li style="cursor: pointer" v-for="elevatorMotor_Brake_LengthBowdenwire in elevatorMotor_Brake_LengthBowdenwire_Selection" @click="change_Selected_at_elevatorMotor_Brake_LengthBowdenwire_Selection(elevatorMotor_Brake_LengthBowdenwire)" v-bind:class="{active: elevatorMotor_Brake_LengthBowdenwire.selected}"><span class="my_fontWeight_600">{{elevatorMotor_Brake_LengthBowdenwire.length}}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="elevatorMotor_Encoder_Selection.length>0" style="align-items:center; margin-top:15px">
            <div>
                <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">
                    <span class="my_fontSize_1Point5 my_color_00338e my_fontWeight_600 ZAfont_family">{{$t('3075')}}</span>
                    <span v-if="elevatorMotor_Encoder_Selection.length==1">: <span> {{selected_elevatorMotor_Encoder.elevatorMotor_Encoder.typeDesignation}} </span></span>
                </div>
            </div>
            <div v-if="elevatorMotor_Encoder_Selection.length>1" style="grid-column:2 / span 1">
                <ul class="my_elevatorMotor_Encoder my_fontSize_1Point1 my_fontWeight_600">
                    <li style="cursor: pointer" v-for="elevatorMotor_Encoder in elevatorMotor_Encoder_Selection" @click="change_Selected_at_elevatorMotor_Encoder_Selection(elevatorMotor_Encoder)" v-bind:class="{active: elevatorMotor_Encoder.selected}">{{elevatorMotor_Encoder.elevatorMotor_Encoder.typeDesignation}}</li>
                </ul>
            </div>
        </div>
        <div class="my_btn_nextConfigurationStep_position">
            <button @click="furtherToManualHome()" class="btn my_btn_nextConfigurationStep" style="margin-right: 5px">{{$t('3366')}}</button>
            <button @click="furtherToInput()" class="btn my_btn_nextConfigurationStep" style="margin-right: 5px">{{$t('1018')}}</button>
            <button @click="furtherToCables()" class="btn my_btn_nextConfigurationStep">{{$t('1023')}}</button>
        </div>
    </div>
</template>


<script>
    import router from '../../../router';
    import LoaderIcon from '../../loader/LoaderIcon';
    import InvalidInputs from '../../configuration/InvalidInputs'
    import InvalidityReasons from '../../dataValidation/InvalidityReasons';
    import Images from '../../configuration/Images';
    import { driveTechnologiesManual } from '../../../store/driveTechnologiesManual.module';

    export default {
        name: 'DriveTechnologiesManual',

        components: {
            LoaderIcon,
            InvalidInputs,
            InvalidityReasons,
            Images,
        },

        created() {
            if (this.$store.state.inputsManual.configurationStepsManualId == null) {
                router.push({ params: { slug: 'inputsManual' } })
            }
            else {
                if (this.$store.state.configurations.MANUAL == false) {
                    this.$store.commit('configurations/MANU_configuration', true);
                    this.$store.commit('configurations/change_elevatorMotor_Manual_storeMutation', true);
                }

                if (this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data != null && this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.hasOwnProperty('elevatorMotor_Brake_Selection') && this.$store.state.driveTechnologiesManual.elevatorMotor_Encoder_Data.hasOwnProperty('elevatorMotor_Encoder_Selection')) {

                }
                else {
                    this.$store.dispatch('driveTechnologiesManual/check_toDos_at_driveTechnologiesManual_storeAction');
                }
            }
        },
        computed: {
            elevatorMotor_Machine_Manual: {
                get() {
                    if (this.$store.state.driveTechnologiesManual.elevatorMotor_Machine_Manual != null && this.$store.state.driveTechnologiesManual.elevatorMotor_Machine_Manual.hasOwnProperty('elevatorMotorMachineManual')) {
                        return this.$store.state.driveTechnologiesManual.elevatorMotor_Machine_Manual;
                    }
                    else {
                        return null;
                    }
                }
            },
            // elevatorMotor_Brake
            elevatorMotor_Brake_Selection: {
                get() {
                    if ((this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.hasOwnProperty('elevatorMotor_Brake_Selection')) && (Array.isArray(this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection))) {
                        return this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection;
                    }
                    else {
                        return [];
                    }
                },
            },
            elevatorMotor_Brake_Selected: {
                get() {
                    if ((this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.hasOwnProperty('elevatorMotor_Brake_Selection')) && (Array.isArray(this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection))) {
                        return this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.some(i => i.selected == true)
                    }
                },
            },
            selected_elevatorMotor_Brake: {
                get() {
                    return this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Selection.find(i => i.selected == true);
                }
            },
            elevatorMotor_Brake_Voltage_Selection: {
                get() {
                    return this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Voltage_Selection;
                }
            },
            selected_elevatorMotor_Brake_Voltage: {
                get() {
                    return this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Voltage_Selection.find(i => i.selected == true);
                },
            },
            elevatorMotor_Brake_MechanicalRelease_Selection: {
                get() {
                    return this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_MechanicalRelease_Selection;
                }
            },
            selected_elevatorMotor_Brake_MechanicalRelease: {
                get() {
                    return this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_MechanicalRelease_Selection.find(i => i.selected == true);
                },
            },
            elevatorMotor_Brake_ReleaseMonitoring_Selection: {
                get() {
                    return this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_ReleaseMonitoring_Selection;
                }
            },
            selected_elevatorMotor_Brake_ReleaseMonitoring: {
                get() {
                    return this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_ReleaseMonitoring_Selection.find(i => i.selected == true);
                },
            },
            elevatorMotor_Brake_Connection_Selection: {
                get() {
                    if (this.selected_elevatorMotor_Brake != null && this.selected_elevatorMotor_Brake.elevatorMotor_Brake != null) {
                        return this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Connection_Selection.filter(i => i.brakeVoltage == this.selected_elevatorMotor_Brake.elevatorMotor_Brake.switchOnVoltage && i.releaseMonitoring == this.selected_elevatorMotor_Brake.elevatorMotor_Brake.releaseMonitoring);
                    }
                    else {
                        return null;
                    }
                },
            },
            selected_elevatorMotor_Brake_Connection: {
                get() {
                    return this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Connection_Selection.find(i => i.selected == true);
                },
            },
            elevatorMotor_Brake_DeliverBowdenwire_Selection: {
                get() {
                    const firstIndex_whichMeetConditions = this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_MechanicalRelease_Selection.findIndex(i => i.selected == true);
                    if (firstIndex_whichMeetConditions > -1) {
                        const _deliver_Valuelist = this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.filter(i => i.mechanicalRelease == this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_MechanicalRelease_Selection[firstIndex_whichMeetConditions].text);
                        return _deliver_Valuelist;
                    }
                    else {
                        return [];
                    }
                },
            },
            selected_elevatorMotor_Brake_DeliverBowdenwire: {
                get() {
                    const firstIndex_whichMeetConditions = this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.find(i => i.selected == true);
                    if (firstIndex_whichMeetConditions) {
                        return this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.find(i => i.selected == true);
                    }
                    else {
                        return null;
                    }
                },
            },
            elevatorMotor_Brake_LengthBowdenwire_Selection: {
                get() {
                    const firstIndex_whichMeetConditions_DeliverBowdenwire = this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection.findIndex(i => i.selected == true);
                    if (firstIndex_whichMeetConditions_DeliverBowdenwire > -1) {
                        const _length_Valuelist = this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection.filter(i => i.length != null && i.deliver.text == this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection[firstIndex_whichMeetConditions_DeliverBowdenwire].deliver.text && i.mechanicalRelease == this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_DeliverBowdenwire_Selection[firstIndex_whichMeetConditions_DeliverBowdenwire].mechanicalRelease);
                        return _length_Valuelist;
                    }
                    else {
                        return [];
                    }
                },
            },
            selected_elevatorMotor_Brake_LengthBowdenwire: {
                get() {
                    return this.$store.state.driveTechnologiesManual.elevatorMotor_Brake_Manual_Data.elevatorMotor_Brake_Bowdenwire_Selection.find(i => i.selected == true);
                },
            },
            // elevatorMotor_Encoder
            elevatorMotor_Encoder_Selection: {
                get() {
                    if ((this.$store.state.driveTechnologiesManual.elevatorMotor_Encoder_Data.hasOwnProperty('elevatorMotor_Encoder_Selection')) && (Array.isArray(this.$store.state.driveTechnologiesManual.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection))) {
                        return this.$store.state.driveTechnologiesManual.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection;
                    }
                    else {
                        return [];
                    }

                }
            },
            elevatorMotor_Encoder_Selected: {
                get() {
                    if ((this.$store.state.driveTechnologiesManual.elevatorMotor_Encoder_Data.hasOwnProperty('elevatorMotor_Encoder_Selection')) && (Array.isArray(this.$store.state.driveTechnologiesManual.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection))) {
                        return this.$store.state.driveTechnologiesManual.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection.some(i => i.selected == true)
                    }
                },
            },
            selected_elevatorMotor_Encoder: {
                get() {
                    return this.$store.state.driveTechnologiesManual.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection.find(i => i.selected == true)
                }
            },
            elevatorMotor_Encoder_Selection_Shown: {
                get() {
                    return this.$store.state.driveTechnologiesManual.elevatorMotor_Encoder_Selection_Shown;
                },
                set(value) {
                    this.$store.dispatch('driveTechnologiesManual/change_elevatorMotor_Encoder_Selection_Shown_storeAction', value)
                }
            },

        },
        methods: {
            // elevatorMotor_Brake
            // elevatorMotor_Brake_Voltage
            change_Selected_at_elevatorMotor_Brake_Voltage_Selection(elevatorMotor_Brake_Voltage) {
                if (elevatorMotor_Brake_Voltage.nominalVoltage != this.selected_elevatorMotor_Brake.elevatorMotor_Brake.nominalVoltage) {
                    this.$store.dispatch('driveTechnologiesManual/checkIf_changesAt_configurationFlow_storeAction');
                    this.$store.dispatch('driveTechnologiesManual/change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_Voltage_storeAction', elevatorMotor_Brake_Voltage.nominalVoltage);
                }
            },
            // elevatorMotor_Brake_MechanicalRelease
            change_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection(elevatorMotor_Brake_MechanicalRelease) {
                if (elevatorMotor_Brake_MechanicalRelease.text != this.selected_elevatorMotor_Brake.elevatorMotor_Brake.mechanicalRelease.text) {
                    this.$store.dispatch('driveTechnologiesManual/checkIf_changesAt_configurationFlow_storeAction');
                    this.$store.dispatch('driveTechnologiesManual/change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_MechanicalRelease_storeAction', elevatorMotor_Brake_MechanicalRelease.text);
                }
            },
            // elevatorMotor_Brake_ReleaseMonitoring
            change_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection(elevatorMotor_Brake_ReleaseMonitoring) {
                if (elevatorMotor_Brake_ReleaseMonitoring.text != this.selected_elevatorMotor_Brake.elevatorMotor_Brake.releaseMonitoring.text) {
                    this.$store.dispatch('driveTechnologiesManual/checkIf_changesAt_configurationFlow_storeAction');
                    this.$store.dispatch('driveTechnologiesManual/change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_ReleaseMonitoring_storeAction', elevatorMotor_Brake_ReleaseMonitoring.text);
                }
            },
            // elevatorMotor_Brake_Connection
            change_Selected_at_elevatorMotor_Brake_Connection_Selection(elevatorMotor_Brake_Connection) {
                if (this.selected_elevatorMotor_Brake.elevatorMotor_Brake.hasOwnProperty('brakeConnectionTypeDesignation') == false || elevatorMotor_Brake_Connection.text != this.selected_elevatorMotor_Brake.elevatorMotor_Brake.brakeConnectionTypeDesignation) {
                    this.$store.dispatch('driveTechnologiesManual/checkIf_changesAt_configurationFlow_storeAction');
                    this.$store.dispatch('driveTechnologiesManual/change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_Connection_storeAction', elevatorMotor_Brake_Connection);
                }
            },
            // elevatorMotor_Brake_Bowdenwire
            change_Selected_at_elevatorMotor_Brake_DeliverBowdenwire_Selection(elevatorMotor_Brake_DeliverBowdenwire) {
                if (this.selected_elevatorMotor_Brake.elevatorMotor_Brake.hasOwnProperty('bowdenwireDeliver') == false || this.selected_elevatorMotor_Brake.elevatorMotor_Brake.bowdenwireDeliver == null || elevatorMotor_Brake_DeliverBowdenwire.text != this.selected_elevatorMotor_Brake.elevatorMotor_Brake.bowdenwireDeliver) {
                    this.$store.dispatch('driveTechnologiesManual/checkIf_changesAt_configurationFlow_storeAction');
                    this.$store.dispatch('driveTechnologiesManual/change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_DeliverBowdenwire_storeAction', elevatorMotor_Brake_DeliverBowdenwire);
                }
            },
            change_Selected_at_elevatorMotor_Brake_LengthBowdenwire_Selection(elevatorMotor_Brake_LengthBowdenwire) {
                if (this.selected_elevatorMotor_Brake.elevatorMotor_Brake.hasOwnProperty('bowdenwireLength') == false || this.selected_elevatorMotor_Brake.elevatorMotor_Brake.bowdenwireLength == null || elevatorMotor_Brake_LengthBowdenwire != this.selected_elevatorMotor_Brake.elevatorMotor_Brake.bowdenwireLength) {
                    this.$store.dispatch('driveTechnologiesManual/checkIf_changesAt_configurationFlow_storeAction');
                    this.$store.dispatch('driveTechnologiesManual/change_Selected_at_elevatorMotor_Brake_Selection_basedOnChangesTo_elevatorMotor_Brake_Bowdenwire_storeAction', elevatorMotor_Brake_LengthBowdenwire);
                }
            },

            // elevatorMotor_Encoder
            change_Selected_at_elevatorMotor_Encoder_Selection(elevatorMotor_Encoder) {
                if (elevatorMotor_Encoder.elevatorMotor_Encoder.typeDesignation != this.selected_elevatorMotor_Encoder.elevatorMotor_Encoder.typeDesignation) {
                    const firstIndex_whichMeetCondition = this.elevatorMotor_Encoder_Selection.findIndex(i => i.elevatorMotor_Encoder.typeDesignation == elevatorMotor_Encoder.elevatorMotor_Encoder.typeDesignation);
                    if (firstIndex_whichMeetCondition > -1) {
                        this.$store.dispatch('driveTechnologiesManual/checkIf_changesAt_configurationFlow_storeAction');
                        this.$store.dispatch('driveTechnologiesManual/checkIf_changesAt_elevatorMotor_Encoder_changes_configurationFlow_storeAction', firstIndex_whichMeetCondition);
                    }
                    else {
                        // error
                    }
                }
            },
            furtherToCables() {
                this.$store.dispatch('driveTechnologiesManual/checkIf_driveTechnologiesManual_areValid_storeAction');
            },
            furtherToInput() {
                router.push({ params: { slug: 'inputsManual' } })
            },
            furtherToManualHome() {
                this.$store.dispatch('inputsManual/cancelManualMotorConfiguration_storeAction')
            },

        }
    }
</script>


<style>
    .my_elevatorMotor_TemperatureMonitoring {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_elevatorMotor_TemperatureMonitoring li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            text-align: center;
            margin-bottom: 10px;
        }

            .my_elevatorMotor_TemperatureMonitoring li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_elevatorMotor_TemperatureMonitoring li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_elevatorMotor_TemperatureMonitoring .active {
            border: 2px solid blue;
        }

    .my_elevatorMotor_Brake_Voltage_Selection {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_elevatorMotor_Brake_Voltage_Selection li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            text-align: center;
            margin-bottom: 10px;
        }

            .my_elevatorMotor_Brake_Voltage_Selection li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_elevatorMotor_Brake_Voltage_Selection li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_elevatorMotor_Brake_Voltage_Selection .active {
            border: 2px solid blue;
        }

    .my_elevatorMotor_Brake_MechanicalRelease_Selection {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_elevatorMotor_Brake_MechanicalRelease_Selection li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            text-align: center;
            margin-bottom: 10px;
        }

            .my_elevatorMotor_Brake_MechanicalRelease_Selection li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_elevatorMotor_Brake_MechanicalRelease_Selection li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_elevatorMotor_Brake_MechanicalRelease_Selection .active {
            border: 2px solid blue;
        }

        .my_elevatorMotor_Brake_MechanicalRelease_Selection .disabled {
            background-color: #ced2d8;
            pointer-events: none;
        }

    .my_elevatorMotor_Brake_ReleaseMonitoring_Selection {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_elevatorMotor_Brake_ReleaseMonitoring_Selection li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            text-align: center;
            margin-bottom: 10px;
        }

            .my_elevatorMotor_Brake_ReleaseMonitoring_Selection li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_elevatorMotor_Brake_ReleaseMonitoring_Selection li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_elevatorMotor_Brake_ReleaseMonitoring_Selection .active {
            border: 2px solid blue;
        }

        .my_elevatorMotor_Brake_ReleaseMonitoring_Selection .disabled {
            background-color: #ced2d8;
            pointer-events: none;
        }

    .my_elevatorMotor_Brake_Connection_Selection {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_elevatorMotor_Brake_Connection_Selection li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            text-align: center;
            margin-bottom: 10px;
        }

            .my_elevatorMotor_Brake_Connection_Selection li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_elevatorMotor_Brake_Connection_Selection li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_elevatorMotor_Brake_Connection_Selection .active {
            border: 2px solid blue;
        }

    .my_elevatorMotor_Brake_Bowdenwire_Selection {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_elevatorMotor_Brake_Bowdenwire_Selection li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            text-align: center;
            margin-bottom: 10px;
        }

            .my_elevatorMotor_Brake_Bowdenwire_Selection li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_elevatorMotor_Brake_Bowdenwire_Selection li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_elevatorMotor_Brake_Bowdenwire_Selection .active {
            border: 2px solid blue;
        }

    .my_elevatorMotor_Encoder {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_elevatorMotor_Encoder li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            text-align: center;
            margin-bottom: 10px;
        }

            .my_elevatorMotor_Encoder li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_elevatorMotor_Encoder li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_elevatorMotor_Encoder .active {
            border: 2px solid blue;
        }

    .my_elevatorMotor_Machine_filter {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_elevatorMotor_Machine_filter li {
            border: 2px solid #00338e;
            color: #00338e;
            display: inline-block;
            padding: 9px;
            width: 50%;
            text-align: center;
            font-weight: 600;
        }

        .my_elevatorMotor_Machine_filter .active {
            background-color: #00338e;
            color: #ffffff;
        }


    #my_engine_table_images_ZAproducts {
        height: 70px !important;
    }

    #my_engine_variables {
        margin-top: 15px;
    }

    #my_engine_table_parameters_slash {
        font-size: 1.0rem;
    }

    .my_table_parameters_infoText {
        font-size: 0.9rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        width: calc(100%);
    }

        .my_table_parameters_infoText:hover {
            text-overflow: clip;
            white-space: normal;
            word-break: break-word;
        }
</style>