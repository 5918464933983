<template>
    <!--<button @click="newConfiguration()" class="btn my_btn_standard" style="width:100%">{{$t('3249')}}</button>-->
    <div class="ZAfont_family" style="border: 2px solid #ced2d8; padding: 15px; margin-top:15px">
        <div style="text-align:right; margin-bottom:10px">
            <div class="my_color_00338e">
                <span style="font-size: 1.2em; font-weight:500">{{$t('3332')}}:</span>
                <span style="font-size: 1.8em; font-weight:900"> {{zlmNumber}}</span>
                <input v-model="zlm_Number" class="form-control" />
                <button @click="search_zlmNumber()" class="btn my_btn_standard_blue" style="width:100%;margin-top:10px"> {{$t('1186')}}</button>
            </div>
        </div>
        <hr />
        <div v-if="showPriceButton==true">
            <div style="margin-top:10px">
                <input type="checkbox" v-model="accept_terms_pricing" id="accept_terms_pricing" /> <label style="font-weight:600; font-size:0.9rem; margin-bottom:10px" class="ZAfont_family">{{$t('3468')}}</label>
            </div>
            <button v-if="showPriceButton==true" :disabled="!accept_terms_pricing" @click="change_showInfo()" class="btn my_btn_standard_blue" style="width:100%;margin-top:10px">{{$t('3467')}}</button>
            <hr />
        </div>
        <div>
            <input type="checkbox" v-model="accept_terms_of_request" id="accept_terms_of_request" /> <label style="font-weight:600; font-size:0.9rem; margin-bottom:10px" class="ZAfont_family" for="accept_terms_of_request">{{$t('3370')}}<a v-if="$t('3369') =='de'" v-b-modal="'modal-accept_terms_of_request'" href="/pdf/de/Nutzungsbedingungen-DE.pdf" target="_blank">Anfragebedingungen</a><a v-else v-b-modal="'modal-accept_terms_of_request'" href="/pdf/en/Nutzungsbedingungen-EN.pdf" target="_blank"><!--href="javascript:void(0)"-->Terms of Request</a> </label>
        </div>
        <div v-if="accept_request_a_quote==true">
            <!--<button v-if="sapProfile==false" :disabled="!accept_terms_of_request" @click="request_offer_or_post_order('A')" class="btn my_btn_standard_blue" style="width:100%;margin-top:10px"> {{$t('3201')}} <i class="fas fa-chevron-right"></i></button>-->
            <button v-if="sapProfile==true" :disabled="!accept_terms_of_request" @click="request_offer_or_post_order_sap('A')" class="btn my_btn_standard_blue" style="width:100%;margin-top:10px"> {{$t('3372')}} <i class="fas fa-chevron-right"></i></button>
            <!--Test Dimmler Angebot-->
            <button v-if="sapProfile==true" :disabled="!accept_terms_of_request" @click="request_Quotation_or_post_Quotation_sap('A')" class="btn my_btn_standard_blue" style="width:100%;margin-top:10px"> {{$t('3371')}} <i class="fas fa-chevron-right"></i></button>
        </div>
    </div>
</template>

<script>
    import router from '../../../router';
    import axios from 'axios';
    import Images from '../../configuration/Images';
    import InvalidityReasons from '../../dataValidation/InvalidityReasons';
    export default {
        name: 'SummaryInformationManual',
        components: {
            Images,
            InvalidityReasons,
        },
        props: {
            additional: false,
        },
        data() {
            return {
                accept_terms_of_request: false,
                accept_terms_pricing: false,
                showModal: false,
                scrollYPosition: 0,
                zlm_Number: null,
            }
        },
        computed: {
            sapProfile: {
                get() {
                    return this.$store.state.configurations.sap;
                }
            },
            showPriceButton: {
                get() {
                    return false;
                },
            },
            accept_request_a_quote: {
                get() {
                    return true;
                },
            },
            zlmNumber: {
                get() {
                    if (this.$store.state.manualHome.ZLM != undefined && this.$store.state.manualHome.ZLM != null && this.$store.state.manualHome.ZLM != '') {
                        const zlm = "ZLM-" + this.$store.state.manualHome.ZLM;
                        this.zlm_Number = zlm;
                        return zlm;
                    }
                    else {
                        this.zlm_Number = null;
                        return null;
                    }
                },
                set(value) {
                    this.zlm_Number = value
                }
            },
        },

        methods: {
            change_scrollYPosision() {
                this.scrollYPosition = window.scrollY;
            },
            search_zlmNumber() {
                this.$store.dispatch('manualHome/check_ZLM_Exist_storeAction', this.zlm_Number);
            },
            change_showInfo() {
            },
            request_offer_or_post_order(type) {
            },
            request_offer_or_post_order_sap(type) {
            },
            request_Quotation_or_post_Quotation_sap(type) {
            },


        }
    }</script>

<style>
    .modal-mask {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: scroll;
        /*        height: 120%;*/
    }

    .modal-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 650px;
    }

    .modal-container {
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        max-width: 500px;
        width: 100%;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

        .modal-header h3 {
            margin: 0;
        }

    .modal-body {
        margin-bottom: 20px;
    }

    .modal-footer {
        display: flex;
        justify-content: flex-end;
    }
</style>
