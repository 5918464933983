<template>
    <div style="overflow-x:auto !important">

        <div v-if="Baug_Select != undefined">
            <span class="my_color_00338e my_font-weight_600">{{$t('3503')}}</span>
            <span> {{Baug_Select}}</span>
        </div>

        <input v-if="Baug_Select != undefined" type="checkbox" v-model="FI_fuer_suche_verwenden" style="margin-right: 10px"/>
        <label v-if="Baug_Select != undefined">{{$t(3504)}}</label>

        <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('3500')}}</div>

        <div style="display:grid; grid-template-columns:2fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">
            <div style="padding-right:25px;border-right: 1px solid rgba(0, 0, 21, 0.2)">
                <table style=" margin-top:10px; width:100%; vertical-align: middle !important;" cellpadding="10">
                    <tr>
                        <td style=" width:calc(100%/2)">
                            <div class="ZAfont_family ZAfont_size">
                                {{$t('407')}}
                            </div>
                        </td>
                        <td style=" width: calc(100%);">
                            <select v-if="EVAC_Typ_list!=null&&(EVAC_Typ_list.length==0 || EVAC_Typ_list.length>=5)" v-model="EVAC_Typ_select_Combo" class="my_select" style="width: 100%">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option v-for="itemOf_EVAC_Typ in EVAC_Typ_list" v-bind:value ="itemOf_EVAC_Typ" for ="itemOf_EVAC_Typ">{{itemOf_EVAC_Typ.text.textUI}}</option>
                            </select>

                            <div class="my_inputCheckbox" v-if="EVAC_Typ_list!=null&&EVAC_Typ_list.length<5&&EVAC_Typ_list.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                <div class=" my_inputCheckbox_line" v-for="itemOf_EVAC_Typ in EVAC_Typ_list">
                                    <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                    <input type="checkbox" v-bind:checked="itemOf_EVAC_Typ.selected" @input="EVAC_Typ_select(itemOf_EVAC_Typ)" />
                                    <label v-if="itemOf_EVAC_Typ.text.useTextKey==false" v-bind:value="itemOf_EVAC_Typ" for="itemOf_EVAC_Typ" style="margin-left: 10px">{{itemOf_EVAC_Typ.text.textUI}}</label>
                                    <label v-if="itemOf_EVAC_Typ.text.useTextKey==true" v-bind:value="itemOf_EVAC_Typ" for="itemOf_EVAC_Typ" style="margin-left: 10px">{{$t(itemOf_EVAC_Typ.text.textKey)}}</label>
                                </div>
                            </div>

                        </td>
                    </tr>
                    <tr>
                        <td style=" width:calc(100%/2)">
                            <div class="ZAfont_family ZAfont_size">
                                {{$t('3505')}}
                            </div>
                        </td>
                        <td style=" width:calc(100%);">
                            <select v-if="EVAC_Batt_list!=null&&(EVAC_Batt_list.length==0|| EVAC_Batt_list.length>=5)" v-model="EVAC_Batt_select_Combo" class="my_select" style="width:100%">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option v-for="itemOf_EVAC_Batt in EVAC_Batt_list" v-bind:value="itemOf_EVAC_Batt">{{itemOf_EVAC_Batt.text.textUI}}</option>
                            </select>

                            <div class="my_inputCheckbox" v-if="EVAC_Batt_list!=null&&EVAC_Batt_list.length<5&&EVAC_Batt_list.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                <div class=" my_inputCheckbox_line" v-for="itemOf_EVAC_Batt in EVAC_Batt_list">
                                    <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                    <input type="checkbox" v-bind:checked="itemOf_EVAC_Batt.selected" @input="EVAC_Batt_select(itemOf_EVAC_Batt)" />
                                    <label v-if="itemOf_EVAC_Batt.text.useTextKey==false" v-bind:value="itemOf_EVAC_Batt" for="itemOf_EVAC_Batt" style="margin-left: 10px">{{itemOf_EVAC_Batt.text.textUI}}</label>
                                    <label v-if="itemOf_EVAC_Batt.text.useTextKey==true" v-bind:value="itemOf_EVAC_Batt" for="itemOf_EVAC_Batt" style="margin-left: 10px">{{$t(itemOf_EVAC_Batt.text.textKey)}}</label>
                                </div>
                            </div>



                        </td>
                    </tr>
                    <tr>
                        <td style=" width:calc(100%/2)">
                            <div class="ZAfont_family ZAfont_size">
                                {{$t('3506')}}
                            </div>
                        </td>
                        <td style=" width: calc(100%);">
                            <select v-if="EVAC_Cable_list!=null&&(EVAC_Cable_list.length==0 || EVAC_Cable_list.length>=5)" v-model="EVAC_Cable_select_Combo" class="my_select" style="width:100%">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option v-for="itemOf_EVAC_Cable in EVAC_Cable_list" v-bind:value="itemOf_EVAC_Cable">{{itemOf_EVAC_Cable.text.textUI}}</option>
                            </select>

                            <div class="my_inputCheckbox" v-if="EVAC_Cable_list!=null&&EVAC_Cable_list.length<5&&EVAC_Cable_list.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                <div class=" my_inputCheckbox_line" v-for="itemOf_EVAC_Cable in EVAC_Cable_list">
                                    
                                    <input type="checkbox" v-bind:checked="itemOf_EVAC_Cable.selected" @input="EVAC_Cable_select(itemOf_EVAC_Cable)" />
                                    <label v-if="itemOf_EVAC_Cable.text.useTextKey==false" v-bind:value="itemOf_EVAC_Cable" for="itemOf_EVAC_Cable" style="margin-left: 10px">{{itemOf_EVAC_Cable.text.textUI}}</label>
                                    <label v-if="itemOf_EVAC_Cable.text.useTextKey==true" v-bind:value="itemOf_EVAC_Cable" for="itemOf_EVAC_Cable" style="margin-left: 10px">{{$t(itemOf_EVAC_Cable.text.textKey)}}</label>
                                </div>
                            </div>

                        </td>
                    </tr>
                    <tr>
                        <td style=" width:calc(100%/2)">
                            <div class="ZAfont_family ZAfont_size">
                                {{$t('3507')}}
                            </div>
                        </td>
                        <td style=" width: calc(100%);">
                            <select v-if="EVAC_Control_Cable_list!=null&&(EVAC_Control_Cable_list.length==0 || EVAC_Control_Cable_list.length>=5)" v-model="EVAC_Control_Cable_select_Combo" class="my_select" style="width:100%">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option v-for="itemOf_EVAC_Control_Cable in EVAC_Control_Cable_list" v-bind:value="itemOf_EVAC_Control_Cable">{{itemOf_EVAC_Control_Cable.text.textUI}}</option>
                            </select>

                            <div class="my_inputCheckbox" v-if="EVAC_Control_Cable_list!=null&&EVAC_Control_Cable_list.length<5&&EVAC_Control_Cable_list.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                <div class="my_inputCheckbox_line" v-for="itemOf_EVAC_Control_Cable in EVAC_Control_Cable_list">

                                    <input type="checkbox" v-bind:checked="itemOf_EVAC_Control_Cable.selected" @input="EVAC_Control_Cable_select(itemOf_EVAC_Control_Cable)" />
                                    <label v-if="itemOf_EVAC_Control_Cable.text.useTextKey==false" v-bind:value="itemOf_EVAC_Control_Cable" for="itemOf_EVAC_Control_Cable" style="margin-left: 10px">{{itemOf_EVAC_Control_Cable.text.textUI}}</label>
                                    <label v-if="itemOf_EVAC_Control_Cable.text.useTextKey==true" v-bind:value="itemOf_EVAC_Control_Cable" for="itemOf_EVAC_Control_Cable" style="margin-left: 10px">{{$t(itemOf_EVAC_Control_Cable.text.textKey)}}</label>
                                </div>
                            </div>

                        </td>
                    </tr>
                    <tr>
                        <td style=" width:calc(100%/2)">
                            <div class="ZAfont_family ZAfont_size">
                                {{$t('3391')}}
                            </div>
                        </td>
                        <td style=" width: calc(100%);">
                            <select v-if="EVAC_Bal_list!=null&&(EVAC_Bal_list.length==0 || EVAC_Bal_list.length>=5)" v-model="EVAC_Bal_select_Combo" class="my_select" style="width:100%">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option v-for="itemOf_EVAC_Bal in EVAC_Bal_list" v-bind:value="itemOf_EVAC_Bal">{{itemOf_EVAC_Bal.text.textUI}}</option>
                            </select>

                            <div class="my_inputCheckbox" v-if="EVAC_Bal_list!=null&&EVAC_Bal_list.length<5&&EVAC_Bal_list.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                <div class="my_inputCheckbox_line" v-for="itemOf_EVAC_Bal in EVAC_Bal_list">

                                    <input type="checkbox" v-bind:checked="itemOf_EVAC_Bal.selected" @input="EVAC_Bal_select(itemOf_EVAC_Bal)" />
                                    <label v-if="itemOf_EVAC_Bal.text.useTextKey==false" v-bind:value="itemOf_EVAC_Bal" for="itemOf_EVAC_Bal" style="margin-left: 10px">{{itemOf_EVAC_Bal.text.textUI}}</label>
                                    <label v-if="itemOf_EVAC_Bal.text.useTextKey==true" v-bind:value="itemOf_EVAC_Bal" for="itemOf_EVAC_Bal" style="margin-left: 10px">{{$t(itemOf_EVAC_Bal.text.textKey)}}</label>
                                </div>
                            </div>

                        </td>
                    </tr>
                </table>

            </div>
            <div>
                <img src="../../../../public/img/ProductImages/EVAC_3/EVAC3C-front-web.jpg" style="width:calc(100%/3)" />

            </div>

        </div>




        <div>
            <button @click="RouteToManualHomeCancel" class="btn my_btn_standard_blue" style="margin-top:40px; margin-right:5px">{{$t('3237')}}</button>
            <button @click="RouteToManualHomeOK" class="btn my_btn_standard_blue" style="margin-top:40px">{{$t('383')}}</button>
        </div>
        <hr />

    </div>
</template>


<script>
    import router from '../../../router';
    import InvalidityReasons from '../../dataValidation/InvalidityReasons';
    import LoaderIcon from '../../loader/LoaderIcon';

    export default {
        name: 'EVAC',

        components: {
            InvalidityReasons,
            LoaderIcon,
        },

        data() {
            return {


            }
        },

        computed: {
            //sapProfile: {
            //    get() {
            //        console.log('test', this.$store.state.configurations.sap)
            //        return this.$store.state.configurations.sap;
            //    },
            //},
            EVAC_Typ_list: {
                get() {
                    return this.$store.state.EVAC.EVAC_Typ_list;
                },
            },
            EVAC_Batt_list: {
                get() {
                    return this.$store.state.EVAC.EVAC_Batt_list;
                }
            },
            EVAC_Cable_list: {
                get() {
                    return this.$store.state.EVAC.EVAC_Cable_list;
                }
            },
            EVAC_Control_Cable_list: {
                get() {
                    return this.$store.state.EVAC.EVAC_Control_Cable_list;
                }
            },
            EVAC_Bal_list: {
                get() {
                    return this.$store.state.EVAC.EVAC_Bal_list;
                }
            },

            EVAC_Typ_select_Combo: {
                get() {
                    if (this.$store.state.EVAC.EVAC_Typ_list != null && Array.isArray(this.$store.state.EVAC.EVAC_Typ_list)) {
                        const firstIndex_whichMeetConditions = this.$store.state.EVAC.EVAC_Typ_list.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.EVAC.EVAC_Typ_list[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    console.log(value)
                    this.EVAC_Typ_select(value);
                },
            },
            EVAC_Batt_select_Combo: {
                get() {
                    if (this.$store.state.EVAC.EVAC_Batt_list != null && Array.isArray(this.$store.state.EVAC.EVAC_Batt_list)) {
                        const firstIndex_whichMeetConditions = this.$store.state.EVAC.EVAC_Batt_list.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.EVAC.EVAC_Batt_list[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.EVAC_Batt_select(value);
                },
            },
            EVAC_Cable_select_Combo: {
                get() {
                    //return this.$store.state.EVAC.EVAC_Cable_select;
                    if (this.$store.state.EVAC.EVAC_Cable_list != null && Array.isArray(this.$store.state.EVAC.EVAC_Cable_list)) {
                        const firstIndex_whichMeetConditions = this.$store.state.EVAC.EVAC_Cable_list.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.EVAC.EVAC_Cable_list[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    //this.$store.commit('EVAC/change_EVAC_Cable_selection_storeMutation', value);
                    this.EVAC_Cable_select(value);
                },
            },
            EVAC_Control_Cable_select_Combo: {
                get() {
                    //return this.$store.state.EVAC.EVAC_Control_Cable_select;
                    if (this.$store.state.EVAC.EVAC_Control_Cable_list != null && Array.isArray(this.$store.state.EVAC.EVAC_Control_Cable_list)) {
                        const firstIndex_whichMeetConditions = this.$store.state.EVAC.EVAC_Control_Cable_list.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.EVAC.EVAC_Control_Cable_list[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    //this.$store.commit('EVAC/change_EVAC_Control_Cable_selection_storeMutation', value);
                    this.EVAC_Control_Cable_select(value);
                },
            },
            EVAC_Bal_select_Combo: {
                get() {
                    //return this.$store.state.EVAC.EVAC_Bal_select;
                    if (this.$store.state.EVAC.EVAC_Bal_list != null && Array.isArray(this.$store.state.EVAC.EVAC_Bal_list)) {
                        const firstIndex_whichMeetConditions = this.$store.state.EVAC.EVAC_Bal_list.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.EVAC.EVAC_Bal_list[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    //this.$store.commit('EVAC/change_EVAC_Bal_selection_storeMutation', value);
                    this.EVAC_Bal_select(value);
                },
            },
            Baug_Select: {
                get() {
                    return this.$store.state.FI.FI_Model_selected;
                },
            },
            FI_fuer_suche_verwenden: {
                get() {
                    return this.$store.state.EVAC.FI_fuer_suche_verwenden;
                },
                set(value) {
                    this.$store.dispatch('EVAC/change_FI_fuer_suche_verwenden_selection_storeAction', value);
                },
            },

        },



        created() {
            if (this.configurationOverviewsId != null) {
                this.$store.dispatch('configurations/check_toDos_at_inputs_storeAction')
            }
            else {
                this.$store.dispatch('configurations/toDos_after_routeSlug_UpdatedOrEntered_storeAction', 'inputs')
            }
        },

        methods: {
            EVAC_Typ_select(value) {
                
                this.$store.dispatch('EVAC/change_EVAC_Typ_selection_storeAction', value);
               
            },
            EVAC_Batt_select(value) {
                
                this.$store.dispatch('EVAC/change_EVAC_Batt_selection_storeAction', value);

            },
            EVAC_Cable_select(value) {

                this.$store.dispatch('EVAC/change_EVAC_Cable_selection_storeAction', value);

            },
            EVAC_Control_Cable_select(value) {

                this.$store.dispatch('EVAC/change_EVAC_Control_Cable_selection_storeAction', value);

            },
            EVAC_Bal_select(value) {

                this.$store.dispatch('EVAC/change_EVAC_Bal_selection_storeAction', value);

            },
            RouteToManualHomeOK() {
                this.$store.dispatch('EVAC/insert_data');
                router.push('../manualStep/ManualHome');
                
            },
            RouteToManualHomeCancel() {
                console.log('Test Default');
                this.$store.dispatch('EVAC/change_inputs_EVAC_toDefault_storeAction');
                router.push('../manualStep/ManualHome');
            }
        },

        }
</script>


<style>
    .my_inputCheckbox {
        border: 1px solid #ced2d8;
        list-style: none;
        padding-top: 5px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        margin: 0;
    }

    .my_inputCheckbox_line {
        margin-left: 3px;
    }

    .my_typeOfSuspensionMeans {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_typeOfSuspensionMeans li {
            border: 2px solid #00338e;
            color: #000000;
            display: inline-block;
            padding: 0.3rem;
            text-align: center;
            width: 50%;
        }

        .my_typeOfSuspensionMeans .active {
            background-color: #00338e;
            color: #ffffff;
        }

    .classC {
        margin-top: 78px;
    }

    .input_margin-bottom {
        margin-bottom: 20px;
    }
</style>
