<template>
    <div style="overflow-x:auto !important">
        <div style="display:grid; grid-template-columns:1fr 1fr 10fr; grid-column-gap:10px; margin-top:10px;width:contain">
           <!-- Eingabe einer ATKF-Nummer -->
            <!--<div>
                <label>ATKF-Nummer:</label>
            </div>
            <div>
                <input v-model="calculationNumber" class="my_input" />
            </div>
            <div>
                <button @click="searchCalculationNumber" class="btn my_btn_standard_blue">{{$t('1186')}}</button>
            </div>-->
        </div>
            <div>
                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('3091')}}</div>

                <div style="display:grid; grid-template-columns:2fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">
                    <div style="padding-right:25px;border-right: 1px solid rgba(0, 0, 21, 0.2)">
                        <table style=" margin-top:10px; width:100%; vertical-align: middle !important;" cellpadding="10">
                            <tr>
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3227')}}:
                                    </div>
                                </td>
                                <td style=" width: calc(100%);">
                                    <select v-if="FI_Type_List!=null&&(FI_Type_List.length==0||FI_Type_List.length>=5)" v-model="Baur_select_Combo" class="my_select" style="width: 100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_Type in FI_Type_List" v-bind:value="itemOf_FI_Type">{{itemOf_FI_Type.text.textUI}}</option>
                                    </select>



                                    <div class="my_inputCheckbox" v-if="FI_Type_List!=null&&FI_Type_List.length<5&&FI_Type_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_Type in FI_Type_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_Type.selected" @input="Baur_select(itemOf_FI_Type)" />
                                            <label v-if="itemOf_FI_Type.text.useTextKey==false" v-bind:value="itemOf_FI_Type" for="itemOf_FI_Type" style="margin-left: 10px">{{itemOf_FI_Type.text.textUI}}</label>
                                            <label v-if="itemOf_FI_Type.text.useTextKey==true" v-bind:value="itemOf_FI_Type" for="itemOf_FI_Type" style="margin-left: 10px">{{$t(itemOf_FI_Type.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3508')}}:
                                    </div>
                                </td>
                                <td style=" width: calc(100%);">
                                    <select v-if="FI_Model_List!=null&&(FI_Model_List.length==0||FI_Model_List.length>=5)" v-model="Baug_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_Model in FI_Model_List" v-bind:value="itemOf_FI_Model">{{itemOf_FI_Model.text.textUI}}</option>
                                    </select>



                                    <!--<select v-if="FI_Type_selected=null" v-model="Baug_select_Combo" class="my_select" style="width:100%">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option v-for="itemOf_FI_Model in FI_Model_List" v-bind:value="itemOf_FI_Model">{{itemOf_FI_Model.text.text}}</option>
                            </select>-->

                                    <div class="my_inputCheckbox" v-if="FI_Model_List!=null&&FI_Model_List.length<5&&FI_Model_List.length>0" style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 10px; width: contain; ">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_Model in FI_Model_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_Model.selected" @input="Baug_select(itemOf_FI_Model)" v-bind:disabled="itemOf_FI_Model.selected" />
                                            <label v-if="itemOf_FI_Model.text.useTextKey==false" v-bind:value="itemOf_FI_Model" for="itemOf_FI_Model" style="margin-left: 10px">{{itemOf_FI_Model.text.textUI}}</label>
                                            <label v-if="itemOf_FI_Model.text.useTextKey==true" v-bind:value="itemOf_FI_Model" for="itemOf_FI_Model" style="margin-left: 10px">{{$t(itemOf_FI_Model.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3509')}}:
                                    </div>
                                </td>
                                <td style=" width: calc(100%);">
                                    <select v-if="FI_Menue_List!=null&&(FI_Menue_List.length==0||FI_Menue_List.length>=5)" v-model="Menue_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_Menue in FI_Menue_List" v-bind:value="itemOf_FI_Menue">{{itemOf_FI_Menue.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_Menue_List!=null&&FI_Menue_List.length<5&&FI_Menue_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_Menue in FI_Menue_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_Menue.selected" @input="Menue_select(itemOf_FI_Menue)" />
                                            <label v-if="itemOf_FI_Menue.text.useTextKey==false" v-bind:value="itemOf_FI_Menue" for="itemOf_FI_Menue" style="margin-left: 10px">{{itemOf_FI_Menue.text.textUI}}</label>
                                            <label v-if="itemOf_FI_Menue.text.useTextKey==true" v-bind:value="itemOf_FI_Menue" for="itemOf_FI_Menue" style="margin-left: 10px">{{$t(itemOf_FI_Menue.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3391')}}:
                                    </div>
                                </td>
                                <td style=" width: calc(100%);">
                                    <select v-if="FI_Bal_List!=null&&(FI_Bal_List.length==0||FI_Bal_List.length>=5)" v-model="Bal_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_Bal in FI_Bal_List" v-bind:value="itemOf_FI_Bal">{{itemOf_FI_Bal.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_Bal_List!=null&&FI_Bal_List.length<5&&FI_Bal_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_Bal in FI_Bal_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_Bal.selected" @input="Bal_select(itemOf_FI_Bal)" />
                                            <label v-if="itemOf_FI_Bal.text.useTextKey==false" v-bind:value="itemOf_FI_Bal" for="itemOf_FI_Bal" style="margin-left: 10px">{{itemOf_FI_Bal.text.textUI}}</label>
                                            <label v-if="itemOf_FI_Bal.text.useTextKey==true" v-bind:value="itemOf_FI_Bal" for="itemOf_FI_Bal" style="margin-left: 10px">{{$t(itemOf_FI_Bal.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3510')}}:
                                    </div>
                                </td>
                                <td style=" width: calc(100%);">
                                    <select v-if="FI_Par_List!=null&&(FI_Par_List.length==0||FI_Par_List.length>=5)" v-model="Par_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_Par in FI_Par_List" v-bind:value="itemOf_FI_Par">{{itemOf_FI_Par.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_Par_List!=null&&FI_Par_List.length<5&&FI_Par_List.length>0" style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 10px; width: contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_Par in FI_Par_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_Par.selected" @input="Par_select(itemOf_FI_Par)" v-bind:disabled="itemOf_FI_Par.selected" />
                                            <label v-if="itemOf_FI_Par.text.useTextKey==false" v-bind:value="itemOf_FI_Par" for="itemOf_FI_Par" style="margin-left: 10px">{{itemOf_FI_Par.text.textUI}}</label>
                                            <label v-if="itemOf_FI_Par.text.useTextKey==true" v-bind:value="itemOf_FI_Par" for="itemOf_FI_Par" style="margin-left: 10px">{{$t(itemOf_FI_Par.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3511')}}:
                                    </div>
                                </td>
                                <td style=" width: calc(100%);">
                                    <select v-if="FI_Conf_List!=null&&(FI_Conf_List.length==0||FI_Conf_List.length>=5)" v-model="Conf_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_Conf in FI_Conf_List" v-bind:value="itemOf_FI_Conf">{{itemOf_FI_Conf.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_Conf_List!=null&&FI_Conf_List.length<5&&FI_Conf_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_Conf in FI_Conf_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_Conf.selected" @input="Conf_select(itemOf_FI_Conf)" />
                                            <label v-if="itemOf_FI_Conf.text.useTextKey==false" v-bind:value="itemOf_FI_Conf" for="itemOf_FI_Conf" style="margin-left: 10px">{{itemOf_FI_Conf.text.textUI}}</label>
                                            <label v-if="itemOf_FI_Conf.text.useTextKey==true" v-bind:value="itemOf_FI_Conf" for="itemOf_FI_Conf" style="margin-left: 10px">{{$t(itemOf_FI_Conf.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr >
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3512')}}:
                                    </div>
                                </td>
                                <td style=" width: calc(100%);">
                                    <select v-if="FI_Code_List!=null&&(FI_Code_List.length==0||FI_Code_List.length>=5)" v-model="Code_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_Code in FI_Code_List" v-bind:value="itemOf_FI_Code">{{itemOf_FI_Code.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_Code_List!=null&&FI_Code_List.length<5&&FI_Code_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_Code in FI_Code_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_Code.selected" @input="Code_select(itemOf_FI_Code)" v-bind:disabled="itemOf_FI_Code.selected" />
                                            <label v-if="itemOf_FI_Code.text.useTextKey==false" v-bind:value="itemOf_FI_Code" for="itemOf_FI_Code" style="margin-left: 10px">{{itemOf_FI_Code.text.textUI}}</label>
                                            <label v-if="itemOf_FI_Code.text.useTextKey==true" v-bind:value="itemOf_FI_Code" for="itemOf_FI_Code" style="margin-left: 10px">{{$t(itemOf_FI_Code.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                        </table>

                    </div>
                    <div>
                        <img v-if="Baur_select_Combo != null && Baur_select_Combo.text.text == 'ZAdynpro'" src="../../../../public/img/ProductImages/ZAdynpro/AT_RT_PF_ZAdyn4B01M01-ZAdynpro-011-017-front_SALL_AQU_V1.jpg" style="width:calc(100%/3)" />
                        <img v-if="Baur_select_Combo != null && Baur_select_Combo.text.text == 'ZAdyn4BAplus'" src="../../../../public/img/ProductImages/ZAdyn4B/ZAdyn4Bplus-front.jpg" style="width:calc(100%/4)" />
                        <img v-if="Baur_select_Combo != null && Baur_select_Combo.text.text == 'ZAdyn4BSplus'" src="../../../../public/img/ProductImages/ZAdyn4B/ZAdyn4Bplus-front.jpg" style="width:calc(100%/4)" />
                        <img v-if="Baur_select_Combo != null && Baur_select_Combo.text.text == 'ZAdyn4CA'" src="../../../../public/img/ProductImages/ZAdyn4C/ZAdyn4C_011-032_ZD4C01M0-front.jpg" style="width:calc(100%/3)" />
                        <img v-if="Baur_select_Combo != null && Baur_select_Combo.text.text == 'ZAdyn4CS'" src="../../../../public/img/ProductImages/ZAdyn4C/ZAdyn4C_011-032_ZD4C01M0-front.jpg" style="width:calc(100%/3)" />
                    </div>

                </div>

                <hr />

                <div v-if="Baur_select_Combo != null && Baug_select_Combo != null && Menue_select_Combo != null && Bal_select_Combo != null && Par_select_Combo != null && Conf_select_Combo != null && Code_select_Combo != null" class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('3526')}}</div>
                <div style="display:grid; grid-template-columns:2fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">
                    <div v-if="Baur_select_Combo != null && Baug_select_Combo != null && Menue_select_Combo != null && Bal_select_Combo != null && Par_select_Combo != null && Conf_select_Combo != null && Code_select_Combo != null" style="padding-right:25px;border-right: 1px solid rgba(0, 0, 21, 0.2)">
                        <table style=" margin-top:10px; width:100%; vertical-align: middle !important;" cellpadding="10">
                            <tr>
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3513')}}:
                                    </div>
                                </td>
                                <td style=" width: calc(100%);">
                                    <select v-if="FI_PAD_List!=null&&(FI_PAD_List.length==0||FI_PAD_List.length>=5)" v-model="PAD_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_PAD in FI_PAD_List" v-bind:value="itemOf_FI_PAD">{{itemOf_FI_PAD.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_PAD_List!=null&&FI_PAD_List.length<5&&FI_PAD_List.length>0" style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 10px; width: contain;">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_PAD in FI_PAD_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_PAD.selected" @input="PAD_select(itemOf_FI_PAD)" />
                                            <label v-if="itemOf_FI_PAD.text.useTextKey==false" v-bind:value="itemOf_FI_PAD" for="itemOf_FI_PAD" style="margin-left: 10px">{{itemOf_FI_PAD.text.textUI}}</label>
                                            <label v-if="itemOf_FI_PAD.text.useTextKey==true" v-bind:value="itemOf_FI_PAD" for="itemOf_FI_PAD" style="margin-left: 10px">{{$t(itemOf_FI_PAD.text.textKey)}}</label>
                                        </div>
                                    </div>


                                </td>
                            </tr>
                            <tr>
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3514')}}:
                                    </div>
                                </td>
                                <td style=" width: calc(100%);">
                                    <select v-if="FI_MON_List!=null&&(FI_MON_List.length==0||FI_MON_List.length>=5)" v-model="MON_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_MON in FI_MON_List" v-bind:value="itemOf_FI_MON">{{itemOf_FI_MON.text.textUI}}</option>
                                    </select>



                                    <div class="my_inputCheckbox" v-if="FI_MON_List!=null&&FI_MON_List.length<5&&FI_MON_List.length>0" style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 10px; width: contain;">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_MON in FI_MON_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_MON.selected" @input="MON_select(itemOf_FI_MON)" />
                                            <label v-if="itemOf_FI_MON.text.useTextKey==false" v-bind:value="itemOf_FI_MON" for="itemOf_FI_MON" style="margin-left: 10px">{{itemOf_FI_MON.text.textUI}}</label>
                                            <label v-if="itemOf_FI_MON.text.useTextKey==true" v-bind:value="itemOf_FI_MON" for="itemOf_FI_MON" style="margin-left: 10px">{{$t(itemOf_FI_MON.text.textKey)}}</label>
                                        </div>
                                    </div>


                                </td>
                            </tr>
                            <tr v-if="Baur_select_Combo != null && (Baur_select_Combo.text.text == 'ZAdyn4BS' || Baur_select_Combo.text.text == 'ZAdyn4BA' || Baur_select_Combo.text.text == 'ZAdynpro' || Baur_select_Combo.text.text == 'ZAdyn4BAplus' || Baur_select_Combo.text.text == 'ZAdyn4BSplus' || Baur_select_Combo.text.text == 'ZAdynpro DCP')">
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3515')}}:
                                    </div>
                                </td>
                                <td style=" width:calc(100%)">
                                    <select v-if="FI_ND_List!=null&&(FI_ND_List.length==0||FI_ND_List.length>=5)" v-model="ND_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_ND in FI_ND_List" v-bind:value="itemOf_FI_ND">{{itemOf_FI_ND.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_ND_List!=null&&FI_ND_List.length<5&&FI_ND_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_ND in FI_ND_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_ND.selected" @input="ND_select(itemOf_FI_ND)" />
                                            <label v-if="itemOf_FI_ND.text.useTextKey==false" v-bind:value="itemOf_FI_ND" for="itemOf_FI_ND" style="margin-left: 10px">{{itemOf_FI_ND.text.textUI}}</label>
                                            <label v-if="itemOf_FI_ND.text.useTextKey==true" v-bind:value="itemOf_FI_ND" for="itemOf_FI_ND" style="margin-left: 10px">{{$t(itemOf_FI_ND.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr v-if="Baur_select_Combo != null && (Baur_select_Combo.text.text =='ZAdyn4BS' || Baur_select_Combo.text.text =='ZAdyn4BA' || Baur_select_Combo.text.text =='ZAdyn4BAplus' || Baur_select_Combo.text.text =='ZAdyn4BSplus')">
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3516')}}:
                                    </div>
                                </td>
                                <td style=" width:calc(100%)">
                                    <select v-if="FI_FEF_List!=null&&(FI_FEF_List.length==0||FI_FEF_List.length>=5)" v-model="FEF_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_FEF in FI_FEF_List" v-bind:value="itemOf_FI_FEF">{{itemOf_FI_FEF.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_FEF_List!=null&&FI_FEF_List.length<5&&FI_FEF_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_FEF in FI_FEF_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_FEF.selected" @input="FEF_select(itemOf_FI_FEF)" />
                                            <label v-if="itemOf_FI_FEF.text.useTextKey==false" v-bind:value="itemOf_FI_FEF" for="itemOf_FI_FEF" style="margin-left: 10px">{{itemOf_FI_FEF.text.textUI}}</label>
                                            <label v-if="itemOf_FI_FEF.text.useTextKey==true" v-bind:value="itemOf_FI_FEF" for="itemOf_FI_FEF" style="margin-left: 10px">{{$t(itemOf_FI_FEF.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('388')}}:
                                    </div>
                                </td>
                                <td style=" width:calc(100%)">
                                    <select v-if="FI_BR_List!=null&&(FI_BR_List.length==0||FI_BR_List.length>=5)" v-model="BR_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_BR in FI_BR_List" v-bind:value="itemOf_FI_BR">{{itemOf_FI_BR.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_BR_List!=null&&FI_BR_List.length<5&&FI_BR_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_BR in FI_BR_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_BR.selected" @input="BR_select(itemOf_FI_BR)" />
                                            <label v-if="itemOf_FI_BR.text.useTextKey==false" v-bind:value="itemOf_FI_BR" for="itemOf_FI_BR" style="margin-left: 10px">{{itemOf_FI_BR.text.textUI}}</label>
                                            <label v-if="itemOf_FI_BR.text.useTextKey==true" v-bind:value="itemOf_FI_BR" for="itemOf_FI_BR" style="margin-left: 10px">{{$t(itemOf_FI_BR.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <!-- Test Menge BR -->
                            <tr v-if="BR_select_Combo!=null && (BR_select_Combo.text.text == 'BR100-3B' || BR_select_Combo.text.text == 'BR100-3A') ">
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3528')}}:
                                    </div>
                                </td>
                                <td style=" width:calc(100%)">
                                    <select v-if="FI_BR_Quantity_List!=null&&(FI_BR_Quantity_List.length==0||FI_BR_Quantity_List.length>=5)" v-model="BR_Quantity_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_BR_Quantity in FI_BR_Quantity_List" v-bind:value="itemOf_FI_BR_Quantity">{{itemOf_FI_BR_Quantity.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_BR_Quantity_List!=null&&FI_BR_Quantity_List.length<5&&FI_BR_Quantity_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_BR_Quantity in FI_BR_Quantity_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_BR_Quantity.selected" @input="BR_Quantity_select(itemOf_FI_BR_Quantity)" v-bind:disabled="itemOf_FI_BR_Quantity.selected" />
                                            <label v-if="itemOf_FI_BR_Quantity.text.useTextKey==false" v-bind:value="itemOf_FI_BR_Quantity" for="itemOf_FI_BR_Quantity" style="margin-left: 10px">{{itemOf_FI_BR_Quantity.text.textUI}}</label>
                                            <label v-if="itemOf_FI_BR_Quantity.text.useTextKey==true" v-bind:value="itemOf_FI_BR_Quantity" for="itemOf_FI_BR_Quantity" style="margin-left: 10px">{{$t(itemOf_FI_BR_Quantity.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>




                            <tr v-if="BR_select_Combo!=null && BR_select_Combo.text.text != 'BR14-A' && BR_select_Combo.text.text != 'BR11-A' && BR_select_Combo.text.text != 'Rueckspeisung'">
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3517')}}:
                                    </div>
                                </td>
                                <td style=" width:calc(100%)">
                                    <select v-if="FI_BRLT_List!=null&&(FI_BRLT_List.length==0||FI_BRLT_List.length>=5)" v-model="BRLT_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_BRLT in FI_BRLT_List" v-bind:value="itemOf_FI_BRLT">{{itemOf_FI_BRLT.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_BRLT_List!=null&&FI_BRLT_List.length<5&&FI_BRLT_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_BRLT in FI_BRLT_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_BRLT.selected" @input="BRLT_select(itemOf_FI_BRLT)" />
                                            <label v-if="itemOf_FI_BRLT.text.useTextKey==false" v-bind:value="itemOf_FI_BRLT" for="itemOf_FI_BRLT" style="margin-left: 10px">{{itemOf_FI_BRLT.text.textUI}}</label>
                                            <label v-if="itemOf_FI_BRLT.text.useTextKey==true" v-bind:value="itemOf_FI_BRLT" for="itemOf_FI_BRLT" style="margin-left: 10px">{{$t(itemOf_FI_BRLT.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr>

                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3518')}}:
                                    </div>
                                </td>
                                <td style=" width:calc(100%)">
                                    <select v-if="FI_Nachruestsatz_List!=null&&(FI_Nachruestsatz_List.length==0||FI_Nachruestsatz_List.length>=5)" v-model="Nachruestsatz_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_Nachruestsatz in FI_Nachruestsatz_List" v-bind:value="itemOf_FI_Nachruestsatz">{{itemOf_FI_Nachruestsatz.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_Nachruestsatz_List!=null&&FI_Nachruestsatz_List.length<5&&FI_Nachruestsatz_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_Nachruestsatz in FI_Nachruestsatz_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_Nachruestsatz.selected" @input="Nachruestsatz_select(itemOf_FI_Nachruestsatz)" />
                                            <label v-if="itemOf_FI_Nachruestsatz.text.useTextKey==false" v-bind:value="itemOf_FI_Nachruestsatz" for="itemOf_FI_Nachruestsatz" style="margin-left: 10px">{{itemOf_FI_Nachruestsatz.text.textUI}}</label>
                                            <label v-if="itemOf_FI_Nachruestsatz.text.useTextKey==true" v-bind:value="itemOf_FI_Nachruestsatz" for="itemOf_FI_Nachruestsatz" style="margin-left: 10px">{{$t(itemOf_FI_Nachruestsatz.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>

                            </tr>
                            <tr v-if="Baur_select_Combo!= null&& Baur_select_Combo.text.text != 'ZAdynpro'">
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3519')}}:
                                    </div>
                                </td>
                                <td style=" width:calc(100%)">
                                    <select v-if="FI_DCP_List!=null&&(FI_DCP_List.length==0||FI_DCP_List.length>=5)" v-model="DCP_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_DCP in FI_DCP_List" v-bind:value="itemOf_FI_DCP">{{itemOf_FI_DCP.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_DCP_List!=null&&FI_DCP_List.length<5&&FI_DCP_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_DCP in FI_DCP_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_DCP.selected" @input="DCP_select(itemOf_FI_DCP)" />
                                            <label v-if="itemOf_FI_DCP.text.useTextKey==false" v-bind:value="itemOf_FI_DCP" for="itemOf_FI_DCP" style="margin-left: 10px">{{itemOf_FI_DCP.text.textUI}}</label>
                                            <label v-if="itemOf_FI_DCP.text.useTextKey==true" v-bind:value="itemOf_FI_DCP" for="itemOf_FI_DCP" style="margin-left: 10px">{{$t(itemOf_FI_DCP.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr v-if="Baur_select_Combo!= null&& Baur_select_Combo.text.text != 'ZAdynpro'">
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3520')}}:
                                    </div>
                                </td>
                                <td style=" width:calc(100%)">
                                    <select v-if="FI_STO_List!=null&&(FI_STO_List.length==0||FI_STO_List.length>=5)" v-model="STO_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_STO in FI_STO_List" v-bind:value="itemOf_FI_STO">{{itemOf_FI_STO.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_STO_List!=null&&FI_STO_List.length<5&&FI_STO_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_STO in FI_STO_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_STO.selected" @input="STO_select(itemOf_FI_STO)" />
                                            <label v-if="itemOf_FI_STO.text.useTextKey==false" v-bind:value="itemOf_FI_STO" for="itemOf_FI_STO" style="margin-left: 10px">{{itemOf_FI_STO.text.textUI}}</label>
                                            <label v-if="itemOf_FI_STO.text.useTextKey==true" v-bind:value="itemOf_FI_STO" for="itemOf_FI_STO" style="margin-left: 10px">{{$t(itemOf_FI_STO.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr v-if="Baur_select_Combo!= null&& Baur_select_Combo.text.text != 'ZAdynpro'">
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3521')}}:
                                    </div>
                                </td>
                                <td style=" width:calc(100%)">
                                    <select v-if="FI_XOUT_List!=null&&(FI_XOUT_List.length==0||FI_XOUT_List.length>=5)" v-model="XOUT_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_XOUT in FI_XOUT_List" v-bind:value="itemOf_FI_XOUT">{{itemOf_FI_XOUT.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_XOUT_List!=null&&FI_XOUT_List.length<5&&FI_XOUT_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_XOUT in FI_XOUT_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_XOUT.selected" @input="XOUT_select(itemOf_FI_XOUT)" />
                                            <label v-if="itemOf_FI_XOUT.text.useTextKey==false" v-bind:value="itemOf_FI_XOUT" for="itemOf_FI_XOUT" style="margin-left: 10px">{{itemOf_FI_XOUT.text.textUI}}</label>
                                            <label v-if="itemOf_FI_XOUT.text.useTextKey==true" v-bind:value="itemOf_FI_XOUT" for="itemOf_FI_XOUT" style="margin-left: 10px">{{$t(itemOf_FI_XOUT.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr v-if="Baur_select_Combo!= null&& Baur_select_Combo.text.text != 'ZAdynpro'">
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3522')}}:
                                    </div>
                                </td>
                                <td style=" width:calc(100%)">
                                    <select v-if="FI_XIN_List!=null&&(FI_XIN_List.length==0||FI_XIN_List.length>=5)" v-model="XIN_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_XIN in FI_XIN_List" v-bind:value="itemOf_FI_XIN">{{itemOf_FI_XIN.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_XIN_List!=null&&FI_XIN_List.length<5&&FI_XIN_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_XIN in FI_XIN_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_XIN.selected" @input="XIN_select(itemOf_FI_XIN)" />
                                            <label v-if="itemOf_FI_XIN.text.useTextKey==false" v-bind:value="itemOf_FI_XIN" for="itemOf_FI_XIN" style="margin-left: 10px">{{itemOf_FI_XIN.text.textUI}}</label>
                                            <label v-if="itemOf_FI_XIN.text.useTextKey==true" v-bind:value="itemOf_FI_XIN" for="itemOf_FI_XIN" style="margin-left: 10px">{{$t(itemOf_FI_XIN.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3523')}}:
                                    </div>
                                </td>
                                <td style=" width:calc(100%)">
                                    <select v-if="FI_ZAsbc_List!=null&&(FI_ZAsbc_List.length==0||FI_ZAsbc_List.length>=5)" v-model="ZAsbc_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_ZAsbc in FI_ZAsbc_List" v-bind:value="itemOf_FI_ZAsbc">{{itemOf_FI_ZAsbc.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_ZAsbc_List!=null&&FI_ZAsbc_List.length<5&&FI_ZAsbc_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_ZAsbc in FI_ZAsbc_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_ZAsbc.selected" @input="ZAsbc_select(itemOf_FI_ZAsbc)" />
                                            <label v-if="itemOf_FI_ZAsbc.text.useTextKey==false" v-bind:value="itemOf_FI_ZAsbc" for="itemOf_FI_ZAsbc" style="margin-left: 10px">{{itemOf_FI_ZAsbc.text.textUI}}</label>
                                            <label v-if="itemOf_FI_ZAsbc.text.useTextKey==true" v-bind:value="itemOf_FI_ZAsbc" for="itemOf_FI_ZAsbc" style="margin-left: 10px">{{$t(itemOf_FI_ZAsbc.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3524')}}:
                                    </div>
                                </td>
                                <td style=" width:calc(100%)">
                                    <select v-if="FI_ZAsbc_LS_List!=null&&(FI_ZAsbc_LS_List.length==0||FI_ZAsbc_LS_List.length>=5)" v-model="ZAsbc_LS_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_ZAsbc_LS in FI_ZAsbc_LS_List" v-bind:value="itemOf_FI_ZAsbc_LS">{{itemOf_FI_ZAsbc_LS.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_ZAsbc_LS_List!=null&&FI_ZAsbc_LS_List.length<5&&FI_ZAsbc_LS_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_ZAsbc_LS in FI_ZAsbc_LS_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_ZAsbc_LS.selected" @input="ZAsbc_LS_select(itemOf_FI_ZAsbc_LS)" />
                                            <label v-if="itemOf_FI_ZAsbc_LS.text.useTextKey==false" v-bind:value="itemOf_FI_ZAsbc_LS" for="itemOf_FI_ZAsbc_LS" style="margin-left: 10px">{{itemOf_FI_ZAsbc_LS.text.textUI}}</label>
                                            <label v-if="itemOf_FI_ZAsbc_LS.text.useTextKey==true" v-bind:value="itemOf_FI_ZAsbc_LS" for="itemOf_FI_ZAsbc_LS" style="margin-left: 10px">{{$t(itemOf_FI_ZAsbc_LS.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3525')}}:
                                    </div>
                                </td>
                                <td style=" width:calc(100%)">
                                    <select v-if="FI_ZAsbc_LSBA_List!=null&&(FI_ZAsbc_LSBA_List.length==0||FI_ZAsbc_LSBA_List.length>=5)" v-model="ZAsbc_LSBA_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_ZAsbc_LSBA in FI_ZAsbc_LSBA_List" v-bind:value="itemOf_FI_ZAsbc_LSBA">{{itemOf_FI_ZAsbc_LSBA.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_ZAsbc_LSBA_List!=null&&FI_ZAsbc_LSBA_List.length<5&&FI_ZAsbc_LSBA_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_ZAsbc_LSBA in FI_ZAsbc_LSBA_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_ZAsbc_LSBA.selected" @input="ZAsbc_LSBA_select(itemOf_FI_ZAsbc_LSBA)" />
                                            <label v-if="itemOf_FI_ZAsbc_LSBA.text.useTextKey==false" v-bind:value="itemOf_FI_ZAsbc_LSBA" for="itemOf_FI_ZAsbc_LSBA" style="margin-left: 10px">{{itemOf_FI_ZAsbc_LSBA.text.textUI}}</label>
                                            <label v-if="itemOf_FI_ZAsbc_LSBA.text.useTextKey==true" v-bind:value="itemOf_FI_ZAsbc_LSBA" for="itemOf_FI_ZAsbc_LSBA" style="margin-left: 10px">{{$t(itemOf_FI_ZAsbc_LSBA.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td style=" width:calc(100%/2)">
                                    <div class="ZAfont_family ZAfont_size">
                                        {{$t('3391')}} {{$t('3523')}}:
                                    </div>
                                </td>
                                <td style=" width:calc(100%)">
                                    <select v-if="FI_ZAsbc_BAL_List!=null&&(FI_ZAsbc_BAL_List.length==0||FI_ZAsbc_BAL_List.length>=5)" v-model="ZAsbc_BAL_select_Combo" class="my_select" style="width:100%">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option v-for="itemOf_FI_ZAsbc_BAL in FI_ZAsbc_BAL_List" v-bind:value="itemOf_FI_ZAsbc_BAL">{{itemOf_FI_ZAsbc_BAL.text.textUI}}</option>
                                    </select>

                                    <div class="my_inputCheckbox" v-if="FI_ZAsbc_BAL_List!=null&&FI_ZAsbc_BAL_List.length<5&&FI_ZAsbc_BAL_List.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                        <div class=" my_inputCheckbox_line" v-for="itemOf_FI_ZAsbc_BAL in FI_ZAsbc_BAL_List">
                                            <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                            <input type="checkbox" v-bind:checked="itemOf_FI_ZAsbc_BAL.selected" @input="ZAsbc_BAL_select(itemOf_FI_ZAsbc_BAL)" />
                                            <label v-if="itemOf_FI_ZAsbc_BAL.text.useTextKey==false" v-bind:value="itemOf_FI_ZAsbc_BAL" for="itemOf_FI_ZAsbc_BAL" style="margin-left: 10px">{{itemOf_FI_ZAsbc_BAL.text.textUI}}</label>
                                            <label v-if="itemOf_FI_ZAsbc_BAL.text.useTextKey==true" v-bind:value="itemOf_FI_ZAsbc_BAL" for="itemOf_FI_ZAsbc_BAL" style="margin-left: 10px">{{$t(itemOf_FI_ZAsbc_BAL.text.textKey)}}</label>
                                        </div>
                                    </div>

                                </td>
                            </tr>

                        </table>

                    </div>
                    <div>

                    </div>

                </div>


                <div>
                    <button @click="RouteToManualHomeCancel" class="btn my_btn_standard_blue" style="margin-top:40px; margin-right:5px">{{$t('3237')}}</button>
                    <button @click="RouteToManualHomeOK()" class="btn my_btn_standard_blue" style="margin-top:40px">{{$t('383')}}</button>
                </div>
            </div>

        </div>
</template>


<script>
    import router from '../../../router';
    import InvalidityReasons from '../../dataValidation/InvalidityReasons';
    import LoaderIcon from '../../loader/LoaderIcon';

    export default {
        name: 'Inputs',

        components: {
            InvalidityReasons,
            LoaderIcon,
        },


        data() {
            return {
                calculationNumber: null
                //// Tets Dimmler Konfiguration FU
                //nominalLoad_ValueList_Ausfue: ['SERIAL', 'SAMPEL', 'REPLACE'],

                //nominalLoad_ValueList_MENUE: ['DE', 'EN', 'S'],
                ////nominalLoad_MENUE: 'deutsch',
                //nominalLoad_valueList_Config: ['10.0', '10.1'],
                ////nominalLoad_Config: '10. ZA',
                //nominalLoad_valueList_BAL: ['DE', 'EN', 'S'],
                ////nominalLoad_BAL: 'deutsch',
                //nominalLoad_valueList_PAR: ['STD', 'CALC', 'CUST'],
                ////nominalLoad_PAR: 'Standard',
                //nominalLoad_valueList_CODE: ['STD', 'CODE'],
                ////nominalLoad_CODE: 'Standard'
                //// suspensionMeans
                //modal_suspensionMeans_Selection_Shown: false,
            }
        },

        computed: {
            //Baureihen des Frequenzumrichtes auflisten
            FI_Type_List: {
                get() {
                    return this.$store.state.FI.FI_Type_List;
                },
            },
            //Baugr��en des Frequenzumrichters auflisten
            FI_Model_List: {
                get() {
                    return this.$store.state.FI.FI_Model_List;
                },
            },
            //Menuesprachen des Frequenzumrichters auflisten
            FI_Menue_List: {
                get() {
                    return this.$store.state.FI.FI_Menue_List;
                },
            },
            //Betriebsanleitung des Frequenzumrichters auflisten
            FI_Bal_List: {
                get() {
                    return this.$store.state.FI.FI_Bal_List;
                },
            },
            //Motor und Anlagedaten des Frequenumrichters auflisten
            FI_Par_List: {
                get() {
                    return this.$store.state.FI.FI_Par_List;
                },
            },
            //Kundenkonfiguration des Frequenzumrichters auflisten
            FI_Conf_List: {
                get() {
                    return this.$store.state.FI.FI_Conf_List;
                },
            },
            //Umschaltcode des Frequenzumrichters auflisten
            FI_Code_List: {
                get() {
                    return this.$store.state.FI.FI_Code_List
                },
            },
            //ZApad auflisten
            FI_PAD_List: {
                get() {
                    return this.$store.state.FI.FI_PAD_List
                },
            },
            //ZAmon auflisten
            FI_MON_List: {
                get() {
                    return this.$store.state.FI.FI_MON_List
                },
            },
            //ND auflisten
            FI_ND_List: {
                get() {
                    return this.$store.state.FI.FI_ND_List
                },
            },
            //FEF auflisten
            FI_FEF_List: {
                get() {
                    return this.$store.state.FI.FI_FEF_List
                },
            },
            //BR auflisten
            FI_BR_List: {
                get() {
                    return this.$store.state.FI.FI_BR_List
                },
            },
            //BR Menge
            FI_BR_Quantity_List: {
                get() {
                    return this.$store.state.FI.FI_BR_Quantity_List
                },
            },
            //BRLT auflisten
            FI_BRLT_List: {
                get() {
                    //console.log(this.$store.state.FI.FI_BR_selected)
                    //if (this.$store.state.FI.FI_BR_selected != null && this.$store.state.FI.FI_BR_selected == "Rueckspeisung") {
                    //    return null
                    //}

                    return this.$store.state.FI.FI_BRLT_List
                },
            },
            //DCP auflisten
            FI_DCP_List: {
                get() {
                    return this.$store.state.FI.FI_DCP_List
                },
            },
            //STO auflisten
            FI_STO_List: {
                get() {
                    return this.$store.state.FI.FI_STO_List
                },
            },
            //XOUT auflisten
            FI_XOUT_List: {
                get() {
                    return this.$store.state.FI.FI_XOUT_List
                },
            },
            //XIN auflisten
            FI_XIN_List: {
                get() {
                    return this.$store.state.FI.FI_XIN_List
                },
            },
            FI_Nachruestsatz_List: {
                get() {
                    return this.$store.state.FI.FI_Nachruestsatz_List
                },
            },
            FI_ZAsbc_List: {
                get() {
                    return this.$store.state.FI.FI_ZAsbc_List
                },
            },
            FI_ZAsbc_LS_List: {
                get() {
                    return this.$store.state.FI.FI_ZAsbc_LS_List
                },
            },
            FI_ZAsbc_LSBA_List: {
                get() {
                    return this.$store.state.FI.FI_ZAsbc_LSBA_List
                },
            },
            FI_ZAsbc_BAL_List: {
                get() {
                    return this.$store.state.FI.FI_ZAsbc_BAL_List
                },
            },
            //Gew�hlte Baureihe abspeichern
            Baur_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_Type_List != null && Array.isArray(this.$store.state.FI.FI_Type_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_Type_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_Type_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                    //return this.$store.state.FI.FI_Type_selected;
                },
                set(value) {
                    this.Baur_select(value);
                    //this.$store.dispatch('FI/change_FI_Type_selection_storeAction', value);
                }
            },
            //Gew�hlte Baugr��es abspeicern
            Baug_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_Model_List != null && Array.isArray(this.$store.state.FI.FI_Model_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_Model_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_Model_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                    //return this.$store.state.FI.FI_Model_selected;
                    return this.$store.state.FI.FI_MNFI_selected;
                    return this.$store.state.FI.FI_MNFISTL_selected;
                    return this.$store.state.FI.FI_MNFIBGGT_selected;
                    
                },
                set(value) {
                    this.$store.commit('FI/change_FI_Model_selection_storeMutation', value);
                    //this.$store.dispatch('FI/get_FI_Model_storeAction');
                    this.Baug_select(value);
                    //this.$store.dispatch('FI/change_FI_Model_selection_storeAction', value);
                   
                }
            },
            //Gew�hlte Menuesprache abspeichern
            Menue_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_Menue_List != null && Array.isArray(this.$store.state.FI.FI_Menue_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_Menue_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_Menue_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                    //return this.$store.state.FI.FI_Menue_selected;
                },
                set(value) {
                    //this.$store.dispatch('FI/change_FI_Menue_selection_storeAction', value);
                    this.Menue_select(value);
                },
            },
            //Gew�hlte Betriebsanleitung abspeichern
            Bal_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_Bal_List != null && Array.isArray(this.$store.state.FI.FI_Bal_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_Bal_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_Bal_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                    //return this.$store.state.FI.FI_Bal_selected;
                },
                set(value) {
                    this.Bal_select(value);
                    //this.$store.dispatch('FI/change_FI_Bal_selection_storeAction', value);
                },
            },
            //Gew�hlte Motor und Anlagedaten abspeichern
            Par_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_Par_List != null && Array.isArray(this.$store.state.FI.FI_Par_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_Par_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_Par_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                    //return this.$store.state.FI.FI_Par_selected;
                },
                set(value) {
                    this.Par_select(value);
                    //this.$store.dispatch('FI/change_FI_Par_selection_storeAction', value);
                },
            },
            //Gew�hlte Kundenkonfiguration abspeichern
            Conf_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_Conf_List != null && Array.isArray(this.$store.state.FI.FI_Conf_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_Conf_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_Conf_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                    //return this.$store.state.FI.FI_Conf_selected;
                },
                set(value) {
                    /* this.$store.dispatch('FI/change_FI_Conf_selection_storeAction', value);*/
                    this.Conf_select(value);
                    //this.$store.dispatch('FI/change_FI_Conf_selection_storeAction', value);
                },
            },
            //Gew�hlter Umschaltcode abspeichern
            Code_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_Code_List != null && Array.isArray(this.$store.state.FI.FI_Code_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_Code_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_Code_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                    //return this.$store.state.FI.FI_Code_selected;
                },
                set(value) {
                    this.Code_select(value);
                    //this.$store.commit('FI/change_FI_Code_selection_storeMutation', value);
                },
            },
            //Gew�ltes PAD abspeichern
            PAD_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_PAD_List != null && Array.isArray(this.$store.state.FI.FI_PAD_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_PAD_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_PAD_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                        this.PAD_select(value);
                        //this.$store.commit('FI/change_FI_Code_selection_storeMutation', value);
                },
                
            },
            
            //PAD_select: {
            //    get() {
            //        return this.$store.state.FI.FI_PAD_selected;
            //    },
            //    set(value) {
            //        this.$store.commit('FI/change_FI_PAD_selection_storeMutation', value);
            //    },
            //},
            //MON abspeichern
            //MON_select: {
            //    get() {
            //        return this.$store.state.FI.FI_MON_selected;
            //    },
            //    set(value) {
            //        this.$store.commit('FI/change_FI_MON_selection_storeMutation', value);
            //    },
            //},
            MON_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_MON_List != null && Array.isArray(this.$store.state.FI.FI_MON_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_MON_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_MON_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                        this.MON_select(value);
                        //this.$store.commit('FI/change_FI_Code_selection_storeMutation', value);
                    
                },
            },
            //ND abspeichern
            //ND_select: {
            //    get() {
            //        return this.$store.state.FI.FI_ND_selected;
            //    },
            //    set(value) {
            //        this.$store.commit('FI/change_FI_ND_selection_storeMutation', value);
            //    },
            //},
            ND_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_ND_List != null && Array.isArray(this.$store.state.FI.FI_ND_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_ND_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_ND_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                     this.ND_select(value);
                     //this.$store.commit('FI/change_FI_Code_selection_storeMutation', value);
                },
                
            },
            //FEF abspeichern
            //FEF_select: {
            //    get() {
            //        return this.$store.state.FI.FI_FEF_selected;
            //    },
            //    set(value) {
            //        this.$store.commit('FI/change_FI_FEF_selection_storeMutation', value);
            //    },
            //},
            FEF_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_FEF_List != null && Array.isArray(this.$store.state.FI.FI_FEF_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_FEF_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_FEF_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.FEF_select(value);
                    //this.$store.commit('FI/change_FI_Code_selection_storeMutation', value);
                },
            },
            //BR abspeichern
            //BR_select: {
            //    get() {
                    
            //        return this.$store.state.FI.FI_BR_selected;
            //        return this.$store.state.FI.FI_MNBR_selected;
                    
            //    },
            //    set(value) {
            //        /*this.$store.commit('FI/change_FI_BR_selection_storeMutation', value);*/
            //        this.$store.dispatch('FI/change_FI_BR_selection_storeAction', value);
            //    },
            //},
            BR_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_BR_List != null && Array.isArray(this.$store.state.FI.FI_BR_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_BR_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_BR_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.BR_select(value);
                    //this.$store.commit('FI/change_FI_Code_selection_storeMutation', value);
                },
            },
            //BRLT abspeichern
            //BRLT_select: {
            //    get() {
            //        return this.$store.state.FI.FI_BRLT_selected;
            //    },
            //    set(value) {
            //        this.$store.commit('FI/change_FI_BRLT_selection_storeMutation', value);
            //    },
            //},

            //Menge BR
            BR_Quantity_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_BR_Quantity_List != null && Array.isArray(this.$store.state.FI.FI_BR_Quantity_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_BR_Quantity_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_BRLT_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {

                    this.BR_Quantity_select(value);
                },
            },

            BRLT_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_BRLT_List != null && Array.isArray(this.$store.state.FI.FI_BRLT_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_BRLT_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_BRLT_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.BRLT_select(value);
                    //this.$store.commit('FI/change_FI_Code_selection_storeMutation', value);
                },
            },

            //DCP abspeichern
            //DCP_select: {
            //    get() {
            //        return this.$store.state.FI.FI_DCP_selected;
            //    },
            //    set(value) {
            //        this.$store.commit('FI/change_FI_DCP_selection_storeMutation', value);
            //    },
            //},
            DCP_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_DCP_List != null && Array.isArray(this.$store.state.FI.FI_DCP_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_DCP_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_DCP_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.DCP_select(value);
                    //this.$store.commit('FI/change_FI_Code_selection_storeMutation', value);
                },
            },
            //STO abspeichern
            //STO_select: {
            //    get() {
            //        return this.$store.state.FI.FI_STO_selected;
            //    },
            //    set(value) {
            //        this.$store.commit('FI/change_FI_STO_selection_storeMutation', value);
            //    },
            //},
            STO_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_STO_List != null && Array.isArray(this.$store.state.FI.FI_STO_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_STO_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_STO_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.STO_select(value);
                    //this.$store.commit('FI/change_FI_Code_selection_storeMutation', value);
                },
            },
            //XOUT abspeichern
            //XOUT_select: {
            //    get() {
            //        return this.$store.state.FI.FI_XOUT_selected;
            //    },
            //    set(value) {
            //        this.$store.commit('FI/change_FI_XOUT_selection_storeMutation', value);
            //    },
            //},
            XOUT_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_XOUT_List != null && Array.isArray(this.$store.state.FI.FI_XOUT_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_XOUT_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_XOUT_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.XOUT_select(value);
                    //this.$store.commit('FI/change_FI_Code_selection_storeMutation', value);
                },
            },
            //XIN abspeichern
            //XIN_select: {
            //    get() {
            //        return this.$store.state.FI.FI_XIN_selected;
            //    },
            //    set(value) {
            //        this.$store.commit('FI/change_FI_XIN_selection_storeMutation', value);
            //    },
            //},
            XIN_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_XIN_List != null && Array.isArray(this.$store.state.FI.FI_XIN_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_XIN_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_XIN_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.XIN_select(value);
                    //this.$store.commit('FI/change_FI_Code_selection_storeMutation', value);
                },
            },
            //Nachruessatz abspeichern
            //Nachruestsatz_select: {
            //    get() {
            //        return this.$store.state.FI.FI_Nachruestsatz_selected
            //    },
            //    set(value) {
            //        this.$store.commit('FI/change_FI_Nachruestsatz_selection_storeMutation', value);
            //    },
            //},
            Nachruestsatz_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_Nachruestsatz_List != null && Array.isArray(this.$store.state.FI.FI_Nachruestsatz_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_Nachruestsatz_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_Nachruestsatz_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.Nachruestsatz_select(value);
                    //this.$store.commit('FI/change_FI_Code_selection_storeMutation', value);
                },
            },
            //ZAsbc_select: {
            //    get() {
            //        return this.$store.state.FI.FI_ZAsbc_selected
            //    },
            //    set(value) {
            //        this.$store.commit('FI/change_FI_ZAsbc_selection_storeMutation', value);
            //    },
            //},
            ZAsbc_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_ZAsbc_List != null && Array.isArray(this.$store.state.FI.FI_ZAsbc_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_ZAsbc_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_ZAsbc_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.ZAsbc_select(value);
                    //this.$store.commit('FI/change_FI_Code_selection_storeMutation', value);
                },
            },

            //ZAsbc_LS_select: {
            //    get() {
            //        return this.$store.state.FI.FI_ZAsbc_LS_selected
            //    },
            //    set(value) {
            //        this.$store.commit('FI/change_FI_ZAsbc_LS_selection_storeMutation', value);
            //    },
            //},
            ZAsbc_LS_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_ZAsbc_LS_List != null && Array.isArray(this.$store.state.FI.FI_ZAsbc_LS_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_ZAsbc_LS_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_ZAsbc_LS_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.ZAsbc_LS_select(value);
                    //this.$store.commit('FI/change_FI_Code_selection_storeMutation', value);
                },
            },
            //ZAsbc_LSBA_select: {
            //    get() {
            //        return this.$store.state.FI.FI_ZAsbc_LSBA_selected
            //    },
            //    set(value) {
            //        this.$store.commit('FI/change_FI_ZAsbc_LSBA_selection_storeMutation', value);
            //    }
            //},
            ZAsbc_LSBA_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_ZAsbc_LSBA_List != null && Array.isArray(this.$store.state.FI.FI_ZAsbc_LSBA_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_ZAsbc_LSBA_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_ZAsbc_LSBA_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.ZAsbc_LSBA_select(value);
                    //this.$store.commit('FI/change_FI_Code_selection_storeMutation', value);
                },
            },
            //ZAsbc_BAL_select: {
            //    get() {
            //        return this.$store.state.FI.FI_ZAsbc_BAL_selected
            //    },
            //    set(value) {
            //        this.$store.commit('FI/change_FI_ZAsbc_BAL_selection_storeMutation', value);
            //    },
            //},
            ZAsbc_BAL_select_Combo: {
                get() {
                    if (this.$store.state.FI.FI_ZAsbc_BAL_List != null && Array.isArray(this.$store.state.FI.FI_ZAsbc_BAL_List)) {
                        const firstIndex_whichMeetConditions = this.$store.state.FI.FI_ZAsbc_BAL_List.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.FI.FI_ZAsbc_BAL_List[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.ZAsbc_BAL_select(value);
                    //this.$store.commit('FI/change_FI_Code_selection_storeMutation', value);
                },
            },

        },

        created() {
            if (this.configurationOverviewsId != null) {
                this.$store.dispatch('configurations/check_toDos_at_inputs_storeAction')
            }
            else {
                this.$store.dispatch('configurations/toDos_after_routeSlug_UpdatedOrEntered_storeAction', 'inputs')
            }
        },

        methods: {
            searchCalculationNumber() {
                alert(this.calculationNumber)
            },

            Baur_select(value) {
                this.$store.dispatch('FI/change_FI_Type_selection_storeAction', value);
            },
            Baug_select(value) {
                this.$store.dispatch('FI/change_FI_Model_selection_storeAction', value);
            },
            Menue_select(value) {
                this.$store.dispatch('FI/change_FI_Menue_selection_storeAction', value);
            },
            Bal_select(value) {
                this.$store.dispatch('FI/change_FI_Bal_selection_storeAction', value);
            },
            Par_select(value) {
                this.$store.dispatch('FI/change_FI_Par_selection_storeAction', value);
            },
            Conf_select(value) {
                this.$store.dispatch('FI/change_FI_Conf_selection_storeAction', value);
            },
            Code_select(value) {
                this.$store.dispatch('FI/change_FI_Code_selection_storeAction', value);
            },
            PAD_select(value) {
                this.$store.dispatch('FI/change_FI_PAD_selection_storeAction', value);
            },
            MON_select(value) {
                this.$store.dispatch('FI/change_FI_MON_selection_storeAction', value);
            },
            ND_select(value) {
                this.$store.dispatch('FI/change_FI_ND_selection_storeAction', value);
            },
            FEF_select(value) {
                this.$store.dispatch('FI/change_FI_FEF_selection_storeAction', value);
            },
            BR_select(value) {
                this.$store.dispatch('FI/change_FI_BR_selection_storeAction', value);
            },
            BR_Quantity_select(value) {
                this.$store.dispatch('FI/change_FI_BR_Quantity_selection_storeAction', value);
            },
            BRLT_select(value) {
                this.$store.dispatch('FI/change_FI_BRLT_selection_storeAction', value);
            },
            DCP_select(value) {
                this.$store.dispatch('FI/change_FI_DCP_selection_storeAction', value);
            },
            STO_select(value) {
                this.$store.dispatch('FI/change_FI_STO_selection_storeAction', value);
            },
            XOUT_select(value) {
                this.$store.dispatch('FI/change_FI_XOUT_selection_storeAction', value);
            },
            XIN_select(value) {
                this.$store.dispatch('FI/change_FI_XIN_selection_storeAction', value);
            },
            Nachruestsatz_select(value) {
                this.$store.dispatch('FI/change_FI_Nachruestsatz_selection_storeAction', value);
            },
            ZAsbc_select(value) {
                this.$store.dispatch('FI/change_FI_ZAsbc_selection_storeAction', value);
            },
            ZAsbc_LS_select(value) {
                this.$store.dispatch('FI/change_FI_ZAsbc_LS_selection_storeAction', value);
            },
            ZAsbc_LSBA_select(value) {
                this.$store.dispatch('FI/change_FI_ZAsbc_LSBA_selection_storeAction', value);
            },
            ZAsbc_BAL_select(value) {
                this.$store.dispatch('FI/change_FI_ZAsbc_BAL_selection_storeAction', value);
            },

            RouteToManualHomeOK(value) {
                if (this.$store.state.FI.FI_Type_selected != null && this.$store.state.FI.FI_Model_selected != null && this.$store.state.FI.FI_Conf_selected != null && this.$store.state.FI.FI_Par_selected != null &&
                    this.$store.state.FI.FI_Bal_selected != null && this.$store.state.FI.FI_Menue_selected != null && this.$store.state.FI.FI_Code_selected != null) {
                    
                    this.$store.dispatch('FI/insert_data'); 
                    //this.$store.dispatch('FI/change_ZLM_storeAction', value);
                    
                    if (this.$store.state.FI.FI_BRUI_selected != "R�ckspeisung") {
                        router.push('../manualStep/ManualHome');
                    }
                    if (this.$store.state.FI.FI_BRUI_selected = "R�ckspeisung") {
                        this.$store.dispatch('ZArec/get_ZArec_Typ_storeAction');
                        this.$store.dispatch('ZArec/get_RLD_Typ_storeAction');
                        router.push('../manualStep/ZArec');
                    }
                }
                else {
                    alert("Bitte die Konfiguration des Frequenzumrichters vervollstaendigen!")
                }
            },
            RouteToManualHomeCancel() {
                
                this.$store.dispatch('FI/change_inputs_FI_toDefault_storeAction');
                router.push('../manualStep/ManualHome');
            }
        },

    }
</script>


<style>
    .my_inputCheckbox {
        border: 1px solid #ced2d8;
        list-style: none;
        padding-top: 5px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        margin: 0;
    }

    .my_inputCheckbox_line {
        margin-left: 3px;
        
    }

    .my_typeOfSuspensionMeans {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_typeOfSuspensionMeans li {
            border: 2px solid #00338e;
            color: #000000;
            display: inline-block;
            padding: 0.3rem;
            text-align: center;
            width: 50%;
        }

        .my_typeOfSuspensionMeans .active {
            background-color: #00338e;
            color: #ffffff;
        }

    .classC {
        margin-top: 78px;
    }

    .input_margin-bottom {
        margin-bottom: 20px;
    }
</style>
