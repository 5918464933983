<template>
    <div style="overflow-x:auto !important">
        <div class="my_fontSize_1Point5 my_color_00338e my_fontWeight_600 ZAfont_family">{{$t('3477')}}</div>
        <div v-if="showFrameDeliverOptions==true" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
            <div class="ZAfont_family ZAfont_size" style="grid-column:2 ;background-color:#f5f5f5; padding:0.75rem">
                <div class="my_color_00338e my_fontWeight_600">{{$t('3476')}}</div>
                <div>
                    <span>{{$t('407')}}: </span>
                    <span class="my_font-weight_700" v-if="frameInformation.typeDesignation.useTextKey==false">{{frameInformation.typeDesignation.textUI}} {{frameInformation.type}}</span>
                    <span class="my_font-weight_700" v-if="frameInformation.typeDesignation.useTextKey==true">{{$t(frameInformation.typeDesignation.textKey)}} {{frameInformation.type}}</span>
                </div>
                <div v-if="frameInformation.horizontalDistance!=null && frameInformation.horizontalDistance != '0'">
                    <div>{{$t('1027')}}: <span class="my_font-weight_700">{{frameInformation.horizontalDistance}}</span> mm</div>
                    <div>{{$t('1026')}}: <span class="my_font-weight_700">{{frameInformation.verticalDistance}}</span> mm</div>
                    <div>{{$t('1326')}} {{$t('3413')}}: <span class="my_font-weight_700">{{frameInformation.axleLoadPulley}}</span> kg</div>
                </div>
            </div>
        </div>
        <!--<div>
    <div class="my_fontSize_1Point5 my_color_00338e my_fontWeight_600 ZAfont_family">{{$t('1115')}} </div>
    <div v-if="pulley_Available==true" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
        <div style="justify-self:center; font-size:20px; margin-top:-5px" class="my_color_00338e"><span class="fas fa-plus"></span></div>
        <div>
            <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">{{$t('1062')}} </div>
        </div>
        <div v-if="pulley_ArrayLength>1" style="grid-column: 2 /-1">
            <div>
                <ul class="my_pulley">
                    <li style="cursor: pointer" v-for="pulley in pulley_Selection" @click.prevent="change_Selected_at_pulley_Selection(pulley)" v-bind:class="{active: pulley.selected==true}">
                        <div v-if="pulley.pulley.onRequest==true" style="display:grid;grid-template-columns: auto auto auto;padding: 3px; overflow:auto; width:100%; text-align:left">
                            <div class="my_div_minWidth_100" style="margin-right:10px">
                                <div class="my_fontSize_1Point1 ZAfont_family"><span class="my_font-weight_600">{{$t('3378')}}</span> </div>
                            </div>
                            <div class="my_div_minWidth_100 my_div_maginleft_5" style="margin-right:10px">
                                <div class="my_fontSize_1Point1 ZAfont_family"><span class="my_font-weight_600">{{pulley.pulley.diameter}}</span> mm</div>
                                <div class="my_table_parameters_infoText">{{$t('3038')}}</div>
                            </div>
                            <div class="my_div_minWidth_100 my_div_maginleft_5">
                                <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{pulley.pulley.grooveDistance}}</span> mm</div>
                                <div class="my_table_parameters_infoText">{{$t('1294')}}</div>
                            </div>

                        </div>
                        <div v-if="pulley.pulley.onRequest==false" style="display: grid; grid-template-columns: auto auto auto auto auto auto auto; padding: 3px; overflow: auto; width: 100%; text-align: left">
                            <div class="my_div_minWidth_100" style="margin-right:10px">
                                <div class="my_fontSize_1Point1 ZAfont_family"><span class="my_font-weight_600">{{pulley.pulley.diameter}}</span> mm</div>
                                <div class="my_table_parameters_infoText">{{$t('3038')}}</div>
                            </div>
                            <div class="my_div_minWidth_100 my_div_maginleft_5">
                                <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{pulley.pulley.grooveDistance}}</span> mm</div>
                                <div class="my_table_parameters_infoText">{{$t('1294')}}</div>
                            </div>-->
        <!--<div class="my_div_minWidth_100 my_div_maginleft_5">
        <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{pulley.pulley.blockNumber}}</span></div>
        <div class="my_table_parameters_infoText">ID</div>
    </div>-->
        <!--<div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{pulley.pulley.sheaveWidth}}</span> mm</div>
                                    <div class="my_table_parameters_infoText">{{$t('1182')}}</div>
                                </div>
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{pulley.pulley.grooveDiameterMinimum}}</span> mm</div>
                                    <div class="my_table_parameters_infoText">{{$t('3038')}} {{$t('1432')}} min</div>
                                </div>
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{pulley.pulley.grooveDiameterMaximum}}</span> mm</div>
                                    <div class="my_table_parameters_infoText">{{$t('3038')}} {{$t('1432')}} max</div>
                                </div>
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{pulley.pulley.maximumLoad}}</span></div>
                                    <div class="my_table_parameters_infoText">{{$t('106')}}</div>
                                </div>
                                <div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{pulley.pulley.type}}</span></div>
                                    <div class="my_table_parameters_infoText">{{$t('407')}}</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="pulley_ArrayLength==1 && pulley_FirstSelected.pulley.onRequest==false" style="grid-column:2/-1">
                <div class="ZAfont_family ZAfont_size">
                    <span>{{$t('3038')}}: {{pulley_FirstSelected.pulley.diameter}} mm</span>
                    <span> | </span>
                    <span>{{$t('1294')}}: {{pulley_FirstSelected.pulley.grooveDistance}} mm</span>
                    <span> | </span>
                    <span>{{$t('1182')}}: {{pulley_FirstSelected.pulley.sheaveWidth}} mm</span>
                    <span> | </span>
                    <span>{{$t('3038')}} {{$t('1432')}} min: {{pulley_FirstSelected.pulley.grooveDiameterMinimum}} mm</span>
                    <span> | </span>
                    <span>{{$t('3038')}} {{$t('1432')}} max: {{pulley_FirstSelected.pulley.grooveDiameterMaximum}} mm</span>
                    <span> | </span>
                    <span>{{$t('106')}}: {{pulley_FirstSelected.pulley.maximumLoad}}</span>
                    <span> | </span>
                    <span>{{$t('407')}}: {{pulley_FirstSelected.pulley.type}}</span>
                </div>
            </div>
            <div v-if="pulley_ArrayLength==1 && pulley_FirstSelected.pulley.onRequest==true" style="grid-column:2/-1">
                <div class="ZAfont_family ZAfont_size">
                    <span>{{$t('3378')}}</span>
                    <span> | </span>
                    <span>{{$t('3038')}}: {{pulley_FirstSelected.pulley.diameter}} mm</span>
                    <span> | </span>
                    <span>{{$t('1294')}}: {{pulley_FirstSelected.pulley.grooveDistance}} mm</span>
                </div>
            </div>
        </div>
    </div>-->
    <div v-if="showFrameDeliverOptions == true">
        <div v-if="optionAssembled_Available == true" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
            <div style="justify-self:center; font-size:20px; margin-top:-5px" class="my_color_00338e"><span class="fas fa-plus"></span></div>
            <div>
                <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">
                    <span>{{$t('3384')}}</span>
                </div>
            </div>
            <div style="grid-column:2 / span 1">
                <ul class="my_frame_Option my_fontSize_1Point1 my_fontWeight_600">
                    <li style="cursor: pointer" @click="change_Selected_at_selectedAssembled(false)" v-bind:class="{active: selectedAssembled == false}">{{$t('3392')}}</li>
                    <li style="cursor: pointer" @click="change_Selected_at_selectedAssembled(true)" v-bind:class="{active: selectedAssembled == true}">{{$t('3393')}}</li>
                </ul>
            </div>
        </div>
        <div v-if="optionPulleys_Available == true" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
            <div style="justify-self:center; font-size:20px; margin-top:-5px" class="my_color_00338e"><span class="fas fa-plus"></span></div>
            <div>
                <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">
                    <span>{{$t('3385')}}</span>
                </div>
            </div>
            <div style="grid-column:2 / span 1">
                <ul class="my_frame_Option my_fontSize_1Point1 my_fontWeight_600">
                    <li style="cursor: pointer" @click="change_Selected_at_selectedPulleys(false)" v-bind:class="{active: selectedPulleys == false}">{{$t('1407')}}</li>
                    <li style="cursor: pointer" @click="change_Selected_at_selectedPulleys(true)" v-bind:class="{active: selectedPulleys == true}">{{$t('211')}}</li>
                </ul>
            </div>
        </div>
        <div v-if="selectedPulleys==true">
            <div v-if="pulley_Available==true" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
                <!--<div style="justify-self:center; font-size:20px; margin-top:-5px" class="my_color_00338e"><span class="fas fa-plus"></span></div>-->
                <!--<div>
                <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">{{$t('1062')}} </div>
            </div>-->
                <div v-if="pulley_ArrayLength>1" style="grid-column: 2 /-1">
                    <div>
                        <ul class="my_pulley">
                            <li style="cursor: pointer" v-for="pulley in pulley_Selection" @click.prevent="change_Selected_at_pulley_Selection(pulley)" v-bind:class="{active: pulley.selected==true}">
                                <div v-if="pulley.pulley.onRequest==true" style="display:grid;grid-template-columns: auto auto auto;padding: 3px; overflow:auto; width:100%; text-align:left">
                                    <div class="my_div_minWidth_100" style="margin-right:10px">
                                        <div class="my_fontSize_1Point1 ZAfont_family"><span class="my_font-weight_600">{{$t('3378')}}</span> </div>
                                    </div>
                                    <div class="my_div_minWidth_100 my_div_maginleft_5" style="margin-right:10px">
                                        <div class="my_fontSize_1Point1 ZAfont_family"><span class="my_font-weight_600">{{pulley.pulley.diameter}}</span> mm</div>
                                        <div class="my_table_parameters_infoText">{{$t('3038')}}</div>
                                    </div>
                                    <div class="my_div_minWidth_100 my_div_maginleft_5">
                                        <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{pulley.pulley.grooveDistance}}</span> mm</div>
                                        <div class="my_table_parameters_infoText">{{$t('1294')}}</div>
                                    </div>

                                </div>
                                <div v-if="pulley.pulley.onRequest==false" style="display: grid; grid-template-columns: auto auto auto auto auto auto auto; padding: 3px; overflow: auto; width: 100%; text-align: left">
                                    <div class="my_div_minWidth_100" style="margin-right:10px">
                                        <div class="my_fontSize_1Point1 ZAfont_family"><span class="my_font-weight_600">{{pulley.pulley.diameter}}</span> mm</div>
                                        <div class="my_table_parameters_infoText">{{$t('3038')}}</div>
                                    </div>
                                    <div class="my_div_minWidth_100 my_div_maginleft_5">
                                        <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{pulley.pulley.grooveDistance}}</span> mm</div>
                                        <div class="my_table_parameters_infoText">{{$t('1294')}}</div>
                                    </div>
                                    <!--<div class="my_div_minWidth_100 my_div_maginleft_5">
                                    <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{pulley.pulley.blockNumber}}</span></div>
                                    <div class="my_table_parameters_infoText">ID</div>
                                </div>-->
                                    <div class="my_div_minWidth_100 my_div_maginleft_5">
                                        <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{pulley.pulley.sheaveWidth}}</span> mm</div>
                                        <div class="my_table_parameters_infoText">{{$t('1182')}}</div>
                                    </div>
                                    <div class="my_div_minWidth_100 my_div_maginleft_5">
                                        <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{pulley.pulley.grooveDiameterMinimum}}</span> mm</div>
                                        <div class="my_table_parameters_infoText">{{$t('3038')}} {{$t('1432')}} min</div>
                                    </div>
                                    <div class="my_div_minWidth_100 my_div_maginleft_5">
                                        <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{pulley.pulley.grooveDiameterMaximum}}</span> mm</div>
                                        <div class="my_table_parameters_infoText">{{$t('3038')}} {{$t('1432')}} max</div>
                                    </div>
                                    <div class="my_div_minWidth_100 my_div_maginleft_5">
                                        <div class="my_fontSize_1Point1"><span class="my_font-weight_600">{{pulley.pulley.maximumLoad}}</span> kg</div>
                                        <div class="my_table_parameters_infoText">{{$t('106')}}</div>
                                    </div>
                                    <div class="my_div_minWidth_100 my_div_maginleft_5">
                                        <div class="my_fontSize_1Point1">
                                            <span class="my_font-weight_600" v-if="pulley.pulley_Type==null || pulley.pulley_Type.useTextKey==false">{{pulley.pulley.type}}</span>
                                            <span class="my_font-weight_600" v-if="pulley.pulley_Type!=null && pulley.pulley_Type.useTextKey==true">{{$t(pulley.pulley_Type.textKey)}}</span>
                                        </div>
                                        <div class="my_table_parameters_infoText">{{$t('407')}}</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="pulley_ArrayLength==1 && pulley_FirstSelected.pulley.onRequest==false" style="grid-column:2/-1">
                    <div class="ZAfont_family ZAfont_size">
                        <span>{{$t('3038')}}: {{pulley_FirstSelected.pulley.diameter}} mm</span>
                        <span> | </span>
                        <span>{{$t('1294')}}: {{pulley_FirstSelected.pulley.grooveDistance}} mm</span>
                        <span> | </span>
                        <span>{{$t('1182')}}: {{pulley_FirstSelected.pulley.sheaveWidth}} mm</span>
                        <span> | </span>
                        <span>{{$t('1067')}}: {{pulley_FirstSelected.pulley.numberOfGrooves}}</span>
                        <span> x {{ValueDecimalSeparator(pulley_FirstSelected.pulley.grooveDiameterMinimum)}}</span>
                        <span v-if="pulley_FirstSelected.pulley.grooveDiameterMaximum==pulley_FirstSelected.pulley.grooveDiameterMinimum"> mm</span>
                        <span v-if="pulley_FirstSelected.pulley.grooveDiameterMaximum!=pulley_FirstSelected.pulley.grooveDiameterMinimum"> / {{ValueDecimalSeparator(pulley_FirstSelected.pulley.grooveDiameterMaximum)}} mm</span>
                        <span> | </span>
                        <span>{{$t('106')}}: {{pulley_FirstSelected.pulley.maximumLoad}} kg</span>
                        <span> | </span>
                        <span v-if="pulley_FirstSelected.pulley_Type==null || pulley_FirstSelected.pulley_Type.useTextKey==false">{{$t('407')}}: {{pulley_FirstSelected.pulley.type}}</span>
                        <span v-if="pulley_FirstSelected.pulley_Type!=null && pulley_FirstSelected.pulley_Type.useTextKey==true">{{$t('407')}}: {{$t(pulley_FirstSelected.pulley_Type.textKey)}}</span>

                    </div>
                </div>
                <div v-if="pulley_ArrayLength==1 && pulley_FirstSelected.pulley.onRequest==true" style="grid-column:2/-1">
                    <div class="ZAfont_family ZAfont_size">
                        <span>{{$t('3378')}}</span>
                        <span> | </span>
                        <span>{{$t('3038')}}: {{pulley_FirstSelected.pulley.diameter}} mm</span>
                        <span> | </span>
                        <span>{{$t('1294')}}: {{pulley_FirstSelected.pulley.grooveDistance}} mm</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="optionRetrofit_Available == true" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
            <div style="justify-self:center; font-size:20px; margin-top:-5px" class="my_color_00338e"><span class="fas fa-plus"></span></div>
            <div>
                <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">
                    <span>{{$t('3386')}}</span>
                </div>
            </div>
            <div style="grid-column:2 / span 1">
                <ul class="my_frame_Option my_fontSize_1Point1 my_fontWeight_600">
                    <li style="cursor: pointer" @click="change_Selected_at_selectedRetrofit(false)" v-bind:class="{active: selectedRetrofit == false}">{{$t('1407')}}</li>
                    <li style="cursor: pointer" @click="change_Selected_at_selectedRetrofit(true)" v-bind:class="{active: selectedRetrofit == true}">{{$t('211')}}</li>
                </ul>
            </div>
        </div>
        <div v-if="optionEmergencyStopSwitch_Available == true" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
            <div style="justify-self:center; font-size:20px; margin-top:-5px" class="my_color_00338e"><span class="fas fa-plus"></span></div>
            <div>
                <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">
                    <span>{{$t('3387')}}</span>
                </div>
            </div>
            <div style="grid-column:2 / span 1">
                <ul class="my_frame_Option my_fontSize_1Point1 my_fontWeight_600">
                    <li style="cursor: pointer" @click="change_Selected_at_selectedEmergencyStopSwitch(false)" v-bind:class="{active: selectedEmergencyStopSwitch == false}">{{$t('1407')}}</li>
                    <li style="cursor: pointer" @click="change_Selected_at_selectedEmergencyStopSwitch(true)" v-bind:class="{active: selectedEmergencyStopSwitch == true}">{{$t('211')}}</li>
                </ul>
            </div>
        </div>
        <div v-if="optionRopeGuard_Available == true" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
            <div style="justify-self:center; font-size:20px; margin-top:-5px" class="my_color_00338e"><span class="fas fa-plus"></span></div>
            <div>
                <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">
                    <span>{{$t('3388')}}</span>
                </div>
            </div>
            <div style="grid-column:2 / span 1">
                <ul class="my_frame_Option my_fontSize_1Point1 my_fontWeight_600">
                    <li style="cursor: pointer" @click="change_Selected_at_selectedRopeGuard(false)" v-bind:class="{active: selectedRopeGuard == false}">{{$t('1407')}}</li>
                    <li style="cursor: pointer" @click="change_Selected_at_selectedRopeGuard(true)" v-bind:class="{active: selectedRopeGuard == true}">{{$t('211')}}</li>
                </ul>
            </div>
        </div>
        <!--<div v-if="optionIsolation_Available == true" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
        <div style="justify-self:center; font-size:20px; margin-top:-5px" class="my_color_00338e"><span class="fas fa-plus"></span></div>
        <div>
            <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">
                <span>{{$t('3389')}}</span>
            </div>
        </div>
        <div style="grid-column:2 / span 1">
            <ul class="my_frame_Option my_fontSize_1Point1 my_fontWeight_600">
                <li style="cursor: pointer" @click="change_Selected_at_selectedIsolation(false)" v-bind:class="{active: selectedIsolation == false}">{{$t('1407')}}</li>
                <li style="cursor: pointer" @click="change_Selected_at_selectedIsolation(true)" v-bind:class="{active: selectedIsolation == true}">{{$t('211')}}</li>
            </ul>
        </div>
    </div>-->
        <!--<div v-if="optionOperatingInstruction_Available == true" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
        <div style="justify-self:center; font-size:20px; margin-top:-5px" class="my_color_00338e"><span class="fas fa-plus"></span></div>
        <div>
            <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">
                <span>{{$t('3390')}}</span>
            </div>
        </div>
        <div style="grid-column:2 / span 1">
            <ul class="my_frame_Option my_fontSize_1Point1 my_fontWeight_600">-->
        <!--<li style="cursor: pointer" @click="change_Selected_at_selectedOperatingInstructions(false)" v-bind:class="{active: selectedOperatingInstructions == false}">{{$t('3394')}}</li>-->
        <!--<li style="cursor: pointer" @click="change_Selected_at_selectedOperatingInstructions(true)" v-bind:class="{active: selectedOperatingInstructions == true}">{{$t('3395')}}</li>
            </ul>
        </div>
    </div>
    <div v-if="selectedOperatingInstructions == true" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
        <div style="justify-self:center; font-size:20px; margin-top:-5px" class="my_color_00338e"><span class="fas fa-plus"></span></div>
        <div>
            <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">
                <span>{{$t('3391')}}</span>
            </div>
        </div>
        <div style="grid-column:2 / span 1">
            <ul class="my_frame_Option my_fontSize_1Point1 my_fontWeight_600">
                <li style="cursor: pointer" v-for="operatingInstructionLanguage in operatingInstructionLanguage_Selection" @click.prevent="change_Selected_at_operatingInstructionLanguage_Selection(operatingInstructionLanguage)" v-bind:class="{active: operatingInstructionLanguage.selected==true}">
                    {{$t(operatingInstructionLanguage.textKey)}}
                </li>
            </ul>

        </div>
    </div>-->
        <div v-if="optionMotorConsole_Available == true" style="display: grid; grid-template-columns: 40px auto; align-items:center; margin-top:15px">
            <div style="justify-self:center; font-size:20px; margin-top:-5px" class="my_color_00338e"><span class="fas fa-plus"></span></div>
            <div>
                <div class="my_fontSize_1Point5 my_color_00338e ZAfont_family">
                    <span>{{$t('3396')}}</span>
                </div>
            </div>
            <div style="grid-column:2 / span 1">
                <ul class="my_frame_Option my_fontSize_1Point1 my_fontWeight_600">
                    <li style="cursor: pointer" v-for="motorConsole in motorConsole_Selection" @click.prevent="change_Selected_at_motorConsole_Selection(motorConsole)" v-bind:class="{active: motorConsole.selected==true}">
                        {{$t(motorConsole.textKey)}}
                    </li>
                </ul>

            </div>
        </div>
    </div>
        <div class="my_btn_nextConfigurationStep_position">
            <button @click="furtherToSummary()" class="btn my_btn_nextConfigurationStep" :disabled="navigation.summary=='disabled'">{{$t('1023')}}</button>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import router from '../../../router';
    import LoaderIcon from '../../loader/LoaderIcon';
    import InvalidInputs from '../../configuration/InvalidInputs'
    import InvalidityReasons from '../../dataValidation/InvalidityReasons';
    import Images from '../../configuration/Images';
    export default {
        name: 'Frames',

        components: {
            LoaderIcon,
            InvalidInputs,
            InvalidityReasons,
            Images,
        },
        //created() {
        //    this.$store.dispatch('frames/get_pulley_Informations_storeAction');
        //    this.$store.dispatch('frames/get_frameDeliverOptions_Informations_storeAction');
        //},
        computed: {
            configurationSteps: {
                get() {
                    return this.$store.state.configurations.configurationSteps;
                },
            },
            configurationOverviewsId: {
                get() {
                    return this.$store.state.configurations.configurationOverviewsId;
                },
            },

            // pulley
            pulley_Selection: {
                get() {
                    if ((this.$store.state.frames.pulley_Data.hasOwnProperty('pulley_Selection')) && (Array.isArray(this.$store.state.frames.pulley_Data.pulley_Selection))) {
                        return this.$store.state.frames.pulley_Data.pulley_Selection;
                    }
                    else {
                        return [];
                    }
                },
            },

            pulley_FirstSelected: {
                get() {
                    if ((this.$store.state.frames.pulley_Data.hasOwnProperty('pulley_Selection')) && (Array.isArray(this.$store.state.frames.pulley_Data.pulley_Selection))) {
                        return this.$store.state.frames.pulley_Data.pulley_Selection[0];
                    }
                    else {
                        return [];
                    }
                },
            },

            pulley_ArrayLength: {
                get() {
                    if ((this.$store.state.frames.pulley_Data.hasOwnProperty('pulley_Selection')) && (Array.isArray(this.$store.state.frames.pulley_Data.pulley_Selection))) {
                        return this.$store.state.frames.pulley_Data.pulley_Selection.length;
                    }
                    else {
                        return 0;
                    }
                },
            },

            pulley_Available: {
                get() {
                    if (this.$store.state.frames.frameOptionsAvailable != undefined && this.$store.state.frames.frameOptionsAvailable.hasOwnProperty('pulleys')) {
                        return this.$store.state.frames.frameOptionsAvailable.pulleys;
                    }
                    else {
                        return false;
                    }
                },
            },

            // frame deliver options
            showFrameDeliverOptions: {
                get() {
                    if (this.$store.state.frames.frameDeliverOptions_Data.hasOwnProperty('selectedAssembled')) {
                        return true;
                    }
                    else {
                        return false;
                    }
                },
            },

            selectedAssembled: {
                get() {
                    if (this.$store.state.frames.frameDeliverOptions_Data != undefined && this.$store.state.frames.frameDeliverOptions_Data.hasOwnProperty('selectedAssembled')) {
                        return this.$store.state.frames.frameDeliverOptions_Data.selectedAssembled;
                    }
                    else {
                        return false;
                    }
                },
            },
            selectedEmergencyStopSwitch: {
                get() {
                    if (this.$store.state.frames.frameDeliverOptions_Data != undefined && this.$store.state.frames.frameDeliverOptions_Data.hasOwnProperty('selectedEmergencyStopSwitch')) {
                        return this.$store.state.frames.frameDeliverOptions_Data.selectedEmergencyStopSwitch;
                    }
                    else {
                        return false;
                    }
                },
            },
            selectedIsolation: {
                get() {
                    if (this.$store.state.frames.frameDeliverOptions_Data != undefined && this.$store.state.frames.frameDeliverOptions_Data.hasOwnProperty('selectedIsolation')) {
                        return this.$store.state.frames.frameDeliverOptions_Data.selectedIsolation;
                    }
                    else {
                        return false;
                    }
                },
            },
            selectedOperatingInstructions: {
                get() {
                    if (this.$store.state.frames.frameDeliverOptions_Data != undefined && this.$store.state.frames.frameDeliverOptions_Data.hasOwnProperty('selectedOperatingInstructions')) {
                        return this.$store.state.frames.frameDeliverOptions_Data.selectedOperatingInstructions;
                    }
                    else {
                        return false;
                    }
                },
            },
            selectedPulleys: {
                get() {
                    if (this.$store.state.frames.frameDeliverOptions_Data != undefined && this.$store.state.frames.frameDeliverOptions_Data.hasOwnProperty('selectedPulleys')) {
                        return this.$store.state.frames.frameDeliverOptions_Data.selectedPulleys;
                    }
                    else {
                        return false;
                    }
                },
            },
            selectedRetrofit: {
                get() {
                    if (this.$store.state.frames.frameDeliverOptions_Data != undefined && this.$store.state.frames.frameDeliverOptions_Data.hasOwnProperty('selectedRetrofit')) {
                        return this.$store.state.frames.frameDeliverOptions_Data.selectedRetrofit;
                    }
                    else {
                        return false;
                    }
                },
            },
            selectedRopeGuard: {
                get() {
                    if (this.$store.state.frames.frameDeliverOptions_Data != undefined && this.$store.state.frames.frameDeliverOptions_Data.hasOwnProperty('selectedRopeGuard')) {
                        return this.$store.state.frames.frameDeliverOptions_Data.selectedRopeGuard;
                    }
                    else {
                        return false;
                    }
                },
            },
            operatingInstructionLanguage_Selection: {
                get() {
                    if (this.$store.state.frames.frameOperatingInstruction_Selection != undefined) {
                        return this.$store.state.frames.frameOperatingInstruction_Selection;
                    }
                    else {
                        return [];
                    }
                },
            },
            motorConsole_Selection: {
                get() {
                    if (this.$store.state.frames.frameMotorConsole_Selection != undefined) {
                        return this.$store.state.frames.frameMotorConsole_Selection;
                    }
                    else {
                        return [];
                    }
                },
            },
            optionAssembled_Available: {
                get() {
                    if (this.$store.state.frames.frameOptionsAvailable != undefined) {
                        return this.$store.state.frames.frameOptionsAvailable.assebled;
                    }
                    else {
                        return false;
                    }
                },
            },
            optionPulleys_Available: {
                get() {
                    if (this.$store.state.frames.frameOptionsAvailable != undefined) {
                        return this.$store.state.frames.frameOptionsAvailable.pulleys;
                    }
                    else {
                        return false;
                    }
                },
            },
            optionRetrofit_Available: {
                get() {
                    if (this.$store.state.frames.frameOptionsAvailable != undefined) {
                        return this.$store.state.frames.frameOptionsAvailable.retrofit;
                    }
                    else {
                        return false;
                    }
                },
            },
            optionEmergencyStopSwitch_Available: {
                get() {
                    if (this.$store.state.frames.frameOptionsAvailable != undefined) {
                        return this.$store.state.frames.frameOptionsAvailable.emergencyStopSwitch;
                    }
                    else {
                        return false;
                    }
                },
            },
            optionRopeGuard_Available: {
                get() {
                    if (this.$store.state.frames.frameOptionsAvailable != undefined) {
                        return this.$store.state.frames.frameOptionsAvailable.ropeGuard;
                    }
                    else {
                        return false;
                    }
                },
            },
            optionIsolation_Available: {
                get() {
                    if (this.$store.state.frames.frameOptionsAvailable != undefined) {
                        return this.$store.state.frames.frameOptionsAvailable.isolation;
                    }
                    else {
                        return false;
                    }
                },
            },
            optionOperatingInstruction_Available: {
                get() {
                    if (this.$store.state.frames.frameOptionsAvailable != undefined) {
                        return this.$store.state.frames.frameOptionsAvailable.operatingInstructions;
                    }
                    else {
                        return false;
                    }
                },
            },
            optionMotorConsole_Available: {
                get() {
                    if (this.$store.state.frames.frameOptionsAvailable != undefined) {
                        return this.$store.state.frames.frameOptionsAvailable.motorConsole;
                    }
                    else {
                        return false;
                    }
                },
            },
            frameInformation: {
                get() {
                    return this.$store.state.frames.frameInformation;
                }
            },
            navigation: {
                get() {
                    return this.$store.state.configurations.navigation;
                },
            },

        },
        created() {
            if (this.configurationOverviewsId != null) {
                this.$store.dispatch('configurations/check_toDos_at_frames_storeAction')
            }
            else {
                this.$store.dispatch('configurations/toDos_after_routeSlug_UpdatedOrEntered_storeAction', 'frames')
            }
        },
        methods: {
            ValueDecimalSeparator(value) {
                if (value != null) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                }
                return value;
            },
            change_Selected_at_pulley_Selection(value) {
                this.$store.dispatch('frames/checkIf_changesAt_pulley_changes_configurationFlow_storeAction', value);
            },
            change_Selected_at_operatingInstructionLanguage_Selection(value) {
                this.$store.dispatch('frames/checkIf_changesAt_operatingInstructionLanguage_changes_configurationFlow_storeAction', value);
            },
            change_Selected_at_motorConsole_Selection(value) {
                this.$store.dispatch('frames/checkIf_changesAt_motorConsole_changes_configurationFlow_storeAction', value);
            },

            // frame deliver optins
            change_Selected_at_selectedAssembled(value) {
                this.$store.dispatch('frames/change_selectedAssembled_storeAction', value);
            },
            change_Selected_at_selectedEmergencyStopSwitch(value) {
                this.$store.dispatch('frames/change_selectedEmergencyStopSwitch_storeAction', value);
            },
            change_Selected_at_selectedIsolation(value) {
                this.$store.dispatch('frames/change_selectedIsolation_storeAction', value);
            },
            change_Selected_at_selectedOperatingInstructions(value) {
                this.$store.dispatch('frames/change_selectedOperatingInstructions_storeAction', value);
            },
            change_Selected_at_selectedPulleys(value) {
                this.$store.dispatch('frames/change_selectedPulleys_storeAction', value);
            },
            change_Selected_at_selectedRetrofit(value) {
                this.$store.dispatch('frames/change_selectedRetrofit_storeAction', value);
            },
            change_Selected_at_selectedRopeGuard(value) {
                this.$store.dispatch('frames/change_selectedRopeGuard_storeAction', value);
            },
            furtherToSummary() {
                router.push({ params: { slug: 'summary' } });
            },

        },
        mounted() {
            window.addEventListener('resize', this.changeWindowSize);
        },
        unmounted() {
            window.removeEventListener('resize', this.changeWindowSize);
        },
    }</script>

<style>

    .my_frame_filter {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_frame_filter li {
            border: 2px solid #00338e;
            color: #00338e;
            display: inline-block;
            padding: 9px;
            width: 50%;
            text-align: center;
            font-weight: 600;
        }

        .my_frame_filter .active {
            background-color: #00338e;
            color: #ffffff;
        }


    .my_frame {
        list-style: none;
        margin: 0;
        padding: 0;
    }

        .my_frame li {
            display: block;
            border: 1px solid #ced2d8;
            margin-bottom: 15px;
        }

        .my_frame .active {
            border: 2px solid blue;
        }

    .my_pulley {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_pulley li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            margin-bottom: 10px;
        }

            .my_pulley li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_pulley li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_pulley .active {
            border: 2px solid blue;
        }

    .my_frame_Option {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_frame_Option li {
            border: 1px solid #ced2d8;
            color: #000000;
            display: inline-block;
            padding: 9px;
            text-align: center;
            margin-bottom: 10px;
        }

            .my_frame_Option li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_frame_Option li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }

        .my_frame_Option .active {
            border: 2px solid blue;
        }

    .my_table_parameters_infoText {
        font-size: 0.9rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        width: calc(100%);
    }

        .my_table_parameters_infoText:hover {
            text-overflow: clip;
            white-space: normal;
            word-break: break-word;
        }
</style>
