<template>
    <div style="overflow-x:auto !important">
        <div>
            <div style="overflow:auto">
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">CMD:</div>
                    </div>
                    <div>
                        <input v-model="cmd" class="form-control" />
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3334')}}:</div>
                    </div>
                    <div>
                        <input v-model="note" class="form-control" />
                        <!--<InvalidityReasons v-if="quantity_ValidationResult.invalid==true" v-bind:validationResult="quantity_ValidationResult"></InvalidityReasons>-->
                    </div>
                </div><div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('1046')}}:</div>
                    </div>
                    <div>
                        <input v-model="projekt" class="form-control" />
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3587')}}:</div>
                    </div>
                    <div>
                        <input v-model="sanum" class="form-control" />
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3545')}}:</div>
                    </div>
                    <div>
                        <input v-model="onok" class="form-control" />
                        <!--<input v-model="onok" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control" v-bind:class="{my_input_border_at_invalidInput: onok_ValidationResult.invalid==true, my_input_background_color_at_validInput: onok_ValidationResult.invalid==false}" />
                        <InvalidityReasons v-if="onok_ValidationResult.invalid==true" v-bind:validationResult="onok_ValidationResult"></InvalidityReasons>-->
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3546')}}:</div>
                    </div>
                    <div>
                        <input v-model="inok" class="form-control" />
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('170')}}:</div>
                    </div>
                    <div>
                        <input v-model="mvlift" class="form-control" />
                        <!--<input v-model="mvlift" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control" v-bind:class="{my_input_border_at_invalidInput: mvlift_ValidationResult.invalid==true, my_input_background_color_at_validInput: mvlift_ValidationResult.invalid==false}" />
    <InvalidityReasons v-if="mvlift_ValidationResult.invalid==true" v-bind:validationResult="mvlift_ValidationResult"></InvalidityReasons>-->
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('1055')}}:</div>
                    </div>
                    <div>
                        <input v-model="mis" class="form-control" />
                        <!--<input v-model="mis" v-bind:placeholder="this.$i18n.i18next.t('3172')" class="form-control" v-bind:class="{my_input_border_at_invalidInput: mis_ValidationResult.invalid==true, my_input_background_color_at_validInput: mis_ValidationResult.invalid==false}" />
    <InvalidityReasons v-if="mis_ValidationResult.invalid==true" v-bind:validationResult="mis_ValidationResult"></InvalidityReasons>-->
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3452')}}:</div>
                    </div>
                    <div>
                        <select v-model="selectedCustomerName" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: customerName==null, my_input_background_color_at_validInput:customerName!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option v-for="name in customerNameSelection" v-bind:value="name">
                                <span v-if="name.text.useTextKey == false">{{name.text.textUI}}</span>
                                <span v-if="name.text.useTextKey == true">{{$t(name.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                </div>
                <hr />
            </div>
            <div style="overflow:auto">
                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('1098')}}</div>
                <!--<div style="display: grid; grid-template-columns: 2fr 1fr;">
                <div>-->
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size"></div>
                    </div>
                    <div class="ZAfont_family ZAfont_size" style="display: grid; margin-bottom: 10px">
                        <div>
                            <input type="checkbox" v-model="SM250_TS280" />
                            <span style="margin-left:10px">SM250 TS280</span>
                        </div>
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3455')}}:</div>
                    </div>
                    <div v-if="certificationSelection.length==0 || certificationSelection.length>4">
                        <select v-model="selectedCertification" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selectedCertification==null, my_input_background_color_at_validInput:selectedCertification!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="certification in certificationSelection" v-bind:value="certification">
                                <span v-if="certification.text.useTextKey == false">{{certification.text.textUI}}</span>
                                <span v-if="certification.text.useTextKey == true">{{$t(certification.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="certificationSelection.length>0 && certificationSelection.length<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedCertification==null, my_input_background_color_at_validInput:selectedCertification!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="certification in certificationSelection">
                                    <input type="checkbox" @input="change_Selected_atElementOf_certification(certification)" v-bind:checked="certification.selected" v-bind:disabled="certification.selected" />
                                    <span v-if="certification.text.useTextKey == false" style="margin-left:10px">{{certification.text.textUI}}</span>
                                    <span v-if="certification.text.useTextKey == true" style="margin-left:10px">{{$t(certification.text.textKey)}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3456')}}:</div>
                    </div>
                    <div v-if="preferenceSelection.length==0 || preferenceSelection.length>4">
                        <select v-model="selectedPreference" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selectedCertification==null, my_input_background_color_at_validInput:selectedPreference!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="preference in preferenceSelection" v-bind:value="preference">
                                <span v-if="preference.text.useTextKey == false">{{preference.text.textUI}}</span>
                                <span v-if="preference.text.useTextKey == true">{{$t(preference.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="preferenceSelection.length>0 && preferenceSelection.length<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedCertification==null, my_input_background_color_at_validInput:selectedPreference!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="preference in preferenceSelection">
                                    <input type="checkbox" @input="change_Selected_atElementOf_preference(preference)" v-bind:checked="preference.selected" v-bind:disabled="preference.selected" />
                                    <span v-if="preference.text.useTextKey == false" style="margin-left:10px">{{preference.text.textUI}}</span>
                                    <span v-if="preference.text.useTextKey == true" style="margin-left:10px">{{$t(preference.text.textKey)}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3067')}}:</div>
                    </div>
                    <div v-if="temperatureMonitoringSelection.length==0 || temperatureMonitoringSelection.length>4">
                        <select v-model="selectedTemperatureMonitoring" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selectedTemperatureMonitoring==null, my_input_background_color_at_validInput:selectedTemperatureMonitoring!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="temperatureMonitoring in temperatureMonitoringSelection" v-bind:value="temperatureMonitoring.text">{{temperatureMonitoring.text}}</option>
                        </select>
                    </div>
                    <div v-if="temperatureMonitoringSelection.length>0 && temperatureMonitoringSelection.length<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedTemperatureMonitoring==null, my_input_background_color_at_validInput:selectedTemperatureMonitoring!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="temperatureMonitoring in temperatureMonitoringSelection">
                                    <input type="checkbox" @input="change_Selected_atElementOf_temperatureMonitoring(temperatureMonitoring)" v-bind:checked="temperatureMonitoring.selected" v-bind:disabled="temperatureMonitoring.selected" />
                                    <span style="margin-left:10px">{{temperatureMonitoring.text}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('407')}}:</div>
                    </div>
                    <div v-if="motorTypeSelectionLength==0 || motorTypeSelectionLength>4">
                        <select v-model="selectedMotorType" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selectedMotorType==null, my_input_background_color_at_validInput:selectedMotorType!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="motorType in motorTypeSelection" v-bind:value="motorType">
                                <span v-if="motorType.text.useTextKey == false">{{motorType.text.textUI}}</span>
                                <span v-if="motorType.text.useTextKey == true">{{$t(motorType.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="motorTypeSelectionLength>0 && motorTypeSelectionLength<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedMotorType==null, my_input_background_color_at_validInput:selectedMotorType!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="motorType in motorTypeSelection">
                                    <input type="checkbox" @input="change_Selected_atElementOf_motorType(motorType)" v-bind:checked="motorType.selected" v-bind:disabled="motorType.selected" />
                                    <span style="margin-left:10px">{{motorType.text.textUI}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3453')}}:</div>
                    </div>
                    <div v-if="motorSizeSelectionLength==0 || motorSizeSelectionLength>4">
                        <select v-model="selectedMotorSize" class="my_select" style="width:100%" v-bind:disabled="selectedMotorType==null || selectedMotorType.text.text==null" v-bind:class="{my_input_border_at_invalidInput: selectedMotorSize==null, my_input_background_color_at_validInput:selectedMotorSize!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="motorSize in motorSizeSelection" v-bind:value="motorSize" v-if="motorSize.disabled==false">
                                <span v-if="motorSize.text.useTextKey == false">{{motorSize.text.textUI}}</span>
                                <span v-if="motorSize.text.useTextKey == true">{{$t(motorSize.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="motorSizeSelectionLength>0 && motorSizeSelectionLength<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedMotorSize==null, my_input_background_color_at_validInput:selectedMotorSize!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="motorSize in motorSizeSelection" v-if="motorSize.disabled==false">
                                    <input type="checkbox" @input="change_Selected_atElementOf_motorSize(motorSize)" v-bind:checked="motorSize.selected" v-bind:disabled="motorSize.selected" />
                                    <span style="margin-left:10px">{{motorSize.text.textUI}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3454')}}:</div>
                    </div>
                    <div v-if="motorPackageSelectionLength==0 || motorPackageSelectionLength>4">
                        <select v-model="selectedMotorPackage" class="my_select" style="width:100%" v-bind:disabled="selectedMotorSize==null || selectedMotorSize.text.text==null" v-bind:class="{my_input_border_at_invalidInput: selectedMotorPackage==null, my_input_background_color_at_validInput:selectedMotorPackage!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="motorPackage in motorPackageSelection" v-bind:value="motorPackage" v-if="motorPackage.disabled==false">
                                <span v-if="motorPackage.text.useTextKey == false">{{motorPackage.text.textUI}}</span>
                                <span v-if="motorPackage.text.useTextKey == true">{{$t(motorPackage.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="motorPackageSelectionLength>0 && motorPackageSelectionLength<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedMotorPackage==null, my_input_background_color_at_validInput:selectedMotorPackage!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="motorPackage in motorPackageSelection" v-if="motorPackage.disabled==false">
                                    <input type="checkbox" @input="change_Selected_atElementOf_motorPackage(motorPackage)" v-bind:checked="motorPackage.selected" v-bind:disabled="motorPackage.selected" />
                                    <span style="margin-left:10px">{{motorPackage.text.textUI}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="motorZusatzSelection!=null" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">Zusatz:</div>
                    </div>
                    <div v-if="motorZusatzSelectionLength==0 || motorZusatzSelectionLength>4">
                        <select v-model="selectedMotorZusatz" class="my_select" style="width:100%" v-bind:disabled="selectedMotorPackage==null || selectedMotorPackage.text.text==null" v-bind:class="{my_input_border_at_invalidInput: motorZusatzSelection.length > 0 && selectedMotorPackage==null, my_input_background_color_at_validInput: motorZusatzSelection.length == 0 || selectedMotorPackage!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="motorZusatz in motorZusatzSelection" v-bind:value="motorZusatz" v-if="motorZusatz.disabled==false">
                                <span v-if="motorZusatz.text.useTextKey == false">{{motorZusatz.text.textUI}}</span>
                                <span v-if="motorZusatz.text.useTextKey == true">{{$t(motorZusatz.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="motorZusatzSelectionLength>0 && motorZusatzSelectionLength<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput:motorZusatzSelectionLength > 0 && selectedMotorPackage==null, my_input_background_color_at_validInput: motorZusatzSelectionLength == 0 || selectedMotorPackage!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="motorZusatz in motorZusatzSelection" v-if="motorZusatz.disabled==false">
                                    <input type="checkbox" @input="change_Selected_atElementOf_motorZusatz(motorZusatz)" v-bind:checked="motorZusatz.selected" v-bind:disabled="motorZusatz.selected && motorZusatzNecessary==true" />
                                    <span style="margin-left:10px">{{motorZusatz.text.textUI}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('121')}}:</div>
                    </div>
                    <div v-if="motorVoltageSelectionLength==0 || motorVoltageSelectionLength>4">
                        <select v-model="selectedMotorVoltage" class="my_select" style="width:100%" v-bind:disabled="((selectedMotorPackage==null || selectedMotorPackage.text.text==null)) || (motorZusatzSelection!=null && (selectedMotorZusatz==null || selectedMotorZusatz.text.text==null) && motorZusatzNecessary==true)" v-bind:class="{my_input_border_at_invalidInput: selectedMotorVoltage==null, my_input_background_color_at_validInput:selectedMotorVoltage!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="motorVoltage in motorVoltageSelection" v-bind:value="motorVoltage" v-if="motorVoltage.disabled==false">{{motorVoltage.value}}</option>
                        </select>
                    </div>
                    <div v-if="motorVoltageSelectionLength>0 && motorVoltageSelectionLength<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedMotorVoltage==null, my_input_background_color_at_validInput:selectedMotorVoltage!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="motorVoltage in motorVoltageSelection" v-if="motorVoltage.disabled==false">
                                    <input type="checkbox" @input="change_Selected_atElementOf_motorVoltage(motorVoltage)" v-bind:checked="motorVoltage.selected" v-bind:disabled="motorVoltage.selected" />
                                    <span style="margin-left:10px">{{motorVoltage.value}} V</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('1091')}}:</div>
                    </div>
                    <div v-if="motorRatedSpeedSelectionLength==0 || motorRatedSpeedSelectionLength>4">
                        <select v-model="selectedMotorRatedSpeed" class="my_select" style="width:100%" v-bind:disabled="selectedMotorVoltage==null || selectedMotorVoltage.value==null" v-bind:class="{my_input_border_at_invalidInput: selectedMotorRatedSpeed==null, my_input_background_color_at_validInput:selectedMotorRatedSpeed!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="motorRatedSpeed in motorRatedSpeedSelection" v-bind:value="motorRatedSpeed" v-if="motorRatedSpeed.disabled==false">{{motorRatedSpeed.value}}</option>
                        </select>
                    </div>
                    <div v-if="motorRatedSpeedSelectionLength>0 && motorRatedSpeedSelectionLength<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedMotorRatedSpeed==null, my_input_background_color_at_validInput:selectedMotorRatedSpeed!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="motorRatedSpeed in motorRatedSpeedSelection" v-if="motorRatedSpeed.disabled==false">
                                    <input type="checkbox" @input="change_Selected_atElementOf_motorRatedSpeed(motorRatedSpeed)" v-bind:checked="motorRatedSpeed.selected" v-bind:disabled="motorRatedSpeed.selected" />
                                    <span style="margin-left:10px">{{motorRatedSpeed.value}} rpm</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3457')}}:</div>
                        </div>
                        <div v-if="upgradeSelection.length==0 || upgradeSelection.length>4">
                            <select v-model="selectedUpgrade" class="my_select" style="width:100%" v-bind:disabled="selectedMotorVoltage==null || selectedMotorVoltage.value==null" v-bind:class="{my_input_border_at_invalidInput: selectedUpgrade==null, my_input_background_color_at_validInput:selectedUpgrade!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="upgrade in upgradeSelection" v-bind:value="upgrade.text" v-bind:disabled="upgrade.disabled">{{upgrade.text}}</option>
                            </select>
                        </div>
                        <div v-if="upgradeSelection.length>0 && upgradeSelection.length<=4" style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedUpgrade==null, my_input_background_color_at_validInput:selectedUpgrade!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="upgrade in upgradeSelection" v-if="upgrade.display!=null && upgrade.display==true">
                                    <input type="checkbox" @input="change_Selected_atElementOf_upgradeSelection(upgrade)" v-bind:checked="upgrade.selected" v-bind:disabled="upgrade.disabled" />
                                    <span style="margin-left:10px">{{upgrade.text}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('298')}}:</div>
                    </div>
                    <div v-if="forcedVentilationSelection.length==0 && forcedVentilationSelection.length>4">
                        <select v-model="selectedForcedVentilation" class="my_select" style="width:100%" v-bind:disabled="selectedMotorVoltage==null || selectedMotorVoltage.value==null" v-bind:class="{my_input_border_at_invalidInput: selectedForcedVentilation==null, my_input_background_color_at_validInput:selectedForcedVentilation!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="forcedVentilation in forcedVentilationSelection" v-bind:value="forcedVentilation">
                                <span v-if="forcedVentilation.text.useTextKey==false">{{forcedVentilation.text.textUI}}</span>
                                <span v-if="forcedVentilation.text.useTextKey==true">{{$t(forcedVentilation.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="forcedVentilationSelection.length>0 && forcedVentilationSelection.length<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedForcedVentilation==null, my_input_background_color_at_validInput:selectedForcedVentilation!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="forcedVentilation in forcedVentilationSelection">
                                    <input type="checkbox" @input="change_Selected_atElementOf_forcedVentilation(forcedVentilation)" v-bind:checked="forcedVentilation.selected" v-bind:disabled="forcedVentilation.selected" />
                                    <span v-if="forcedVentilation.text.useTextKey == false" style="margin-left:10px">{{forcedVentilation.text.textUI}}</span>
                                    <span v-if="forcedVentilation.text.useTextKey == true" style="margin-left:10px">{{$t(forcedVentilation.text.textKey)}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="selectedForcedVentilationWithBrakeRelease!=null" class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>{{$t('3553')}}</div>
                    <div>
                        <div>
                            <span v-if="selectedForcedVentilationWithBrakeRelease.useTextKey==false">{{selectedForcedVentilationWithBrakeRelease.textUI}} </span>
                            <span v-if="selectedForcedVentilationWithBrakeRelease.useTextKey==true">{{$t(selectedForcedVentilationWithBrakeRelease.textKey)}} </span>
                        </div>
                    </div>
                </div>
                <!--<div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div class="ZAfont_family ZAfont_size">
                        <div>{{$t('3049')}} 1:</div>
                    </div>
                    <div>
                        <input class="form-control" v-bind:placeholder="this.$i18n.i18next.t('3173')" v-model="operatingCurrentSplus" v-bind:disabled="customerName!='S+'" />
                    </div>
                </div>-->
                <!--</div>-->
                <!--<div style="margin-left:50px">
                        <iframe src="https://za.io/products/drive-technology/zatop-sm315" style="height:500px;width:500px"></iframe>
                        <div>
                            <a href="https://za.io/products/drive-technology/zatop-sm315" target="_blank">ZAtop SM315</a>
                        </div>
                    </div>
                </div>-->
                <hr />
            </div>
            <div style="overflow:auto">
                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('1301')}}</div>

                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('411')}} {{$t('3395')}}:</div>
                    </div>
                    <div v-if="deliverTractionSheaveSelection.length==0 || deliverTractionSheaveSelection.length>4">
                        <select v-model="selectedDeliverTractionSheave" class="my_select" style="width:100%" v-bind:disabled="selectedTemperatureMonitoring==null || selectedTemperatureMonitoring==''" v-bind:class="{my_input_border_at_invalidInput: selectedDeliverTractionSheave==null, my_input_background_color_at_validInput:selectedDeliverTractionSheave!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="deliver in deliverTractionSheaveSelection" v-bind:value="deliver">
                                <span v-if="deliver.text.useTextKey==null || deliver.text.useTextKey==false">{{deliver.text.textUI}}</span>
                                <span v-if="deliver.text.useTextKey!=null && deliver.text.useTextKey==true">{{$t(deliver.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="deliverTractionSheaveSelection.length>0 && deliverTractionSheaveSelection.length<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedDeliverTractionSheave==null, my_input_background_color_at_validInput:selectedDeliverTractionSheave!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="deliver in deliverTractionSheaveSelection">
                                    <input type="checkbox" @input="change_Selected_atElementOf_deliverTractionSheave(deliver)" v-bind:checked="deliver.selected" v-bind:disabled="deliver.selected" />
                                    <span v-if="deliver.text.useTextKey == false" style="margin-left:10px">{{deliver.text.textUI}}</span>
                                    <span v-if="deliver.text.useTextKey == true" style="margin-left:10px">{{$t(deliver.text.textKey)}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="showTractionSheaveDetails==true">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3460')}}:</div>
                        </div>
                        <div v-if="machiningForHandWheelSelection.length==0 || machiningForHandWheelSelection.length>4">
                            <select v-model="selectedMachiningForHandWheel" class="my_select" style="width:100%" v-bind:disabled="selectedTemperatureMonitoring==null || selectedTemperatureMonitoring==''" v-bind:class="{my_input_border_at_invalidInput: machiningForHandWheelSelection.length > 0 && selectedMachiningForHandWheel==null, my_input_background_color_at_validInput: machiningForHandWheelSelection == 0 || selectedMachiningForHandWheel!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="machiningForHandWheel in machiningForHandWheelSelection" v-bind:value="machiningForHandWheel">
                                    <span v-if="machiningForHandWheel.text.useTextKey==null || machiningForHandWheel.text.useTextKey==false">{{machiningForHandWheel.text.textUI}}</span>
                                    <span v-if="machiningForHandWheel.text.useTextKey!=null && machiningForHandWheel.text.useTextKey==true">{{$t(machiningForHandWheel.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="machiningForHandWheelSelection.length>0 && machiningForHandWheelSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: machiningForHandWheelSelection.length > 0 && selectedMachiningForHandWheel==null, my_input_background_color_at_validInput: machiningForHandWheelSelection == 0 || selectedMachiningForHandWheel!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="machiningForHandWheel in machiningForHandWheelSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_machiningForHandWheel(machiningForHandWheel)" v-bind:checked="machiningForHandWheel.selected" v-bind:disabled="machiningForHandWheel.selected" />
                                        <span v-if="machiningForHandWheel.text.useTextKey == false" style="margin-left:10px">{{machiningForHandWheel.text.textUI}}</span>
                                        <span v-if="machiningForHandWheel.text.useTextKey == true" style="margin-left:10px">{{$t(machiningForHandWheel.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3038')}} | {{$t('1182')}}:</div>
                        </div>
                        <div v-if="tractionSheave_DiameterAndWidthSelection.length==0 || tractionSheave_DiameterAndWidthSelection.length>4">
                            <select v-model="selectedTractionSheave_DiameterAndWidth" class="my_select" style="width:100%" v-bind:disabled="selectedTemperatureMonitoring==null || selectedTemperatureMonitoring==''" v-bind:class="{my_input_border_at_invalidInput: selectedTractionSheave_DiameterAndWidth==null, my_input_background_color_at_validInput:selectedTractionSheave_DiameterAndWidth!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="diameterAndWidth in tractionSheave_DiameterAndWidthSelection" v-bind:value="diameterAndWidth">{{diameterAndWidth.text}}</option>
                            </select>
                        </div>
                        <div v-if="tractionSheave_DiameterAndWidthSelection.length>0 && tractionSheave_DiameterAndWidthSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedTractionSheave_DiameterAndWidth==null, my_input_background_color_at_validInput:selectedTractionSheave_DiameterAndWidth!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="diameterAndWidth in tractionSheave_DiameterAndWidthSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_diameterAndWidth(diameterAndWidth)" v-bind:checked="diameterAndWidth.selected" v-bind:disabled="diameterAndWidth.selected" />
                                        <span style="margin-left:10px">{{diameterAndWidth.text}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('1123')}}:</div>
                        </div>
                        <div v-if="sheaveProfileSelection.length==0 || sheaveProfileSelection.length>4">
                            <select v-model="selectedSheaveProfile" class="my_select" style="width:100%" v-bind:disabled="selectedTemperatureMonitoring==null || selectedTemperatureMonitoring==''" v-bind:class="{my_input_border_at_invalidInput: selectedSheaveProfile==null, my_input_background_color_at_validInput:selectedSheaveProfile!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="sheaveProfile in sheaveProfileSelection" v-bind:value="sheaveProfile">{{sheaveProfile.text.textUI}}</option>
                            </select>
                        </div>
                        <div v-if="sheaveProfileSelection.length>0 && sheaveProfileSelection.length<=4">
                            <div v-bind:class="{my_input_border_at_invalidInput: selectedSheaveProfile==null, my_input_background_color_at_validInput:selectedSheaveProfile!=null}">
                                <ul class="my_inputCheckbox2 ZAfont_family ZAfont_size">
                                    <li v-for="sheaveProfile in sheaveProfileSelection">
                                        <div>
                                            <input type="checkbox" @input="change_Selected_atElementOf_sheaveProfile(sheaveProfile)" v-bind:checked="sheaveProfile.selected" v-bind:disabled="sheaveProfile.selected" />
                                            <span v-if="sheaveProfile.text.useTextKey == false" style="margin-left:10px">{{sheaveProfile.text.textUI}}</span>
                                            <span v-if="sheaveProfile.text.useTextKey == true" style="margin-left:10px">{{$t(sheaveProfile.text.textKey)}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('1134')}}:</div>
                        </div>
                        <div>
                            <select v-model="selectedUndercutAngle" class="my_select" style="width:100%" v-bind:disabled="undercutAngleSelectionDisabled==true || selectedTemperatureMonitoring==null || selectedTemperatureMonitoring==''" v-bind:class="{my_input_border_at_invalidInput: undercutAngleSelectionLength > 0 && selectedUndercutAngle==null, my_input_background_color_at_validInput:undercutAngleSelectionLength==0 || selectedUndercutAngle!=null}">
                                <option v-for="undercutAngle in undercutAngleSelection" v-bind:value="undercutAngle" v-if="undercutAngle.disabled==false">{{ValueDecimalSeparator(undercutAngle.value)}}</option>
                            </select>
                        </div>
                        <!--<div v-if="undercutAngleSelection.length>0 && undercutAngleSelection.length<=4">
                            <div>
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="undercutAngle in undercutAngleSelection">
                                        <div>
                                            <input type="checkbox" @input="change_Selected_atElementOf_tractionSheaveAngleUndercut(undercutAngle)" v-bind:checked="undercutAngle.selected" v-bind:disabled="undercutAngle.selected" />
                                            <span style="margin-left:10px">{{undercutAngle.angle}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>-->
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('1135')}}:</div>
                        </div>
                        <div>
                            <select v-model="selectedVgrooveAngle" class="my_select" style="width:100%" v-bind:disabled="vgrooveAngleSelectionDisabled==true ||selectedTemperatureMonitoring==null || selectedTemperatureMonitoring==''" v-bind:class="{my_input_border_at_invalidInput: vgrooveAngleSelectionLength > 0 && selectedVgrooveAngle==null, my_input_background_color_at_validInput:vgrooveAngleSelectionLength==0 || selectedVgrooveAngle!=null}">
                                <option v-for="vgrooveAngle in vgrooveAngleSelection" v-bind:value="vgrooveAngle" v-if="vgrooveAngle.disabled==false">{{vgrooveAngle.value}}</option>
                            </select>
                        </div>
                        <!--<div v-if="vgrooveAngleSelection.length>0 && vgrooveAngleSelection.length<=4">
                            <div>
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size"
                                    <li v-for="vgrooveAngle in vgrooveAngleSelection">
                                    <div>
                                        <input type="checkbox" @input="change_Selected_atElementOf_tractionSheaveAngleVgroove(vgrooveAngle)" v-bind:checked="vgrooveAngle.selected" v-bind:disabled="vgrooveAngle.selected" />
                                        <span style="margin-left:10px">{{vgrooveAngle.angle}}</span>
                                    </div>
                                    </li>
                                </ul>
                            </div>
                        </div>-->
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('1067')}}:</div>
                        </div>
                        <div v-if="ropeDiameterSelectionLength==0 || ropeDiameterSelectionLength>4">
                            <select v-model="selectedRopeDiameter" class="my_select" style="width:100%" v-bind:disabled="selectedSheaveProfile==null || selectedSheaveProfile.text.text==null" v-bind:class="{my_input_border_at_invalidInput: selectedRopeDiameter==null, my_input_background_color_at_validInput:selectedRopeDiameter!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="ropeDiameter in ropeDiameterSelection" v-bind:value="ropeDiameter" v-if="ropeDiameter.disabled==false">{{ValueDecimalSeparator(ropeDiameter.value)}}</option>
                            </select>
                        </div>
                        <div v-if="ropeDiameterSelectionLength>0 && ropeDiameterSelectionLength<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedRopeDiameter==null, my_input_background_color_at_validInput:selectedRopeDiameter!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="ropeDiameter in ropeDiameterSelection" v-if="ropeDiameter.disabled==false">
                                        <input type="checkbox" @input="change_Selected_atElementOf_ropeDiameter(ropeDiameter)" v-bind:checked="ropeDiameter.selected" v-bind:disabled="ropeDiameter.selected" />
                                        <span style="margin-left:10px">{{ValueDecimalSeparator(ropeDiameter.value)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('294')}}:</div>
                        </div>
                        <div v-if="grooveDistanceSelectionLength==0 || grooveDistanceSelectionLength>4">
                            <select v-model="selectedGrooveDistance" class="my_select" style="width:100%" v-bind:disabled="selectedRopeDiameter==null || selectedRopeDiameter.value==null" v-bind:class="{my_input_border_at_invalidInput: selectedGrooveDistance==null, my_input_background_color_at_validInput:selectedGrooveDistance!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="grooveDistance in grooveDistanceSelection" v-bind:value="grooveDistance" v-if="grooveDistance.disabled==false">{{ValueDecimalSeparator(grooveDistance.value)}}</option>
                            </select>
                        </div>
                        <div v-if="grooveDistanceSelectionLength>0 && grooveDistanceSelectionLength<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedGrooveDistance==null, my_input_background_color_at_validInput:selectedGrooveDistance!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="grooveDistance in grooveDistanceSelection" v-if="grooveDistance.disabled==false">
                                        <input type="checkbox" @input="change_Selected_atElementOf_grooveDistance(grooveDistance)" v-bind:checked="grooveDistance.selected" v-bind:disabled="grooveDistance.selected" />
                                        <span style="margin-left:10px">{{ValueDecimalSeparator(grooveDistance.value)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('165')}}:</div>
                        </div>
                        <div v-if="numberOfGroovesSelectionLength==0 || numberOfGroovesSelectionLength>4">
                            <select v-model="selectedNumberOfGrooves" class="my_select" style="width:100%" v-bind:disabled="selectedGrooveDistance==null || selectedGrooveDistance.value==null" v-bind:class="{my_input_border_at_invalidInput: selectedNumberOfGrooves==null, my_input_background_color_at_validInput:selectedNumberOfGrooves!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="numberOfGrooves in numberOfGroovesSelection" v-bind:value="numberOfGrooves" v-if="numberOfGrooves.disabled==false">{{numberOfGrooves.value}}</option>
                            </select>
                        </div>
                        <div v-if="numberOfGroovesSelectionLength>0 && numberOfGroovesSelectionLength<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedNumberOfGrooves==null, my_input_background_color_at_validInput:selectedNumberOfGrooves!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="numberOfGrooves in numberOfGroovesSelection" v-if="numberOfGrooves.disabled==false">
                                        <input type="checkbox" @input="change_Selected_atElementOf_numberOfGrooves(numberOfGrooves)" v-bind:checked="numberOfGrooves.selected" v-bind:disabled="numberOfGrooves.selected" />
                                        <span style="margin-left:10px">{{numberOfGrooves.value}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            <div style="overflow:auto">
                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('3533')}}</div>
                <div style="display: grid; grid-template-columns: 2fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div v-if="showTractionSheaveDetails==false && showDeliverRope==true">
                            <div style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                                <div>
                                    <div class="ZAfont_family ZAfont_size">{{$t('3038')}} | {{$t('1182')}}:</div>
                                </div>
                                <div v-if="tractionSheave_DiameterAndWidthSelection.length==0 || tractionSheave_DiameterAndWidthSelection.length>4">
                                    <select v-model="selectedTractionSheave_DiameterAndWidth" class="my_select" style="width:100%" v-bind:disabled="selectedTemperatureMonitoring==null || selectedTemperatureMonitoring==''" v-bind:class="{my_input_border_at_invalidInput: selectedTractionSheave_DiameterAndWidth==null, my_input_background_color_at_validInput:selectedTractionSheave_DiameterAndWidth!=null}">
                                        <option value="null" hidden>{{$t('3174')}}</option>
                                        <option value="" hidden>{{$t('3174')}}</option>
                                        <option v-for="diameterAndWidth in tractionSheave_DiameterAndWidthSelection" v-bind:value="diameterAndWidth">{{diameterAndWidth.text}}</option>
                                    </select>
                                </div>
                                <div v-if="tractionSheave_DiameterAndWidthSelection.length>0 && tractionSheave_DiameterAndWidthSelection.length<=4">
                                    <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedTractionSheave_DiameterAndWidth==null, my_input_background_color_at_validInput:selectedTractionSheave_DiameterAndWidth!=null}">
                                        <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                            <li v-for="diameterAndWidth in tractionSheave_DiameterAndWidthSelection">
                                                <input type="checkbox" @input="change_Selected_atElementOf_diameterAndWidth(diameterAndWidth)" v-bind:checked="diameterAndWidth.selected" v-bind:disabled="diameterAndWidth.selected" />
                                                <span style="margin-left:10px">{{diameterAndWidth.text}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3452')}} KID:</div>
                            </div>
                            <div v-if="customerIdKidSelection.length==0 || customerIdKidSelection.length>4">
                                <select v-model="selectedCustomerIdKid" class="my_select" style="width:100%" v-bind:disabled="selectedNumberOfGrooves==null || selectedNumberOfGrooves=='' || deliverRopeDisabled==true" v-bind:class="{my_input_border_at_invalidInput: customerIdKidSelection.length > 0 && selectedCustomerIdKid==null, my_input_background_color_at_validInput: customerIdKidSelection.length == 0 || selectedCustomerIdKid!=null}">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option value="" hidden>{{$t('3174')}}</option>
                                    <option v-for="customerIdKid in customerIdKidSelection" v-bind:value="customerIdKid">
                                        <span v-if="customerIdKid.text.useTextKey==null || customerIdKid.text.useTextKey==false">{{customerIdKid.text.textUI}}</span>
                                        <span v-if="customerIdKid.text.useTextKey!=null && customerIdKid.text.useTextKey==true">{{$t(customerIdKid.text.textKey)}}</span>
                                    </option>
                                </select>
                            </div>
                            <div v-if="customerIdKidSelection.length>0 && customerIdKidSelection.length<=4">
                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedCustomerIdKid==null, my_input_background_color_at_validInput:selectedCustomerIdKid!=null}">
                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                        <li v-for="customerIdKid in customerIdKidSelection">
                                            <input type="checkbox" @input="change_Selected_atElementOf_customerIdKid(customerIdKid)" v-bind:checked="customerIdKid.selected" v-bind:disabled="customerIdKid.selected || deliverRopeDisabled==true" />
                                            <span v-if="customerIdKid.text.useTextKey == false" style="margin-left:10px">{{customerIdKid.text.textUI}}</span>
                                            <span v-if="customerIdKid.text.useTextKey == true" style="margin-left:10px">{{$t(customerIdKid.text.textKey)}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3533')}} {{$t('3395')}}:</div>
                            </div>
                            <div v-if="deliverRopeSelection.length==0 || deliverRopeSelection.length>4">
                                <select v-model="selectedDeliverRope" class="my_select" style="width:100%" v-bind:disabled="selectedNumberOfGrooves==null || selectedNumberOfGrooves=='' || deliverRopeSelectionDisabled==true" v-bind:class="{my_input_border_at_invalidInput: selectedDeliverRope==null, my_input_background_color_at_validInput:selectedDeliverRope!=null}">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option value="" hidden>{{$t('3174')}}</option>
                                    <option v-for="deliverRope in deliverRopeSelection" v-bind:value="sheaveProfile">{{deliverRope.text.textUI}}</option>
                                </select>
                            </div>
                            <div v-if="deliverRopeSelection.length>0 && deliverRopeSelection.length<=4">
                                <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedDeliverRope==null, my_input_background_color_at_validInput:selectedDeliverRope!=null}">
                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                        <li v-for="deliverRope in deliverRopeSelection">
                                            <div>
                                                <input type="checkbox" @input="change_Selected_atElementOf_deliverRope(deliverRope)" v-bind:checked="deliverRope.selected" v-bind:disabled="deliverRope.selected || deliverRopeSelectionDisabled==true" />
                                                <span v-if="deliverRope.text.useTextKey == false" style="margin-left:10px">{{deliverRope.text.textUI}}</span>
                                                <span v-if="deliverRope.text.useTextKey == true" style="margin-left:10px">{{$t(deliverRope.text.textKey)}}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div v-if="tractionSheaveCoverIncluded==true" style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3534')}}:</div>
                            </div>
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3585')}}</div>
                            </div>
                        </div>
                        <div v-if="tractionSheaveCoverIncluded==false" style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3534')}}:</div>
                            </div>
                            <div v-if="tractionSheaveCoverSelection.length==0 || tractionSheaveCoverSelection.length>4">
                                <select v-model="selectedTractionSheaveCover" class="my_select" style="width:100%" v-bind:disabled="selectedNumberOfGrooves==null || selectedNumberOfGrooves.value==null || tractionSheaveCoverSelection.length || tractionSheaveCoverSelectionDisabled==true">
                                    <option v-for="tractionSheaveCover in tractionSheaveCoverSelection" v-bind:value="tractionSheaveCover">{{tractionSheaveCover.text.text}}</option>
                                </select>
                            </div>
                            <div v-if="tractionSheaveCoverSelection.length>0 && tractionSheaveCoverSelection.length<=4">
                                <div style="display: grid;">
                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                        <li v-for="tractionSheaveCover in tractionSheaveCoverSelection">
                                            <input type="checkbox" @input="change_Selected_atElementOf_tractionSheaveCover(tractionSheaveCover)" v-bind:checked="tractionSheaveCover.selected" v-bind:disabled="tractionSheaveCover.selected || tractionSheaveCoverSelectionDisabled==true" />
                                            <span style="margin-left:10px">{{tractionSheaveCover.text.text}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3535')}}:</div>
                            </div>
                            <div v-if="positionMotorSelection.length==0 || positionMotorSelection.length>4">
                                <select v-model="selectedPositionMotor" class="my_select" style="width:100%" v-bind:disabled="selectedCustomerIdKid==null || selectedCustomerIdKid=='' || deliverRopeDisabled==true" v-bind:class="{my_input_border_at_invalidInput: positionMotorSelection.length > 0 && selectedPositionMotor==null, my_input_background_color_at_validInput: positionMotorSelection.length==0 || selectedPositionMotor!=null}">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option value="" hidden>{{$t('3174')}}</option>
                                    <option v-for="positionMotor in positionMotorSelection" v-bind:value="positionMotor">
                                        <span v-if="positionMotor.text.useTextKey==null || positionMotor.text.useTextKey==false">{{positionMotor.text.textUI}}</span>
                                        <span v-if="positionMotor.text.useTextKey!=null && positionMotor.text.useTextKey==true">{{$t(positionMotor.text.textKey)}}</span>
                                    </option>
                                </select>
                            </div>
                            <div v-if="positionMotorSelection.length>0 && positionMotorSelection.length<=4">
                                <div style="display: grid;" v-bind:disabled="selectedCustomerIdKid==null || selectedCustomerIdKid=='' || deliverRopeDisabled==true" v-bind:class="{my_input_border_at_invalidInput: positionMotorSelection.length > 0 && selectedPositionMotor==null, my_input_background_color_at_validInput: positionMotorSelection.length==0 || selectedPositionMotor!=null}">
                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                        <li v-for="positionMotor in positionMotorSelection">
                                            <input type="checkbox" @input="change_Selected_atElementOf_positionMotor(positionMotor)" v-bind:checked="positionMotor.selected" v-bind:disabled="positionMotor.selected || deliverRopeDisabled==true" />
                                            <span v-if="positionMotor.text.useTextKey == false" style="margin-left:10px">{{positionMotor.text.textUI}}</span>
                                            <span v-if="positionMotor.text.useTextKey == true" style="margin-left:10px">{{$t(positionMotor.text.textKey)}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3536')}}:</div>
                            </div>
                            <div v-if="ropePullSelection.length==0 || ropePullSelection.length>4">
                                <select v-model="selectedRopePull" class="my_select" style="width:100%" v-bind:disabled="selectedPositionMotor==null || selectedPositionMotor=='' || deliverRopeDisabled==true" v-bind:class="{my_input_border_at_invalidInput: ropePullSelection.length > 0 && selectedRopePull==null, my_input_background_color_at_validInput: ropePullSelection.length==0 || selectedRopePull!=null}">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option value="" hidden>{{$t('3174')}}</option>
                                    <option v-for="ropePull in ropePullSelection" v-bind:value="ropePull">
                                        <span v-if="ropePull.text.useTextKey==null || ropePull.text.useTextKey==false">{{ropePull.text.textUI}}</span>
                                        <span v-if="ropePull.text.useTextKey!=null && ropePull.text.useTextKey==true">{{$t(ropePull.text.textKey)}}</span>
                                    </option>
                                </select>
                            </div>
                            <div v-if="ropePullSelection.length>0 && ropePullSelection.length<=4">
                                <div style="display: grid;" v-bind:disabled="selectedCustomerIdKid==null || selectedCustomerIdKid=='' || deliverRopeDisabled==true" v-bind:class="{my_input_border_at_invalidInput: ropePullSelection.length > 0 && selectedRopePull==null, my_input_background_color_at_validInput: ropePullSelection.length==0 || selectedRopePull!=null}">
                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                        <li v-for="ropePull in ropePullSelection">
                                            <input type="checkbox" @input="change_Selected_atElementOf_ropePull(ropePull)" v-bind:checked="ropePull.selected" v-bind:disabled="ropePull.selected || deliverRopeDisabled==true" />
                                            <span v-if="ropePull.text.useTextKey == false" style="margin-left:10px">{{ropePull.text.textUI}}</span>
                                            <span v-if="ropePull.text.useTextKey == true" style="margin-left:10px">{{$t(ropePull.text.textKey)}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3537')}}:</div>
                            </div>
                            <div v-if="adjustabilitySelection.length==0 || adjustabilitySelection.length>4">
                                <select v-model="selectedAdjustability" class="my_select" style="width:100%" v-bind:disabled="selectedRopePull==null || selectedRopePull=='' || deliverRopeDisabled==true" v-bind:class="{my_input_border_at_invalidInput: adjustabilitySelection.length > 0 && selectedAdjustability==null, my_input_background_color_at_validInput: adjustabilitySelection.length==0 || selectedAdjustability!=null}">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option value="" hidden>{{$t('3174')}}</option>
                                    <option v-for="adjustability in adjustabilitySelection" v-bind:value="sheaveProfile">{{adjustability.text.textUI}}</option>
                                </select>
                            </div>
                            <div v-if="adjustabilitySelection.length>0 && adjustabilitySelection.length<=4">
                                <div v-bind:class="{my_input_border_at_invalidInput: adjustabilitySelection.length > 0 && selectedAdjustability==null, my_input_background_color_at_validInput: adjustabilitySelection.length==0 || selectedAdjustability!=null}">
                                    <ul class="my_inputCheckbox2 ZAfont_family ZAfont_size">
                                        <li v-for="adjustability in adjustabilitySelection">
                                            <div>
                                                <input type="checkbox" @input="change_Selected_atElementOf_adjustability(adjustability)" v-bind:checked="adjustability.selected" v-bind:disabled="adjustability.selected || deliverRopeDisabled==true" />
                                                <span v-if="adjustability.text.useTextKey == false" style="margin-left:10px">{{adjustability.text.textUI}}</span>
                                                <span v-if="adjustability.text.useTextKey == true" style="margin-left:10px">{{$t(adjustability.text.textKey)}}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div v-if="deliverRopeDisabled==false" class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div>{{$t('3538')}}:</div>
                            </div>
                            <div>
                                <div v-if="thirdRopeGuard!=null">
                                    <span v-if="thirdRopeGuard.text.useTextKey==false">{{thirdRopeGuard.text.textUI}} </span>
                                    <span v-if="thirdRopeGuard.text.useTextKey==true">{{$t(thirdRopeGuard.text.textKey)}} </span>
                                </div>
                            </div>
                        </div>
                        <div v-if="tnruev!=null" class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div>{{$t('3586')}}:</div>
                            </div>
                            <div>
                                <div>{{tnruev}}</div>
                            </div>
                        </div>
                        <div style="display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                            <div>
                                <div class="ZAfont_family ZAfont_size">{{$t('3543')}}:</div>
                            </div>
                            <div v-if="ropeClampSelection.length==0 || ropeClampSelection.length>4">
                                <select v-model="selectedRopeClamp" class="my_select" style="width:100%" v-bind:disabled="selectedNumberOfGrooves==null || selectedNumberOfGrooves.value==null || ropeClampSelection.length==0 || ropeClampSelectionDisabled">
                                    <option v-for="ropeClamp in ropeClampSelection" v-bind:value="ropeClamp">{{ropeClamp.text.textUI}}</option>
                                </select>
                            </div>
                            <div v-if="ropeClampSelection.length>0 && ropeClampSelection.length<=4">
                                <div style="display: grid;">
                                    <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                        <li v-for="ropeClamp in ropeClampSelection">
                                            <input type="checkbox" @input="change_Selected_atElementOf_ropeClamp(ropeClamp)" v-bind:checked="ropeClamp.selected" v-bind:disabled="ropeClamp.selected || ropeClampSelectionDisabled" />
                                            <span style="margin-left:10px">{{ropeClamp.text.textUI}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="display:flex; justify-content: center; align-items: center">
                        <div v-if="imagePathRopeGuard!=null">
                            <Images :imageFilePath="imagePathRopeGuard" :maxImageHeight="250" :maxImageWidth="250"></Images>
                        </div>
                    </div>
                </div>
                <hr />
            </div>

            <div class="my_btn_nextConfigurationStep_position">
                <button @click="furtherToManualHome()" class="btn my_btn_nextConfigurationStep" style="margin-right: 5px">{{$t('3366')}}</button>
                <button @click="furtherToDriveTechnologies()" class="btn my_btn_nextConfigurationStep">{{$t('1023')}}</button>
            </div>
        </div>
    </div>
</template>


<script>
    import router from '../../../router';
    import { configurations } from '../../../store/configurations.module';
    import InvalidityReasons from '../../dataValidation/InvalidityReasons';
    import LoaderIcon from '../../loader/LoaderIcon';
    import axios from 'axios';
    import Images from '../../configuration/Images';

    export default {
        name: 'InputsManual',

        components: {
            InvalidityReasons,
            LoaderIcon,
            Images,
        },

        data() {
            return {
                accept_terms_of_request: false,
            }
        },

        computed: {
            cmd: {
                get() {
                    return this.$store.state.inputsManual.cmd;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_cmd_storeAction', value);
                }
            },
            note: {
                get() {
                    return this.$store.state.inputsManual.note;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_note_storeAction', value);
                }
            },
            projekt: {
                get() {
                    return this.$store.state.inputsManual.projekt;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_projekt_storeAction', value);
                }
            },
            sanum: {
                get() {
                    return this.$store.state.inputsManual.sanum;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_sanum_storeAction', value);
                }
            },
            onok: {
                get() {
                    return this.$store.state.inputsManual.onok.value;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_onok_storeAction', value);
                }
            },
            onok_ValidationResult: {
                get() {
                    return this.$store.state.inputsManual.onok;
                },
            },
            inok: {
                get() {
                    return this.$store.state.inputsManual.inok;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_inok_storeAction', value);
                }
            },
            mvlift: {
                get() {
                    return this.$store.state.inputsManual.mvlift.value;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_mvlift_storeAction', value);
                }
            },
            mvlift_ValidationResult: {
                get() {
                    return this.$store.state.inputsManual.mvlift;
                },
            },
            mis: {
                get() {
                    return this.$store.state.inputsManual.mis.value;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_mis_storeAction', value);
                }
            },
            mis_ValidationResult: {
                get() {
                    return this.$store.state.inputsManual.mis;
                },
            },
            customerNameSelection: {
                get() {
                    return this.$store.state.inputsManual.customerNameSelection;
                },
            },

            // Preselection motor
            motorTypeSelection: {
                get() {
                    return this.$store.state.inputsManual.motorTypeSelection;
                },
            },
            motorSizeSelection: {
                get() {
                    return this.$store.state.inputsManual.motorSizeSelection;
                },
            },
            motorPackageSelection: {
                get() {
                    return this.$store.state.inputsManual.motorPackageSelection;
                },
            },
            motorZusatzSelection: {
                get() {
                    if (this.$store.state.inputsManual.motorZusatzSelection != null && this.$store.state.inputsManual.motorZusatzSelection.hasOwnProperty('textSelection') && this.$store.state.inputsManual.motorZusatzSelection.textSelection.length > 0) {
                        return this.$store.state.inputsManual.motorZusatzSelection.textSelection;
                    }
                    else {
                        return null;
                    }
                },
            },
            motorZusatzNecessary: {
                get() {
                    if (this.$store.state.inputsManual.motorZusatzSelection != null && this.$store.state.inputsManual.motorZusatzSelection.hasOwnProperty('necessary')) {
                        return this.$store.state.inputsManual.motorZusatzSelection.necessary;
                    }
                    else {
                        return false;
                    }
                },
            },
            motorVoltageSelection: {
                get() {
                    return this.$store.state.inputsManual.motorVoltageSelection;
                },
            },
            motorRatedSpeedSelection: {
                get() {
                    return this.$store.state.inputsManual.motorRatedSpeedSelection;
                },
            },
            certificationSelection: {
                get() {
                    return this.$store.state.inputsManual.certificationSelection;
                },
            },
            preferenceSelection: {
                get() {
                    return this.$store.state.inputsManual.preferenceSelection;
                },
            },
            temperatureMonitoringSelection: {
                get() {
                    return this.$store.state.inputsManual.temperatureMonitoringSelection;
                },
            },
            forcedVentilationSelection: {
                get() {
                    return this.$store.state.inputsManual.forcedVentilationSelection;
                },
            },
            upgradeSelection: {
                get() {
                    return this.$store.state.inputsManual.upgradeSelection;
                },
            },
            selectedCustomerName: {
                get() {
                    if (this.$store.state.inputsManual.customerNameSelection != null && Array.isArray(this.$store.state.inputsManual.customerNameSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.customerNameSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.customerNameSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_customerName(value);
                }
            },
            customerName: {
                get() {
                    if (this.$store.state.inputsManual.customerNameSelection != null && Array.isArray(this.$store.state.inputsManual.customerNameSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.customerNameSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.customerNameSelection[firstIndex_whichMeetConditions].text.text;
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
            },
            motorTypeSelectionLength: {
                get() {
                    if (this.$store.state.inputsManual.motorTypeSelection != null && Array.isArray(this.$store.state.inputsManual.motorTypeSelection)) {
                        let length = this.$store.state.inputsManual.motorTypeSelection.filter((x) => { return (x.disabled == false) }).length;
                        if (length != null && length > 0) {
                            return length;
                        }
                        else {
                            return 0;
                        }
                    }
                    else {
                        return 0;
                    }
                }
            },
            motorSizeSelectionLength: {
                get() {
                    if (this.$store.state.inputsManual.motorSizeSelection != null && Array.isArray(this.$store.state.inputsManual.motorSizeSelection)) {
                        let length = this.$store.state.inputsManual.motorSizeSelection.filter((x) => { return (x.disabled == false) }).length;
                        if (length != null && length > 0) {
                            return length;
                        }
                        else {
                            return 0;
                        }
                    }
                    else {
                        return 0;
                    }
                }
            },
            motorPackageSelectionLength: {
                get() {
                    if (this.$store.state.inputsManual.motorPackageSelection != null && Array.isArray(this.$store.state.inputsManual.motorPackageSelection)) {
                        let length = this.$store.state.inputsManual.motorPackageSelection.filter((x) => { return (x.disabled == false) }).length;
                        if (length != null && length > 0) {
                            return length;
                        }
                        else {
                            return 0;
                        }
                    }
                    else {
                        return 0;
                    }
                }
            },
            motorZusatzSelectionLength: {
                get() {
                    if (this.$store.state.inputsManual.motorZusatzSelection != null && Array.isArray(this.$store.state.inputsManual.motorZusatzSelection)) {
                        let length = this.$store.state.inputsManual.motorZusatzSelection.filter((x) => { return (x.disabled == false) }).length;
                        if (length != null && length > 0) {
                            return length;
                        }
                        else {
                            return 0;
                        }
                    }
                    else {
                        return 0;
                    }
                }
            },
            motorVoltageSelectionLength: {
                get() {
                    if (this.$store.state.inputsManual.motorVoltageSelection != null && Array.isArray(this.$store.state.inputsManual.motorVoltageSelection)) {
                        let length = this.$store.state.inputsManual.motorVoltageSelection.filter((x) => { return (x.disabled == false) }).length;
                        if (length != null && length > 0) {
                            return length;
                        }
                        else {
                            return 0;
                        }
                    }
                    else {
                        return 0;
                    }
                }
            },
            motorRatedSpeedSelectionLength: {
                get() {
                    if (this.$store.state.inputsManual.motorRatedSpedSelection != null && Array.isArray(this.$store.state.inputsManual.motorRatedSpedSelection)) {
                        let length = this.$store.state.inputsManual.motorRatedSpedSelection.filter((x) => { return (x.disabled == false) }).length;
                        if (length != null && length > 0) {
                            return length;
                        }
                        else {
                            return 0;
                        }
                    }
                    else {
                        return 0;
                    }
                }
            },
            selectedMotorType: {
                get() {
                    if (this.$store.state.inputsManual.motorTypeSelection != null && Array.isArray(this.$store.state.inputsManual.motorTypeSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.motorTypeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.motorTypeSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorType(value);
                }
            },
            selectedMotorSize: {
                get() {
                    if (this.$store.state.inputsManual.motorSizeSelection != null && Array.isArray(this.$store.state.inputsManual.motorSizeSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.motorSizeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.motorSizeSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorSize(value);
                }
            },
            selectedMotorPackage: {
                get() {
                    if (this.$store.state.inputsManual.motorPackageSelection != null && Array.isArray(this.$store.state.inputsManual.motorPackageSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.motorPackageSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.motorPackageSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorPackage(value);
                }
            },
            selectedMotorZusatz: {
                get() {
                    if (this.$store.state.inputsManual.motorZusatzSelection != null && this.$store.state.inputsManual.motorZusatzSelection.hasOwnProperty('textSelection') && Array.isArray(this.$store.state.inputsManual.motorZusatzSelection.textSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.motorZusatzSelection.textSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.motorZusatzSelection.textSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorPackage(value);
                }
            },
            selectedMotorVoltage: {
                get() {
                    if (this.$store.state.inputsManual.motorVoltageSelection != null && Array.isArray(this.$store.state.inputsManual.motorVoltageSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.motorVoltageSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.motorVoltageSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorVoltage(value);
                }
            },
            selectedMotorRatedSpeed: {
                get() {
                    if (this.$store.state.inputsManual.motorRatedSpeedSelection != null && Array.isArray(this.$store.state.inputsManual.motorRatedSpeedSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.motorRatedSpeedSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.motorRatedSpeedSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorRatedSpeed(value);
                }
            },
            selectedCertification: {
                get() {
                    if (this.$store.state.inputsManual.certificationSelection != null && Array.isArray(this.$store.state.inputsManual.certificationSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.certificationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.certificationSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_certification(value);
                }
            },
            selectedPreference: {
                get() {
                    if (this.$store.state.inputsManual.preferenceSelection != null && Array.isArray(this.$store.state.inputsManual.preferenceSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.preferenceSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.preferenceSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_preference(value);
                }
            },
            selectedTemperatureMonitoring: {
                get() {
                    if (this.$store.state.inputsManual.temperatureMonitoringSelection != null && Array.isArray(this.$store.state.inputsManual.temperatureMonitoringSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.temperatureMonitoringSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.temperatureMonitoringSelection[firstIndex_whichMeetConditions].text;
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_temperatureMonitoring(value);
                }
            },
            selectedUpgrade: {
                get() {
                    if (this.$store.state.inputsManual.upgradeSelection != null && Array.isArray(this.$store.state.inputsManual.upgradeSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.upgradeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.upgradeSelection[firstIndex_whichMeetConditions].text;
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_upgradeSelection(value);
                }
            },
            selectedForcedVentilation: {
                get() {
                    if (this.$store.state.inputsManual.forcedVentilationSelection != null && Array.isArray(this.$store.state.inputsManual.forcedVentilationSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.forcedVentilationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.forcedVentilationSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_forcedVentilation(value);
                }
            },
            selectedForcedVentilationWithBrakeRelease: {
                get() {
                    if (this.$store.state.inputsManual.forcedVentilationSelection != null && Array.isArray(this.$store.state.inputsManual.forcedVentilationSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.forcedVentilationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.forcedVentilationSelection[firstIndex_whichMeetConditions].canBeCombinedWithBrakeRelease;
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
            },
            operatingCurrentSplus: {
                get() {
                    return this.$store.state.inputsManual.operatingCurrentSplus.value;
                },
                set(value) {
                    this.$store.dispatch('inputsManual/change_operatingCurrentSplus_storeAction', value);
                }
            },
            operatingCurrentSplus_ValidationResult: {
                get() {
                    return this.$store.state.inputsManual.operatingCurrentSplus;
                },
            },

            // Preselection traction sheave
            deliverTractionSheaveSelection: {
                get() {
                    return this.$store.state.inputsManual.deliverTractionSheaveSelection;
                },
            },
            selectedDeliverTractionSheave: {
                get() {
                    if (this.$store.state.inputsManual.deliverTractionSheaveSelection != null && Array.isArray(this.$store.state.inputsManual.deliverTractionSheaveSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.deliverTractionSheaveSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.deliverTractionSheaveSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_deliverTractionSheave(value);
                }
            },
            showTractionSheaveDetails: {
                get() {
                    return this.$store.state.inputsManual.showTractionSheaveDetails;
                },
            },
            machiningForHandWheelSelection: {
                get() {
                    return this.$store.state.inputsManual.machiningForHandWheelSelection;
                },
            },
            selectedMachiningForHandWheel: {
                get() {
                    if (this.$store.state.inputsManual.machiningForHandWheelSelection != null && Array.isArray(this.$store.state.inputsManual.machiningForHandWheelSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.machiningForHandWheelSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.machiningForHandWheelSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_machiningForHandWheel(value);
                }
            },

            tractionSheave_DiameterAndWidthSelection: {
                get() {
                    return this.$store.state.inputsManual.tractionSheave_DiameterAndWidthSelection;
                },
            },
            selectedTractionSheave_DiameterAndWidth: {
                get() {
                    if (this.$store.state.inputsManual.tractionSheave_DiameterAndWidthSelection != null && Array.isArray(this.$store.state.inputsManual.tractionSheave_DiameterAndWidthSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.tractionSheave_DiameterAndWidthSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.tractionSheave_DiameterAndWidthSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_diameterAndWidth(value);
                }
            },
            sheaveProfileSelection: {
                get() {
                    return this.$store.state.inputsManual.sheaveProfileSelection;
                },
            },
            selectedSheaveProfile: {
                get() {
                    if (this.$store.state.inputsManual.sheaveProfileSelection != null && Array.isArray(this.$store.state.inputsManual.sheaveProfileSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.sheaveProfileSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.sheaveProfileSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_sheaveProfile(value);
                },
            },
            ropeDiameterSelection: {
                get() {
                    return this.$store.state.inputsManual.ropeDiameterSelection;
                },
            },
            ropeDiameterSelectionLength: {
                get() {
                    if (this.$store.state.inputsManual.ropeDiameterSelection != null && Array.isArray(this.$store.state.inputsManual.ropeDiameterSelection)) {
                        let length = this.$store.state.inputsManual.ropeDiameterSelection.filter((x) => { return (x.disabled == false) }).length;
                        if (length != null && length > 0) {
                            return length;
                        }
                        else {
                            return 0;
                        }
                    }
                    else {
                        return 0;
                    }
                }
            },
            selectedRopeDiameter: {
                get() {
                    if (this.$store.state.inputsManual.ropeDiameterSelection != null && Array.isArray(this.$store.state.inputsManual.ropeDiameterSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.ropeDiameterSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.ropeDiameterSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_ropeDiameter(value);
                }
            },
            undercutAngleSelection: {
                get() {
                    return this.$store.state.inputsManual.undercutAngleSelection;
                },
            },
            undercutAngleSelectionDisabled: {
                get() {
                    if (this.$store.state.inputsManual.undercutAngleSelection == null && this.$store.state.inputsManual.undercutAngleSelection.length < 1) {
                        return true;
                    }
                    else {
                        return false;
                    }
                },
            },
            undercutAngleSelectionLength: {
                get() {
                    if (this.$store.state.inputsManual.undercutAngleSelection != null && Array.isArray(this.$store.state.inputsManual.undercutAngleSelection)) {
                        let length = this.$store.state.inputsManual.undercutAngleSelection.filter((x) => { return (x.disabled == false) }).length;
                        if (length != null && length > 0) {
                            return length;
                        }
                        else {
                            return 0;
                        }
                    }
                    else {
                        return 0;
                    }
                }
            },
            selectedUndercutAngle: {
                get() {
                    if (this.$store.state.inputsManual.undercutAngleSelection != null && Array.isArray(this.$store.state.inputsManual.undercutAngleSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.undercutAngleSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.undercutAngleSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_tractionSheaveAngleUndercut(value);
                },
            },
            vgrooveAngleSelection: {
                get() {
                    return this.$store.state.inputsManual.vgrooveAngleSelection;
                },
            },
            vgrooveAngleSelectionDisabled: {
                get() {
                    if (this.$store.state.inputsManual.vgrooveAngleSelection != null && this.$store.state.inputsManual.vgrooveAngleSelection.length < 1) {
                        return true;
                    }
                    else {
                        return false;
                    }
                },
            },
            vgrooveAngleSelectionLength: {
                get() {
                    if (this.$store.state.inputsManual.vgrooveAngleSelection != null && Array.isArray(this.$store.state.inputsManual.vgrooveAngleSelection)) {
                        let length = this.$store.state.inputsManual.vgrooveAngleSelection.filter((x) => { return (x.disabled == false) }).length;
                        if (length != null && length > 0) {
                            return length;
                        }
                        else {
                            return 0;
                        }
                    }
                    else {
                        return 0;
                    }
                }
            },
            selectedVgrooveAngle: {
                get() {
                    if (this.$store.state.inputsManual.vgrooveAngleSelection != null && Array.isArray(this.$store.state.inputsManual.vgrooveAngleSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.vgrooveAngleSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.vgrooveAngleSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_tractionSheaveAngleVgroove(value);
                },
            },
            grooveDistanceSelection: {
                get() {
                    return this.$store.state.inputsManual.grooveDistanceSelection;
                },
            },
            grooveDistanceSelectionLength: {
                get() {
                    if (this.$store.state.inputsManual.grooveDistanceSelection != null && Array.isArray(this.$store.state.inputsManual.grooveDistanceSelection)) {
                        let length = this.$store.state.inputsManual.grooveDistanceSelection.filter((x) => { return (x.disabled == false) }).length;
                        if (length != null && length > 0) {
                            return length;
                        }
                        else {
                            return 0;
                        }
                    }
                    else {
                        return 0;
                    }
                }
            },
            selectedGrooveDistance: {
                get() {
                    if (this.$store.state.inputsManual.grooveDistanceSelection != null && Array.isArray(this.$store.state.inputsManual.grooveDistanceSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.grooveDistanceSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.grooveDistanceSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_grooveDistance(value);
                }
            },
            numberOfGroovesSelection: {
                get() {
                    return this.$store.state.inputsManual.numberOfGroovesSelection;
                },
            },
            numberOfGroovesSelectionLength: {
                get() {
                    if (this.$store.state.inputsManual.numberOfGroovesSelection != null && Array.isArray(this.$store.state.inputsManual.numberOfGroovesSelection)) {
                        let length = this.$store.state.inputsManual.numberOfGroovesSelection.filter((x) => { return (x.disabled == false) }).length;
                        if (length != null && length > 0) {
                            return length;
                        }
                        else {
                            return 0;
                        }
                    }
                    else {
                        return 0;
                    }
                }
            },
            selectedNumberOfGrooves: {
                get() {
                    if (this.$store.state.inputsManual.numberOfGroovesSelection != null && Array.isArray(this.$store.state.inputsManual.numberOfGroovesSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.numberOfGroovesSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.numberOfGroovesSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_numberOfGrooves(value);
                }
            },
            // rope guard
            customerIdKidSelection: {
                get() {
                    return this.$store.state.inputsManual.customerIdKidSelection;
                },
            },
            selectedCustomerIdKid: {
                get() {
                    if (this.$store.state.inputsManual.customerIdKidSelection != null && Array.isArray(this.$store.state.inputsManual.customerIdKidSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.customerIdKidSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.customerIdKidSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_customerIdKid(value);
                }
            },
            positionMotorSelection: {
                get() {
                    return this.$store.state.inputsManual.positionMotorSelection;
                },
            },
            selectedPositionMotor: {
                get() {
                    if (this.$store.state.inputsManual.positionMotorSelection != null && Array.isArray(this.$store.state.inputsManual.positionMotorSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.positionMotorSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.positionMotorSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                }
                ,
                set(value) {
                    this.change_Selected_atElementOf_positionMotor(value);
                }
            },
            ropePullSelection: {
                get() {
                    return this.$store.state.inputsManual.ropePullSelection;
                },
            },
            selectedRopePull: {
                get() {
                    if (this.$store.state.inputsManual.ropePullSelection != null && Array.isArray(this.$store.state.inputsManual.ropePullSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.ropePullSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.ropePullSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_ropePull(value);
                }
            },
            adjustabilitySelection: {
                get() {
                    return this.$store.state.inputsManual.adjustabilitySelection;
                },
            },
            selectedAdjustability: {
                get() {
                    if (this.$store.state.inputsManual.adjustabilitySelection != null && Array.isArray(this.$store.state.inputsManual.adjustabilitySelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.adjustabilitySelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.adjustabilitySelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_adjustability(value);
                }
            },
            thirdRopeGuard: {
                get() {
                    if (this.$store.state.inputsManual.thirdRopeGuard != null && this.$store.state.inputsManual.thirdRopeGuard.hasOwnProperty('text')) {
                        return this.$store.state.inputsManual.thirdRopeGuard;
                    }
                    else {
                        return null;
                    }
                },
            },
            tnruev: {
                get() {
                    return this.$store.state.inputsManual.tnruev;
                },

            },
            deliverRopeSelection: {
                get() {
                    return this.$store.state.inputsManual.deliverRopeSelection;
                },
            },
            selectedDeliverRope: {
                get() {
                    if (this.$store.state.inputsManual.deliverRopeSelection != null && Array.isArray(this.$store.state.inputsManual.deliverRopeSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.deliverRopeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.deliverRopeSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_deliverRope(value);
                }
            },
            showDeliverRope: {
                get() {
                    if (this.$store.state.inputsManual.deliverRopeSelection != null && Array.isArray(this.$store.state.inputsManual.deliverRopeSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.deliverRopeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            if (this.$store.state.inputsManual.deliverRopeSelection[firstIndex_whichMeetConditions].text.code == 0) {
                                return false;
                            }
                        }
                    }
                    return true;
                },
                set(value) {
                    this.change_Selected_atElementOf_deliverRope(value);
                }
            },
            deliverRopeSelectionDisabled: {
                get() {
                    if (this.$store.state.inputsManual.deliverRopeSelection != null && this.$store.state.inputsManual.deliverRopeSelection.length > 0 && this.$store.state.inputsManual.tractionSheaveCoverSelection != null && this.$store.state.inputsManual.tractionSheaveCoverSelection.hasOwnProperty('disabled')) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.tractionSheaveCoverSelection.selection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            if (this.$store.state.inputsManual.tractionSheaveCoverSelection.selection[firstIndex_whichMeetConditions].text.text != null && this.$store.state.inputsManual.tractionSheaveCoverSelection.selection[firstIndex_whichMeetConditions].text.text != 'ohne') {
                                if (this.$store.state.inputsManual.tractionSheaveCoverSelection.additionalCover == false) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    }
                    else {
                        return false;
                    }
                },
            },
            tractionSheaveCoverIncluded: {
                get() {
                    if (this.$store.state.inputsManual.tractionSheaveCoverSelection != null && this.$store.state.inputsManual.tractionSheaveCoverSelection.hasOwnProperty('coverIncluded')) {
                        return this.$store.state.inputsManual.tractionSheaveCoverSelection.coverIncluded;
                    }
                    else {
                        return false;
                    }
                },
            },
            tractionSheaveCoverSelection: {
                get() {
                    if (this.$store.state.inputsManual.tractionSheaveCoverSelection != null && this.$store.state.inputsManual.tractionSheaveCoverSelection.hasOwnProperty('selection') && Array.isArray(this.$store.state.inputsManual.tractionSheaveCoverSelection.selection)) {
                        return this.$store.state.inputsManual.tractionSheaveCoverSelection.selection;
                    }
                    else {
                        return [];
                    }
                },
            },
            selectedTractionSheaveCover: {
                get() {
                    if (this.$store.state.inputsManual.tractionSheaveCoverSelection != null && this.$store.state.inputsManual.tractionSheaveCoverSelection.hasOwnProperty('selection') && Array.isArray(this.$store.state.inputsManual.tractionSheaveCoverSelection.selection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.tractionSheaveCoverSelection.selection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.tractionSheaveCoverSelection.selection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_tractionSheaveCover(value);
                }
            },
            tractionSheaveCoverSelectionDisabled: {
                get() {
                    if (this.$store.state.inputsManual.tractionSheaveCoverSelection != null && this.$store.state.inputsManual.tractionSheaveCoverSelection.hasOwnProperty('disabled')) {
                        if (this.$store.state.inputsManual.deliverRopeSelection != null && this.$store.state.inputsManual.deliverRopeSelection.length > 0) {
                            const firstIndex_whichMeetConditions = this.$store.state.inputsManual.deliverRopeSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                if (this.$store.state.inputsManual.deliverRopeSelection[firstIndex_whichMeetConditions].text.text != null && this.$store.state.inputsManual.deliverRopeSelection[firstIndex_whichMeetConditions].text.text != 'ohne') {
                                    if (this.$store.state.inputsManual.tractionSheaveCoverSelection.additionalCover == false) {
                                        return true;
                                    }
                                }
                                else {
                                    if (this.$store.state.inputsManual.tractionSheaveCoverSelection.additionalCover == true && this.$store.state.inputsManual.tractionSheaveCoverSelection.coverInsteadOfRopeGuard == false) {
                                        this.change_Selected_atElementOf_tractionSheaveCover(this.$store.state.inputsManual.deliverRopeSelection[0]);
                                        return true;
                                    }
                                }
                            }
                        }
                        return this.$store.state.inputsManual.tractionSheaveCoverSelection.disabled;
                    }
                    else {
                        return true;
                    }
                },
            },
            ropeClampSelection: {
                get() {
                    if (this.$store.state.inputsManual.ropeClampSelection != null && this.$store.state.inputsManual.ropeClampSelection.hasOwnProperty('selection') && Array.isArray(this.$store.state.inputsManual.ropeClampSelection.selection)) {
                        return this.$store.state.inputsManual.ropeClampSelection.selection;
                    }
                    else {
                        return [];
                    }
                },
            },
            selectedRopeClamp: {
                get() {
                    if (this.$store.state.inputsManual.ropeClampSelection != null && this.$store.state.inputsManual.ropeClampSelection.hasOwnProperty('selection') && Array.isArray(this.$store.state.inputsManual.ropeClampSelection.selection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.inputsManual.ropeClampSelection.selection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.inputsManual.ropeClampSelection.selection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_ropeClamp(value);
                }
            },
            ropeClampSelectionDisabled: {
                get() {
                    if (this.$store.state.inputsManual.ropeClampSelection != null && this.$store.state.inputsManual.ropeClampSelection.hasOwnProperty('disabled')) {
                        return this.$store.state.inputsManual.ropeClampSelection.disabled;
                    }
                    else {
                        return true;
                    }
                },
            },
            deliverRopeDisabled: {
                get() {
                    return this.$store.state.inputsManual.deliverRopeDisabled;
                }
            },
            SM250_TS280: {
                get() {
                    return this.$store.state.inputsManual.sM250_TS280;
                },
                set(value) {
                    this.change_Selected_SM250_TS280(value);
                }
            },
            imagePathRopeGuard: {
                get() {
                    return this.$store.state.inputsManual.imagePathRopeGuard;
                }
            },
            statusModal_Shown: {
                get() {
                    return this.$store.state.statusHandling.statusModal_Shown;
                },
            },
        },

        created() {

            if (this.$store.state.configurations.MANUAL == false) {
                this.$store.commit('configurations/MANU_configuration', true);
                this.$store.commit('configurations/change_elevatorMotor_Manual_storeMutation', true);
            }

            this.$store.dispatch('psgMenu/check_SCHMOD_Menu_storeAction', this.$store.state.inputsManual.configurationStepsManualId);

            if (this.$store.state.inputsManual.motorTypeSelection != null && Array.isArray(this.$store.state.inputsManual.motorTypeSelection) && this.$store.state.inputsManual.motorTypeSelection.length > 0) {
                //if (this.$store.state.inputsManual.motorManualData != null && this.$store.state.inputsManual.motorManualData.preselectionMotorManual != null && this.$store.state.inputsManual.motorManualData.preselectionMotorManual.mN_MOT != null) {
                //    this.$store.dispatch('inputsManual/load_inputsManualDatabase_storeAction');
                //}
            }
            else {
                this.$store.dispatch('inputsManual/set_inputsManual_storeAction');
            }
        },

        methods: {
            ValueDecimalSeparator(value) {
                if (value != null) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                }
                return value;
            },
            SM500() {

            },
            change_Selected_SM250_TS280(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_SM250_TS280_storeAction', value);
            },
            change_Selected_atElementOf_upgradeSelection(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_upgradeSelected_storeAction', value);
            },
            change_Selected_atElementOf_customerName(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_customerName_storeAction', value);
            },
            change_Selected_atElementOf_motorType(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_motorType_storeAction', value);
            },
            change_Selected_atElementOf_motorSize(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_motorSize_storeAction', value);
            },
            change_Selected_atElementOf_motorPackage(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_motorPackage_storeAction', value);
            },
            change_Selected_atElementOf_motorZusatz(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_motorZusatz_storeAction', value);
            },
            change_Selected_atElementOf_motorVoltage(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_motorVoltage_storeAction', value);
            },
            change_Selected_atElementOf_motorRatedSpeed(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_motorRatedSpeed_storeAction', value);
            },
            change_Selected_atElementOf_certification(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_certification_storeAction', value);
            },
            change_Selected_atElementOf_preference(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_preference_storeAction', value);
            },
            change_Selected_atElementOf_temperatureMonitoring(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_temperatureMonitoring_storeAction', value);
            },
            change_Selected_atElementOf_forcedVentilation(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_forcedVentilation_storeAction', value);
            },

            // traction sheave
            change_Selected_atElementOf_deliverTractionSheave(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_deliverTractionSheave_storeAction', value);
            },
            change_Selected_atElementOf_machiningForHandWheel(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_machiningForHandWheel_storeAction', value);
            },
            change_Selected_atElementOf_diameterAndWidth(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_tractionSheave_DiameterAndWidth_storeAction', value);
            },
            change_Selected_atElementOf_ropeDiameter(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_ropeDiameter_storeAction', value);
            },
            change_Selected_atElementOf_grooveDistance(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_grooveDistance_storeAction', value);
            },
            change_Selected_atElementOf_numberOfGrooves(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_numberOfGrooves_storeAction', value);
            },
            change_Selected_atElementOf_sheaveProfile(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_sheaveProfile_storeAction', value);
            },
            change_Selected_atElementOf_tractionSheaveAngleVgroove(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_vgrooveAngle_storeAction', value);
            },
            change_Selected_atElementOf_tractionSheaveAngleUndercut(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_undercutAngle_storeAction', value);
            },

            // Rope guard
            change_Selected_atElementOf_customerIdKid(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_customerIdKid_storeAction', value);
            },
            change_Selected_atElementOf_positionMotor(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_positionMotor_storeAction', value);
            },
            change_Selected_atElementOf_ropePull(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_ropePull_storeAction', value);
            },
            change_Selected_atElementOf_adjustability(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_adjustability_storeAction', value);
            },
            change_Selected_atElementOf_deliverRope(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_deliverRope_storeAction', value);
            },
            change_Selected_atElementOf_tractionSheaveCover(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_tractionSheaveCover_storeAction', value);
            },
            change_Selected_atElementOf_ropeClamp(value) {
                this.$store.dispatch('inputsManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('inputsManual/change_ropeClamp_storeAction', value);
            },

            furtherToDriveTechnologies() {
                this.$store.dispatch('inputsManual/checkIf_inputsManual_areValid_storeAction');
            },
            furtherToManualHome() {
                this.$store.dispatch('inputsManual/cancelManualMotorConfiguration_storeAction')
            },

        },
    }
</script>


<style>
    .my_inputCheckbox2 {
        border: 1px solid #ced2d8;
        list-style: none;
        padding-top: 5px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        margin: 0;
    }

        .my_inputCheckbox2 li {
            padding: 3px;
            /*margin-bottom: 3px;*/
        }

    .my_inputCheckbox {
        border: 1px solid #ced2d8;
        list-style: none;
        padding-top: 5px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        margin: 0;
    }

        .my_inputCheckbox li {
            display: inline-block;
            padding: 3px;
            /*margin-bottom: 3px;*/
        }

            .my_inputCheckbox li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_inputCheckbox li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }
</style>
